<div class="layout-topbar">
  <div class="topbar-start">
    <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
      <i class="pi pi-bars"></i>
    </button>
    <app-breadcrumb class="topbar-breadcrumb" />
  </div>

  <div class="topbar-end flex gap-3">
    <div class="badge flex justify-content-center align-items-center">
      <p-button
        label="Notifications"
        icon="pi pi-bell"
        [badge]="notificationCount()"
        badgeClass="p-badge-contrast"
        (click)="op1.toggle($event)"
      />
      <p-overlayPanel #op1 [style]="{ maxHeight: '500px', overflow: 'auto' }">
        @if (!notifications().length) {
          <p>There are no notifications</p>
        } @else {
          @for (notification of notifications(); track notification.id) {
            <p [style]="{ cursor: 'pointer' }">{{ notification.data.title }}</p>
          }
        }
      </p-overlayPanel>
    </div>
    <p-button
      [outlined]="true"
      [iconPos]="'left'"
      icon="pi pi-user"
      [label]="authStore?.user()?.full_name"
      (click)="onProfileButtonClick()"
    />
  </div>
</div>
