import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';

import { FormGroupValue } from '../../models/FormGroup';
import { User } from '../../models/User';
// eslint-disable-next-line max-len
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { UserPerrmissionsModalComponent } from '../user-permissions-modal/user-permissions-modal.component';

@Component({
  selector: 'app-user-table-list',
  imports: [
    TableModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextModule,
    EditUserDialogComponent,
    ProgressBarModule,
    RouterLink,
    CommonModule,
    SkeletonModule
  ],
  providers: [ConfirmationService, DialogService],
  standalone: true,
  templateUrl: './user-table-list.component.html'
})
export class UserTableListComponent {
  confirmationService = inject(ConfirmationService);
  dialogService = inject(DialogService);

  @Input() users: User[] = [];
  @Input() totalRecords = 0;
  @Input() loading = false;
  @Input() rows = 10;
  @Input() globalFilterFields: string[] = [];
  @Input() companyUsers = true;
  @Output() lazyLoadUsers: EventEmitter<TableLazyLoadEvent> = new EventEmitter();
  @Output() deleteUserEmmiter: EventEmitter<number> = new EventEmitter();
  @Output() editUserEmitter: EventEmitter<{
    formData: FormGroupValue;
    id: number;
  }> = new EventEmitter<{ formData: FormGroupValue; id: number }>();

  loadingRows = Array(this.rows)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });

  userForEdit: User;
  editUserDialog = false;
  dialogRef: DynamicDialogRef | undefined;

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  openDeleteModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: !this.companyUsers
        ? 'Are you sure that you want to delete this user? This will delete all his companies and stores'
        : 'Are you sure that you want to remove this user from company?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteUserEmmiter.emit(id);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  editUser(user: User) {
    this.userForEdit = { ...user };
    this.editUserDialog = true;
  }

  hideDialog() {
    this.editUserDialog = false;
  }

  emitEditUser(formData: FormGroupValue) {
    this.editUserEmitter.emit({
      formData,
      id: this.userForEdit.id
    });
  }

  openUserInviteDialog() {
    this.dialogRef = this.dialogService.open(UserPerrmissionsModalComponent, {
      header: 'Invite new user to SSM platform',
      width: '540px'
    });
  }
}
