<div class="card">
  <div class="grid grid-nogutter">
    <div class="col-6">
      @if (library && storefrontLibraryFolders) {
        <app-library-file-uploader
          [storefrontLibraryFolders]="storefrontLibraryFolders"
          [editMode]="true"
          [libraryFile]="library"
          (formSubmitted)="onSubmit($event)"
          (additionaFileChenged)="updateAdditionaFile($event)"
          [showRequestPrepButton]="!library.preparation_request"
          [showAdditionalFiles]="account?.role?.name === UserRoleNames.SuperAdmin"
        />
      }
    </div>
  </div>
</div>
