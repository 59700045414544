import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription } from 'rxjs';

import { InviteService } from 'app/_shared/services/invite.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-request-invite-modal',
  standalone: true,
  imports: [InputTextModule, ButtonModule, ReactiveFormsModule],
  templateUrl: './request-invite-modal.component.html'
})
export class RequestInviteModalComponent implements OnInit, OnDestroy {
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);
  toastService = inject(ToastService);
  inviteService = inject(InviteService);
  fb = inject(FormBuilder);

  form: FormGroup;

  submitted = false;
  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.submitted = true;
      this.toastService.error('Please enter a valid email');
      return;
    }

    this.requestInvite();
  }

  requestInvite() {
    this.subscriptions.push(
      this.inviteService.requestInvite(this.form.value).subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: err => {
          console.error('Operation failed:', err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
