import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthStore } from '../../_store/auth.store';

export const superAdminGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authStore = inject(AuthStore);

  if (authStore.user() && authStore.isSuperAdmin()) {
    return true;
  }

  router.navigateByUrl('/auth/login');
  return false;
};
