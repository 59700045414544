import { Injectable } from '@angular/core';

import { FormGroupValue } from '../models/FormGroup';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PaymentService extends ApiService {
  routePrefix = `${this.apiUrl}company-payment-methods/`;

  public getCompanyPaymentMethods(companyId: number) {
    return this.get(this.routePrefix + companyId);
  }

  public generateSecret(companyId: number) {
    return this.get(`${this.routePrefix}${companyId}/setup-intent/generate-secret`);
  }

  public deletePaymentMethod(companyId: number, paymentMethodId: string) {
    return this.delete(`${this.routePrefix}${companyId}/${paymentMethodId}`);
  }

  public setDefaultPaymentMethod(companyId: number, paymentMethodId: string) {
    return this.patch(`${this.routePrefix}${companyId}/default/${paymentMethodId}`);
  }
}
