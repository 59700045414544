import { Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';

import { toSnakeCase } from 'app/_shared/helpers/shared';
import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { MockupFile } from 'app/_shared/models/MockupFile';
import { FileSizePipe } from 'app/_shared/pipes/file-size.pipe';
import { ToastService } from 'app/_shared/services/toast.service';
import { LibraryRequestFilePrepComponent } from 'app/admin/storefront/files/library-request-file-prep/library-request-file-prep.component';

interface Version {
  id?: number;
  isMain: boolean;
  file: File | null;
  objectURL: string | null;
  fileName: string | null;
}

@Component({
  selector: 'app-library-file-uploader',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FileUploadModule,
    DropdownModule,
    InputTextModule,
    SelectButtonModule,
    FormsModule,
    FileSizePipe,
    ChipModule
  ],
  providers: [DialogService],
  templateUrl: './library-file-uploader.component.html',
  styleUrls: ['./library-file-uploader.component.scss']
})
export class LibraryFileUploaderComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private library;
  storefrontLibraryFolders = input.required<LibraryFolder[]>();
  editMode = input<boolean>(false);
  showRequestPrepButton = input<boolean>(false);
  showAdditionalFiles = input<boolean>(false);
  libraryFile = input<LibraryFile>();
  formSubmitted = output<FormData>();
  additionaFileChenged = output<FormData>();
  deletedMockupsIds: number[] = [];
  dialogService = inject(DialogService);

  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  dialogRef: DynamicDialogRef | undefined;

  versions = signal<Version[]>([
    { isMain: true, file: null, objectURL: null, fileName: null },
    { isMain: false, file: null, objectURL: null, fileName: null },
    { isMain: false, file: null, objectURL: null, fileName: null },
    { isMain: false, file: null, objectURL: null, fileName: null },
    { isMain: false, file: null, objectURL: null, fileName: null }
  ]);
  mainMockupName = signal<string>('mockupFile');
  actiteStep = signal<number>(0);

  uploadFileForm: FormGroup;
  additionaFiles: { id?: number; name: string }[] = [];

  ngOnInit() {
    this.uploadFileForm = this.fb.group({
      name: [this.libraryFile()?.name ?? null, [Validators.required]],
      library_folder_id: [this.libraryFile()?.library_folder?.id ?? null, [Validators.required]],
      type: ['embroidery', [Validators.required]]
    });
    if (this.editMode() && this.libraryFile()) {
      this.initializeVersions();
    }
    if (this.showAdditionalFiles() && this.libraryFile()?.mockup_file?.additional_files) {
      this.initializeAdditionalFiles();
    }
  }

  initializeVersions() {
    if (!this.libraryFile()) return;

    const mockupKeys = [
      'mockup_file',
      'second_mockup_file',
      'third_mockup_file',
      'fourth_mockup_file',
      'fifth_mockup_file'
    ];

    this.versions.update(() => {
      const newVersions: Version[] = mockupKeys.map((key, index) => {
        const mockup = this.libraryFile()?.[key as keyof LibraryFile] as MockupFile;
        const isMain = toSnakeCase(this.libraryFile()?.main_mockup ?? '') === key;
        return {
          isMain,
          file: null,
          objectURL: mockup?.full_path ?? null,
          id: mockup?.id ?? null,
          fileName: mockup?.name ?? null
        };
      });
      return newVersions;
    });
  }

  initializeAdditionalFiles() {
    this.libraryFile()?.mockup_file?.additional_files?.forEach(additionalFile =>
      this.additionaFiles.push({ id: additionalFile.id, name: additionalFile.name })
    );
  }

  onVersionUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const objectURL = URL.createObjectURL(file);

    const currentVersion = this.versions()[this.actiteStep()];
    if (currentVersion.id) {
      this.deletedMockupsIds.push(currentVersion.id);
    }

    this.versions.update(versions => {
      const newVersions = versions.slice();
      newVersions[this.actiteStep()] = {
        ...newVersions[this.actiteStep()],
        file: file,
        objectURL: objectURL,
        fileName: file.name
      };

      return newVersions;
    });
  }

  selectVersion(index: number) {
    const previousIndex = index - 1;

    if (previousIndex >= 0 && !this.versions()[previousIndex].objectURL) {
      this.toastService.warning(`You must upload the previous version first.`);
      return;
    }
    this.actiteStep.set(index);
  }

  removeMockupVersion(): void {
    const versions = this.versions();
    const versionToDelete = versions[this.actiteStep()];
    if (versionToDelete.id) {
      this.deletedMockupsIds.push(versionToDelete.id);
    }

    const updatedVersions = [
      ...versions.slice(0, this.actiteStep()),
      ...versions.slice(this.actiteStep() + 1),
      { isMain: false, file: null, objectURL: null, fileName: null }
    ];

    updatedVersions[0].isMain = true;
    this.mainMockupName.set(this.getMainMockupName(0));

    this.versions.set(updatedVersions);
    this.toastService.success('Version removed.');
  }

  setAsMainMockup() {
    if (!this.versions()[this.actiteStep()]?.objectURL) {
      this.toastService.warning(`You must upload a mockup before setting it as main.`);
      return;
    }

    this.versions.update(versions =>
      versions.map((version, index) => ({
        ...version,
        isMain: index === this.actiteStep()
      }))
    );

    this.mainMockupName.set(this.getMainMockupName(this.actiteStep()));
    this.toastService.success(`Version set as main mockup.`);
  }

  getMainMockupName(index: number): string {
    const mockupFiles = ['mockupFile', 'secondMockupFile', 'thirdMockupFile', 'fourthMockupFile', 'fifthMockupFile'];
    return mockupFiles[index] ?? '';
  }

  onSubmit() {
    if (this.uploadFileForm.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      this.uploadFileForm.markAllAsTouched();
      return;
    }
    if (!this.versions()[0].objectURL) {
      this.toastService.error('Please upload mockup files!');
      return;
    }
    const formData = this.prepareCreateFormData();
    this.formSubmitted.emit(formData);
  }

  prepareCreateFormData() {
    const formData = new FormData();
    const formValue = this.uploadFileForm.value;
    formData.append('name', formValue.name);
    formData.append('library_folder_id', formValue.library_folder_id);
    formData.append('type', formValue.type);
    this.versions().forEach((version, index) => {
      if (version.file) {
        formData.append(this.getMockupVersionsKey(index), version.file);
      }
    });
    formData.append('main_mockup', this.mainMockupName());

    if (this.editMode()) {
      this.handleEditMode(formData);
    }

    return formData;
  }

  handleEditMode(formData: FormData) {
    if (this.deletedMockupsIds) {
      this.deletedMockupsIds.forEach((mockupId, index) => {
        formData.append(`deleted_mockups[${index}]`, mockupId.toString());
      });
    }

    formData.append('_method', 'put');
  }

  getMockupVersionsKey(index: number): string {
    const mockupKeys = [
      'mockup_file',
      'second_mockup_file',
      'third_mockup_file',
      'fourth_mockup_file',
      'fifth_mockup_file'
    ];
    return mockupKeys[index] ?? '';
  }

  openLibraryFilePrepRequestDialog() {
    this.dialogRef = this.dialogService.open(LibraryRequestFilePrepComponent, {
      header: 'Create Admin files prep request',
      width: '600px',
      data: {
        libraryId: this.libraryFile()?.id
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Preparation file request successfully created.');
        this.router.navigate([`../../`], { relativeTo: this.route });
      }
    });
  }

  onAdditionalFilesUpload(event: FileUploadEvent | FileSelectEvent) {
    this.additionaFiles.push({ name: event.files[0].name });

    const preparedData = this.prepateAdditionalFilesData(event.files[0]);
    this.additionaFileChenged.emit(preparedData);
  }

  onAdditionalFileRemove(index: number) {
    const removedFile = this.additionaFiles[index];
    this.additionaFiles.splice(index, 1);
    const libraryFile = this.libraryFile();
    if (libraryFile && removedFile.id) {
      const preparedData = this.prepateAdditionalFilesData(undefined, removedFile.id);
      this.additionaFileChenged.emit(preparedData);
    }
  }

  prepateAdditionalFilesData(file?: File, additionalFileId?: number) {
    const formData = new FormData();
    const libraryFile = this.libraryFile();
    if (libraryFile) {
      formData.append('library_file_id', libraryFile.id.toString());
    }

    if (file) {
      formData.append('additional_file', file);
    }
    if (additionalFileId) {
      formData.append('additional_file_id', additionalFileId.toString());
    }
    return formData;
  }
}
