import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ProductPaginatorOptions } from 'app/_shared/components/list-products/list-products.component';
import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { ClientSelectionList, Collection } from 'app/_shared/interfaces/clientSelection';

import { IApiPaginatedData, PaginatorOptions } from '../interfaces/IApiPaginatedData';
import { IApiResponse } from '../interfaces/IApiResponse';
import ChangeUserPermissionsData from '../models/ChangeUserPermissionsData';
import { FormGroupValue } from '../models/FormGroup';
import UserInviteData from '../models/UserInviteData';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class CompanyService extends ApiService {
  routePrefix = `${this.apiUrl}companies/`;

  public index() {
    return this.get(this.routePrefix);
  }

  public paginatedIndex($event?: any) {
    return this.get(this.routePrefix, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  public getById(id: number) {
    return this.get(this.routePrefix + id);
  }

  public updateCompany(companyId: number, data: FormGroupValue) {
    return this.put(`${this.routePrefix + companyId}`, data);
  }

  public getCompanyUsers($event: any, companyId?: number): Observable<IApiResponse<IApiPaginatedData>> {
    return this.get(`${this.routePrefix + companyId}/users`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  public getSingleCompanyUser(userId: number, companyId?: number) {
    return this.get(`${this.routePrefix + companyId}/users/${userId}`);
  }

  public updateCompanyUser(userId: number, data: FormGroupValue, companyId?: number) {
    return this.put(`${this.routePrefix + companyId}/users/${userId}`, data);
  }

  public updateCompanyUserPersmissions(userId: number, data: ChangeUserPermissionsData, companyId?: number) {
    return this.put(`${this.routePrefix + companyId}/users/${userId}/permissions`, data);
  }

  public deleteCompanyUser(userId: number, companyId?: number) {
    return this.delete(`${this.routePrefix + companyId}/users/${userId}`);
  }

  public inviteUser(inviteData: UserInviteData, companyId?: number): Observable<IApiResponse> {
    return this.post(`${this.routePrefix + companyId}/invites`, inviteData);
  }

  public invites(companyId: number, $event?: any) {
    return this.get(`${this.routePrefix + companyId}/invites`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  public deleteInvite(companyId: number, inviteId: number) {
    return this.delete(`${this.routePrefix + companyId}/invites/${inviteId}`);
  }

  public resendInvite(companyId: number, inviteId: number) {
    return this.get(`${this.routePrefix + companyId}/resend/invites/${inviteId}`);
  }

  public companyStorefronts(companyId?: number) {
    return this.get(`${this.routePrefix + companyId}/storefronts`);
  }

  public companyStorefrontsPaginated(companyId?: number, $event?: any) {
    const params = { ...this.handleTableLazyLoadEvent($event) };
    return this.get(`${this.routePrefix + companyId}/storefronts/paginate`, {
      params
    });
  }

  public getClientSelectionList(companyId: number, params: PaginatorOptions) {
    return this.get<IApiPaginatedData<ClientSelectionList[]>>(`${this.routePrefix + companyId}/client-selections`, {
      params
    });
  }

  public deleteClientSelection(companyId: number, selectionId: number) {
    return this.delete(`${this.routePrefix + companyId}/client-selections/${selectionId}`);
  }

  public getSelection(companyId: string, selectionId: string, params) {
    return this.get(`${this.routePrefix + companyId}/client-selections/${selectionId}`, { params });
  }

  public createSelection(
    companyId: string,
    params: { title: string; comment: string; catalog_product_collections: number[] }
  ) {
    return this.post(`${this.routePrefix + companyId}/client-selections`, params);
  }

  public editSelection(
    companyId: string,
    selectionId: number,
    params: { title: string; comment: string; catalog_product_collections: number[] }
  ) {
    return this.patch(`${this.routePrefix + companyId}/client-selections/${selectionId}`, params);
  }

  public editClientSelection(params: { token: string; catalog_products_ids: number[] }) {
    return this.patch(`${this.apiUrl}client-selection/select-catalog-products`, params);
  }
  public getClientSelectionProducts(params: { token: string }) {
    return this.get<IApiPaginatedData<CatalogProduct[]>>(`${this.apiUrl}client-selection/get-catalog-products`, {
      params
    });
  }
  public getSelectionByToken(params: any) {
    return this.get<ClientSelectionList>(`${this.apiUrl}client-selection`, { params });
  }

  public getCollections(companyId: number, params: PaginatorOptions) {
    return this.get<IApiPaginatedData<Collection[]>>(`${this.routePrefix + companyId}/catalog-product-collections`, {
      params
    });
  }

  public getCollection(companyId: string, collectionId: string, params: any) {
    return this.get<Collection>(`${this.routePrefix + companyId}/catalog-product-collections/${collectionId}`, {
      params
    });
  }

  public updateCollection(
    companyId: string,
    collectionId: string,
    params: { title: string; catalog_product_ids: number[] }
  ) {
    return this.patch<Collection>(
      `${this.routePrefix + companyId}/catalog-product-collections/${collectionId}`,
      params
    );
  }

  public createCollection(companyId: string, params: { title: string; catalog_product_ids: number[] }) {
    return this.post(`${this.routePrefix + companyId}/catalog-product-collections`, params);
  }

  public deleteCollection(companyId: string, collectionId: number) {
    return this.delete(`${this.routePrefix + companyId}/catalog-product-collections/${collectionId}`);
  }
}
