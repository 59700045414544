<p-table
  #dt1
  [value]="colors"
  dataKey="id"
  [loading]="false"
  [rowHover]="true"
  styleClass="p-datatable-gridlines"
  [globalFilterFields]="['name']"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="caption">
    <div class="flex justify-content-between flex-column sm:flex-row">
      <span class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          #filter
          (input)="onGlobalFilter(dt1, $event)"
          placeholder="Search Keyword"
          class="w-full"
        />
      </span>
      <div>
        <button pButton pRipple type="button" label="Add new color" (click)="addNewColor()"></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width: 12rem">
        <div class="flex justify-content-between align-items-center">Color name</div>
      </th>
      @for (supplier of suppliers; track supplier.id) {
        <th style="min-width: 12rem">
          <div class="flex justify-content-between align-items-center">
            {{ supplier.name }}
          </div>
        </th>
      }
      <th style="min-width: 10rem">
        <div class="flex justify-content-between align-items-center">Edit</div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-color>
    <tr>
      <td>
        <div class="color-wrap">
          <div class="color-div" [ngStyle]="{ backgroundColor: color.hex }"></div>
          {{ color.name }}
        </div>
      </td>
      @for (supplier of suppliers; track supplier.id) {
        <td>
          {{ color[supplier.slug] }}
        </td>
      }
      <td>
        <button pButton pRipple type="button" label="Edit" class="p-button-text" (click)="editColor(color)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No colors found.</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td colspan="8">Loading colors data. Please wait.</td>
    </tr>
  </ng-template>
</p-table>
