<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '450px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
/>
<div class="card">
  <h5>Details</h5>
  <div class="grid">
    <div class="col-12 md:col-6">
      <ul class="list-none p-0 m-0 text-600 mb-4 text-600">
        <li class="mb-3 flex justify-content-between">
          <span>Name:</span>
          <span class="font-semibold">{{ giftCard?.name }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Date created:</span>
          <span class="font-semibold">{{ (giftCard?.created_at | usdDate) || 'not set' }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Total value when created:</span>
          <span class="font-semibold">{{ totalValue | usdPipe }}</span>
        </li>
        <li class="mb-3 flex justify-content-between">
          <span>Total value left:</span>
          <span class="font-semibold">{{ totalRemainingValue | usdPipe }}</span>
        </li>
        <li class="flex justify-content-between">
          <span>Additional options:</span>
          <div class="flex flex-column">
            @for (additionalInfoItem of detailsAddtionalText; track $index) {
              <span class="font-semibold text-right">{{ additionalInfoItem }}</span>
            }
          </div>
        </li>
      </ul>
    </div>
    <div class="col-12 md:col-6">
      <p-chart type="pie" [data]="data" [options]="options" height="300px" />
    </div>
  </div>
</div>
@if (giftCard) {
  <div class="card">
    <h5>Gift Card Codes</h5>
    <p-table
      [value]="giftCard.codes"
      dataKey="id"
      [lazy]="true"
      [paginator]="true"
      [rows]="10"
      [totalRecords]="10"
      [showCurrentPageReport]="true"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[5, 10, 25, 50]"
      [globalFilterFields]="['name']"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox name="Select all" />
          </th>
          <th pSortableColumn="code">
            Code
            <p-sortIcon field="code" />
          </th>
          <th>Full Name</th>
          @if (giftCard.specified_recipients) {
            <th>Email address</th>
          }
          <th>Issued</th>
          <th>Spent</th>
          <th>Balance</th>
          <th pSortableColumn="status">
            Status
            <p-sortIcon field="status" />
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-code>
        <tr>
          <td>
            <p-tableCheckbox [value]="giftCard" [name]="giftCard.name" />
          </td>
          <td>
            {{ code?.id }}
          </td>
          <td>{{ code?.name }}</td>
          @if (giftCard.specified_recipients) {
            <td>{{ code.email }}</td>
          }
          <td>{{ code.amount | usdPipe }}</td>
          <td>{{ code.amount - code.balance | usdPipe }}</td>
          <td>{{ code.balance | usdPipe }}</td>
          <td>{{ code.status }}</td>
          <td>
            <p-splitButton
              #splitBtn
              label="Actions"
              appendTo="body"
              (onClick)="splitClick(code)"
              (onDropdownClick)="onDropdownClick($event, code)"
              [model]="splitModel"
            ></p-splitButton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
}
