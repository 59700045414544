import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ColorPickerControl, CompactPickerComponent } from '@iplab/ngx-color-picker';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { debounceTime, Subject } from 'rxjs';

import { IframeComponent } from 'app/_shared/components/iframe/iframe.component';
import { DesignerService } from 'app/_shared/services/designer.service';
import { DesignerStore, Settings } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    ButtonModule,
    EditorModule,
    FileUploadModule,
    NgIf,
    RadioButtonModule,
    SharedModule,
    FormsModule,
    IframeComponent,
    CardModule,
    AccordionModule,
    DividerModule,
    CompactPickerComponent,
    InputTextareaModule
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {
  public storefrontStore = inject(StorefrontStore);
  private designerService = inject(DesignerService);
  private designerHelperService = inject(DesignerHelperService);
  private designerStore = inject(DesignerStore);
  private textChangeSubject = new Subject<string>();
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront()!);
  settings = computed(() => this.designerStore?.config?.().settings);
  logo = computed(() => this.settings()?.logo ?? '');
  primaryColor = '#4e60cc';
  secondaryColor = '#4e60cc';
  logoPosition: string = 'left';
  footerText: string;
  iframeUrl: SafeUrl;
  buttonShape: string = 'square';
  public primaryColorControl = new ColorPickerControl().hidePresets();
  public secondaryColorControl = new ColorPickerControl().hidePresets();
  constructor(private sanitizer: DomSanitizer) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedStorefront()?.url}/?preview=true`);

    // Add debounce for color change
    this.primaryColorControl.valueChanges.pipe(debounceTime(500)).subscribe(next => {
      this.saveSettings({ primaryColor: next.toHexString() });
    });
    this.textChangeSubject.pipe(debounceTime(500)).subscribe(value => {
      this.saveSettings({ footerContent: value });
    });
  }

  ngOnInit() {
    this.primaryColor = this.settings()?.primaryColor ?? '';
    this.secondaryColor = this.settings()?.secondaryColor ?? '';
    this.logoPosition = this.settings()?.logoPosition || 'left';
    this.buttonShape = this.settings()?.buttonShape || 'square';
    this.footerText = this.settings()?.footerContent || '';
  }

  applyChanges() {
    const config = this.designerStore.config();
    this.designerStore.saveConfig(this.selectedStorefront().id!, config).subscribe();
  }

  onUpload(event: FileSelectEvent) {
    const file = event.files[0];
    const logoFormData = new FormData();
    logoFormData.append('preview_logo', file);
    this.designerService.uploadLogoPreview(logoFormData, this.selectedStorefront().id).subscribe(next => {
      this.saveSettings({ logo: next.data.preview_logo.full_path });
    });
  }

  saveSettings(settings: Settings) {
    const prevSettings = this.settings();
    this.designerStore
      .saveConfig(this.selectedStorefront().id!, { settings: { ...prevSettings, ...settings } }, true)
      .subscribe(next => {
        this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      });
  }

  removeImage() {
    this.designerService.removeLogo(this.storefrontId()!).subscribe(() => {
      this.saveSettings({ logo: undefined });
    });
  }

  onTextChange(value: EditorTextChangeEvent) {
    this.textChangeSubject.next(value.htmlValue);
  }
}
