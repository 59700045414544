import { Component, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CardModule } from 'primeng/card';
import { map } from 'rxjs';

import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { SupplierService } from 'app/_shared/services/supplier.service';

@Component({
  selector: 'app-supplier-product-view',
  standalone: true,
  imports: [CardModule, PricePipe, UsdPipe],
  templateUrl: './supplier-product-view.component.html',
  styleUrl: './supplier-product-view.component.scss'
})
export class SupplierProductViewComponent implements OnInit {
  supplierService = inject(SupplierService);
  activatedRoute = inject(ActivatedRoute);
  sanitizer = inject(DomSanitizer);
  product: SupplierProduct;
  slug: string;
  id: string;
  sanitizedHtml: SafeHtml;
  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    this.fetchProduct();
  }

  fetchProduct() {
    this.supplierService
      .getSupplierProduct(this.slug, this.id)
      .pipe(
        map(product => {
          return handleSupplierMapping(this.slug, product.data);
        })
      )
      .subscribe(product => {
        this.product = product;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(product.description);
      });
  }
}
