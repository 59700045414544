import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withState, withMethods } from '@ngrx/signals';

import { Storefront } from 'app/_shared/models/Storefront';

import { Company } from '../_shared/models/Company';

export type CompanyState = {
  workingCompany: Company | null;
  isLoading: boolean;
};

const initialWorkingCompanyState: CompanyState = {
  workingCompany: null,
  isLoading: false
};

export const CompanyStore = signalStore(
  { providedIn: 'root' },
  withDevtools('workingCompany'),
  withState(initialWorkingCompanyState),
  withMethods(store => ({
    setWorkingCompany: (company: Company) => patchState(store, { workingCompany: company }),
    addEditStorefront: (storefront: Storefront, storefrontId: number | null | undefined = null) => {
      const currentWorkingCompany = store.workingCompany();

      if (!currentWorkingCompany) return;

      const updatedStorefronts = storefrontId
        ? currentWorkingCompany.storefronts.map(item => (item.id === storefrontId ? storefront : item))
        : [...currentWorkingCompany.storefronts, { ...storefront }];

      const updatedCompany = {
        ...currentWorkingCompany,
        storefronts: updatedStorefronts
      };

      patchState(store, { workingCompany: updatedCompany });
    },
    removeCompanyStorefront: (storefrontId: number) => {
      const currentWorkingCompany = store.workingCompany();
      if (currentWorkingCompany) {
        const updatedStorefronts = currentWorkingCompany.storefronts.filter(
          storefront => storefrontId !== storefront.id
        );
        patchState(store, { workingCompany: { ...currentWorkingCompany, storefronts: updatedStorefronts } });
      }
    },
    unsetWorkingCompany: () => {
      localStorage.removeItem('workingCompany');
      patchState(store, { workingCompany: undefined });
    },
    setLoading: (isLoading: boolean) => patchState(store, { isLoading })
  }))
);
