@if (!selectedStorefront()) {
  <div class="flex justify-content-center">
    <p-progressSpinner ariaLabel="loading" />
  </div>
} @else {
  <div class="grid">
    <div class="col-12">
      <p-tabMenu [model]="routeItems" [activeItem]="activeItem"></p-tabMenu>
    </div>
    <div class="col-12">
      <router-outlet></router-outlet>
    </div>
  </div>
}
