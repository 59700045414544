import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DecorationMethodService extends ApiService {
  routePrefix = `${this.apiUrl}decoration-methods/`;

  public index() {
    return this.get(`${this.routePrefix}`);
  }
}
