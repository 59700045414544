import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiPaginatedData } from 'app/_shared/interfaces/IApiPaginatedData';
import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { Color } from 'app/_shared/interfaces/colors';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends ApiService {
  routePrefix = `${this.apiUrl}catalog`;

  public getProducts(params: any): Observable<IApiResponse<IApiPaginatedData<CatalogProduct[]>>> {
    return this.get(`${this.routePrefix}/products`, { params });
  }

  public createProduct(product: CatalogProduct) {
    return this.post(`${this.routePrefix}/products`, product);
  }

  public editProduct(product: CatalogProduct, productId: number) {
    return this.patch<CatalogProduct>(`${this.routePrefix}/products/${productId}`, product);
  }

  public getProduct(productId: string) {
    return this.get<CatalogProduct>(`${this.routePrefix}/products/${productId}`, {
      params: {
        'relations[]': [
          'catalogProductConfiguration',
          'supplier',
          'featuredImage',
          'printAreas',
          'catalogProductVariants.catalogVariantSizes',
          'catalogProductVariants.frontImage.supplierMedia',
          'catalogProductVariants.rearImage.supplierMedia',
          'catalogProductVariants.sideImage.supplierMedia',
          'decorationMethods',
          'adminCategories',
          'sizeGuide',
          'sizeGuideTimeline'
        ]
      }
    });
  }

  public getProductForCatalogProductCreator(productId: string) {
    return this.get<CatalogProduct>(`${this.routePrefix}/products/${productId}`, {
      params: {
        'relations[]': [
          'catalogProductConfiguration',
          'supplier',
          'featuredImage',
          'printAreas',
          'catalogProductVariants.catalogVariantSizes',
          'catalogProductVariants.supplierProductVariant',
          'catalogProductVariants.frontImage.supplierMedia',
          'catalogProductVariants.rearImage.supplierMedia',
          'catalogProductVariants.sideImage.supplierMedia',
          'sizeGuide',
          'adminCategories',
          'sizeGuideTimeline'
        ]
      }
    });
  }

  public getColors() {
    return this.get<Color[]>(`${this.routePrefix}/colors`);
  }

  public syncCatalogColors(colorId: number, colors: number[]) {
    return this.post(`${this.routePrefix}/colors/${colorId}/sync`, {
      supplier_product_colors: colors
    });
  }

  public addNewColor(color: Color) {
    return this.post(`${this.routePrefix}/colors`, color);
  }

  public deleteColor(colorId: number) {
    return this.delete(`${this.routePrefix}/colors/${colorId}`);
  }
}
