import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { environment } from 'environments/environment';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { finalize, Subscription } from 'rxjs';

import { StoreOperatorEditorDialogComponent } from 'app/_shared/components/store-operator-editor-dialog/store-operator-editor-dialog.component';
import { Country } from 'app/_shared/models/Country';
import { GeneralStoreSettings } from 'app/_shared/models/GeneralStoreSettings';
import { StoreOperator } from 'app/_shared/models/StoreOperator';
import { StoreStatusType } from 'app/_shared/models/StoreStatusType';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { StorefrontStore } from 'app/_store/storefront.store';

@Component({
  selector: 'app-general-settings',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    CommonModule,
    InputGroupModule,
    InputNumberModule,
    InputGroupAddonModule,
    CheckboxModule,
    FormsModule,
    RadioButtonModule
  ],
  providers: [DialogService],
  templateUrl: './general-settings.component.html'
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
  protected prefix = environment.PREFIX;
  protected suffix = environment.SUFFIX;
  fb = inject(FormBuilder);
  storefrontStore = inject(StorefrontStore);
  storefrontService = inject(StorefrontService);
  route = inject(ActivatedRoute);
  dialogService = inject(DialogService);
  toastService = inject(ToastService);
  generalStoreSettings: GeneralStoreSettings;
  storeOperator: StoreOperator | null = null;
  generalSettingsForm: FormGroup;
  generalSettingsFormReady = false;
  protected readonly StoreStatusType = StoreStatusType;
  storeStatus: StoreStatusType;
  countries: Country[];
  submitted = false;
  isLoading = false;
  public storefrontId: number;
  private routeSub: Subscription;
  dialogRef: DynamicDialogRef | undefined;
  priceRoundingTypes = [
    { name: 'Dont round', key: 'none' },
    { name: 'Round to 0.50', key: '50' },
    { name: 'Round to 0.95', key: '95' },
    { name: 'Round to 0.99', key: '99' }
  ];

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.storefrontId = params.storefrontId;
    });
    this.fetchGeneralStoreSettings(this.storefrontId);
    this.fetchCountries();
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  fetchGeneralStoreSettings(storefrontId: number) {
    this.storefrontService.getStoreGeneralSettings(storefrontId).subscribe({
      next: response => {
        this.generalStoreSettings = response.data;
        this.storeOperator = this.generalStoreSettings.operator_information;
        this.storeStatus = this.generalStoreSettings.status;
        this.buildForm();
      },
      error: error => {
        console.error(error);
      }
    });
  }

  fetchCountries() {
    this.storefrontService.storeCountries().subscribe({
      next: response => {
        this.countries = response.data;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  buildForm() {
    this.generalSettingsForm = this.fb.group({
      name: [this.generalStoreSettings.name, [Validators.required]],
      subdomain: [this.generalStoreSettings?.subdomain, [Validators.required, CustomValidators.validateSubdomain()]],
      domain: [this.generalStoreSettings?.domain],
      customDomain: [!!this.generalStoreSettings?.domain],
      shipping_margin: [this.generalStoreSettings?.shipping_margin],
      single_variant_price: [!!this.generalStoreSettings?.single_variant_price],
      price_margin: [this.generalStoreSettings?.price_margin, Validators.required],
      price_rounding: [this.generalStoreSettings?.price_rounding ?? 'NaN', Validators.required],
      salesperson_email: [this.generalStoreSettings?.salesperson_email, [Validators.email]]
    });
    this.updateDomainValidators();
    this.generalSettingsFormReady = true;
  }

  updateDomainValidators() {
    this.generalSettingsForm.get('customDomain')?.valueChanges.subscribe(newValue => {
      const domainControl = this.generalSettingsForm.get('domain');
      if (domainControl) {
        if (newValue) {
          domainControl.setValidators([Validators.required]);
        } else {
          this.generalSettingsForm.get('domain')?.clearValidators();
        }
        domainControl.updateValueAndValidity();
        domainControl.markAsUntouched();
      }
    });
  }

  openEditOperatorDialog() {
    this.dialogRef = this.dialogService.open(StoreOperatorEditorDialogComponent, {
      header: 'Store Operator',
      width: '700px',
      data: {
        storeOperator: this.generalStoreSettings.operator_information,
        countries: this.countries,
        storefrontId: this.generalStoreSettings.id
      }
    });
    this.dialogRef.onClose.subscribe((updatedStoreOperator: StoreOperator | null) => {
      if (updatedStoreOperator) {
        this.storeOperator = updatedStoreOperator;
        this.generalStoreSettings.operator_information = updatedStoreOperator;
        this.toastService.success('Store operator updated successfully!');
      }
    });
  }

  onSubmit() {
    if (this.generalSettingsForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.updateStoreGeneralSettings();
  }

  updateStoreGeneralSettings() {
    this.isLoading = true;
    const formValues = { ...this.generalSettingsForm.value };
    !formValues.customDomain && delete formValues.domain;
    delete formValues.customDomain;

    this.storefrontService
      .updateGeneralStoreSettings(formValues, this.storefrontId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated general settings!');
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }

  changeStoreStatus(status: string) {
    this.isLoading = true;

    this.storefrontService
      .updateStatus({ status }, this.storefrontId)
      .pipe(
        finalize(() => {
          this.isLoading = true;
        })
      )
      .subscribe({
        next: response => {
          this.toastService.success('Successfully updated store status!');
          this.storeStatus = response.data.status;
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }
}
