import { NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ColorPickerControl, CompactPickerComponent } from '@iplab/ngx-color-picker';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Color } from 'app/_shared/interfaces/colors';
import { CatalogService } from 'app/_shared/services/catalog.service';

@Component({
  selector: 'app-add-color',
  standalone: true,
  imports: [
    CompactPickerComponent,
    DialogModule,
    FormsModule,
    InputComponent,
    ButtonModule,
    ReactiveFormsModule,
    CardModule,
    NgStyle
  ],
  templateUrl: './add-color.component.html',
  styleUrl: './add-color.component.scss'
})
export class AddColorComponent {
  catalogService = inject(CatalogService);
  private router = inject(Router);
  colorValue = '#4e60cc';
  public colorControl = new ColorPickerControl().hidePresets();
  newColorForm = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  get name(): FormControl {
    return this.newColorForm.get('name') as FormControl;
  }

  handleSubmit() {
    const color = {
      name: this.newColorForm.value.name,
      hex: this.colorValue
    } as Color;
    this.catalogService.addNewColor(color).subscribe(next => {
      this.router.navigate(['colors']);
    });
  }
}
