<p-card>
  @if (this.form) {
    <form [formGroup]="form" (ngSubmit)="submit()">
      <h5 [style]="{ textTransform: 'capitalize' }">{{ type() }} catalog product</h5>
      <p-tabMenu [model]="items" [(activeItem)]="activeItem"></p-tabMenu>
      <div style="margin-top: 20px">
        @if (activeItem.label === Tabs.INFO) {
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <app-input
                name="name"
                label="Name"
                [control]="form.controls.name"
                placeholder="Product name"
                [fullWidth]="true"
              ></app-input>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <app-input
                name="brand"
                label="Brand"
                [control]="form.controls.brand"
                placeholder="Brand name"
                [fullWidth]="true"
              ></app-input>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="flex flex-column pt-1">
                <label for="similar_product">Similar Product</label>
                <p-dropdown
                  formControlName="similar_product_id"
                  [options]="catalogProducts"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Select a Product"
                  [style]="{ width: '100%' }"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="flex flex-column">
                <label for="companion_product">Companion Product</label>
                <p-dropdown
                  formControlName="companion_product_id"
                  [options]="catalogProducts"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Select a Product"
                  [style]="{ width: '100%' }"
                />
              </div>
            </div>
            <ng-container formGroupName="catalog_product_configuration">
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="product_price"
                  label="Price"
                  [control]="form.get('catalog_product_configuration.product_price')!"
                  placeholder="Price"
                  [fullWidth]="true"
                ></app-input>
                @if (this.type() === 'create') {
                  <small>
                    Supplier product price ({{ supplierPrintPrice | price }}) + margin ({{ supplierPriceMargin }}%)
                  </small>
                }
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="print_price"
                  label="First Print Price"
                  [control]="form.get('catalog_product_configuration.print_price')!"
                  placeholder="First Print Price"
                  [fullWidth]="true"
                ></app-input>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="print_price_second"
                  label="Print price second"
                  [control]="form.get('catalog_product_configuration.print_price_second')!"
                  placeholder="Product price second"
                  [fullWidth]="true"
                ></app-input>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="short_name"
                  label="Short name"
                  [control]="form.get('catalog_product_configuration.short_name')!"
                  placeholder="Short name"
                  [fullWidth]="true"
                ></app-input>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="weight"
                  label="Weight"
                  [control]="form.get('catalog_product_configuration.weight')!"
                  placeholder="Weight"
                  [fullWidth]="true"
                ></app-input>
              </div>

              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="moq"
                  label="MOQ"
                  [control]="form.get('catalog_product_configuration.moq')!"
                  placeholder="MOQ"
                  [fullWidth]="true"
                ></app-input>
              </div>
              <div class="col-12 md:col-6 lg:col-3">
                <app-input
                  name="number_of_tiers"
                  label="Number of tiers"
                  [control]="form.get('catalog_product_configuration.number_of_tiers')!"
                  placeholder="Number of tiers"
                  [fullWidth]="true"
                ></app-input>
              </div>
            </ng-container>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="flex flex-column pt-1">
                <label for="catalog_category_ids">Catalog Catagory</label>
                <p-multiSelect
                  [options]="productCategories"
                  placeholder="Select Categories"
                  optionLabel="name"
                  optionValue="id"
                  [style]="{ width: '100%' }"
                  formControlName="catalog_category_ids"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <label for="size_guide">Size Guide</label>
              <p-fileUpload
                name="size_guide"
                accept="application/pdf, image/*"
                [auto]="true"
                (onSelect)="onUpload($event, 'size_guide')"
                mode="basic"
                [maxFileSize]="1000000"
                [chooseLabel]="sizeGuideLabel"
                [style]="{ width: '100%' }"
                styleClass="p-button-outlined p-button-plain"
              ></p-fileUpload>
            </div>

            <div class="col-12 md:col-6 lg:col-3">
              <label for="size_guide_timeline">Size Guide Timeline</label>
              <p-fileUpload
                name="size_guide_timeline"
                accept="application/pdf, image/*"
                [auto]="true"
                (onSelect)="onUpload($event, 'size_guide_timeline')"
                mode="basic"
                [maxFileSize]="1000000"
                [chooseLabel]="sizeGuideTimelineLabel"
                [style]="{ width: '100%' }"
                styleClass="p-button-outlined p-button-plain"
              ></p-fileUpload>
            </div>
            <div class="col-12 md:col-6 lg:col-9">
              <label for="decoration_methods">Decoration Methods</label>
              <p-selectButton
                [options]="decorationMethodOptions"
                [multiple]="true"
                optionLabel="name"
                optionValue="id"
                formControlName="decoration_methods"
              />
              @if (form.get('decoration_methods')?.touched && form.get('decoration_methods')?.invalid) {
                <div>
                  <small class="p-text-danger">Decoration methods are required</small>
                </div>
              }
            </div>
            <div class="col-12">
              <div class="flex flex-column">
                <label for="description">Description</label>
                <p-editor
                  #editor
                  (onInit)="setDefaultDescriptionValue()"
                  formControlName="description"
                  [style]="{ height: '320px' }"
                />
                @if (form.get('description')?.touched && form.get('description')?.invalid) {
                  <div>
                    <small class="p-text-danger">Description is required</small>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @if (activeItem.label === Tabs.PRINT_AREA) {
          <app-print-area
            [printAreas]="printAreas"
            [product]="catalogProduct"
            (onPrintAreasChange)="handlePrintAreaChange($event)"
          ></app-print-area>
        }
        @if (activeItem.label === Tabs.VARIANTS) {
          <app-variants-list
            [supplierId]="catalogProduct.supplier_id!"
            [(variants)]="productVariants"
            [type]="type()"
            (onVariantChange)="onVariantChange($event)"
            (onDeleteVariant)="onDeleteVariant($event)"
            (onDeleteVariantMedia)="onDeleteVariantMedia($event)"
          ></app-variants-list>
        }
      </div>
      <div class="flex gap-3">
        <button
          pButton
          type="button"
          label="Back"
          aria-label="Back"
          class="p-button-outlined"
          (click)="handleBack()"
        ></button>
        <button
          [disabled]="form.invalid"
          [loading]="isSubmitting"
          [style]="{ textTransform: 'capitalize' }"
          pButton
          icon="pi pi-check"
          type="submit"
          [label]="type() + ' catalog product'"
          aria-label="Create catalog product"
        ></button>
      </div>
    </form>
  }
</p-card>
