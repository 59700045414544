import { Clipboard } from '@angular/cdk/clipboard';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';

import {
  Columns,
  HeaderActions,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { ClientSelectionList } from 'app/_shared/interfaces/clientSelection';
import { Storefront } from 'app/_shared/models/Storefront';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { CompanyStore } from 'app/_store/company.store';

@Component({
  selector: 'app-client-selection-list',
  standalone: true,
  imports: [ListProductsComponent, ConfirmDialogModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './client-selection-list.component.html',
  styleUrl: './client-selection-list.component.scss'
})
export class ClientSelectionListComponent implements OnInit {
  private companyService = inject(CompanyService);
  private activatedRoute = inject(ActivatedRoute);
  private clipboard = inject(Clipboard);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  router = inject(Router);
  companyStore = inject(CompanyStore);
  isLoading: boolean;
  edit: boolean;
  clientSelectionList: ClientSelectionList[];
  companyId: number;
  selectedStorefront: Storefront;
  cols: Columns[] = [
    { field: 'title', header: 'Title', width: '30%' },
    { field: 'comment', header: 'Comment', width: '30%' },
    {
      field: 'products',
      header: 'Number of products selected',
      width: '30%',
      value: selection => (selection.distinct_catalog_product_selections ?? []).map(c => c.id).length
    },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '10%',
      actions: [
        {
          icon: 'pi pi-copy',
          label: 'Duplicate selection',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => {
            this.companyService.cloneSelectionList(this.companyId, selection.id).subscribe({
              next: next => {
                this.handleLazyLoadProducts(this.paginatorOptions);
                this.toastService.success('Collection was duplicated successfully.');
              },
              error: err => {
                console.error(err);
              }
            });
          }
        },
        {
          icon: 'pi pi-share-alt',
          label: 'Copy selection link',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => this.copySelectionLink(selection)
        },
        {
          icon: 'pi pi-pencil ',
          label: 'Edit',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => {
            this.router.navigate([selection.id, 'edit'], { relativeTo: this.activatedRoute });
          }
        },
        {
          icon: 'pi pi-trash ',
          label: 'Remove',
          severity: 'danger',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => this.openDeleteModal(selection)
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };
  headerActions: HeaderActions[] = [
    {
      icon: 'pi pi-share-alt',
      label: 'Create selection',
      onClick: () => this.router.navigate(['create'], { relativeTo: this.activatedRoute })
    }
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.id;
    });
  }

  copySelectionLink(selection: ClientSelectionList) {
    const baseUrl = window.location.origin;
    this.clipboard.copy(baseUrl + '/client-selection?token=' + selection.token);
  }

  handleLazyLoadProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      ...(globalFilter && { global_filter: globalFilter }),
      'relations[]': ['distinctCatalogProductSelections']
    };
    this.companyService.getClientSelectionList(this.companyId, params).subscribe(next => {
      this.clientSelectionList = next.data.data;
      this.paginatorOptions = {
        total: next.data.total,
        rows: next.data.per_page,
        first: next.data.from - 1
      };
    });
  }

  openDeleteModal(selection: ClientSelectionList) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message: 'Are you sure that you want to delete this selection?',
      accept: () => {
        this.companyService.deleteClientSelection(this.companyId, selection.id).subscribe(() => {
          this.handleLazyLoadProducts(this.paginatorOptions);
          this.toastService.success('Selection deleted successfully.');
        });
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }
}
