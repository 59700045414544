<form [formGroup]="uploadFileForm" (ngSubmit)="onSubmit()">
  <div class="grid">
    <div class="col-12">
      <p>
        You can add more than one .png mockup file - for example dark and light versions of the same logo. You’ll be
        able to adjust that for each product color separately.
      </p>
    </div>
    <div class="col-12">
      <p-fileUpload
        [multiple]="false"
        [customUpload]="true"
        (onSelect)="onVersionUpload($event)"
        accept=".png"
        maxFileSize="1000000"
        showUploadButton="false"
        customUpload="true"
        showUploadButton="false"
      >
        <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
          <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
            <div class="w-full flex justify-content-between gap-2">
              <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Mockups" />
              <p-button
                (click)="setAsMainMockup()"
                icon="pi pi-check"
                outlined
                label="Set As Main Mockup"
                severity="success"
              />
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="file"></ng-template>
        <ng-template pTemplate="content">
          @if (versions()[actiteStep()]?.file || versions()[actiteStep()]?.objectURL) {
            <div class="flex">
              <div class="col-4">
                <img [src]="versions()[actiteStep()]?.objectURL" alt="Uploaded Image" style="width: 50px" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-center align-items-center text-center h-full">
                  {{ versions()[actiteStep()]?.fileName }}
                </div>
              </div>
              <div class="col-4">
                <div class="flex justify-content-end">
                  <p-button type="button" icon="pi pi-times" (onClick)="removeMockupVersion()"></p-button>
                </div>
              </div>
            </div>
          } @else {
            <div class="flex align-items-center justify-content-center flex-column">
              <i class="pi pi-cloud-upload border-1 border-circle p-5 text-4xl text-400 border-400"></i>
              <p class="mt-4 mb-0">Drag and drop files here to upload.</p>
            </div>
          }
        </ng-template>
      </p-fileUpload>
      @if (uploadFileForm.get('mockup')?.touched && uploadFileForm.get('mockup')?.invalid) {
        <div>
          <small class="p-text-danger">Design file is required</small>
        </div>
      }
    </div>
    <div class="col-12">
      <div class="flex justify-content-between">
        @for (version of versions(); let i = $index; track version) {
          <p-button
            class="border-success"
            type="button"
            [label]="'Version ' + (i + 1)"
            [outlined]="actiteStep() !== i"
            (click)="selectVersion(i)"
            [severity]="version.isMain ? 'success' : null"
          ></p-button>
        }
      </div>
    </div>
    <div class="col-12 sm:col-6">
      <label for="country">Design folder</label>
      <p-dropdown
        [options]="storefrontLibraryFolders()"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a folder"
        [style]="{ width: '100%' }"
        formControlName="library_folder_id"
      />
      @if (uploadFileForm.get('library_folder_id')?.touched && uploadFileForm.get('library_folder_id')?.invalid) {
        <div>
          <small class="p-text-danger">Design folder is required</small>
        </div>
      }
    </div>
    <div class="col-12 sm:col-6">
      <label for="design_name">Design File Name</label>
      <input formControlName="name" type="text" pInputText class="w-full" />
      @if (uploadFileForm.get('name')?.touched && uploadFileForm.get('name')?.invalid) {
        <div>
          <small class="p-text-danger">File name is required</small>
        </div>
      }
    </div>
    <div class="col-12">
      <div class="flex justify-content-end">
        <p-button pRipple label="Save & Close" type="submit" />
      </div>
    </div>
  </div>
</form>
