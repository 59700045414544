import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';

import { GiftCard, GiftCardCode, GiftCardCodesNumber } from 'app/_shared/models/Coupon';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { GiftCardCodeService } from 'app/_shared/services/gift-card-codes.service';
import { GiftCardService } from 'app/_shared/services/gift-card.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { GiftCardCodeEditorDialogComponent } from '../gift-card-code-editor-dialog/gift-card-code-editor-dialog.component';

@Component({
  selector: 'app-gift-catd-details',
  standalone: true,
  imports: [ChartModule, TableModule, SplitButtonModule, ConfirmDialogModule, UsdPipe, DisplayDatePipe],
  providers: [DialogService, ConfirmationService],
  templateUrl: './gift-card-details.component.html',
  styleUrl: './gift-card-details.component.scss'
})
export class GiftCardDetailsComponent implements OnInit {
  private giftCardService: GiftCardService = inject(GiftCardService);
  private giftCardCodeService: GiftCardCodeService = inject(GiftCardCodeService);

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private dialogService: DialogService = inject(DialogService);
  private toastService: ToastService = inject(ToastService);
  private confirmationService: ConfirmationService = inject(ConfirmationService);

  dialogRef: DynamicDialogRef | undefined;

  storefrontId: string;
  giftCardId: string;
  giftCard: GiftCard;
  data: any;
  options: any;
  detailsAddtionalText: string[] = [];
  totalValue: number;
  totalRemainingValue: number;

  splitModel: MenuItem[] = [];

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.giftCardId = params.get('giftCardId') ?? '';
      if (this.giftCardId) {
        this.fetchGiftCard();
      }
    });
  }

  fetchGiftCard() {
    this.giftCardService.find(this.storefrontId, this.giftCardId).subscribe({
      next: res => {
        this.giftCard = res.data;
        this.prepareChartData();
        this.prepareAdditionalText();
        this.calculateGiftCardTotals();
        this.prepareSplitModel();
      },
      error: err => {
        console.error('Operation failed:', err);
      }
    });
  }

  prepareChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    this.data = this.createChartData(this.giftCard.number_of_codes);

    this.options = this.createChartOptions(textColor);
  }

  createChartData(statusCounts: GiftCardCodesNumber) {
    return {
      labels: ['Active', 'Used', 'Deleted'],
      datasets: [
        {
          data: [statusCounts.active, statusCounts.used, statusCounts.deleted],
          backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
          hoverBackgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)']
        }
      ]
    };
  }

  createChartOptions(textColor: string): any {
    return {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor
          }
        }
      }
    };
  }

  prepareAdditionalText() {
    const { cover_sales_tax, cover_shipping, single_use, expiration_date } = this.giftCard;

    const additionalText: string[] = [];

    if (cover_sales_tax) additionalText.push('Store covers sales tax');
    if (cover_shipping) additionalText.push('Store covers shipping costs');
    if (single_use) additionalText.push('Single use');

    if (expiration_date) {
      const formattedDate = new Date(expiration_date).toLocaleDateString();
      additionalText.push(`Expiration date: ${formattedDate}`);
    }

    if (additionalText.length === 0) {
      additionalText.push('Not set');
    }

    this.detailsAddtionalText = additionalText;
  }

  prepareSplitModel() {
    if (!this.giftCard) {
      return;
    }

    this.splitModel = [
      ...(this.giftCard.specified_recipients
        ? [
            {
              label: 'Send Gift email to the recipient',
              icon: 'pi pi-envelope',
              giftCard: null,
              command: event => {
                const codeId = event?.item?.code.id;
                this.sendEmails(codeId);
              }
            },
            { separator: true }
          ]
        : []),
      ...(this.giftCard?.specified_recipients
        ? [
            {
              label: 'Edit',
              icon: 'pi pi-pencil',
              command: event => {
                const code = event?.item?.code;
                this.openGiftCardCodeEditDialog(code);
              }
            },
            { separator: true }
          ]
        : []),
      {
        label: 'Delete',
        styleClass: 'p-text-danger',
        icon: 'pi pi-trash',
        command: event => {
          const codeId = event?.item?.code.id;
          this.openDeleteModal(codeId);
        }
      }
    ];
  }

  calculateGiftCardTotals() {
    let totalValue = 0;
    let totalRemainingValue = 0;

    this.giftCard.codes.forEach(code => {
      totalValue += code.amount;
      totalRemainingValue += code.balance;
    });

    this.totalValue = totalValue;
    this.totalRemainingValue = totalRemainingValue;
  }

  openGiftCardCodeEditDialog(code: GiftCardCode) {
    this.dialogRef = this.dialogService.open(GiftCardCodeEditorDialogComponent, {
      header: 'Edit Gift Card Code',
      width: '540px',
      data: {
        code: code,
        storefrontId: this.storefrontId
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Gift card code successfully updated.');
        this.fetchGiftCard();
      }
    });
  }

  sendEmails(giftCardCodeId: number) {
    this.giftCardCodeService.sendEmails(+this.storefrontId, this.giftCard.id, [giftCardCodeId]).subscribe({
      next: () => {
        this.toastService.success('Email successfully sent!');
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }

  openDeleteModal(giftCardCodeId: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message:
        'Are you sure that you want to delete this Gift Card Code?</br></br><strong>This operation cannot be undone!</strong>',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteGiftCardCode(giftCardCodeId);
      }
    });
  }

  deleteGiftCardCode(giftCardCodeId: number) {
    this.giftCardCodeService.remove(+this.storefrontId, giftCardCodeId).subscribe({
      next: () => {
        this.toastService.success('Gift card code successfully deleted!');
        this.fetchGiftCard();
      },
      error: err => {
        console.error('Operation failed:', err);
      }
    });
  }

  splitClick(code: any) {
    console.log('e');
  }

  onDropdownClick($event: Event, code: GiftCardCode) {
    this.splitModel.forEach(menuItem => (menuItem.code = code));
  }
}
