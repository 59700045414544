import { inject } from '@angular/core';

import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';

import { withLocalStorageSync } from 'app/_store/with-local-storage-sync';

import { Storefront } from '../_shared/models/Storefront';
import { Config, DesignerStore } from './designer.store';

export type StorefrontState = {
  selectedStorefront: Storefront | null;
  isLoading: boolean;
};

const initialState: StorefrontState = {
  selectedStorefront: null,
  isLoading: false
};

export const StorefrontStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('storefront'),
  withLocalStorageSync('selectedStorefront'),
  withMethods(store => {
    const designerStore = inject(DesignerStore);

    return {
      setSelectedStorefront: (storefront: Storefront) => {
        patchState(store, { selectedStorefront: storefront });
        store.saveToLocalStorage();
        // TODO: Maybe this needs to be moved into storefront resolver???
        const data = JSON.parse(storefront.data);
        const designerConfigData: Config = {
          ...data,
          settings: {
            ...data.settings,
            logo: storefront.logo ? storefront.logo.full_path : {}
          }
        };
        designerStore.saveConfig(storefront.id, designerConfigData, true);
      },

      unsetSelectedStorefront: () => {
        patchState(store, { selectedStorefront: undefined });
        designerStore.resetConfig();
        store.removeFromLocalStorage();
      },
      setLoading: (isLoading: boolean) => patchState(store, { isLoading })
    };
  }),
  withHooks(store => ({
    onInit() {
      if (store.loadFromLocalStorage()) {
        return;
      }
    }
  }))
);
