<div class="mb-6 flex flex-column align-items-center">
  <div class="text-900 text-xl font-bold mb-2">Screen Locked</div>
  <span class="text-600 font-medium mb-5">Please enter your password</span>
  <img src="assets/layout/images/avatar.png" class="w-3rem h-3rem mb-2" alt="Avatar" />
  <span class="font-medium text-900 font-medium">Isabella Andolini</span>
</div>
<div class="flex flex-column">
  <span class="p-input-icon-left w-full mb-4">
    <i class="pi pi-lock"></i>
    <input id="password" type="password" pInputText class="w-full md:w-25rem" placeholder="Password" />
  </span>
  <button pButton pRipple icon="pi pi-lock-open" label="Unlock" class="w-full" [routerLink]="['/']"></button>
</div>
