@if (user) {
  <p-dialog
    [(visible)]="visible"
    [style]="{ width: '28rem' }"
    header="User Details"
    [modal]="true"
    class="p-fluid"
    (onHide)="hideDialog()"
  >
    <ng-template pTemplate="content">
      <form [formGroup]="userEditForm" (ngSubmit)="onSubmit()">
        <div class="field">
          <label for="first_name">First Name</label>
          <input
            id="first_name"
            type="text"
            pInputText
            formControlName="first_name"
          />
          @if (
            (submitted || userEditForm.get('first_name')?.touched) &&
            userEditForm.get('first_name')?.errors?.['required']
          ) {
            <div>
              <small class="p-text-danger">First name is required</small>
            </div>
          }
        </div>
        <div class="field">
          <label for="last_name">Last Name</label>
          <input
            id="last_name"
            type="text"
            pInputText
            formControlName="last_name"
          />
          @if (
            (submitted || userEditForm.get('last_name')?.touched) &&
            userEditForm.get('last_name')?.errors?.['required']
          ) {
            <div>
              <small class="p-text-danger">Last name is required</small>
            </div>
          }
        </div>
        <div class="field">
          <label for="phone">Phone</label>
          <input id="phone" type="text" pInputText formControlName="phone" />
        </div>
        <div class="field">
          <label for="email">Email</label>
          <input id="email" type="email" pInputText formControlName="email" />
          @if (submitted || userEditForm.get('email')?.touched) {
            @if (userEditForm.get('email')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Email is required</small>
              </div>
            } @else if (userEditForm.get('email')?.errors?.['email']) {
              <div>
                <small class="p-text-danger">Email is invalid</small>
              </div>
            }
          }
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
        outlined
      ></p-button>
      <p-button
        type="button"
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        (click)="onSubmit()"
      ></p-button>
    </ng-template>
  </p-dialog>
}
