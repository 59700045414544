<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Categories</h5>
      <p-table
        class="category-table"
        [value]="isLoading || isLoadingDelete ? [] : productCategories"
        dataKey="name"
        [loading]="isLoading || isLoadingDelete"
        [showLoader]="false"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex table-header justify-content-end">
            <p-button icon="pi pi-fw pi-plus" label="Create category" outlined (click)="openCategoryEditor()" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="white-space-nowrap" style="width: 5%"></th>
            <th class="white-space-nowrap" style="width: 20%">Image</th>
            <th class="white-space-nowrap" style="width: 60%">Name</th>
            <th class="white-space-nowrap" style="width: 15%">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-category let-expanded="expanded">
          <tr class="p-14 row-hover">
            <td>
              <p-button
                type="button"
                [pRowToggler]="category"
                styleClass="p-button-text"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                outlined
              ></p-button>
            </td>
            <td>
              <img
                [ngSrc]="category.category_image.full_path"
                [alt]="category.category_image.name"
                width="80"
                height="100"
                class="shadow-4"
                style="object-fit: contain"
                priority
              />
            </td>
            <td>
              {{ category.name }}
            </td>
            <td>
              <div class="flex gap-2">
                <p-button
                  type="button"
                  icon="pi pi-plus"
                  outlined
                  pTooltip="Add subcategory"
                  tooltipPosition="top"
                  (click)="openCategoryEditor(undefined, category.id)"
                ></p-button>
                <p-button
                  type="button"
                  icon="pi pi-pen-to-square"
                  outlined
                  (click)="openCategoryEditor(category)"
                ></p-button>
                <p-button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  outlined
                  (click)="openDeleteModal($event, category.id)"
                ></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-category>
          <tr>
            <td colspan="4" class="p-0">
              <div class="my-5">
                <p-table [value]="category.sub_categories" dataKey="id" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="white-space-nowrap" style="width: 5%; min-width: 70px; visibility: hidden"></th>
                      <th class="white-space-nowrap" style="width: 20%; min-width: 108px">Image</th>
                      <th class="white-space-nowrap" style="width: 60%">Name</th>
                      <th class="white-space-nowrap" style="width: 15%; min-width: 168px">Actions</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-subcategory>
                    <tr>
                      <td style="visibility: hidden"></td>
                      <td>
                        <div class="flex gap-2 justify-content-start align-items-center" style="width: 40px">
                          <img
                            [ngSrc]="subcategory.category_image.full_path"
                            [alt]="subcategory.name"
                            width="60"
                            height="75"
                            class="shadow-4"
                            style="object-fit: contain"
                            priority
                          />
                        </div>
                      </td>
                      <td>{{ subcategory.name }}</td>
                      <td>
                        <div class="flex gap-2">
                          <p-button
                            type="button"
                            icon="pi pi-pen-to-square"
                            outlined
                            (click)="openCategoryEditor(subcategory)"
                          ></p-button>
                          <p-button
                            type="button"
                            icon="pi pi-trash"
                            severity="danger"
                            outlined
                            (click)="openDeleteModal($event, subcategory.id)"
                          ></p-button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td style="visibility: hidden"></td>
                      <td colspan="3" class="text-center">There are no subcategories</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4" class="text-center">There are no categories</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          @for (row of loadingRows; track row) {
            <tr>
              @for (col of ['expand', 'image', 'name', 'actions']; track col) {
                <td>
                  @if (col === 'image') {
                    <p-skeleton width="6rem" height="7rem" />
                  } @else if (col === 'actions') {
                    <div class="flex">
                      <p-skeleton size="3rem" styleClass="mr-2" />
                      <p-skeleton size="3rem" styleClass="mr-2" />
                      <p-skeleton size="3rem" styleClass="mr-2" />
                    </div>
                  } @else if (col === 'expand') {
                    <p-skeleton size="3rem" styleClass="mr-2" />
                  } @else {
                    <p-skeleton />
                  }
                </td>
              }
            </tr>
          }
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteCategory"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
