<div class="card">
  <a [routerLink]="['../']">Back</a>
  <h3>{{ selectionId ? 'Edit' : 'Create' }} selection</h3>
  <form [formGroup]="selectionForm" (ngSubmit)="handleSubmit()">
    <div class="new-selection">
      <app-input
        name="Title"
        label="Selection name"
        [control]="selectionForm.get('title')!"
        placeholder="Selection name"
      ></app-input>
      <app-input
        name="Comment"
        label="Comment"
        [control]="selectionForm.get('comment')!"
        placeholder="Comment"
      ></app-input>
    </div>
    <p>Add collections</p>
    <app-list-products
      [products]="collections"
      [columns]="cols"
      [paginatorOptions]="paginatorOptions"
      (lazyLoadProducts)="getCollections($event)"
      [multiSelect]="true"
      [loading]="false"
      [(selectedItems)]="selectedItems"
      (onSelectionChange)="handleSelectionChange($event)"
      emptyMessage="There are no collections"
    ></app-list-products>
    <div class="flex justify-content-end mt-2">
      <button
        [disabled]="!selectionForm.valid || !selectedItems.length"
        pButton
        icon="pi pi-check"
        type="submit"
        label="Save"
      ></button>
    </div>
  </form>
</div>
