import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { finalize, Subscription } from 'rxjs';

import { StatusBadgeComponent } from 'app/_shared/components/status-badge/status-badge.component';

// eslint-disable-next-line max-len
import { InviteCompanyModalComponent } from '../../_shared/components/invite-company-modal/invite-company-modal.component';
import { InviteStatusType } from '../../_shared/models/InviteStatusType';
import { InviteService } from '../../_shared/services/invite.service';
import { ToastService } from '../../_shared/services/toast.service';

@Component({
  selector: 'app-invite-company-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    ProgressBarModule,
    RouterLink,
    CommonModule,
    InputTextModule,
    ConfirmDialogModule,
    SkeletonModule,
    StatusBadgeComponent
  ],
  providers: [ConfirmationService, DialogService],
  templateUrl: './invite-company-list.component.html'
})
export class InviteCompanyListComponent implements OnDestroy {
  inviteService = inject(InviteService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  protected readonly InviteStatusType = InviteStatusType;

  private subscriptions: Subscription[] = [];

  invites: any[];
  totalRecords: number = 0;
  isLoading = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });

  dialogRef: DynamicDialogRef | undefined;

  loadInvites($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.inviteService
      .index($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.invites = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  openDeleteModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this invite?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteInvite(id);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private deleteInvite(inviteId: number) {
    this.subscriptions.push(
      this.inviteService
        .deleteInvite(inviteId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: () => {
            this.toastService.success('Invite successfully deleted!');
            this.loadInvites();
          },
          error: err => {
            console.error('Delete action failed:', err);
          }
        })
    );
  }

  openResendModal(event: Event, id: number, status: string) {
    this.confirmationService.confirm({
      key: 'resendInvite',
      target: event.target || new EventTarget(),
      message:
        status === InviteStatusType.requested
          ? 'Are you sure that you want to accept this invite?'
          : 'Are you sure that you want to resend this invite?',
      icon: 'pi pi-question',
      accept: () => {
        this.resendInviteToUser(id);
      },
      acceptButtonStyleClass: 'p-button-filled',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private resendInviteToUser(inviteId: number) {
    this.isLoading = true;
    this.subscriptions.push(
      this.inviteService
        .resendInvite(inviteId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: () => {
            this.toastService.success('Invite successfully sent!');
            this.loadInvites();
          },
          error: err => {
            console.error('Invite action failed:', err);
          }
        })
    );
  }

  openInviteCompanyModal() {
    this.dialogRef = this.dialogService.open(InviteCompanyModalComponent, {
      header: 'Invite new company to SSM platform',
      width: '28rem'
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.loadInvites();
        this.toastService.success('Invite sent successfully!');
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
