<div>
  <div class="flex gap-3 mb-5">
    @for (printArea of printAreas(); track printArea; let i = $index) {
      @if (selectedArea.id !== printArea.id) {
        <p-splitButton
          [label]="printArea.name"
          [outlined]="true"
          (onClick)="handleSelectPrintArea(printArea)"
          [model]="printAreaOptions()[i]"
        />
      } @else {
        <p-splitButton
          [label]="printArea.name"
          (onClick)="handleSelectPrintArea(printArea)"
          [model]="printAreaOptions()[i]"
        />
      }
    }
    <p-button icon="pi pi-plus" label="New print area" (click)="addPrintAreaModal = true" />
  </div>

  <div class="col-12 overflow-x-auto mb-5" style="transform: rotateX(180deg)">
    <app-konva [printArea]="selectedArea" (onChange)="handlePrintAreaChange($event)" />
  </div>
  <form [formGroup]="printAreaForm" (ngSubmit)="handleAddPrintArea()">
    <p-dialog
      [header]="editMode ? 'Edit print area' : 'Add print area'"
      [(visible)]="addPrintAreaModal"
      [modal]="true"
      [style]="{ width: '35rem' }"
      (onHide)="closeModal()"
    >
      <div class="new-page">
        <app-input
          name="Name"
          label="Area name"
          [control]="printAreaForm.get('name')!"
          placeholder="Print area name"
        ></app-input>
        <label class="mt-2 mb-2 block">Select area type</label>
        @for (type of areaTypes; track type.key) {
          <div class="field-checkbox">
            <p-radioButton [inputId]="type.key" [value]="type.key" formControlName="type" />
            <label [for]="type.key" class="ml-2">
              {{ type.name }}
            </label>
          </div>
        }
      </div>

      <ng-template pTemplate="footer">
        <p-button [outlined]="true" [type]="'button'" [label]="'Cancel'" (click)="closeModal()" />
        <p-button [type]="'submit'" [label]="'Save'" [disabled]="printAreaForm.invalid" />
      </ng-template>
    </p-dialog>
  </form>
</div>
