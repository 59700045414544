import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { TabMenuModule } from 'primeng/tabmenu';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Supplier } from 'app/_shared/interfaces/supplier';
import { SupplierService } from 'app/_shared/services/supplier.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-supplier-details',
  standalone: true,
  imports: [CardModule, ReactiveFormsModule, InputComponent, InputGroupAddonModule, Button, TabMenuModule],
  templateUrl: './supplier-details.component.html',
  styleUrl: './supplier-details.component.scss'
})
export class SupplierDetailsComponent implements OnInit {
  private supplierService = inject(SupplierService);
  private activatedRoute = inject(ActivatedRoute);
  supplier: Supplier;

  tabMenuItems: MenuItem[] = [
    { label: 'Settings', routerLink: 'settings' },
    { label: 'Dictionary', routerLink: 'dictionary' }
  ];

  ngOnInit() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.supplierService.getSupplierBySlug(slug).subscribe(next => {
      this.supplier = next.data;
    });
  }
}
