import { Injectable } from '@angular/core';

import { FormGroupValue } from '../models/FormGroup';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService {
  routePrefix = `${this.apiUrl}users/`;

  public index($event?: any) {
    return this.get(this.routePrefix, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  public getById(id: number) {
    return this.get(this.routePrefix + id);
  }

  public updateUser(id: number, data: FormGroupValue) {
    return this.put(`${this.routePrefix}${id}`, data);
  }

  public deleteUser(id: number) {
    return this.delete(this.routePrefix + id);
  }
}
