<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '450px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
/>

<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex justify-content-between">
        <h5>Storefronts</h5>
        <div>
          <p-button [routerLink]="['create']" label="Create new Storefront" />
        </div>
      </div>

      <p-dataView
        #dv
        [value]="storefronts"
        [first]="first"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="9"
        [lazy]="true"
        filterBy="name"
        [sortField]="sortField"
        [sortOrder]="sortOrder"
        layout="grid"
        (onLazyLoad)="loadStorefronts($event)"
      >
        <ng-template let-products pTemplate="gridItem">
          <div class="grid grid-nogutter">
            @for (storefront of storefronts; track storefront) {
              <div class="col-12 md:col-6 lg:col-4">
                <div class="card m-3 border-1 surface-border" style="padding: 2rem 0">
                  <div class="flex flex-column align-items-center text-center mb-3">
                    <div
                      class="my-3 mx-0 cursor-pointer"
                      style="width: 100%; height: 150px"
                      [routerLink]="[storefront.id]"
                    >
                      @if (storefront?.logo) {
                        <img
                          [src]="storefront?.logo?.full_path"
                          [alt]="storefront.name"
                          style="object-fit: cover; width: auto; max-height: 150px"
                        />
                      } @else {
                        <div style="height: 100%" class="flex justify-content-center flex-wrap align-content-center">
                          No logo uploaded
                        </div>
                      }
                    </div>
                    <div class="text-2xl font-bold mb-2 cursor-pointer" [routerLink]="[storefront.id]">
                      {{ storefront.name }}
                    </div>
                    <div class="mb-3 flex flex-row gap-3 align-items-center justify-content-center w-full">
                      <div style="max-width: calc(100% - 110px); text-overflow: ellipsis; overflow: hidden">
                        {{ storefront.url }}
                      </div>
                      <div>
                        <p-button
                          icon="pi pi-external-link"
                          (onClick)="openInNewTab(storefront.url)"
                          [outlined]="true"
                          styleClass="btn-without-focus"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex align-items-center justify-content-center gap-3">
                    <p-button [routerLink]="[storefront.id, 'products']" type="button" label="Details" [text]="true" />
                    <p-button [routerLink]="[storefront.id, 'edit']" type="button" label="Edit" [text]="true" />
                    @if (
                      user?.role?.name === UserRoleNames.SuperAdmin || user?.role?.name === UserRoleNames.CompanyAdmin
                    ) {
                      <p-button
                        (click)="openDeleteModal($event, storefront.id)"
                        type="button"
                        [text]="true"
                        label="Delete"
                        severity="danger"
                      />
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
