<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="field">
    @if (editMode) {
      <label class="pb-2" for="email">Edit Receipient’s Email Address</label>
    } @else {
      <label class="pb-2" for="email">New Receipient’s Email Address</label>
    }
    <input type="email" pInputText class="w-full" formControlName="email" />
    @if (submitted || (form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched))) {
      @if (form.get('email')?.errors?.['required']) {
        <div>
          <small class="p-text-danger">Email is required</small>
        </div>
      }
      @if (form.get('email')?.errors?.['email']) {
        <div>
          <small class="p-text-danger">Email is not valied</small>
        </div>
      }
    }
    <div class="pt-3">
      <p-button pRipple label="Confirm" type="submit" />
    </div>
  </div>
</form>
