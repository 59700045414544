<div class="form-wrap">
  @if (form) {
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form">
        <div>
          <h5>Decoration Method</h5>
          <div class="full-width-select">
            <p-selectButton [options]="decorationMethodOptions" optionLabel="name"></p-selectButton>
          </div>
          <h5>Recent Files</h5>
          <ul class="list-none p-0 m-0">
            @for (file of latestUsedMedias; track i; let i = $index) {
              <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border hover:surface-hover">
                <img [src]="file.mockup_file?.full_path" alt="{{ file.name }}" class="w-3rem flex-shrink-0 mr-3" />
                <div class="flex flex-column">
                  <span>date</span>
                </div>
                <button
                  (click)="toggleSelection(file)"
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-upload"
                  aria-label="Toggle"
                  class="ml-auto p-button-text p-button-rounded"
                ></button>
              </li>
            }
          </ul>
        </div>
        <div>
          <p-button
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Upload or choose design file"
            type="button"
            outlined
            (click)="handleOpenMediaLibraryModal.emit()"
          ></p-button>
        </div>
        <p-panel
          #panelName
          toggler="header"
          header="Product Name & URL"
          [toggleable]="true"
          [collapsed]="true"
          [ngClass]="{
            'panel-with-error':
              panelName.collapsed && form.get('name')?.invalid && (form.get('name')?.touched || form.get('name')?.dirty)
          }"
        >
          <div class="flex justify-content-end">
            <button
              pButton
              pRipple
              type="button"
              label="Edit slug"
              aria-label="Edit slug"
              class="p-button-text p-0"
              (click)="visibleSlugInput = true"
            ></button>
          </div>
          <div class="form-inputs">
            <app-input
              [fullWidth]="true"
              name="name"
              label="Name"
              [control]="this.form.get('name')!"
              placeholder="Product name"
            ></app-input>
          </div>

          @if (visibleSlugInput) {
            <div class="form-inputs">
              <app-input
                [fullWidth]="true"
                name="slug"
                label="Slug"
                [control]="this.form.get('slug')!"
                placeholder="Slug"
              ></app-input>
            </div>
          }
          <div class="flex flex-column gap-2 mt-2">
            <p-editor
              formControlName="short_description"
              [style]="{ height: '100px' }"
              placeholder="Short description (optional)"
            >
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
              </ng-template>
            </p-editor>
            <p-editor
              formControlName="copy_underneath_description"
              [style]="{ height: '100px' }"
              placeholder="Copy underneath Add to cart"
            >
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
              </ng-template>
            </p-editor>
          </div>
        </p-panel>
        <p-panel
          #panelStatus
          toggler="header"
          header="Product Status"
          [toggleable]="true"
          [collapsed]="true"
          [ngClass]="{
            'panel-with-error':
              panelStatus.collapsed &&
              ((form.get('status')?.invalid && (form.get('status')?.touched || form.get('status')?.dirty)) ||
                (form.get('start_publish_date')?.invalid &&
                  (form.get('start_publish_date')?.touched || form.get('start_publish_date')?.dirty)))
          }"
        >
          <div class="formgrid grid">
            @for (productStatus of productStatusOptions; track productStatus) {
              <div class="field-radiobutton col-6">
                <p-radioButton
                  formControlName="status"
                  [value]="productStatus.value"
                  [inputId]="productStatus.value"
                ></p-radioButton>
                <label [for]="productStatus.value">{{ productStatus.name }}</label>
              </div>
            }
          </div>
          @if (form.get('status')?.value === 'scheduled') {
            <div class="flex gap-1">
              <div class="flex flex-column field w-full">
                <label for="startDate" class="block text-900 font-semibold">Start Date</label>
                <span class="p-input-icon-left w-full" style="height: 3.5rem">
                  <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
                  <p-calendar
                    formControlName="start_publish_date"
                    name="startDate"
                    [dateFormat]="displayDateFormat"
                    hourFormat="12"
                    [showTime]="true"
                    [required]="form.get('status')?.value === 'scheduled'"
                    inputId="startDate"
                    id="startDate"
                    inputStyleClass="w-full pl-7 text-900 font-semibold"
                    styleClass="w-full"
                    [inputStyle]="{ height: '3.5rem' }"
                  ></p-calendar>
                </span>
                @if (
                  form.get('start_publish_date')?.invalid &&
                  (form.get('start_publish_date')?.touched || form.get('start_publish_date')?.dirty)
                ) {
                  <div class="flex align-items-center">
                    <small class="p-text-danger">A start date is required when scheduling this product</small>
                  </div>
                }
              </div>
              <div class="flex flex-column field w-full">
                <label for="endDate" class="block text-900 font-semibold">End Date</label>
                <span class="p-input-icon-left w-full" style="height: 3.5rem">
                  <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
                  <p-calendar
                    formControlName="end_publish_date"
                    name="endDate"
                    [dateFormat]="displayDateFormat"
                    hourFormat="12"
                    [showTime]="true"
                    [required]="true"
                    inputId="endDate"
                    id="endDate"
                    inputStyleClass="w-full pl-7 text-900 font-semibold"
                    styleClass="w-full"
                    [inputStyle]="{ height: '3.5rem' }"
                    [minDate]="form.get('start_publish_date')?.value"
                  ></p-calendar>
                </span>
              </div>
            </div>
          }
        </p-panel>
        <p-panel #panelStatus toggler="header" header="Product Colors" [toggleable]="true" [collapsed]="true">
          <div class="col-12">
            <div class="flex gap-2">
              <p-button [outlined]="true" label="Select all" (click)="selectAllVariants.emit(true)" />
              <p-button [outlined]="true" label="Deselect all" (click)="selectAllVariants.emit(false)" />
            </div>
            <div class="mt-3 flex flex-wrap gap-2 p-2 product-colors">
              @for (variant of productVariants(); track variant.id; let i = $index) {
                <div>
                  <p-toggleButton
                    [(ngModel)]="variant.checked"
                    (onChange)="variantSelectChange($event, variant.id!)"
                    [ngModelOptions]="{ standalone: true }"
                    [onLabel]="variant.color"
                    [offLabel]="variant.color"
                    onIcon="pi pi-check-square"
                    offIcon="pi pi-stop"
                  />
                </div>
              }
            </div>
          </div>
        </p-panel>
        <p-panel toggler="header" header="Additional Photos" [toggleable]="true" [collapsed]="true">
          <p-fileUpload
            name="demo[]"
            (onSelect)="onAdditionalPhotosUpload($event)"
            [multiple]="true"
            accept="image/*"
            maxFileSize="1000000"
            showUploadButton="false"
          >
            <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
              <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                <div class="w-full flex justify-content-between gap-2">
                  <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Photo" />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="file"></ng-template>
            <ng-template pTemplate="content">
              @for (photo of additionalPhotos; let i = $index; track i) {
                @if (photo?.objectURL) {
                  <div class="flex">
                    <div class="col-4">
                      <img [src]="photo?.objectURL" alt="Uploaded Image" style="width: 50px" />
                    </div>
                    <div class="col-4">
                      <div class="flex justify-content-center align-items-center text-center h-full">
                        {{ photo?.fileName }}
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="flex justify-content-end">
                        <p-button type="button" icon="pi pi-times" (onClick)="removeAdditionalPhoto(i)"></p-button>
                      </div>
                    </div>
                  </div>
                }
              }
            </ng-template>
          </p-fileUpload>
        </p-panel>
        <p-panel toggler="header" header="Categories" [toggleable]="true" [collapsed]="true">
          <p-selectButton
            [options]="productCategoryOptions()"
            [multiple]="true"
            optionLabel="name"
            formControlName="product_categories"
          />
          <div class="pt-2">
            <button
              pButton
              pRipple
              type="button"
              label="Add category"
              aria-label="Add category"
              class="p-button-text p-0"
              (click)="openCategoryEditor()"
            ></button>
          </div>
        </p-panel>
        <p-panel
          #panelDescription
          toggler="header"
          header="Description"
          [toggleable]="true"
          [collapsed]="true"
          [ngClass]="{
            'panel-with-error':
              panelDescription.collapsed &&
              form.get('description')?.invalid &&
              (form.get('description')?.touched || form.get('description')?.dirty)
          }"
        >
          <p-editor formControlName="description" [style]="{ height: '100px' }" placeholder="Product information">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
          @if (
            form.get('description')?.invalid && (form.get('description')?.dirty || form.get('description')?.touched)
          ) {
            @if (form.get('description')?.errors?.['required']) {
              <div class="flex align-items-center">
                <small class="p-text-danger">Description is required</small>
              </div>
            }
          }
        </p-panel>
        <div class="footer-actions justify-content-end">
          <p-button
            [style]="{ flex: 1 }"
            [loading]="isSubmitting()"
            icon="pi pi-check"
            type="submit"
            [label]="editMode() ? 'Update storefront product' : 'Create storefront product'"
            aria-label="Create storefront product"
          ></p-button>
        </div>
      </div>
    </form>
  }
</div>
