import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { StorefrontStore } from '../../_store/storefront.store';
import { StorefrontService } from '../services/storefront.service';

export const selectedStorefrontResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const storefrontService = inject(StorefrontService);
  const storefrontStore = inject(StorefrontStore);
  const { storefrontId } = route.params;

  storefrontStore.setLoading(true);
  return storefrontService.getById(storefrontId).subscribe({
    next: res => storefrontStore.setSelectedStorefront(res.data),
    error: error => console.log(error),
    complete: () => storefrontStore.setLoading(false)
  });
};
