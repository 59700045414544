import { Injectable } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class CompanyOrderService extends ApiService {
  routePrefix = `${this.apiUrl}companies/`;

  public index() {
    return this.get(this.routePrefix);
  }

  public paginatedIndex(companyId?: number, event?: TableLazyLoadEvent, params?: any) {
    return this.get(`${this.routePrefix}${companyId}/orders`, {
      params: this.handleTableLazyLoadEvent(event, params)
    });
  }

  public getById(companyId: number, orderId: number, params?: any) {
    return this.get(`${this.routePrefix}${companyId}/orders/${orderId}`, { params });
  }
}
