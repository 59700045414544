import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === null || value === undefined) return '';

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(value) / Math.log(1024));

    return (value / Math.pow(1024, i)).toFixed(2) + ' ' + units[i];
  }
}
