import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';

import { StatusBadgeComponent } from 'app/_shared/components/status-badge/status-badge.component';

import { UserPerrmissionsModalComponent } from '../../../_shared/components/user-permissions-modal/user-permissions-modal.component';
import { InviteStatusType } from '../../../_shared/models/InviteStatusType';
// eslint-disable-next-line max-len
import UserInvite from '../../../_shared/models/UserInvite';
import { CompanyService } from '../../../_shared/services/company.service';
import { ToastService } from '../../../_shared/services/toast.service';

@Component({
  selector: 'app-invite-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    ProgressBarModule,
    RouterLink,
    CommonModule,
    InputTextModule,
    ConfirmDialogModule,
    TitleCasePipe,
    StatusBadgeComponent
  ],
  providers: [ConfirmationService, DialogService],
  templateUrl: './invite-list.component.html'
})
export class InviteListComponent implements OnInit, OnDestroy {
  companyService = inject(CompanyService);
  toastService = inject(ToastService);
  route = inject(ActivatedRoute);
  confirmationService = inject(ConfirmationService);
  dialogService = inject(DialogService);
  protected readonly InviteStatusType = InviteStatusType;

  invites: UserInvite[] = [];
  totalRecords: number;
  isLoading = false;

  companyId: number;

  private subscriptions: Subscription[] = [];
  editUserDialog = false;
  dialogRef: DynamicDialogRef | undefined;

  ngOnInit(): void {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
  }

  loadInvites($event?: TableLazyLoadEvent) {
    this.companyService.invites(this.companyId, $event).subscribe({
      next: res => {
        this.invites = res.data.data;
        this.totalRecords = res.data.total;
      },
      error: error => console.error(error)
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  openUserInviteDialog() {
    this.dialogRef = this.dialogService.open(UserPerrmissionsModalComponent, {
      header: 'Invite new user to SSM platform',
      width: '540px'
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.loadInvites();
        this.toastService.success('Invite sent successfully!');
      }
    });
  }

  openDeleteModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this invite?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteInvite(id);
      }
    });
  }

  private deleteInvite(inviteId: number) {
    this.subscriptions.push(
      this.companyService.deleteInvite(this.companyId, inviteId).subscribe({
        next: () => {
          this.toastService.success('Invite successfully deleted!');
          this.loadInvites();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      })
    );
  }

  openResendModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'resendInvite',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to resend this invite?',
      accept: () => {
        this.resendInviteToUser(id);
      }
    });
  }

  private resendInviteToUser(inviteId: number) {
    this.subscriptions.push(
      this.companyService.resendInvite(this.companyId, inviteId).subscribe({
        next: () => {
          this.toastService.success('Invite successfully resent!');
          this.loadInvites();
        },
        error: err => {
          console.error('Invite action failed:', err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
