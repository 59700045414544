import { Injectable } from '@angular/core';

import { GiftCardCode } from 'app/_shared/models/Coupon';
import { ApiService } from 'app/_shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class GiftCardCodeService extends ApiService {
  routePrefix = `${this.apiUrl}storefronts/`;

  update(storefrontId: number, code: GiftCardCode, giftCardCodeId?: number) {
    return this.put<GiftCardCode>(`${this.routePrefix}${storefrontId}/gift-card-codes/${giftCardCodeId}`, code);
  }

  remove(storefrontId: number, giftCardCodeId: number) {
    return this.delete(`${this.routePrefix}${storefrontId}/gift-card-codes/${giftCardCodeId}`);
  }

  sendEmails(storefrontId: number, giftCardId: number, giftCardCodeIds: number[]) {
    return this.post(`${this.routePrefix}${storefrontId}/gift-cards/${giftCardId}/gift-card-codes/send-emails`, {
      gift_card_code_ids: giftCardCodeIds
    });
  }
}
