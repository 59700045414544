@if (order) {
  <p-card>
    <div class="p-grid p-dir-col">
      <div class="p-col-12">
        <h2>Order Information</h2>
      </div>

      <div class="p-col-12">
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Order ID:</strong>
            {{ order.id }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Status:</strong>
            {{ order.status }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Total:</strong>
            {{ order.total | price | usdPipe }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Created At:</strong>
            {{ order.created_at | usdDate }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Store name:</strong>
            {{ order.storefront.name }}
          </div>
        </div>
      </div>
      <p-divider></p-divider>

      <div class="p-col-12">
        <h2>Shipping Method</h2>
      </div>
      <div class="p-col-12">
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <i class="pi pi-truck"></i>
            {{ order.shipping_method.name }} ({{ order.shipping_method.code }})
          </div>
        </div>
      </div>
      <p-divider></p-divider>

      <div class="p-col-12">
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <h3>Contact Info</h3>
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Name:</strong>
            {{ order.contact_info.first_name }}
            {{ order.contact_info.last_name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Email:</strong>
            {{ order.contact_info.email }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Phone:</strong>
            {{ order.contact_info.phone_number }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Address 1:</strong>
            {{ order.contact_info.address_1 }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Address 2:</strong>
            {{ order.contact_info.address_2 }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>City:</strong>
            {{ order.contact_info.city }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>State:</strong>
            {{ order.contact_info.state.name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Country:</strong>
            {{ order.contact_info.country.name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Zip Code:</strong>
            {{ order.contact_info.zip }}
          </div>
        </div>
      </div>
      <p-divider></p-divider>

      <div class="p-col-12">
        <h3>Billing Info</h3>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Name:</strong>
            {{ order.billing_info.first_name }}
            {{ order.billing_info.last_name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Address:</strong>
            {{ order.billing_info.address }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>City:</strong>
            {{ order.billing_info.city }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>State:</strong>
            {{ order.billing_info.state.name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Country:</strong>
            {{ order.billing_info.country.name }}
          </div>
        </div>
        <div class="p-field p-grid mb-2">
          <div class="p-col-12">
            <strong>Zip Code:</strong>
            {{ order.billing_info.zip }}
          </div>
        </div>
      </div>
      <p-divider></p-divider>

      <div class="p-col-12">
        <h3>Items</h3>
        <ul class="list-none p-0 m-0">
          @for (item of order.items; track $index) {
            <li
              class="flex flex-column md:flex-row py-3 border-top-1 border-bottom-1 surface-border md:align-items-center"
            >
              <img
                [src]="
                  item.product_variant.front_image?.full_path ||
                  item.product_variant.rear_image?.full_path ||
                  item.product_variant.side_image?.full_path ||
                  item.product.product_thumbnail.full_path
                "
                class="w-12rem flex-shrink-0 mx-auto md:mx-0"
                [alt]="item.product.name"
              />
              <div class="flex-auto py-5 md:pl-5">
                <div
                  class="flex flex-wrap align-items-start sm:align-items-center sm:flex-row sm:justify-content-between surface-border pb-6"
                >
                  <div class="w-full sm:w-6 flex flex-column">
                    <span class="text-900 text-xl font-medium mb-3">
                      {{ item.product.name }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-column">
                  <span class="inline-flex align-items-center mb-3">
                    <i class="pi pi-dollar text-700 mr-2"></i>
                    Price:
                    <span class="text-700 mr-2">
                      {{ item.price | price | usdPipe }}
                    </span>
                  </span>
                  <span class="inline-flex align-items-center mb-3">
                    <i class="pi pi-shopping-bag text-700 mr-2"></i>
                    Quantity:
                    <span class="font-bold">{{ item.quantity }}</span>
                  </span>
                  <span class="flex align-items-center mb-3">
                    <i class="pi pi-info-circle text-700 mr-2"></i>
                    Size:
                    <span class="font-bold">{{ item.product_size.size }}</span>
                  </span>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>
    </div>
  </p-card>
}
