import { NgClass } from '@angular/common';
import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Button } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonChangeEvent, ToggleButtonModule } from 'primeng/togglebutton';
import { debounceTime } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { DateHelperService } from 'app/_shared/helpers/date-helper.service';
import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { PrintArea } from 'app/_shared/interfaces/catalog';
import { AdditionalPhoto, StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { LibraryService } from 'app/_shared/services/library.service';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { CatalogProductVariantWithChecked } from 'app/admin/storefront/products/create-product/create-product.component';
import { StorefrontProductCategoryEditorComponent } from 'app/admin/storefront/products/storefront-product-category-editor/storefront-product-category-editor.component';

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [
    Button,
    ReactiveFormsModule,
    SelectButtonModule,
    PanelModule,
    NgClass,
    InputComponent,
    EditorModule,
    RadioButtonModule,
    CalendarModule,
    ToggleButtonModule,
    FormsModule,
    FileUploadModule
  ],
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent implements OnInit {
  product = input.required<StorefrontProduct>();
  editMode = input.required<boolean>();
  isSubmitting = input<boolean>();
  selectedPrintArea = input.required<PrintArea>();
  storefrontId = input.required<string>();
  productVariants = input.required<CatalogProductVariantWithChecked[]>();
  handleFormSubmit = output();
  setProductImage = output<string>();
  handlePrintAreaImageUpload = output<LibraryFile>();
  handleOpenMediaLibraryModal = output();
  selectAllVariants = output<boolean>();
  handleVariantSelect = output<{ $event: ToggleButtonChangeEvent; variantId: number }>();

  private fb = inject(FormBuilder);
  toastService = inject(ToastService);
  private dateHelperService = inject(DateHelperService);
  private slugHelper = inject(SlugHelper);
  private libraryService = inject(LibraryService);
  private categoryService = inject(StorefrontProductCategoryService);
  private dialogRefCategory = inject(DynamicDialogRef);
  private dialogService = inject(DialogService);

  productCategoryOptions = signal<StorefrontProductCategory[]>([]);
  displayDateFormat: string;
  visibleSlugInput = false;
  latestUsedMedias: LibraryFile[];
  additionalPhotos: AdditionalPhoto[] = [];
  deletedAdditionalPhotosIds: number[] = [];
  decorationMethodOptions = [
    { name: 'DTG', value: 'dtg' },
    { name: 'EMBROIDERY', value: 'embroidery' }
  ];
  productStatusOptions = [
    { name: 'Draft', value: 'draft' },
    { name: 'Published', value: 'published' },
    { name: 'Scheduled publish', value: 'scheduled' }
  ];
  form: FormGroup;

  ngOnInit() {
    this.displayDateFormat = this.dateHelperService.getDateFormat();
    this.fetchLatestUsedFiles();
    this.fetchStorefrontProductCategories();
    this.buildForm(this.product());
    this.initializeAdditionalPhotos();
  }

  initializeAdditionalPhotos() {
    (this.product().additional_photos ?? []).forEach(photo =>
      this.additionalPhotos.push({
        id: photo.id,
        objectURL: photo?.full_path,
        fileName: photo.name
      })
    );
  }

  fetchStorefrontProductCategories() {
    this.categoryService.index(+this.storefrontId()).subscribe({
      next: res => {
        this.productCategoryOptions.set(res.data);
      },
      error: error => console.error(error)
    });
  }

  fetchLatestUsedFiles() {
    this.libraryService.getLatestUsedFiles(+this.storefrontId()).subscribe({
      next: res => {
        this.latestUsedMedias = res.data;
      },
      error: error => console.error(error)
    });
  }

  buildForm(product: StorefrontProduct) {
    this.form = this.fb.group({
      name: [product.name ?? '', Validators.required],
      slug: [product.slug ?? '', Validators.required],
      price: [product.price ?? 10, Validators.required],
      description: [product.description ?? '', Validators.required],
      short_description: [product.short_description ?? ''],
      copy_underneath_description: [product.copy_underneath_description ?? ''],
      line_name: [product.line_name ?? 'line-name'],
      currency: [product.currency ?? 'usd'],
      active: [product.active ?? 1],
      status: [product.status ?? 'published'],
      start_publish_date: new FormControl(
        product.end_publish_date ? this.dateHelperService.parseDateString(product.start_publish_date) : null
      ),
      end_publish_date: new FormControl(
        product.end_publish_date ? this.dateHelperService.parseDateString(product.end_publish_date) : null
      ),
      product_categories: new FormControl(product.product_categories ?? [])
    });

    this.subscribeToFormChanges();
    if (!this.editMode() && this.product()?.name) {
      this.generateAndSetUniqueSlug(this.product()?.name ?? '');
    }
  }

  subscribeToFormChanges() {
    const statusControl = this.form.get('status');
    const nameControl = this.form.get('name');
    const slugControl = this.form.get('slug');
    const startPublishDate = this.form.get('start_publish_date') as FormControl;
    const endPublishDate = this.form.get('end_publish_date') as FormControl;

    statusControl?.valueChanges.subscribe(status => {
      startPublishDate.clearValidators();
      if (status === 'scheduled') {
        startPublishDate.addValidators([Validators.required]);
      } else {
        startPublishDate.reset();
        endPublishDate.reset();
      }
      startPublishDate.updateValueAndValidity();
      endPublishDate.updateValueAndValidity();
    });

    nameControl?.valueChanges.pipe(debounceTime(500)).subscribe(productName => {
      this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), productName).subscribe(uniqueSlug => {
        slugControl?.setValue(uniqueSlug, { emitEvent: false });
      });
    });

    slugControl?.valueChanges.pipe(debounceTime(500)).subscribe(slug => {
      this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), slug).subscribe(uniqueSlug => {
        slugControl?.setValue(uniqueSlug, { emitEvent: false });
      });
    });
  }

  generateAndSetUniqueSlug(productName: string) {
    this.slugHelper.generateUniqueProductSlug(+this.storefrontId(), productName).subscribe(uniqueSlug => {
      this.form.get('slug')?.setValue(uniqueSlug, { emitEvent: false });
    });
  }

  toggleSelection(file: LibraryFile): void {
    if (file) {
      this.handlePrintAreaImageUpload.emit(file);
    }
  }

  variantSelectChange($event: ToggleButtonChangeEvent, id: number) {
    this.handleVariantSelect.emit({ $event, variantId: id });
  }

  onAdditionalPhotosUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      const objectURL = URL.createObjectURL(file);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result;
        this.additionalPhotos.push({
          file,
          objectURL,
          fileName: file.name,
          base64: base64String
        });
      };

      reader.readAsDataURL(file);
    }
  }

  removeAdditionalPhoto(index: number): void {
    const imageToDelete = this.additionalPhotos[index];
    if (imageToDelete.id) {
      this.deletedAdditionalPhotosIds.push(imageToDelete.id);
    }

    this.additionalPhotos = [...this.additionalPhotos.slice(0, index)];
    this.toastService.success('Photo removed.');
  }

  openCategoryEditor(category?: StorefrontProductCategory) {
    this.dialogRefCategory = this.dialogService.open(StorefrontProductCategoryEditorComponent, {
      header: category ? 'Edit Category' : 'Add New Category',
      width: '38rem',
      height: 'auto',
      data: {
        category,
        storefrontId: +this.storefrontId(),
        storeCategory: false
      }
    });
    this.dialogRefCategory.onClose.subscribe((newCategory: StorefrontProductCategory | null) => {
      if (newCategory) {
        this.productCategoryOptions.set([...this.productCategoryOptions(), newCategory]);
        const currentCategories = this.form.get('product_categories')?.value || [];
        this.form.get('product_categories')?.setValue([...currentCategories, newCategory]);
      }
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.handleFormSubmit.emit({
      ...this.form.value,
      additional_photos: this.additionalPhotos,
      deleted_additional_photos: this.deletedAdditionalPhotosIds
    });
  }
}
