<form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
  <div class="mb-4">
    <div class="text-900 text-xl font-bold mb-2">Log in</div>
    <span class="text-600 font-medium">Please enter your details</span>
  </div>
  <div class="flex flex-column">
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-envelope"></i>
        <input
          id="email"
          type="text"
          [ngClass]="{
            'ng-dirty':
              loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)
          }"
          formControlName="email"
          pInputText
          class="w-full md:w-25rem"
          placeholder="Email"
        />
      </span>
      @if (loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)) {
        @if (loginForm.get('email')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Email is required field</small>
          </div>
        }
        @if (loginForm.get('email')?.errors?.['email']) {
          <div><small class="p-text-danger">Must be valid email</small></div>
        }
      }
    </div>

    <div class="flex flex-column">
      <div class="mb-4">
        <span class="p-input-icon-left w-full">
          <i class="pi pi-lock z-2"></i>
          <p-password
            [feedback]="false"
            formControlName="password"
            placeholder="Password"
            styleClass="w-full"
            [inputStyle]="{ paddingLeft: '2.5rem' }"
            inputStyleClass="w-full md:w-25rem"
            [toggleMask]="true"
          ></p-password>
        </span>
        @if (submitted() || loginForm.get('password')?.touched) {
          <div class="mt-1">
            @if (loginForm.get('password')?.errors?.['required']) {
              <small class="p-text-danger">Password is required</small>
            } @else if (loginForm.get('password')?.errors?.['passwordPattern']) {
              <div class="w-50%">
                <small class="p-text-danger">
                  Password must be at least 8 characters,
                  <br />
                  contain at least one uppercase letter,
                  <br />
                  one lowercase letter, one number
                  <br />
                  and one special character.
                </small>
              </div>
            }
          </div>
        }
      </div>
    </div>
    <div class="mb-4 flex flex-wrap gap-3">
      <div>
        <p-checkbox name="checkbox" formControlName="remember" styleClass="mr-2" [binary]="true"></p-checkbox>
        <label for="checkbox" class="text-900 font-medium mr-8">Remember Me</label>
      </div>
      <a
        [routerLink]="['/auth/forgot-password']"
        class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300"
      >
        Reset password
      </a>
    </div>
    <p-button pRipple label="Log In" class="w-full mb-4" type="submit" [disabled]="isLoading()" />
    <span class="font-medium text-600">
      Don't have an account?
      <br />
      <a
        (click)="openInviteCompanyModal()"
        class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"
      >
        Request invite to SSM platform
      </a>
    </span>
  </div>
</form>
