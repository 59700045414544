import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-company',
  standalone: true,
  imports: [MenubarModule, TabMenuModule, CommonModule],
  templateUrl: './settings.component.html'
})
export class SettingsComponent {
  menuItems: MenuItem[] = [
    {
      label: 'General',
      routerLink: './general'
    },
    {
      label: 'Communications',
      routerLink: './communications'
    }
  ];
}
