@if (isCollection()) {
  <p class="m-0 ml-3">Select products for collection</p>
} @else {
  <h3 class="text-center m-5">Client selection</h3>
  <p class="text-center">Select products you want for your shop</p>
}
<div class="grid grid-nogutter">
  <div class="col-12 md:col-8">
    <p-dataView
      #dv
      [value]="products()"
      [paginator]="true"
      [rows]="paginatorOptions.rows"
      [totalRecords]="paginatorOptions.total"
      [first]="paginatorOptions.first"
      filterBy="name"
      layout="grid"
      [lazy]="true"
      [lazyLoadOnInit]="false"
      (onLazyLoad)="handleProductLoading($event)"
    >
      <ng-template let-products pTemplate="gridItem">
        <div class="grid grid-nogutter">
          @for (product of products; track product.id) {
            <div class="col-12 lg:col-12 xl:col-4">
              <div class="card m-3 border-1 surface-border item">
                <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                  <div class="flex align-items-center">
                    <i class="pi pi-tag mr-2"></i>
                    <span class="font-semibold">Shirts</span>
                  </div>
                  <span [class]="'product-badge status'">
                    Starting from:
                    <b>{{ product.catalog_product_configuration.product_price | price | usdPipe }}</b>
                  </span>
                </div>
                <div class="flex flex-column align-items-center text-center mb-3">
                  <img [src]="product.featured_image.full_path" [alt]="product.name" class="shadow-2 my-3 mx-0" />
                  <div class="text-2xl font-bold">{{ product.name }}</div>
                </div>
                <div class="flex align-items-center justify-content-end">
                  <p-button
                    [icon]="product.selected ? 'pi pi-times' : 'pi pi-check'"
                    [severity]="product.selected ? 'danger' : 'primary'"
                    (onClick)="handleAddRemoveProduct(product)"
                  ></p-button>
                </div>
              </div>
            </div>
          }
        </div>
      </ng-template>
    </p-dataView>
  </div>
  <div class="hidden md:block md:col-1">
    <p-divider layout="vertical"></p-divider>
  </div>
  <div class="col-4 md:col-3">
    <div class="selected-products">
      <h5>Selected products</h5>
      @for (product of selectedProducts(); track product.id) {
        <div class="selected-item surface-border mb-2">
          <img [src]="product?.featured_image?.full_path" [alt]="product?.name" class="shadow-2" />
          <p>{{ product.name }}</p>
          <p-button icon="pi pi-times" severity="danger" (onClick)="handleAddRemoveProduct(product)"></p-button>
        </div>
      }
      <div class="footer flex justify-content-end">
        @if (this.selectedProducts().length) {
          <p-button (onClick)="handleSave()" [loading]="isSubmitting()" [disabled]="disabled()">
            Save selected items
          </p-button>
        }
      </div>
    </div>
  </div>
</div>
