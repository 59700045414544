import { NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, input, model, output, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { Button } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { CatalogProductVariant } from 'app/_shared/interfaces/catalog';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-variants-item',
  standalone: true,
  imports: [Button, InputComponent, NgOptimizedImage, InputTextModule, InputGroupModule, FormsModule],
  templateUrl: './variants-item.component.html',
  styleUrl: './variants-item.component.scss'
})
export class VariantsItemComponent {
  variant = model.required<CatalogProductVariant>();
  variantIndex = input.required<number>();
  supplierId = input.required<number>();
  imageUrls = model.required<{ frontImage: string; rearImage: string; sideImage: string }>();
  editMode = false;
  variantDeleted = output<number>();
  mediaDeleted = output<{ index: number; type: string }>();
  sizesChangeDialog = output<number>();
  private colorDictionaryService = inject(ColorDictionaryService);
  private toastService = inject(ToastService);
  private confirmationService = inject(ConfirmationService);

  openDeleteVariantModal(event: Event) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this variant?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.variantDeleted.emit(this.variantIndex())
    });
  }

  openDeleteMediaModal(type: string) {
    this.confirmationService.confirm({
      key: 'confirmDeleteMedia',
      message: `Are you sure you want to delete the ${type} image?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.mediaDeleted.emit({ index: this.variantIndex(), type })
    });
  }

  onFileChange(event: Event, imageType: string) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const imageUrl = e.target?.result as string;
        this.imageUrls.update(image => {
          return { ...image, [imageType + 'Image']: imageUrl };
        });
        console.log(this.imageUrls());
        this.variant.update(variant => {
          return { ...variant, [`${imageType}_image`]: imageUrl };
        });
      };
      reader.readAsDataURL(file);
    }
  }

  addToDictionary() {
    const value = this.variant().color;
    const key = this.variant().supplier_product_variant?.color;

    if (key && key != value) {
      return this.colorDictionaryService.addToDictionary(this.supplierId(), key, value).subscribe({
        next: () => this.toastService.success('Saved!'),
        error: err => {
          this.toastService.error('An error occurred. Please try again');
          console.error(err);
        },
        complete: () => (this.editMode = false)
      });
    }

    this.editMode = false;
    return this.toastService.warning('Name is same as supplier name, skipping!');
  }

  handleEditSizes() {
    this.sizesChangeDialog.emit(this.variantIndex());
  }
}
