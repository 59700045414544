<div class="selection mt-5">
  <div class="grid">
    <div class="col-12 md:col-3 mt-3">
      <h4><b>Filters</b></h4>
      <p-accordion [multiple]="true">
        <p-accordionTab header="Categories">
          <p-tree
            [value]="categories"
            selectionMode="checkbox"
            class="w-full md:w-30rem"
            [(selection)]="selectedCategories"
            (selectionChange)="getProducts(paginatorOptions)"
          />
        </p-accordionTab>
        <p-accordionTab header="Brands">
          <p-tree
            [value]="brands"
            selectionMode="checkbox"
            class="w-full md:w-30rem"
            [(selection)]="selectedBrands"
            (selectionChange)="getProducts(paginatorOptions)"
          />
        </p-accordionTab>
        <p-accordionTab header="Colors">
          <p-tree
            [value]="colors"
            selectionMode="checkbox"
            class="w-full md:w-30rem"
            [(selection)]="selectedColors"
            (selectionChange)="getProducts(paginatorOptions)"
          />
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="col-12 md:col-9">
      <div class="grid">
        <div class="col-12 sm:col-8">
          <span class="p-input-icon-right block">
            <input
              type="text"
              pInputText
              placeholder="Search products"
              [style]="{ width: '100%' }"
              [(ngModel)]="search"
              (ngModelChange)="getProducts(paginatorOptions)"
            />
            <i class="pi pi-search"></i>
          </span>
        </div>
        <div class="col-12 sm:col-4">
          <p-dropdown
            [options]="sortingOptions"
            [(ngModel)]="selectedSort"
            placeholder="Filter"
            [style]="{ width: '100%' }"
            [showClear]="selectedSort"
            (onChange)="getProducts(paginatorOptions)"
          ></p-dropdown>
        </div>
      </div>
      <p-dataView
        #dv
        [value]="products()"
        [paginator]="true"
        [rows]="paginatorOptions.rows"
        [totalRecords]="paginatorOptions.total"
        [first]="paginatorOptions.first"
        filterBy="name"
        layout="grid"
        [lazy]="true"
        [lazyLoadOnInit]="false"
        (onLazyLoad)="getProducts($event)"
      >
        <ng-template let-products pTemplate="gridItem">
          <div class="grid mt-0">
            @for (product of products; track product.id) {
              <div class="col-12 md:col-12 lg:col-6 xl:col-4">
                <div class="card border-1 surface-border item">
                  <div class="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                    <div class="flex align-items-center">
                      <i class="pi pi-tag mr-2"></i>
                      <span class="font-semibold">Shirts</span>
                    </div>
                    <span [class]="'product-badge status'">
                      Starting at:
                      <b>{{ product.starting_price | price | usdPipe }}</b>
                    </span>
                  </div>
                  <div class="flex flex-column align-items-center text-center mb-3">
                    <img [src]="product.featured_image.full_path" [alt]="product.name" class="shadow-2 my-3 mx-0" />
                    <div class="font-bold">
                      <p>{{ product.name }}</p>
                    </div>
                  </div>
                  <div class="flex align-items-center justify-content-end mt-auto">
                    <p-button
                      [label]="product.selected ? 'Remove' : 'Add'"
                      [severity]="product.selected ? 'danger' : 'primary'"
                      (onClick)="handleAddRemoveProduct(product)"
                      [outlined]="true"
                    ></p-button>
                  </div>
                </div>
              </div>
            }
          </div>
        </ng-template>
      </p-dataView>
    </div>
    <!--  <div class="hidden md:block md:col-1">-->
    <!--    <p-divider layout="vertical"></p-divider>-->
    <!--  </div>-->
    <!--    <div class="col-4 md:col-3">-->
    <!--      <div class="selected-products">-->
    <!--        <h5>Selected products</h5>-->
    <!--        @for (product of selectedProducts(); track product.id) {-->
    <!--          <div class="selected-item surface-border mb-2">-->
    <!--            <img [src]="product?.featured_image?.full_path" [alt]="product?.name" class="shadow-2" />-->
    <!--            <p>{{ product.name }}</p>-->
    <!--            <p-button-->
    <!--              [outlined]="true"-->
    <!--              icon="pi pi-times"-->
    <!--              severity="danger"-->
    <!--              (onClick)="handleAddRemoveProduct(product)"-->
    <!--            ></p-button>-->
    <!--          </div>-->
    <!--        }-->
    <!--        <div class="footer flex justify-content-end">-->
    <!--          @if (this.selectedProducts().length) {-->
    <!--            <p-button (onClick)="handleSave()" [loading]="isSubmitting()" [disabled]="disabled()">-->
    <!--              Save selected items-->
    <!--            </p-button>-->
    <!--          }-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
