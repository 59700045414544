import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { ApiService } from 'app/_shared/services/api.service';

@Injectable()
export class ColorDictionaryService extends ApiService {
  routePrefix = `${this.apiUrl}color-dictionaries/`;

  public getDictionary(supplierId: number): Observable<IApiResponse> {
    return this.get(`${this.routePrefix}`, {
      params: {
        owner_id: supplierId,
        type: 'supplier'
      }
    });
  }

  public addToDictionary(supplierId: number, key: string, value: string): Observable<IApiResponse> {
    return this.post(`${this.routePrefix}`, {
      color_dictionaries: [
        {
          owner_id: supplierId,
          key,
          value
        }
      ]
    });
  }

  public removeFromDictionary(recordId: number): Observable<IApiResponse> {
    return this.delete(`${this.routePrefix}${recordId}`, {});
  }
}
