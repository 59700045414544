<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="flex justify-content-center m-1">
    <p-stepper [(activeStep)]="activeStep">
      <!-- User info  -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <p-button
            [text]="true"
            class="bg-transparent border-none inline-flex flex-column gap-2"
            styleClass="p-0"
            (onClick)="onClick.emit()"
          >
            <span
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{
                'bg-primary border-primary': index <= activeStep,
                'surface-border': index > activeStep
              }"
            >
              <i class="pi pi-user"></i>
            </span>
          </p-button>
        </ng-template>
        <ng-template pTemplate="content" let-nextCallback="nextCallback">
          <div class="flex flex-column gap-2 mx-auto mt-5" style="min-height: 16rem">
            <div class="p-fluid">
              @if (!userCompanyForEditPermissions) {
                <div class="field">
                  <h5>New user email</h5>
                  <input
                    type="text"
                    pInputText
                    placeholder="Email"
                    formControlName="email"
                    [ngClass]="{
                      'ng-dirty': form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)
                    }"
                  />
                  @if (form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)) {
                    @if (form.get('email')?.errors?.['required']) {
                      <div>
                        <small class="p-text-danger">Email is required field</small>
                      </div>
                    }
                    @if (form.get('email')?.errors?.['email']) {
                      <div>
                        <small class="p-text-danger">Must be valid email</small>
                      </div>
                    }
                  }
                </div>
                <p-divider />
              }

              <div class="field mt-4">
                <h5>Company Permissions</h5>
              </div>

              <ng-container formArrayName="permissions">
                @for (permission of allCompanyPermissions; track permission; let i = $index) {
                  <div class="grid" [formGroupName]="i">
                    <div class="col align-content-center">
                      <span>{{ permission.full_name }}</span>
                    </div>
                    <div class="col text-center">
                      <p-toggleButton
                        [formControlName]="permission.id"
                        onLabel="Yes"
                        offLabel="No"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        [style]="{ width: '10em' }"
                      />
                    </div>
                  </div>
                }
              </ng-container>
            </div>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button (onClick)="closeModal()" label="Cancel" severity="secondary" />
            <p-button (onClick)="nextStep(nextCallback)" label="Next" icon="pi pi-arrow-right" iconPos="right" />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!--  Storefronts  -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <p-button
            [text]="true"
            class="bg-transparent border-none inline-flex flex-column gap-2"
            (onClick)="onClick.emit()"
            styleClass="p-0"
          >
            <span
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{
                'bg-primary border-primary': index <= activeStep,
                'surface-border': index > activeStep
              }"
            >
              <i class="pi pi-star"></i>
            </span>
          </p-button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback">
          <div class="flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 24rem">
            <div class="text-center mt-3 mb-3 text-xl font-semibold">Choose Storefronts</div>
            <div class="flex flex-wrap justify-content-center gap-3">
              <ng-container formArrayName="storefront_ids">
                @for (storefront of companyStorefronts; track storefront; let i = $index) {
                  <ng-container [formGroupName]="i">
                    <p-toggleButton
                      [formControlName]="storefront.id!"
                      [onLabel]="storefront.name"
                      [offLabel]="storefront.name"
                    />
                  </ng-container>
                }
              </ng-container>
            </div>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button (onClick)="prevCallback.emit()" label="Back" severity="secondary" icon="pi pi-arrow-left" />
            <p-button (onClick)="nextStep(nextCallback)" label="Next" icon="pi pi-arrow-right" iconPos="right" />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Storefront Permissions  -->
      <p-stepperPanel>
        <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
          <p-button
            [text]="true"
            class="bg-transparent border-none inline-flex flex-column gap-2"
            (onClick)="onClick.emit()"
            styleClass="p-0"
          >
            <span
              class="border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center"
              [ngClass]="{
                'bg-primary border-primary': index <= activeStep,
                'surface-border': index > activeStep
              }"
            >
              <i class="pi pi-lock-open"></i>
            </span>
          </p-button>
        </ng-template>
        <ng-template pTemplate="content" let-prevCallback="prevCallback">
          <div class="flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 24rem">
            <div class="text-center mt-3 mb-3 text-xl font-semibold">Set Store Permissions</div>
            <div class="text-center">
              <p-tree
                [value]="permissionTreeNodes"
                selectionMode="checkbox"
                class="w-full md:w-30rem"
                [(selection)]="selectedStorefrontPermissions"
              />
            </div>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button (onClick)="prevCallback.emit()" label="Back" severity="secondary" icon="pi pi-arrow-left" />
            <p-button type="submit" label="Submit" />
          </div>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
  </div>
</form>
