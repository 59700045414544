@if (isLoading) {
  <p-progressSpinner class="overlay-spinner"></p-progressSpinner>
}
<div class="grid">
  @for (plan of plans; track plan) {
    <div class="col-6">
      <div class="p-3 h-full">
        <div
          class="shadow-2 p-6 h-full flex flex-column surface-card"
          style="border-radius: 6px"
          [ngClass]="{
            'border-blue-500 border-2 text-blue-500': currentStorefrontSubscription.plan?.id === plan.id
          }"
        >
          <span class="text-900 block font-medium text-xl mb-2 text-center">
            {{ plan.name }}
          </span>
          <span class="font-bold block text-2xl text-900 text-center">{{ plan.price }}$</span>

          <ul class="list-none p-0 m-0 flex-grow-1 mt-6">
            <li class="flex align-items-center mb-3">
              <i class="pi pi-check text-green-500 mr-2"></i>
              <span>Up to 10 Active Users</span>
            </li>
            <li class="flex align-items-center mb-3">
              <i class="pi pi-check text-green-500 mr-2"></i>
              <span>Up to 30 Project Integrations</span>
            </li>
          </ul>
          @if (currentStorefrontSubscription.plan?.id !== plan.id) {
            <button
              (click)="selectPlan($event, plan.id)"
              pButton
              pRipple
              [label]="getChangePlanButtonLabel(plan.plan_level, currentStorefrontSubscription.plan?.plan_level)"
              class="px-5 w-full mt-6 p-button-outlined"
              icon="pi pi-arrow-right"
              iconPos="right"
            ></button>
          }
        </div>
      </div>
    </div>
  }
</div>
<p-confirmDialog
  header="Confirmation"
  key="confirmPlanChange"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
