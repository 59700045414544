<p-card>
  <p-orderList
    [value]="pages()"
    header="Pages"
    [dragdrop]="false"
    (onSelectionChange)="selectionChange($event)"
    [(selection)]="selectedPage"
    [metaKeySelection]="true"
  >
    <ng-template let-page pTemplate="header">
      <div class="flex flex-wrap justify-content-between align-items-center">
        <p class="m-0 font-bold">Pages</p>
        <p-button
          [outlined]="true"
          [iconPos]="'left'"
          icon="pi pi-plus"
          [label]="'Add new page'"
          (click)="showNewPageDialog = true"
        />
      </div>
    </ng-template>
    <ng-template let-page pTemplate="item">
      <p-panel
        [toggleable]="false"
        [styleClass]="selectedPage()[0]?.id === page.id ? 'test' : ''"
        [style]="{ border: '2px solid transparent' }"
      >
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <span>{{ page.title }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="icons">
          <button class="p-panel-header-icon p-link mr-2" (click)="togglePagePopup($event, menu, page)">
            <span class="pi pi-cog"></span>
          </button>
          <p-menu #menu id="menu_items" [model]="pagePopupItems" [popup]="true" />
        </ng-template>
      </p-panel>
    </ng-template>
  </p-orderList>
  <form [formGroup]="newPageForm" (ngSubmit)="handleCreatePage()">
    <p-dialog
      header="Add new page"
      [(visible)]="showNewPageDialog"
      [modal]="true"
      showEffect="fade"
      [breakpoints]="{ '960px': '75vw' }"
    >
      <div class="new-page">
        <app-input name="Title" label="Title" [control]="title" placeholder="Page name"></app-input>
        <app-input name="Page URL" label="Page URL" [control]="url" placeholder="Page URL"></app-input>
      </div>
      <ng-template pTemplate="footer">
        <button
          [disabled]="!newPageForm.valid"
          pButton
          icon="pi pi-check"
          type="submit"
          label="Ok"
          class="p-button-outlined"
        ></button>
      </ng-template>
    </p-dialog>
  </form>
</p-card>
<p-confirmDialog />
