import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdDate',
  standalone: true
})
export class DisplayDatePipe extends DatePipe implements PipeTransform {
  protected format = 'MM/dd/yyyy';

  override transform(value: any): any {
    return super.transform(value, this.format);
  }
}
