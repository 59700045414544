import { Component, inject, input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'environments/environment';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Editor, EditorModule } from 'primeng/editor';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { PrintAreaComponent } from 'app/_shared/components/catalog-product-form/print-area/print-area.component';
import { VariantsListComponent } from 'app/_shared/components/catalog-product-form/variants-list/variants-list.component';
import { InputComponent } from 'app/_shared/components/input/input.component';
import { handleSupplierMapping } from 'app/_shared/helpers/suppliers-helper';
import {
  CatalogProduct,
  CatalogProductConfig,
  PrintArea,
  PrintAreaData,
  CatalogProductVariant,
  CatalogVariantSize,
  DecorationMethod
} from 'app/_shared/interfaces/catalog';
import { CatalogProductCategory } from 'app/_shared/models/CatalogProductCategory';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { DecorationMethodService } from 'app/_shared/services/decoration-method.service';
import { SupplierService } from 'app/_shared/services/supplier.service';

enum Tabs {
  INFO = 'Info',
  PRINT_AREA = 'Print Area',
  VARIANTS = 'Variants'
}

@Component({
  selector: 'app-catalog-product-form',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    InputComponent,
    ReactiveFormsModule,
    RippleModule,
    TabMenuModule,
    PrintAreaComponent,
    VariantsListComponent,
    DropdownModule,
    InputTextareaModule,
    FileUploadModule,
    EditorModule,
    DialogModule,
    PricePipe,
    SelectButtonModule,
    MultiSelectModule
  ],
  providers: [DynamicDialogConfig, ColorDictionaryService],
  templateUrl: './catalog-product-form.component.html',
  styleUrl: './catalog-product-form.component.scss'
})
export class CatalogProductFormComponent implements OnInit {
  @ViewChild('editor') editor: Editor;
  protected storageUrl = environment.STORAGE_URL;
  type = input.required<'create' | 'edit'>();
  redirectTo = input.required<string>();
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private supplierService = inject(SupplierService);
  private catalogService = inject(CatalogService);
  private categoryService = inject(CatalogProductCategoryService);
  private decorationMethodService = inject(DecorationMethodService);

  protected readonly Tabs = Tabs;
  isSubmitting: boolean;
  slug: string;
  id: string;
  supplierPrintPrice: number;
  supplierPriceMargin: string;
  form: FormGroup;
  catalogProduct: CatalogProduct;
  catalogProducts: CatalogProduct[];
  productVariants: CatalogProductVariant[];
  // printAreaImage: string;
  printAreas: PrintArea[];
  deletedVariants: number[] = [];
  deletedCatalogProductMedias: number[] = [];
  items = [
    { label: Tabs.INFO, icon: 'pi pi-fw pi-pencil' },
    { label: Tabs.PRINT_AREA, icon: 'pi pi-fw pi-calendar' },
    { label: Tabs.VARIANTS, icon: 'pi pi-fw pi-calendar' }
  ];
  decorationMethodOptions: DecorationMethod[] = [];
  activeItem = this.items[0];
  productCategories: CatalogProductCategory[];

  sizeGuideLabel: string;
  sizeGuideTimelineLabel: string;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private dynamicConfig: DynamicDialogConfig
  ) {}
  ngOnInit(): void {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.id = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    this.fetchCatalogCategories();
    this.fetchDecorationMethods();
    this.fetchCatalogProducts();
    this.fetchProduct();
  }

  buildForm(product: CatalogProduct) {
    const {
      name,
      brand,
      description,
      similar_product_id,
      companion_product_id,
      decoration_methods,
      size_guide,
      size_guide_timeline,
      admin_categories,
      catalog_product_configuration: {
        short_name,
        product_price,
        print_price,
        print_price_second,
        weight,
        moq,
        number_of_tiers
      } = {} as CatalogProductConfig
    } = product;

    this.sizeGuideLabel = size_guide?.name ? 'size_guide?.name ' : 'Upload Size Guide';
    this.sizeGuideTimelineLabel = size_guide_timeline?.name ? 'size_guide?.name ' : 'Upload Size Guide Timeline';
    const categoryIds = admin_categories?.map(category => category.id);
    const decorationMethodIds = decoration_methods?.map(method => method.id) || [this.decorationMethodOptions[0]?.id];

    this.form = this.fb.group({
      name: new FormControl(name ?? '', [Validators.required]),
      brand: new FormControl(brand ?? ''),
      description: new FormControl(description ?? ''),
      similar_product_id: new FormControl(similar_product_id ?? ''),
      companion_product_id: new FormControl(companion_product_id ?? ''),
      size_guide: new FormControl(null),
      size_guide_timeline: new FormControl(null),
      decoration_methods: new FormControl(decorationMethodIds ?? [], [Validators.required]),
      catalog_category_ids: new FormControl(categoryIds ?? []),
      catalog_product_configuration: new FormGroup({
        short_name: new FormControl(short_name ?? ''),
        product_price: new FormControl((product_price / 100).toFixed(2) ?? 0, [Validators.required]),
        print_price: new FormControl(print_price ? (print_price / 100).toFixed(2) : 0),
        print_price_second: new FormControl(print_price_second ? (print_price_second / 100).toFixed(2) : 0),
        weight: new FormControl(weight ?? 0, [Validators.required]),
        moq: new FormControl(moq ?? 1),
        number_of_tiers: new FormControl(number_of_tiers ?? 1)
      })
    });
  }

  handleBack() {
    this.router.navigate([this.redirectTo()]);
  }

  generatePrintAreas(product: CatalogProduct) {
    if (this.type() === 'create') {
      const featureImage = this.storageUrl + product.featured_image?.path;
      const areaImage = product.catalog_product_variants[0]?.front_image?.full_path ?? featureImage;

      const areas: PrintArea[] = [
        {
          id: uuidv4(),
          name: 'Full Front',
          type: 'front_image',
          data: {
            x: 60,
            y: 60,
            width: 100,
            height: 90,
            fill: 'rgba(79,75,224, 0.5)',
            image: areaImage,
            name: 'rect',
            draggable: true,
            scaleX: 1,
            scaleY: 1
          }
        }
      ];
      return areas;
    } else {
      return product.print_areas.map<PrintArea>(area => ({
        ...area,
        data: JSON.parse(area.data as string)
      }));
    }
  }

  fetchCatalogCategories() {
    this.categoryService.index().subscribe({
      next: res => {
        this.productCategories = res.data;
      },
      error: error => console.error(error)
    });
  }

  fetchDecorationMethods() {
    this.decorationMethodService.index().subscribe({
      next: res => {
        this.decorationMethodOptions = res.data;
      },
      error: error => console.error(error)
    });
  }

  fetchCatalogProducts() {
    const params = {
      rows: 10,
      page: 1,
      'relations[]': ['catalogProductConfiguration', 'featuredImage']
    };
    this.catalogService.getProducts(params).subscribe({
      next: res => {
        this.catalogProducts = res.data.data;
      },
      error: error => console.error(error)
    });
  }

  fetchProduct() {
    this.handleFetchProduct().subscribe(product => {
      this.catalogProduct = product;
      this.productVariants = product.catalog_product_variants;
      this.printAreas = this.generatePrintAreas(product);
      this.buildForm(product);
    });
  }

  handleFetchProduct() {
    if (this.type() === 'create') {
      return this.supplierService.getSupplierProduct(this.slug, this.id).pipe(
        map(product => {
          // Map product to SupplierProduct type
          return handleSupplierMapping(this.slug, product.data);
        }),
        map(supplierProduct => {
          // Map SupplierProduct to CatalogProduct
          const { name, price, supplierId, id, image, product_variants, description, price_margin } = supplierProduct;
          const marginPrice = price_margin ? price * (1 + +price_margin / 100) : price;
          this.supplierPrintPrice = +price;
          this.supplierPriceMargin = price_margin;
          // TODO: implement function that handles mapping supplier product to catalog product
          return {
            name,
            description,
            supplier_id: supplierId,
            supplier_product_id: id,
            featured_image: image,
            catalog_product_variants: product_variants?.map(variant => ({
              id: undefined,
              color: variant.color,
              supplier_product_variant_id: variant.id,
              ...(variant.front_image && {
                front_image: {
                  ...variant.front_image,
                  supplier_media: {
                    full_path: variant.front_image?.full_path || ''
                  }
                }
              }),
              ...(variant.rear_image && {
                rear_image: {
                  ...variant.rear_image,
                  supplier_media: {
                    full_path: variant.rear_image?.full_path || ''
                  }
                }
              }),
              ...(variant.side_image && {
                side_image: {
                  ...variant.side_image,
                  supplier_media: {
                    full_path: variant.side_image?.full_path || ''
                  }
                }
              }),
              catalog_variant_sizes: variant.supplier_variant_sizes.map(
                size =>
                  ({
                    id: undefined,
                    quantity: size.quantity,
                    size: size.size,
                    weight: size.weight,
                    total: size.total,
                    supplier_variant_size_id: size.id
                  }) as CatalogVariantSize
              ),
              supplier_product_variant: {
                color: variant.color
              }
            })),
            catalog_product_configuration: {
              product_price: +marginPrice.toFixed(2)
            }
          } as CatalogProduct;
        })
      );
    } else {
      return this.catalogService.getProductForCatalogProductCreator(this.id).pipe(map(data => data.data));
    }
  }

  submit() {
    if (this.form.valid) {
      this.isSubmitting = true;
      this.handleSaveProduct().subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Successful',
            detail: `Product ${this.type() === 'create' ? 'created' : 'updated'}`,
            life: 3000
          });
          this.router.navigate([this.redirectTo()]);
        },
        error: () => (this.isSubmitting = false),
        complete: () => (this.isSubmitting = false)
      });
    }
  }

  handleSaveProduct() {
    const printAreasFormatted = this.printAreas.map(area => {
      //  Konva is using scale to resize elements
      //  Calculate width and height based on scale
      const data = area.data as PrintAreaData;
      const width = data.width * data.scaleX;
      const height = data.width * data.scaleY;

      return {
        ...area,
        data: JSON.stringify({ ...data, width, height, scaleX: 1, scaleY: 1 })
      };
    });

    const product: CatalogProduct = {
      ...this.form.value,
      catalog_product_configuration: {
        ...this.form.value.catalog_product_configuration,
        product_price: this.form.value.catalog_product_configuration.product_price * 100,
        print_price: this.form.value.catalog_product_configuration.print_price * 100,
        print_price_second: this.form.value.catalog_product_configuration.print_price_second * 100
      },
      supplier_id: this.catalogProduct.supplier_id as number,
      supplier_product_id: this.catalogProduct.supplier_product_id as number,
      print_areas: printAreasFormatted,
      ...(this.productVariants.length > 0
        ? { catalog_product_variants: this.prepareMediaData(this.productVariants) }
        : {})
    };

    if (!product.size_guide) {
      delete product.size_guide;
    }

    if (!product.size_guide_timeline) {
      delete product.size_guide_timeline;
    }

    if (this.type() === 'edit') {
      product.deleted_variants = this.deletedVariants;
      product.deleted_catalog_product_medias = this.deletedCatalogProductMedias;
      return this.catalogService.editProduct(product, this.catalogProduct.id);
    }

    return this.catalogService.createProduct(product);
  }

  prepareMediaData(productVariants: CatalogProductVariant[]) {
    return productVariants.map(variant => {
      const mediasArray: any[] = [];
      const images = [
        { image: variant.front_image, side: 'front' },
        { image: variant.rear_image, side: 'rear' },
        { image: variant.side_image, side: 'side' }
      ];

      images.forEach(({ image, side }) => {
        if (image) {
          if (!image.id) {
            mediasArray.push({ image, color: variant.color, side });
          } else if (this.type() === 'create') {
            mediasArray.push({ supplier_media_id: image.id });
          }
        }
      });

      return {
        ...variant,
        catalog_product_medias: mediasArray
      };
    });
  }

  handlePrintAreaChange($event: PrintArea[]) {
    this.printAreas = $event;
  }

  onVariantChange($event: CatalogProductVariant[]) {
    this.productVariants = $event;
  }

  onDeleteVariant($event: number[]) {
    this.deletedVariants = $event;
  }

  onDeleteVariantMedia($event: number[]) {
    this.deletedCatalogProductMedias = $event;
  }

  onUpload(event: FileUploadEvent | FileSelectEvent, controlName: string) {
    const uploadedFile = event.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;

        this.form.patchValue({
          [controlName]: base64String
        });

        if (controlName === 'size_guide') {
          this.sizeGuideLabel = uploadedFile.name;
        } else if (controlName === 'size_guide_timeline') {
          this.sizeGuideTimelineLabel = uploadedFile.name;
        }
      };
      reader.readAsDataURL(uploadedFile);
    }
  }

  setDefaultDescriptionValue() {
    const value = this.dynamicConfig.data?.section?.content?.text;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }
}
