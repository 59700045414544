import { Injectable } from '@angular/core';

import { FormGroupValue } from '../models/FormGroup';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class InviteService extends ApiService {
  routePrefix = `${this.apiUrl}invites/`;

  public index($event?: any) {
    return this.get(`${this.routePrefix}`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  public getById(id: number) {
    return this.get(this.routePrefix + id);
  }

  public createInvite(data: FormGroupValue) {
    return this.post(this.routePrefix, data);
  }

  public deleteInvite(id: number) {
    return this.delete(`${this.routePrefix}${id}`);
  }

  public resendInvite(id: number) {
    return this.get(`${this.routePrefix}${id}/resend`);
  }

  public accept(token: string) {
    return this.get(`${this.routePrefix}accept/${token}`);
  }

  public requestInvite(data: FormGroupValue) {
    return this.post(`${this.apiUrl}request-invites`, data);
  }
}
