import { Route } from '@angular/router';

import { UserDetailsComponent } from 'app/_shared/components/user-details/user-details.component';
import { selectedStorefrontBreadcrumbResolver } from 'app/_shared/resolvers/storefrontBreadcrumb.resolver';
import { selectedStorefrontResolver } from 'app/_shared/resolvers/storefronts.resolver';
import { BillingComponent } from 'app/admin/company-managment/billing/billing.component';
import { ClientSelectionEditorComponent } from 'app/admin/company-managment/client-selection-editor/client-selection-editor.component';
import { ClientSelectionListComponent } from 'app/admin/company-managment/client-selection-list/client-selection-list.component';
import { CollectionsEditorComponent } from 'app/admin/company-managment/collections/collections-editor/collections-editor.component';
import { CollectionsListComponent } from 'app/admin/company-managment/collections/collections-list.component';
import { GeneralComponent } from 'app/admin/company-managment/general/general.component';
import { InviteListComponent } from 'app/admin/company-managment/invite-list/invite-list.component';
import { PaymentMethodsComponent } from 'app/admin/company-managment/payment-methods/payment-methods.component';
import { TaxFormsComponent } from 'app/admin/company-managment/tax-forms/tax-forms.component';
import { TransactionsComponent } from 'app/admin/company-managment/transactions/transactions.component';
import { UserListComponent as CompanyUserList } from 'app/admin/company-managment/user-list/user-list.component';
import { OrderDetailsComponent } from 'app/admin/orders/order-details/order-details.component';
import { OrdersComponent } from 'app/admin/orders/orders.component';
import { StorefrontComponent } from 'app/admin/storefront/storefront.component';
import { StorefrontEditorComponent } from 'app/admin/storefronts/storefront-editor/storefront-editor.component';
import { StorefrontListComponent } from 'app/admin/storefronts/storefront-list/storefront-list.component';
import { StorefrontRoutes } from 'app/routes/storefront.routes';

// Prefix: "companies/:id"
export const CompanyRoutes: Route[] = [
  { path: 'general', component: GeneralComponent },
  { path: 'tax-forms', component: TaxFormsComponent },
  { path: 'billing', component: BillingComponent },
  { path: 'transactions', component: TransactionsComponent },
  {
    path: 'users',
    children: [
      { path: '', component: CompanyUserList },
      { path: ':id', component: UserDetailsComponent, data: { companyUser: true } }
    ]
  },
  {
    path: 'invites',
    component: InviteListComponent
  },
  {
    path: 'payment-methods',
    component: PaymentMethodsComponent
  },
  {
    path: 'client-selection',
    children: [
      { path: '', component: ClientSelectionListComponent },
      { path: 'create', component: ClientSelectionEditorComponent },
      { path: ':selectionId/edit', component: ClientSelectionEditorComponent }
    ]
  },
  {
    path: 'collections',
    children: [
      { path: '', component: CollectionsListComponent },
      { path: 'create', component: CollectionsEditorComponent },
      {
        path: ':collectionId/edit',
        component: CollectionsEditorComponent
      }
    ]
  },
  {
    path: 'storefronts',
    component: StorefrontComponent,
    data: { breadcrumb: 'Storefronts' },
    children: [
      { path: '', component: StorefrontListComponent },
      {
        path: 'create',
        component: StorefrontEditorComponent,
        data: { editMode: false }
      },
      {
        path: ':id/edit',
        component: StorefrontEditorComponent,
        data: { editMode: true }
      },
      {
        path: ':storefrontId',
        resolve: { storefront: selectedStorefrontResolver, breadcrumb: selectedStorefrontBreadcrumbResolver },
        component: StorefrontComponent,
        children: StorefrontRoutes
      }
    ]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    data: { breadcrumb: 'Orders' }
  },
  {
    path: 'orders/:orderId',
    component: OrderDetailsComponent,
    data: {
      superadminMode: false,
      breadcrumb: 'Order details'
    }
  }
];
