<app-list-products
  [products]="clientSelectionList"
  [columns]="cols"
  [paginatorOptions]="paginatorOptions"
  (lazyLoadProducts)="handleLazyLoadProducts($event)"
  [loading]="isLoading"
  [headerActions]="headerActions"
  emptyMessage="There are no selections"
></app-list-products>
<p-dialog
  header="Select storefront"
  [(visible)]="showAddProductDialog"
  [modal]="true"
  (visibleChange)="handleProductModalClose()"
  showEffect="fade"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vh' }"
>
  <div class="new-selection">
    <p>Select storefront where you want to products to be added</p>
    <p-dropdown
      [options]="companyStorefronts()"
      [(ngModel)]="selectedStorefront"
      optionLabel="name"
      placeholder="Select storefront"
      appendTo="body"
    />
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="!selectedStorefront"
      pButton
      icon="pi pi-check"
      (click)="createProducts(productsToBeAdded)"
      label="Ok"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
