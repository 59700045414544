<p-card>
  <h5>Create Catalog Product</h5>
  @if (isLoadingSuppliers) {
    <div class="flex justify-content-center">
      <p-progressSpinner ariaLabel="loading" />
    </div>
  } @else {
    <p-tabMenu
      [model]="suppliersList"
      [activeItem]="activeSupplier"
      (activeItemChange)="handleSupplierChange($event)"
    />

    <app-list-products
      [products]="supplierProducts"
      [columns]="cols"
      [paginatorOptions]="paginatorOptions"
      (lazyLoadProducts)="handleLazyLoadProducts($event)"
      [loading]="isLoadingProducts"
    ></app-list-products>
  }
</p-card>
