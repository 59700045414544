<p-card>
  <div class="flex align-items-center mb-4">
    <h5>Products</h5>
  </div>
  <app-list-products
    [products]="storefrontProducts"
    [columns]="cols"
    [headerActions]="headerActions"
    [paginatorOptions]="paginatorOptions"
    (lazyLoadProducts)="handleLazyLoadProducts($event)"
    [loading]="isLoading || isLoadingDelete"
    [multiSelect]="true"
    (onSelectionChange)="handleSelectionChange($event)"
  ></app-list-products>
</p-card>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
