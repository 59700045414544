import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Button } from 'primeng/button';

import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { ClientSelectionList } from 'app/_shared/interfaces/clientSelection';
import { ProductSelectionComponent } from 'app/_shared/product-selection/product-selection.component';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { CatalogProductWithColors } from 'app/superadmin/client-selection/client-selection-details/client-selection-details.component';

@Component({
  selector: 'app-client-selection',
  standalone: true,
  imports: [ProductSelectionComponent, Button],
  templateUrl: './client-selection.component.html',
  styleUrl: './client-selection.component.scss'
})
export class ClientSelectionComponent implements OnInit {
  private companyService = inject(CompanyService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private toastService = inject(ToastService);

  token: string;
  selectedProducts = signal<CatalogProductWithColors[]>([]);
  noOfSelectedProducts = computed(() => this.selectedProducts().length ?? 0);
  selection: ClientSelectionList;
  defaultSelection: CatalogProduct[];

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });

    this.companyService
      .getSelectionByToken({
        token: this.token,
        'relations[]': [
          'catalogSelections.catalogProduct',
          'logo',
          'catalogProductCollections.catalogProducts.featuredImage',
          'catalogProductCollections.catalogProducts.catalogProductVariants.supplierProductVariant'
        ]
      })
      .subscribe(next => {
        this.selection = next.data;
        const selectedItems = this.selection.catalog_selections ?? [];

        this.defaultSelection =
          selectedItems.length > 0
            ? selectedItems
            : next.data.catalog_product_collections.flatMap(collection => collection.catalog_products);
        this.selectedProducts.set(this.defaultSelection as CatalogProductWithColors[]);
      });
  }

  viewSelection() {
    this.router.navigate(['client-selection/view'], { queryParams: { token: this.token } });
  }

  saveSelection() {
    const data = {
      token: this.token,
      catalog_products: this.selectedProducts().map(product => {
        const colors = (product.catalog_product_variants ?? [])
          .filter(col => col.selected)
          .map(col => col.id as number);
        return {
          id: product.id,
          comment: product.comment ?? '',
          catalog_product_variants: colors
        };
      })
    };
    this.companyService.editClientSelection(data).subscribe(next => {
      this.toastService.success('You selection is saved successfully!');
    });
  }

  handleSelectionSave($event: any) {
    this.selectedProducts.set($event);
    this.saveSelection();
  }
}
