import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';

import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { FolderEditorDialogComponent } from './folder-editor-dialog/folder-editor-dialog.component';
import { RequestFilePrepDialogComponent } from './request-file-prep-dialog/request-file-prep-dialog.component';

@Component({
  selector: 'app-files',
  standalone: true,
  imports: [NgClass, ButtonModule, RippleModule, RouterLink, ConfirmDialogModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './library-folders-list.component.html'
})
export class LibraryFoldersListComponent implements OnInit {
  private libraryService = inject(LibraryService);
  private activatedRoute = inject(ActivatedRoute);
  private dialogService = inject(DialogService);
  private confirmationService = inject(ConfirmationService);
  private toastService = inject(ToastService);

  folders: LibraryFolder[];
  storefrontId: string;
  dialogRef: DynamicDialogRef | undefined;
  folderEditordialogRef: DynamicDialogRef | undefined;

  ngOnInit() {
    this.activatedRoute.params.subscribe(next => {
      this.storefrontId = next.storefrontId;
    });
    this.getLibraryFolders(this.storefrontId);
  }

  getLibraryFolders(storefrontId: string) {
    this.libraryService.getStorefrontLibraryFolders(+storefrontId).subscribe(next => {
      this.folders = next.data;
    });
  }

  openFilePrepDialog() {
    this.dialogRef = this.dialogService.open(RequestFilePrepDialogComponent, {
      header: 'Request Files',
      width: '600px'
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        console.log('suc');
      }
    });
  }

  openFolderEditorDialog(event: Event, folder?: LibraryFolder) {
    event.stopPropagation();
    event.preventDefault();
    this.dialogRef = this.dialogService.open(FolderEditorDialogComponent, {
      header: folder ? 'Edit Folder' : 'Create Folder',
      width: '500px',
      data: {
        folder,
        storefrontId: +this.storefrontId
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.getLibraryFolders(this.storefrontId);
      }
    });
  }

  openDeleteFodlerDialog(event: Event, folderId: number) {
    event.stopPropagation();
    event.preventDefault();
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this folder?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteFolder(folderId);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  deleteFolder(folderId: number) {
    this.libraryService.deleteStorefrontLibraryFolder(+this.storefrontId, folderId).subscribe(() => {
      this.toastService.success('Folder successfully deleted!');
      this.getLibraryFolders(this.storefrontId);
    });
  }
}
