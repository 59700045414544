import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonModule } from 'primeng/button';

import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';

@Component({
  selector: 'app-library-file-selector',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './library-file-selector.component.html',
  styleUrl: './library-file-selector.component.scss'
})
export class LibraryFileSelectorComponent {
  @Input() storefrontLibraryFolders: LibraryFolder[] = [];
  @Input() selectedFolderFiles: LibraryFile[] = [];
  @Input() multiple = true;

  @Output() folderSelected = new EventEmitter<number>();
  @Output() filesSelected = new EventEmitter<LibraryFile[]>();

  selectedFiles: Set<LibraryFile> = new Set();
  selectedFolder: LibraryFolder | null = null;

  toggleSelection(file: LibraryFile): void {
    if (this.multiple) {
      if (this.selectedFiles.has(file)) {
        this.selectedFiles.delete(file);
      } else {
        this.selectedFiles.add(file);
      }
    } else {
      if (this.selectedFiles.has(file)) {
        this.selectedFiles.delete(file);
      } else {
        this.selectedFiles.clear();
        this.selectedFiles.add(file);
      }
    }
  }

  isSelected(file: LibraryFile): boolean {
    return this.selectedFiles.has(file);
  }

  selectFolder(folder: LibraryFolder): void {
    this.selectedFolder = folder;
    this.folderSelected.emit(folder.id);
  }

  onSaveAndClose() {
    this.filesSelected.emit(Array.from(this.selectedFiles));
  }
}
