<div class="layout-container" [ngClass]="containerClass">
  <app-sidebar />
  <div class="layout-content-wrapper">
    <app-topbar />
    <div>
      <router-outlet />
    </div>
  </div>

  <app-right-sidebar />

  <div class="layout-mask"></div>
</div>
