/* eslint-disable import/no-cycle */
import { Company } from './Company';
import { Permission } from './Permission';
import { User } from './User';

export class Storefront {
  id: number;

  active: boolean;
  user_id: number;
  company_id: number;
  state_id: number;
  country_id: number;

  name: string;
  url: string;
  subdomain: string;
  domain: string;
  api_key: string;
  logo: { full_path: string; name: string };
  data: any;
  price_margin: string;

  company?: Company;
  users?: User[];
  permissions: Permission[];
  granted?: boolean;
}
