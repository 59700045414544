import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SearchService extends ApiService {
  globalSearch(term: string) {
    return this.get(`${this.apiUrl}global-search`, { params: { global_filter: term } });
  }
}
