// card-mask.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardMask',
  standalone: true
})
export class CardMaskPipe implements PipeTransform {
  transform(lastFourNumber: string): string {
    if (!lastFourNumber) return '';

    return '**** **** **** ' + lastFourNumber;
  }
}
