import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { CompanyStore } from 'app/_store/company.store';

import { Company } from '../../models/Company';
import { CompanyService } from '../../services/company.service';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-company-select-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, ChartModule, TableModule, RouterLink, ButtonModule, SkeletonModule],
  templateUrl: './company-select-dashboard.component.html'
})
export class CompanySelectDashboardComponent implements OnInit {
  companyStore = inject(CompanyStore);
  companyService = inject(CompanyService);
  router = inject(Router);

  companies: Company[] = [];
  totalRecords: number = 0;
  isLoading = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    this.isLoading = true;
  }

  loadCompanies($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.companyService
      .paginatedIndex($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.companies = res.data.data;
          this.totalRecords = res.data.total;
          this.handleSingleCompany();
        },
        error: error => console.error(error)
      });
  }

  handleSingleCompany() {
    if (this.companies.length === 1) {
      this.setWorkingCompany(this.companies[0]);
      this.router.navigateByUrl(`companies/${this.companies[0].id}/management`);
    }
  }

  setWorkingCompany(company: Company) {
    this.companyStore.setWorkingCompany(company);
  }
}
