<form (submit)="handleFormSubmit($event)">
  @if (loading) {
    <p-progressSpinner [style]="{ width: '50px', height: '50px' }"></p-progressSpinner>
  }
  <div id="payment-element"></div>

  @if (!loading) {
    <div class="pt-3">
      <p-button type="submit" label="Submit"></p-button>
    </div>
  }
</form>
