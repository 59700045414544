import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { LibraryService } from 'app/_shared/services/library.service';

interface Logo {
  file: File | null;
  objectURL: string | null;
  name: string | null;
}
interface AdditionalFile {
  file: File | null;
  name: string | null;
}
@Component({
  selector: 'app-request-file-prep-dialog',
  standalone: true,
  imports: [
    StepsModule,
    ButtonModule,
    ReactiveFormsModule,
    InputComponent,
    FileUploadModule,
    SelectButtonModule,
    ChipModule
  ],
  templateUrl: './request-file-prep-dialog.component.html',
  styleUrl: './request-file-prep-dialog.component.scss'
})
export class RequestFilePrepDialogComponent implements OnInit {
  private fb = inject(FormBuilder);
  private dialogRef = inject(DynamicDialogRef);
  private libraryService = inject(LibraryService);

  form: FormGroup;
  activeIndex: number = 0;
  steps = [{ label: 'Information' }, { label: 'Summary' }];
  logo: Logo = {
    file: null,
    objectURL: null,
    name: null
  };

  additionaFiles: AdditionalFile[] = [];
  decorationMethodOptions = [
    { name: 'DTG', value: 'dtg' },
    { name: 'EMBROIDERY', value: 'embroidery' }
  ];

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      file: [null, Validators.required],
      additional_files: [null],
      input_information: [null],
      decoration_method: [null]
    });
  }

  onLogoUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.logo.file = file;
    this.logo.objectURL = objectURL;
    this.logo.name = fileName;
  }

  removeLogo(): void {
    this.logo.file = null;
    this.logo.objectURL = null;
  }

  onAdditionalFileRemove(index: number) {
    console.log(index);
  }

  onAdditionalFilesUpload(event: FileUploadEvent | FileSelectEvent) {
    for (const file of event.files) {
      this.additionaFiles.push({
        file,
        name: file.name
      });
    }
  }

  nextStep() {
    this.activeIndex++;
  }

  previousStep() {
    this.activeIndex--;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
