import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdPipe',
  standalone: true
})
export class UsdPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe;

  constructor() {
    this.currencyPipe = new CurrencyPipe('en-US');
  }

  transform(
    value: number | string | null | undefined,
    currencyCode: string = 'USD',
    display: string = 'symbol',
    digitsInfo: string = '1.2-2'
  ): string | null {
    return this.currencyPipe.transform(value, currencyCode, display, digitsInfo);
  }
}
