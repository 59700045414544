import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-lock-screen',
  standalone: true,
  imports: [ButtonModule, RippleModule, RouterLink],
  templateUrl: './lock-screen.component.html'
})
export class LockScreenComponent {}
