import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionService extends ApiService {
  routePrefix = `${this.apiUrl}subscriptions/`;

  public getCompanyStorefrontsSubscriptions(companyId: number) {
    return this.get(`${this.routePrefix}${companyId}/all`);
  }

  public getStorefrontSubscription(storefront_id: number) {
    return this.get(`${this.routePrefix}storefronts/status${storefront_id}`);
  }

  public addStorefrontSubscription(subscriptionInfo: {
    plan_id: number;
    storefront_id: number;
  }) {
    return this.post(`${this.routePrefix}`, subscriptionInfo);
  }

  public unsubscribe(storefrontId: number) {
    return this.patch(`${this.routePrefix}unsubscribe`, {
      storefront_id: storefrontId
    });
  }

  public reactivate(storefrontId: number) {
    return this.patch(`${this.routePrefix}reactivate-subscription`, {
      storefront_id: storefrontId
    });
  }

  public cancelDowngrade(storefrontId: number) {
    return this.patch(`${this.routePrefix}cancel-downgrade`, {
      storefront_id: storefrontId
    });
  }

  public updateStorefrontSubscription(updateSubscriptionInfo: {
    plan_id: number;
    storefront_id: number;
  }) {
    return this.patch(
      `${this.routePrefix}update-subscription`,
      updateSubscriptionInfo
    );
  }

  public startStorefrontSubscription(startSubscriptionInfo: {
    plan_id: number;
    storefront_id: number;
  }) {
    return this.post(
      `${this.routePrefix}`,
      startSubscriptionInfo
    );
  }

  public getPlans() {
    return this.get(`${this.apiUrl}plans`);
  }
}
