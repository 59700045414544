import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export class SupplierProductStatusChangedEvent {
  static eventName = '\\App\\Events\\Supplier\\SupplierProductStatusChanged';

  supplierProductId: number;
  supplierId: number;
  supplierProductStatus: string;
  supplierProductSubStatus?: string;
  supplierProduct: SupplierProduct;
}
