import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription } from 'rxjs';

import { AuthService } from '../../_shared/services/auth.service';
import { ToastService } from '../../_shared/services/toast.service';
import { AuthStore } from '../../_store/auth.store';

@Component({
  selector: 'app-email-verification',
  standalone: true,
  imports: [ButtonModule, RouterLink, InputTextModule],
  templateUrl: './email-verification.component.html'
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  toastService = inject(ToastService);
  authStore = inject(AuthStore);
  router = inject(Router);

  private subscriptions: Subscription[] = [];

  token: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      if (this.token) {
        this.resendVerificationEmail();
      }
    });
  }

  resendVerificationEmail() {
    this.subscriptions.push(
      this.authService.verifyEmail(this.token).subscribe({
        next: res => {
          this.authStore.setToken(res.data.access_token);
          this.authStore.setAuthUser(res.data.user);
          this.router.navigateByUrl('/');
        },
        error: err => {
          if (err.status === 404) {
            this.toastService.error('Invalid token. Please try again.');
            return;
          }
          console.error('Action failed:', err);
        }
      })
    );
  }

  resendEmail() {
    this.subscriptions.push(
      this.authService.resendVerificationEmail().subscribe({
        next: () => {
          this.toastService.success('Verification email is sent again');
        },
        error: err => {
          console.error('Action failed:', err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
