<form [formGroup]="form" (submit)="onSubmit()">
  <app-input
    [fullWidth]="true"
    [control]="form.controls.name"
    name="Name"
    label="Name"
    placeholder="Enter folder name"
  ></app-input>
  <div class="flex justify-content-end">
    <p-button [label]="folder ? 'Update' : 'Create'" type="submit" />
  </div>
</form>
