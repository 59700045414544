import { NgClass } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { finalize } from 'rxjs';

import { RequestInviteModalComponent } from 'app/_shared/components/request-invite-modal/request-invite-modal.component';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { AuthService } from '../../_shared/services/auth.service';
import { AuthStore } from '../../_store/auth.store';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule,
    InputTextModule,
    RouterLink,
    ButtonModule,
    RippleModule,
    ReactiveFormsModule,
    NgClass,
    MessagesModule,
    PasswordModule
  ],
  providers: [DialogService],
  templateUrl: './login.component.html'
})
export class LoginComponent {
  authService = inject(AuthService);
  authStore = inject(AuthStore);
  router = inject(Router);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;

  submitted = signal(false);
  isLoading = signal(false);

  loginForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required, CustomValidators.passwordPattern()]),
    remember: new FormControl(false, [])
  });
  showPassword = false;

  openInviteCompanyModal() {
    this.dialogRef = this.dialogService.open(RequestInviteModalComponent, {
      header: 'Request invite to SSM platform',
      width: '400px'
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('Your invite request has been sent successfully!');
      }
    });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onFormSubmit() {
    if (!this.loginForm.valid) {
      this.submitted.set(true);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const formData: any = this.loginForm.value;
    this.isLoading.set(true);

    this.authService
      .login(formData.email, formData.password, formData.remember)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: res => {
          this.authStore.setToken(res.data.access_token);
          this.authStore.setAuthUser(res.data.user);
          this.handleNavigationAfterLogin();
        },
        error: err => {
          console.error('Login failed:', err);
          this.toastService.error(err.error.message);
        }
      });
  }

  handleNavigationAfterLogin() {
    if (this.authStore.isVerified()) {
      this.router.navigateByUrl('/');
      return;
    }
    this.router.navigateByUrl('/auth/email-verification');
  }
}
