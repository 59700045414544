<div class="card">
  <app-list-products
    [products]="clientSelectionList"
    [columns]="cols"
    [paginatorOptions]="paginatorOptions"
    (lazyLoadProducts)="handleLazyLoadProducts($event)"
    [loading]="isLoading"
    [headerActions]="headerActions"
    emptyMessage="There are no selections"
  ></app-list-products>
  <p-confirmDialog
    header="Confirmation"
    key="confirmDelete"
    icon="pi pi-exclamation-triangle"
    [style]="{ width: '28rem' }"
  ></p-confirmDialog>
</div>
