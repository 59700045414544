import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService extends ApiService {
  getStorefrontLibraryFolders(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders`);
  }

  getLibraryFolderFiles(storefrontId: number, folderId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/library-folders/${folderId}`);
  }

  createLibraryFile(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries`, data);
  }

  updateLibraryFile(storefrontId: number, libraryId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}`, data);
  }

  getLatestUsedFiles(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/latest-used`);
  }
}
