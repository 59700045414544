<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex flex-row justify-content-between">
        <div class="text-900 font-bold text-3xl mb-4 mt-2">User Info</div>
        <p-button icon="pi pi-pencil" label="Edit User Permissions" outlined (click)="openPermissionEditor()" />
      </div>
      <p-tabView>
        <p-tabPanel header="Details">
          <h4>Basic information</h4>
          <div class="grid">
            <div class="col-12 lg:col-4">
              <ul class="list-none p-0 m-0 text-600 mb-3">
                <li class="mb-3">
                  <div class="flex justify-between align-items-center gap-2">
                    <span class="font-semibold">Name:</span>
                    <span>{{ user?.first_name }}</span>
                  </div>
                </li>
                <li class="mb-3">
                  <div class="flex justify-between align-items-center gap-2">
                    <span class="font-semibold">Email:</span>
                    <span>{{ user?.email }}</span>
                  </div>
                </li>
                <li class="mb-3">
                  <div class="flex justify-between align-items-center gap-2">
                    <span class="font-semibold">Phone:</span>
                    <span>{{ user?.phone || '/' }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Companies">
          <h4>User Companies</h4>
          @if (isLoading) {
            <ul class="m-0 py-0 pl-3 mb-3">
              @for (row of loadingRows; track row) {
                <li class="mb-3">
                  <div class="flex">
                    <div style="flex: 1">
                      <p-skeleton width="100%" />
                    </div>
                  </div>
                </li>
              }
            </ul>
          } @else {
            <ul class="py-0 pl-3 m-0 text-600 mb-3">
              @for (company of user?.companies; track company) {
                <li class="mb-2">{{ company.name }}</li>
              }
            </ul>
            @if (!user?.companies?.length) {
              <p>User doesn't have any company</p>
            }
          }
        </p-tabPanel>
        <p-tabPanel header="Storefronts">
          <h4>User Storefronts</h4>
          @if (isLoading) {
            <ul class="m-0 py-0 pl-3 mb-3">
              @for (row of loadingRows; track row) {
                <li class="mb-3">
                  <div class="flex">
                    <div style="flex: 1">
                      <p-skeleton width="100%" />
                    </div>
                  </div>
                </li>
              }
            </ul>
          } @else {
            <ul class="py-0 pl-3 m-0 text-600 mb-3">
              <ng-container *ngFor="let company of user?.companies">
                <li *ngFor="let storefront of company.storefronts" class="mb-2">
                  {{ storefront.name }}
                </li>
              </ng-container>
            </ul>
            @if (noStorefronts()) {
              <p>User doesn't have any storefronts</p>
            }
          }
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
