import { CurrencyPipe, JsonPipe, NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { Table, TableModule } from 'primeng/table';

import { Color } from 'app/_shared/interfaces/colors';
import { Supplier } from 'app/_shared/interfaces/supplier';

@Component({
  selector: 'app-colors',
  standalone: true,
  imports: [TableModule, CurrencyPipe, InputTextModule, NgStyle, ButtonModule, RippleModule, JsonPipe],
  templateUrl: './colors.component.html',
  styleUrl: './colors.component.scss'
})
export class ColorsComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  colors: Color[];
  suppliers: Supplier[];

  ngOnInit() {
    this.activatedRoute.data.subscribe(next => {
      this.colors = next.colors.data;
      this.suppliers = next.suppliers.data;

      this.colors = this.colors.map(color => {
        const defaultHit = color.supplier_colors?.find(sColor => sColor.supplier.slug === 'hit-promo');
        return { ...color, 'hit-promo': defaultHit?.name };
      });
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  editColor(color: Color) {
    this.router.navigate(['colors', color.id]);
  }

  addNewColor() {
    this.router.navigate(['colors', 'create']);
  }
}
