import { Injectable } from '@angular/core';

import { FormGroupValue } from '../models/FormGroup';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AccountService extends ApiService {
  routePrefix = `${this.apiUrl}account/`;

  public updateAccount(data: FormGroupValue) {
    return this.put(`${this.routePrefix}`, data);
  }

  public updatePassword(data: FormGroupValue) {
    return this.put(`${this.routePrefix}password`, data);
  }
}
