import { SanmarSupplierProduct } from 'app/_shared/interfaces/supplier';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export class SanmarProductMapper extends SupplierProduct {
  constructor(product: SanmarSupplierProduct) {
    super(product);
    this.name = product.product_name;
    this.description = product.description;
  }
}
