<p-card>
  <div class="flex justify-content-between align-items-center mb-4">
    <h5>Select Product From Catalog</h5>
  </div>

  <app-list-products
    [products]="catalogProducts"
    [columns]="cols"
    [paginatorOptions]="paginatorOptions"
    (lazyLoadProducts)="handleLazyLoadProducts($event)"
    [loading]="isLoading"
  ></app-list-products>
</p-card>
