<h5>Margins</h5>
@if (form) {
  <form [formGroup]="form" (ngSubmit)="handleSubmit()">
    <div class="flex">
      <app-input
        name="margin"
        label="Product price margin"
        [control]="form.controls.margin"
        placeholder="Product price margin"
        appendSymbol="%"
        type="number"
      ></app-input>
    </div>

    <div class="mt-5">
      <p-button class="mt-5" type="submit" label="Save" />
    </div>
  </form>
}
