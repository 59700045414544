import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import {
  Columns,
  HeaderActions,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { PaginatorOptions } from 'app/_shared/interfaces/IApiPaginatedData';
import { Collection } from 'app/_shared/interfaces/clientSelection';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-collections-list',
  standalone: true,
  imports: [ListProductsComponent, ConfirmDialogModule],
  providers: [ConfirmationService, ToastService],
  templateUrl: './collections-list.component.html',
  styleUrl: './collections-list.component.scss'
})
export class CollectionsListComponent implements OnInit {
  private confirmationService = inject(ConfirmationService);
  private toastService = inject(ToastService);
  private companyService = inject(CompanyService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  companyId: string;
  collections: Collection[];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 5,
    first: 0
  };
  isLoading: boolean;
  cols: Columns[] = [
    {
      field: 'title',
      header: 'Title',
      width: '80%'
    },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '20%',
      actions: [
        {
          icon: 'pi pi-pencil ',
          label: 'Edit',
          onClick: ($event: MouseEvent, collection: Collection) => {
            this.router.navigate([collection.id, 'edit'], { relativeTo: this.activatedRoute });
          }
        },
        {
          icon: 'pi pi-trash ',
          label: 'Remove',
          severity: 'danger',
          onClick: (_: MouseEvent, collection: Collection) => this.openDeleteModal(collection)
        }
      ]
    }
  ];
  headerActions: HeaderActions[] = [
    {
      icon: 'pi pi-share-alt',
      label: 'Create collection',
      onClick: () => this.router.navigate(['create'], { relativeTo: this.activatedRoute })
    }
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.id;
    });
  }

  getCollections(paginatorOptions: ProductPaginatorOptions | PaginatorOptions) {
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      ...(globalFilter && { global_filter: globalFilter })
    };
    this.companyService.getCollections(1, params).subscribe(next => {
      this.collections = next.data.data;
      this.paginatorOptions = {
        total: next.data.total,
        rows: next.data.per_page,
        first: next.data.from - 1
      };
    });
  }

  openDeleteModal(selection: Collection) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message: 'Are you sure that you want to delete this collection?',
      accept: () => {
        this.companyService.deleteCollection(this.companyId, selection.id).subscribe(() => {
          this.getCollections(this.paginatorOptions);
          this.toastService.success('Selection deleted successfully.');
        });
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }
}
