import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { DataViewLazyLoadEvent, DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { finalize, map } from 'rxjs';

import { ProductPaginatorOptions } from 'app/_shared/components/list-products/list-products.component';
import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { ClientSelectionList, Collection } from 'app/_shared/interfaces/clientSelection';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { CompanyService } from 'app/_shared/services/company.service';

interface CatalogProductWithSelected extends CatalogProduct {
  selected: boolean;
}

@Component({
  selector: 'app-product-selection',
  standalone: true,
  imports: [Button, DataViewModule, PrimeTemplate, DividerModule, PricePipe, UsdPipe],
  templateUrl: './product-selection.component.html',
  styleUrl: './product-selection.component.scss'
})
export class ProductSelectionComponent implements OnInit {
  type = input<'collection' | 'selection'>('selection');
  isSubmitting = input<boolean>();
  disabled = input<boolean>();
  collection = input<Collection>();
  selection = input<ClientSelectionList>();
  onSave = output<number[]>();

  private catalogService = inject(CatalogService);
  private activatedRoute = inject(ActivatedRoute);
  private companyService = inject(CompanyService);

  products = signal<CatalogProductWithSelected[]>([]);
  isCollection = computed(() => this.type() === 'collection');
  selectedProducts = signal<CatalogProductWithSelected[]>([]);
  companyId: string;
  collectionId: string;
  token: string;
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 9,
    first: 0
  };
  isLoading = true;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyId = params.id;
      this.collectionId = params.collectionId;
      this.token = params.token;
    });
    this.handleProductLoading(this.paginatorOptions);
  }

  getProducts(paginatorOptions: ProductPaginatorOptions | DataViewLazyLoadEvent) {
    const { rows, first } = paginatorOptions;
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      'relations[]': ['catalogProductConfiguration', 'featuredImage']
    };

    if (this.isCollection()) {
      return this.catalogService.getProducts(params);
    } else {
      return this.companyService.getClientSelectionProducts({ ...params, token: this.token });
    }
  }

  preselectItems(items: CatalogProduct[]) {
    this.selectedProducts.update(() => {
      return items.map(prod => {
        return { ...prod, selected: true };
      });
    });
    this.selectedProducts.set(this.selectedProducts());
  }

  handleProductLoading($event: ProductPaginatorOptions | DataViewLazyLoadEvent) {
    this.getProducts($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: products => {
          if (this.collection()) {
            // Preselect selected products
            this.preselectItems(this.collection()?.catalog_products ?? []);
          }
          if (this.selection()) {
            this.preselectItems(this.selection()?.catalog_product_selections ?? []);
          }
          const withSelected = products.data.data.map(product => {
            const selected = this.selectedProducts().some(prod => product.id === prod.id);
            return { ...product, selected };
          });
          this.products.set(withSelected);
          this.paginatorOptions = {
            total: products.data.total,
            rows: products.data.per_page,
            first: products.data.from - 1
          };
        },
        error: err => {
          console.error(err);
        }
      });
  }

  removeFromSelectedProducts(product: CatalogProductWithSelected) {
    this.selectedProducts.update(products => products.filter(item => item.id !== product.id));
  }

  handleAddRemoveProduct(product: CatalogProductWithSelected) {
    this.products.update(products => {
      return products.map(item => {
        if (item.id === product.id) {
          return { ...item, selected: !product.selected };
        }
        return item;
      });
    });
    const item = this.products().find(item => item.id === product.id);
    if (item?.selected) {
      this.selectedProducts.update(prev => [...prev, item]);
    } else {
      this.removeFromSelectedProducts(item!);
    }
  }

  handleSave() {
    const ids = this.selectedProducts().map(product => product.id);
    this.onSave.emit(ids);
  }

  addSelectedToProducts(products: CatalogProduct[]) {
    return products.map(product => {
      const selected = this.selectedProducts().some(prod => product.id === prod.id);
      return { ...product, selected };
    });
  }
}
