import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class CatalogProductCategoryService extends ApiService {
  routePrefix = `${this.apiUrl}admin-categories/`;

  public index() {
    return this.get(`${this.routePrefix}`);
  }

  public getById(id: number) {
    return this.get(this.routePrefix + id);
  }

  public deleteCategory(id: number) {
    return this.delete(`${this.routePrefix}${id}`);
  }

  public createCategory(data: FormData) {
    return this.post(this.routePrefix, data);
  }

  public updateCategory(data: FormData, id?: number) {
    return this.post(`${this.routePrefix}${id}`, data);
  }
}
