import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ConfirmationService, MenuItem, PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { Ripple } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';

import { GiftCard } from 'app/_shared/models/Coupon';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { GiftCardService } from 'app/_shared/services/gift-card.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-gift-card-list',
  standalone: true,
  imports: [
    Button,
    PrimeTemplate,
    RouterLink,
    TableModule,
    SplitButtonModule,
    DisplayDatePipe,
    UsdPipe,
    MenuModule,
    Ripple,
    ConfirmDialogModule
  ],
  providers: [GiftCardService, ConfirmationService],
  templateUrl: './gift-card-list.component.html',
  styles: `
    ::ng-deep {
      .list-actions {
        .p-tieredmenu {
          width: 19.4rem;
        }
      }
    }
  `
})
export class GiftCardListComponent implements OnInit {
  private giftCardService: GiftCardService = inject(GiftCardService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private toastService: ToastService = inject(ToastService);
  private router: Router = inject(Router);

  isLoading = false;
  totalRecords: number;
  storefrontId: string;

  coupons: GiftCard[] = [];

  actionButtonItems: MenuItem[] = [
    {
      label: 'Gift Card Accounting',
      icon: 'pi pi-file-excel',
      command: () => {
        console.log('download');
      }
    },
    {
      label: 'Download Gift Card Pack template',
      icon: 'pi pi-download',
      command: () => {
        console.log('download');
      }
    },
    {
      label: 'Customize Gift Card Email',
      icon: 'pi pi-envelope',
      command: () => {
        console.log('download');
      }
    }
  ];

  splitButtonItemsMap: { [giftCardId: number]: MenuItem[] } = {};

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
    });
  }

  loadGiftCards($event?: any) {
    this.giftCardService.paginatedIndex(+this.storefrontId, $event).subscribe({
      next: res => {
        this.coupons = res.data.data;
        this.totalRecords = res.data.total;
        this.populateSplitButtonItems();
      },
      error: err => console.error(err),
      complete: () => (this.isLoading = false)
    });
  }

  populateSplitButtonItems() {
    this.coupons.forEach(giftCard => {
      this.splitButtonItemsMap[giftCard.id] = [
        ...(giftCard.specified_recipients
          ? [
              {
                label: 'Send emails',
                icon: 'pi pi-envelope',
                giftCard,
                command: () => this.sendEmails(giftCard.id)
              },
              { separator: true }
            ]
          : []),
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => this.navigateToEditPage(giftCard.id)
        },
        { separator: true },
        {
          label: 'Delete',
          styleClass: 'p-text-danger',
          icon: 'pi pi-trash',
          command: () => this.openDeleteModal(giftCard.id)
        }
      ];
    });
  }

  splitClick(giftCard: GiftCard) {
    this.router.navigate([giftCard.id], { relativeTo: this.activatedRoute });
  }

  openDeleteModal(giftCardId: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message:
        'Are you sure that you want to delete this gift card?</br></br><strong>This operation cannot be undone!</strong>',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteGiftCard(giftCardId);
      }
    });
  }

  deleteGiftCard(giftCardId: number) {
    this.giftCardService.remove(+this.storefrontId, giftCardId).subscribe({
      next: () => {
        this.toastService.success('Gift card successfully deleted!');
        this.loadGiftCards();
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }

  navigateToEditPage(giftCardId: number) {
    this.router.navigate([giftCardId, 'edit'], {
      relativeTo: this.activatedRoute
    });
  }

  sendEmails(giftCardId: number) {
    this.giftCardService.sendEmails(+this.storefrontId, [giftCardId]).subscribe({
      next: () => {
        this.toastService.success('Email successfully sent!');
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }
}
