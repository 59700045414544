import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';

import { LayoutService } from '../../../_shared/services/layout.service';
import { FileAppService } from './file.app.service';

export interface Metric {
  title: string;
  icon: string;
  fieldColor: string;
  color: string;
  files: string;
  fileSize: string;
  items: object;
}
export interface Folder {
  name: string;
  icon: string;
  size: string;
}
export interface File {
  name?: string;
  image?: string;
  date?: string;
  fileSize?: string;
}

@Component({
  selector: 'app-files',
  standalone: true,
  imports: [MenuModule, NgClass, ChartModule, ButtonModule, RippleModule, TableModule],
  providers: [FileAppService],
  templateUrl: './files.component.html'
})
export class FilesComponent implements OnInit {
  fileChart: any;

  fileChartOptions: any;

  chartPlugins: any;

  files: File[] = [];

  metrics: Metric[] = [];

  folders: Folder[] = [];

  menuitems: MenuItem[] = [];

  subscription: Subscription;

  constructor(
    private fileService: FileAppService,
    private layoutService: LayoutService
  ) {
    this.subscription = this.layoutService.configUpdate$.subscribe(() => {
      this.initChart();
    });
  }

  ngOnInit() {
    this.fileService.getFiles().then(data => {
      this.files = data;
    });
    this.fileService.getMetrics().then(data => {
      this.metrics = data;
    });
    this.fileService.getFoldersLarge().then(data => {
      this.folders = data;
    });

    this.initChart();

    this.menuitems = [
      {
        label: 'View',
        icon: 'pi pi-search'
      },
      {
        label: 'Refresh',
        icon: 'pi pi-refresh'
      }
    ];
  }

  initChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    this.chartPlugins = [
      {
        beforeDraw(chart: any) {
          const { ctx } = chart;
          const { width } = chart;
          const { height } = chart;
          const fontSize = 1.5;
          const oldFill = ctx.fillStyle;

          ctx.restore();
          ctx.font = `${fontSize}rem sans-serif`;
          ctx.textBaseline = 'middle';

          const text = 'Free Space';
          const text2 = `${50}GB / ${80}GB`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = (height + chart.chartArea.top) / 2.25;

          const text2X = Math.round((width - ctx.measureText(text).width) / 2.1);
          const text2Y = (height + chart.chartArea.top) / 1.75;

          ctx.fillStyle = chart.config.data.datasets[0].backgroundColor[0];
          ctx.fillText(text, textX, textY);
          ctx.fillText(text2, text2X, text2Y);
          ctx.fillStyle = oldFill;
          ctx.save();
        }
      }
    ];

    this.fileChart = {
      datasets: [
        {
          data: [300, 100],
          backgroundColor: [
            documentStyle.getPropertyValue('--primary-600'),
            documentStyle.getPropertyValue('--primary-100')
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--primary-700'),
            documentStyle.getPropertyValue('--primary-200')
          ],
          borderColor: 'transparent',
          fill: true
        }
      ]
    };

    this.fileChartOptions = {
      animation: { duration: 0 },
      cutout: '90%',
      plugins: { legend: { labels: { color: textColor } } }
    };
  }
}
