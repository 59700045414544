import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { catchError, map, of } from 'rxjs';

import { CompanyService } from 'app/_shared/services/company.service';

export const companyBreadcrumbResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const companyService = inject(CompanyService);

  const { id } = route.params;

  return companyService.getById(+id).pipe(
    map(res => {
      return res.data.name;
    }),
    catchError(error => {
      console.error('An error occurred:', error);

      return of('Error occurred, fallback value');
    })
  );
};
