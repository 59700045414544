<div>
  <h2>{{ color.name }}</h2>
  <p>Please select color from suppliers to map to this color</p>

  <div class="grid">
    <div class="col">
      <h5>Hit Promotional Products</h5>
      <p-dropdown
        [options]="hitColors"
        [(ngModel)]="selectedColorHit"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="true"
        placeholder="Select color"
      >
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.name }}</div>
          </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ country.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="mt-5">
    <div class="flex align-items-center gap-3">
      <button pButton pRipple type="button" label="Save color" (click)="saveColors()"></button>
      <button
        pButton
        pRipple
        type="button"
        label="Delete color"
        class="p-button-danger"
        (click)="deleteColor()"
      ></button>
    </div>
  </div>
</div>
