<div class="card">
  <h4 class="mb-5">Choose Company</h4>
  <p-table
    #dt
    [value]="isLoading ? [] : companies"
    [lazy]="true"
    (onLazyLoad)="loadCompanies($event)"
    dataKey="id"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [loading]="isLoading"
    [showLoader]="false"
    responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" class="white-space-nowrap">
          Name
          <p-sortIcon field="name" />
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-company>
      @if (companies.length > 0) {
        <tr>
          <td
            [routerLink]="['companies', company.id, 'general']"
            (click)="setWorkingCompany(company)"
            class="cursor-pointer"
          >
            {{ company.name }}
          </td>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td class="text-center">There are no companies</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      @for (row of loadingRows; track row) {
        <tr>
          <td>
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
</div>
