<div class="layout-container" [ngClass]="containerClass">
  <app-superadmin-topbar />
  <app-sidebar />

  <div class="layout-content-wrapper">
    <app-topbar />
    <app-breadcrumb class="content-breadcrumb" />

    <!--    <div class="layout-content">-->
    <div>
      <router-outlet />
    </div>
  </div>

  <app-right-sidebar />

  <div class="layout-mask"></div>
</div>
