import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription } from 'rxjs';

import { CompanyService } from 'app/_shared/services/company.service';
import { CompanyStore } from 'app/_store/company.store';
import { StorefrontStore } from 'app/_store/storefront.store';

import { Storefront } from '../../../_shared/models/Storefront';
import { StorefrontService } from '../../../_shared/services/storefront.service';
import { ToastService } from '../../../_shared/services/toast.service';

@Component({
  selector: 'app-storefront-list',
  standalone: true,
  imports: [RouterLink, ButtonModule, DataViewModule, ConfirmDialogModule, InputGroupModule, InputTextModule],
  providers: [ConfirmationService],
  templateUrl: './storefront-list.component.html',
  styleUrls: ['./storefront-list.component.scss']
})
export class StorefrontListComponent implements OnInit, OnDestroy {
  public companyStore = inject(CompanyStore);
  public storefrontStore = inject(StorefrontStore);
  public storefrontService = inject(StorefrontService);
  public companyService = inject(CompanyService);
  toastService = inject(ToastService);
  route = inject(ActivatedRoute);

  public storefronts: Storefront[] | undefined = [];
  public totalRecords = 0;
  public first = 0;
  companyId: number;
  private subscriptions: Subscription[] = [];

  sortOrder: number = 0;
  sortField: string = '';
  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.storefrontStore.unsetSelectedStorefront();

    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
  }

  loadStorefronts($event?: any) {
    this.companyService.companyStorefrontsPaginated(this.companyId, $event).subscribe({
      next: res => {
        this.storefronts = res.data.data;
        this.totalRecords = res.data.total;
      },
      error: error => console.error(error)
    });
  }

  openDeleteModal(event: Event, storefrontId?: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message:
        'Are you sure that you want to delete this storefront?</br></br><strong>This operation cannot be undone!</strong>',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteStorefront(storefrontId);
      }
    });
  }

  private deleteStorefront(storefrontId?: number) {
    this.subscriptions.push(
      this.storefrontService.deleteStorefront(storefrontId).subscribe({
        next: () => {
          this.companyStore.removeCompanyStorefront(storefrontId!);
          this.toastService.success('Storefront successfully deleted!');
          this.loadStorefronts();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  openInNewTab(url: string) {
    window.open(url, '_blank');
  }
}
