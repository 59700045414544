
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function PositiveIntegerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = /^\d+$/.test(value) && value > 0;
    return isValid ? null : { positiveInteger: true };
  };
}
