<div class="card">
  <div class="grid">
    <div class="col-6 sm:col-4">
      <div>
        @if (files.length) {
          <div class="mb-3">
            <p-button icon="pi pi-plus" label="Expand all" (onClick)="expandAll()" class="mr-2" />
            <p-button icon="pi pi-minus" label="Collapse all" (onClick)="collapseAll()" />
          </div>

          <p-tree
            [value]="files"
            expanded
            class="w-full"
            selectionMode="checkbox"
            [(selection)]="selectedFiles"
            emptyMessage="No files found"
          />
        } @else {
          <p>There area no files in storage</p>
        }
      </div>
    </div>
    <div class="col-6 sm:col-8">
      @if (selectedFiles?.length) {
        <div class="flex gap-2 justify-content-end mb-4">
          <p-button label="Lock selected" (onClick)="lockUnlockFiles(true, 1)" />
          <p-button label="Unlock selected" (onClick)="lockUnlockFiles(true, 0)" />
          <p-button label="Remove selected" severity="danger" (onClick)="deleteFiles()" />
        </div>
        @for (file of selectedFiles; track file.key) {
          @if (file.id) {
            <div class="file">
              <div class="flex justify-content-between">
                <div class="flex gap-3 align-items-center">
                  <img class="image" [src]="file.data" alt="" />
                  <p class="m-0">{{ file.label }}</p>
                </div>
                <div class="flex gap-2">
                  <p-button label="Lock file" [outlined]="true" (onClick)="lockUnlockFiles(false, 1, file)" />
                  <p-button label="Unlock file" [outlined]="true" (onClick)="lockUnlockFiles(false, 0, file)" />
                  <p-button label="Remove" severity="danger" [outlined]="true" (onClick)="deleteFiles(false, file)" />
                </div>
              </div>
            </div>
          }
        }
      }
    </div>
  </div>
</div>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '450px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
/>
