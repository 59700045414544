import { computed, inject, Injectable } from '@angular/core';

import { PromoCode } from 'app/_shared/models/Coupon';
import { ApiService } from 'app/_shared/services/api.service';
import { StorefrontStore } from 'app/_store/storefront.store';

@Injectable({ providedIn: 'root' })
export class PromoCodeService extends ApiService {
  storefrontStore = inject(StorefrontStore);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);

  routePrefix = `${this.apiUrl}storefronts/${this.storefrontId()}/promo-codes`;

  public paginatedIndex($event?: any) {
    return this.get(`${this.routePrefix}`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  store(promoCode: PromoCode) {
    return this.post<PromoCode>(`${this.routePrefix}`, promoCode);
  }

  update(promoCodeId: string, promoCode: PromoCode) {
    return this.put<PromoCode>(`${this.routePrefix}/${promoCodeId}`, promoCode);
  }

  remove(promoCodeId: number) {
    return this.delete(`${this.routePrefix}/${promoCodeId}`);
  }

  getById(promoCodeId: string) {
    return this.get<PromoCode>(`${this.routePrefix}/${promoCodeId}`, {
      params: {
        'relations[]': ['products', 'productCategories', 'products.productThumbnail']
      }
    });
  }
}
