import { Component, inject, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';

import { StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { SuperadminProductService } from 'app/_shared/services/superadmin-product.service';
import { StorefrontStore } from 'app/_store/storefront.store';

import { Columns, ListProductsComponent, ProductPaginatorOptions } from '../list-products/list-products.component';

@Component({
  selector: 'app-products-from-catalog-product',
  standalone: true,
  imports: [ListProductsComponent],
  templateUrl: './products-from-catalog-product.component.html',
  styleUrl: './products-from-catalog-product.component.scss'
})
export class ProductsFromCatalogProductComponent implements OnInit {
  superAdminProductService = inject(SuperadminProductService);
  dialogConfig = inject(DynamicDialogConfig);
  storefrontStore = inject(StorefrontStore);
  catalogProductId: number;
  storefrontProducts: StorefrontProduct[];
  isLoading: boolean = true;
  cols: Columns[] = [
    { element: 'image', field: 'product_thumbnail', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '40%' },
    { field: 'price', header: 'Price', width: '20%' }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 5,
    first: 0
  };
  dialogRef = inject(DynamicDialogRef);

  ngOnInit(): void {
    this.catalogProductId = this.dialogConfig.data.catalogProductId;
  }

  fetchStorefrontProductsCreatedFromCatalog(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1
    };

    const filters = {
      field: 'catalog_product_id',
      field_value: this.catalogProductId
    };

    this.superAdminProductService.paginatedIndex(params, filters).subscribe({
      next: products => {
        this.storefrontProducts = products.data.data;
        this.paginatorOptions = {
          total: products.data.total,
          rows: products.data.per_page,
          first: products.data.from - 1
        };
      },
      error: err => {
        console.error(err);
      },
      complete: () => (this.isLoading = false)
    });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchStorefrontProductsCreatedFromCatalog($event);
  }
}
