<h4 class="test">Libraries</h4>

<p-dataView
  #dv
  [value]="libraries()"
  [paginator]="true"
  [rows]="paginatorOptions.rows"
  [totalRecords]="paginatorOptions.total"
  [first]="paginatorOptions.first"
  filterBy="name"
  layout="grid"
  [lazy]="true"
  [lazyLoadOnInit]="true"
  (onLazyLoad)="getLibraries($event)"
>
  <ng-template let-libraries pTemplate="gridItem">
    <div class="grid grid-nogutter">
      @for (library of libraries; track library.id) {
        <div class="col-12 lg:col-12 xl:col-3">
          <a [routerLink]="['libraries', library.id]">
            <div class="card m-3 border-1 surface-border item">
              <div class="flex flex-column align-items-center text-center mb-3">
                <img
                  [style]="{ height: '50px' }"
                  [src]="library.mainImage"
                  [alt]="library.name"
                  class="shadow-2 my-3 mx-0"
                />
                <div class="text-2xl font-bold">{{ library.name }}</div>
              </div>
            </div>
          </a>
        </div>
      }
    </div>
  </ng-template>
</p-dataView>
