import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { CompanyStore } from 'app/_store/company.store';

import { Storefront } from '../../../_shared/models/Storefront';
import { AuthStore } from '../../../_store/auth.store';
import { StorefrontStore } from '../../../_store/storefront.store';
import { NavItemComponent } from './nav-item/nav-item.component';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [NavItemComponent],
  templateUrl: './nav.component.html',
  styles: ''
})
export class NavComponent implements OnInit, OnDestroy {
  public storefrontStore = inject(StorefrontStore);
  public authStore = inject(AuthStore);
  public companyStore = inject(CompanyStore);

  model: MenuItem[] = [];
  public selectedStorefront?: Storefront | null = undefined;

  private storefrontEffect = effect(() => {
    this.selectedStorefront = this.storefrontStore?.selectedStorefront?.();
    this.buildMenu();
  });

  ngOnInit() {
    this.buildMenu();
  }

  ngOnDestroy() {
    this.storefrontEffect.destroy();
  }

  buildMenu() {
    const menuItems: MenuItem[] = [
      {
        label: 'Dashboard',
        icon: 'assets/images/icons/category.svg',
        routerLink: ['/']
      }
    ];

    if (this.authStore.isSuperAdmin()) {
      menuItems.push(
        ...[
          {
            label: 'Super admin',
            icon: 'assets/images/icons/user-octagon.svg',
            state: { hidden: true },
            items: [
              {
                label: 'Super Admin',
                icon: 'assets/images/icons/user-octagon.svg',
                items: [
                  {
                    label: 'All Orders',
                    icon: 'assets/images/icons/box.svg',
                    routerLink: ['orders']
                  },
                  {
                    label: 'Storefronts',
                    icon: 'assets/images/icons/bank.svg',
                    routerLink: ['storefronts']
                  },
                  {
                    label: 'Users',
                    icon: 'assets/images/icons/user.svg',
                    items: [
                      {
                        icon: 'assets/images/icons/profile-2user.svg',
                        label: 'All users',
                        routerLink: ['users']
                      },
                      {
                        label: 'Invites',
                        icon: 'assets/images/icons/message-text.svg',
                        routerLink: ['invites']
                      }
                    ]
                  },
                  {
                    label: 'Payments',
                    icon: 'assets/images/icons/empty-wallet.svg',
                    routerLink: ['coming-soon']
                    // routerLink: 'payments'
                  },
                  {
                    label: 'File Prep Requests',
                    icon: 'assets/images/icons/empty-wallet.svg',
                    routerLink: ['file-prep-requests']
                  },
                  {
                    label: 'Catalog',
                    icon: 'assets/images/icons/gallery.svg',
                    items: [
                      {
                        label: 'SSM Catalog',
                        routerLink: ['catalog']
                      },
                      {
                        label: 'Suppliers',
                        routerLink: ['suppliers']
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      );
    }

    if (this.companyStore.workingCompany()) {
      menuItems.push(
        ...[
          {
            label: this.companyStore.workingCompany()?.name,
            items: [
              {
                label: 'Company',
                icon: 'assets/images/icons/home-2.svg',
                items: [
                  {
                    label: 'General',
                    icon: 'assets/images/icons/courthouse.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/general`]
                  },
                  {
                    label: 'Users',
                    icon: 'assets/images/icons/user.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/users`]
                  },
                  {
                    label: 'Invites',
                    icon: 'assets/images/icons/message-text.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/invites`]
                  },
                  {
                    label: 'Finance',
                    icon: 'assets/images/icons/dollar-square.svg',
                    items: [
                      {
                        label: 'Transactions',
                        icon: 'assets/images/icons/horizontal.png',
                        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/transactions`]
                      },
                      {
                        label: 'Billing',
                        icon: 'assets/images/icons/dollar-square.svg',
                        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/billing`]
                      },
                      {
                        label: 'Payment Methods',
                        icon: 'assets/images/icons/empty-wallet.svg',
                        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/payment-methods`]
                      },
                      {
                        label: 'Tax',
                        icon: 'assets/images/icons/receipt-2.svg',
                        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/tax-forms`]
                      }
                    ]
                  },
                  {
                    label: 'Client Selection',
                    icon: 'assets/images/icons/box.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/client-selection`]
                  },
                  {
                    label: 'Collections',
                    icon: 'assets/images/icons/box.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/collections`]
                  },
                  {
                    label: 'Orders',
                    icon: 'assets/images/icons/box.svg',
                    routerLink: [`companies/${this.companyStore.workingCompany()?.id}/orders`]
                  },
                  // {
                  //   label: 'Users',
                  //   icon: 'assets/images/icons/user.png',
                  //   items: [
                  //     {
                  //       icon: 'assets/images/icons/profile-2user.png',
                  //       label: 'All users',
                  //       routerLink: ['users']
                  //     },
                  //     {
                  //       label: 'Invites',
                  //       icon: 'assets/images/icons/message-text.png',
                  //       routerLink: ['invites']
                  //     }
                  //   ]
                  // },
                  //
                  // {
                  //   label: 'Payments',
                  //   icon: 'assets/images/icons/empty-wallet.png',
                  //   routerLink: ['coming-soon']
                  //   // routerLink: 'payments'
                  // },
                  //
                  // {
                  //   label: 'Catalog',
                  //   icon: 'assets/images/icons/gallery.png',
                  //   items: [
                  //     {
                  //       label: 'SSM Catalog',
                  //       icon: 'pi pi-book',
                  //       routerLink: ['catalog']
                  //     },
                  //     {
                  //       label: 'Suppliers',
                  //       icon: 'pi pi-book',
                  //       routerLink: ['suppliers']
                  //     }
                  //   ]
                  // }
                ]
              },
              {
                label: 'Storefronts',
                icon: 'assets/images/icons/bank.svg',
                routerLink: [`companies/${this.companyStore.workingCompany()?.id}/storefronts`]
              }
            ]
            // routerLink: [`companies/${this.companyStore.workingCompany()?.id}/management`]
          }
        ]
      );

      if (this.selectedStorefront) {
        menuItems.push({
          label: this.selectedStorefront?.name,
          items: this.getStorefrontItems(this.selectedStorefront.id)
        });
      }
    }

    this.model = menuItems;
  }

  getStorefrontItems(storefrontId: number): MenuItem[] {
    return [
      {
        label: 'Products',
        icon: 'assets/images/icons/bag.svg',
        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/storefronts/${storefrontId}/products`]
      },
      {
        label: 'Design',
        icon: 'assets/images/icons/brush.svg',
        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/storefronts/${storefrontId}/designer`]
      },
      {
        label: 'Files',
        icon: 'assets/images/icons/folder.svg',
        items: [
          {
            label: 'Library',
            icon: 'assets/images/icons/folder-open.svg',
            // fix for navigation to remain open when page is refreshed
            // and to remain open when this route has child routes
            state: { hasChildren: true },
            routerLink: [
              `companies/${this.companyStore.workingCompany()?.id}/storefronts/${storefrontId}/files/folders`
            ]
          },
          {
            label: 'Storage',
            icon: 'assets/images/icons/folder-cloud.svg',
            // fix for navigation to remain open when page is refreshed
            // and to remain open when this route has child routes
            state: { hasChildren: true },
            routerLink: [
              `companies/${this.companyStore.workingCompany()?.id}/storefronts/${storefrontId}/files/storage`
            ]
          }
        ]
      },
      {
        label: 'Coupons',
        icon: 'assets/images/icons/tag-2.svg',
        routerLink: ['companies', this.companyStore.workingCompany()?.id, 'storefronts', storefrontId, 'coupons']
      },
      {
        label: 'Settings',
        icon: 'assets/images/icons/setting-2.svg',
        routerLink: [`companies/${this.companyStore.workingCompany()?.id}/storefronts/${storefrontId}/settings`]
      }
    ];
  }
}
