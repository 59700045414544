@if (formReady) {
  <div class="grid">
    <div class="col-12 md:col-6">
      <form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
        <div class="card p-fluid">
          <h5 class="mb-5">Your pofile</h5>
          <div class="field">
            <label for="first_name">First Name</label>
            <input id="first_name" type="text" pInputText formControlName="first_name" />
            @if (
              submitted ||
              (accountForm.get('first_name')?.invalid &&
                (accountForm.get('first_name')?.dirty || accountForm.get('first_name')?.touched))
            ) {
              @if (accountForm.get('first_name')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">First name is required</small>
                </div>
              }
            }
          </div>
          <div class="field">
            <label for="last_name">Last Name</label>
            <input id="last_name" type="text" pInputText formControlName="last_name" />
            @if (
              submitted ||
              (accountForm.get('last_name')?.invalid &&
                (accountForm.get('last_name')?.dirty || accountForm.get('last_name')?.touched))
            ) {
              @if (accountForm.get('last_name')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Last name is required</small>
                </div>
              }
            }
          </div>
          <div class="field">
            <label for="phone">Phone</label>
            <input id="phone" type="text" pInputText formControlName="phone" />
          </div>
          <div class="field">
            <label for="email">Email</label>
            <input id="email" type="text" pInputText formControlName="email" />
            @if (
              submitted ||
              (accountForm.get('email')?.invalid &&
                (accountForm.get('email')?.dirty || accountForm.get('email')?.touched))
            ) {
              @if (accountForm.get('email')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Email is required</small>
                </div>
              }
            }
          </div>
          <div class="field w-10rem">
            <button pButton pRipple class="p-button-primary flex-1" label="Update"></button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12 md:col-6">
      <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()">
        <div class="card p-fluid">
          <h5 class="mb-5">Update Password</h5>
          <div class="field">
            <label for="password">Password</label>
            <div class="p-inputgroup">
              <input id="password" [type]="showPassword ? 'text' : 'password'" pInputText formControlName="password" />
              <button type="button" pButton icon="pi pi-eye" (click)="togglePasswordVisibility()"></button>
            </div>
            @if (
              submitted ||
              (passwordForm.get('password')?.invalid &&
                (passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched))
            ) {
              @if (passwordForm.get('password')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Password is required</small>
                </div>
              }
              @if (passwordForm.get('password')?.errors?.['passwordPattern']) {
                <div class="w-50%">
                  <small class="p-text-danger">
                    Password must be at least 8 characters,
                    <br />
                    contain at least one uppercase letter,
                    <br />
                    one lowercase letter, one number
                    <br />
                    and one special character.
                  </small>
                </div>
              }
            }
          </div>
          <div class="field">
            <label for="password_confirmation">Confirm Password</label>
            <div class="p-inputgroup">
              <input
                id="password_confirmation"
                [type]="showPasswordConfirmation ? 'text' : 'password'"
                pInputText
                formControlName="password_confirmation"
              />
              <button type="button" pButton icon="pi pi-eye" (click)="togglePasswordConfirmationVisibility()"></button>
            </div>
            @if (
              submitted ||
              (passwordForm.get('password_confirmation')?.invalid &&
                (passwordForm.get('password_confirmation')?.dirty ||
                  passwordForm.get('password_confirmation')?.touched))
            ) {
              @if (passwordForm.get('password_confirmation')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Password Confirmation is required</small>
                </div>
              }
              @if (passwordForm.get('password_confirmation')?.errors?.['isMatching']) {
                <div>
                  <small class="p-text-danger">Passwords do not match</small>
                </div>
              }
            }
          </div>
          <div class="field w-10rem">
            <button pButton pRipple class="p-button-primary flex-1" label="Confirm"></button>
          </div>
        </div>
      </form>
    </div>
  </div>
}
