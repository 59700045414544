import { Route } from '@angular/router';

import { CouponsComponent } from 'app/admin/storefront/coupons/coupons.component';
import { GiftCardDetailsComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-details/gift-card-details.component';
import { GiftCardEditorComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-editor/gift-card-editor.component';
import { GiftCardEmailTemplateEditorComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-email-template-editor/gift-card-email-template-editor.component';
import { GiftCardListComponent } from 'app/admin/storefront/coupons/gift-cards/gift-card-list/gift-card-list.component';
import { PromoCodeEditorComponent } from 'app/admin/storefront/coupons/promo-codes/promo-code-editor/promo-code-editor.component';
import { PromoCodeListComponent } from 'app/admin/storefront/coupons/promo-codes/promo-code-list/promo-code-list.component';
import { DesignerComponent } from 'app/admin/storefront/designer/designer.component';
import { HomepageComponent } from 'app/admin/storefront/designer/homepage/homepage.component';
import { MenuAndPagesComponent } from 'app/admin/storefront/designer/menu-and-pages/menu-and-pages.component';
import { SettingsComponent } from 'app/admin/storefront/designer/settings/settings.component';
import { LibrariesListComponent } from 'app/admin/storefront/files/libraries-list/libraries-list.component';
import { LibraryEditorComponent } from 'app/admin/storefront/files/library-editor/library-editor.component';
import { LibraryFoldersListComponent } from 'app/admin/storefront/files/library-folders-list.component';
import { StorageFilesListComponent } from 'app/admin/storefront/files/storage-files-list/storage-files-list.component';
import { CategoryManagmentComponent } from 'app/admin/storefront/products/category-managment/category-managment.component';
import { CreateProductComponent } from 'app/admin/storefront/products/create-product/create-product.component';
import { CustomProductEditorComponent } from 'app/admin/storefront/products/custom-product-editor/custom-product-editor.component';
import { ListCatalogProductsComponent } from 'app/admin/storefront/products/list-catalog-products/list-catalog-products.component';
import { ProductsComponent } from 'app/admin/storefront/products/products.component';
import { SingleProductComponent } from 'app/admin/storefront/products/single-product/single-product.component';
import { CommunicationsComponent } from 'app/admin/storefront/settings/communications/communications.component';
import { GeneralSettingsComponent } from 'app/admin/storefront/settings/general-settings/general-settings.component';
import { SettingsComponent as StorefrontSettings } from 'app/admin/storefront/settings/settings.component';

// Prefix: "companies/:id/storefronts/:storefrontId"
export const StorefrontRoutes: Route[] = [
  {
    path: 'products',
    data: { breadcrumb: 'Products' },
    children: [
      {
        path: '',
        component: ProductsComponent
      },
      {
        path: 'create',
        data: { breadcrumb: 'Create' },
        children: [
          {
            path: '',
            component: ListCatalogProductsComponent
          },

          {
            path: ':catalogProductId',
            component: CreateProductComponent
          }
        ]
      },
      {
        path: 'custom',
        children: [
          {
            path: 'create',
            component: CustomProductEditorComponent
          },

          {
            path: ':customProductId',
            component: CustomProductEditorComponent
          }
        ]
      },
      {
        path: 'category-management',
        component: CategoryManagmentComponent,
        data: { breadcrumb: 'Categories' }
      },
      {
        path: ':productId',
        component: SingleProductComponent
      }
    ]
  },
  {
    path: 'designer',
    data: { breadcrumb: 'Designer' },
    component: DesignerComponent,
    children: [
      {
        path: '',
        redirectTo: 'homepage',
        pathMatch: 'full'
      },
      {
        path: 'homepage',
        component: HomepageComponent
      },
      {
        path: 'menu-and-pages',
        component: MenuAndPagesComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      }
    ]
  },
  {
    path: 'files',
    data: { breadcrumb: 'Files' },
    children: [
      {
        path: 'folders',
        data: { breadcrumb: 'Libraries' },
        children: [
          { path: '', component: LibraryFoldersListComponent },
          {
            path: ':libraryFolderId',

            children: [
              { path: '', component: LibrariesListComponent },
              {
                path: 'libraries/:libraryId',
                component: LibraryEditorComponent,
                data: { breadcrumb: 'Libraries' }
              }
            ]
          }
        ]
      },
      { path: 'storage', component: StorageFilesListComponent }
    ]
  },
  {
    path: 'settings',
    component: StorefrontSettings,
    data: { breadcrumb: 'Settings' },
    children: [
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
      },
      {
        path: 'general',
        component: GeneralSettingsComponent
      },
      {
        path: 'communications',
        component: CommunicationsComponent
      }
    ]
  },
  {
    path: 'coupons',
    data: { breadcrumb: 'Gift Cards & Promo Codes' },
    component: CouponsComponent,
    children: [
      { path: '', redirectTo: 'gift-cards', pathMatch: 'full' },
      {
        path: 'gift-cards',
        children: [
          {
            path: '',
            component: GiftCardListComponent
          },
          {
            path: 'create',
            component: GiftCardEditorComponent
          },
          {
            path: ':giftCardId/edit',
            component: GiftCardEditorComponent
          }
        ]
      },

      {
        path: 'promo-codes',
        children: [
          {
            path: '',
            component: PromoCodeListComponent
          },
          {
            path: 'create',
            component: PromoCodeEditorComponent
          },
          {
            path: ':promoCodeId/edit',
            component: PromoCodeEditorComponent
          }
        ]
      }
    ]
  },
  {
    path: 'coupons/gift-cards/email-templates',
    component: GiftCardEmailTemplateEditorComponent
  },
  {
    path: 'coupons/gift-cards/:giftCardId',
    component: GiftCardDetailsComponent
  }
];
