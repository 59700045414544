import { OrderStatus } from '../models/OrderStatus';

export const statusOptions = [
  {
    label: 'New Order',
    value: OrderStatus.NEW_ORDER
  },
  {
    label: 'In Progress',
    value: OrderStatus.IN_PROGRESS
  },
  {
    label: 'Shipped',
    value: OrderStatus.SHIPPED
  },
  {
    label: 'Partially Shipped',
    value: OrderStatus.PARTIALLY_SHIPPED
  },
  {
    label: 'Canceled',
    value: OrderStatus.CANCELED
  },
  {
    label: 'On Hold',
    value: OrderStatus.ON_HOLD
  },
  {
    label: 'Not Paid',
    value: OrderStatus.NOT_PAID
  },
  {
    label: 'Refund',
    value: OrderStatus.REFUND
  }
];
