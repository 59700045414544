<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4>Storefronts</h4>
      <p>{{ tabContents[activeTabIndex] }}</p>
      <p-tabView orientation="left" (onChange)="onTabChange($event)">
        <p-tabPanel header="Active" class="line-height-3 m-0">
          @if (activeTabIndex === 0) {
            <app-active-storefront-list></app-active-storefront-list>
          }
        </p-tabPanel>
        <p-tabPanel header="Being deleted" class="line-height-3 m-0">
          @if (activeTabIndex === 1) {
            <app-being-deleted-storefront-list></app-being-deleted-storefront-list>
          }
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
