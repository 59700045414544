import { Component } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';

import { ActiveStorefrontListComponent } from './active-storefront-list/active-storefront-list.component';
import { BeingDeletedStorefrontListComponent } from './being-deleted-storefront-list/being-deleted-storefront-list.component';

@Component({
  selector: 'app-storefront-list',
  standalone: true,
  imports: [
    TabMenuModule,
    PanelModule,
    TabViewModule,
    ActiveStorefrontListComponent,
    BeingDeletedStorefrontListComponent
  ],
  providers: [ConfirmationService],
  templateUrl: './storefront-list.component.html'
})
export class StorefrontListComponent {
  activeTabIndex: number = 0;
  tabContents: string[] = [
    'This is a list of active storefronts.',
    'This is a list of storefronts which will be deleted in few days. Reactivate some if you want.'
  ];

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
  }
}
