<div class="layout-sidebar">
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="app-logo" [style]="{ fontSize: '14px', color: '#ffffff' }">
      SingleServeMerch &trade;
    </a>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-nav />
  </div>
</div>
