<div class="flex flex-column">
  <div class="flex gap-2">
    <p class="text-900 font-bold">Email:</p>
    <p>{{ filePrepRequest.email }}</p>
  </div>
  <div class="flex gap-2">
    <p class="text-900 font-bold">Total:</p>
    <p>{{ filePrepRequest.value | usdPipe }}</p>
  </div>
  <div class="flex gap-2">
    <p class="text-900 font-bold">Additional Information:</p>
    <p>{{ filePrepRequest.additional_info }}</p>
  </div>
  <div class="flex gap-2">
    <p class="text-900 font-bold">Additional Message:</p>
    <p>{{ filePrepRequest.additional_message }}</p>
  </div>
  <div class="flex gap-2 justify-content-center">
    <img
      [src]="filePrepRequest.library?.mockup_file?.full_path"
      [alt]="filePrepRequest.library?.name"
      style="object-fit: contain"
      [ngStyle]="{ maxHeight: '120px' }"
      class="shadow-4"
      priority
    />
  </div>
  <div class="flex gap-2">
    <p class="text-900 font-bold">Status:</p>
    <p>{{ filePrepRequest.paid ? 'Paid' : 'Not Paid' }}</p>
  </div>
</div>
