export interface UserRole {
  id: number;
  name: RoleName;
  company_id?: number;
}

export enum RoleName {
  SuperAdmin = 'super-admin',
  CompanyAdmin = 'company-admin',
  CompanyUser = 'company-user'
}
