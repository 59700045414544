export enum ComponentEnum {
  TextArea = 'TEXT_AREA',
  TextWithImage = 'TEXT_WITH_IMAGE',
  BannerImage = 'BANNER_IMAGE'
}

export interface Section {
  id: string;
  component: ComponentEnum;
  content: any;
  label: string;
}

export interface Page {
  title: string;
  url: string;
  text: string;
  id: string;
  sections: Section[];
}
