<div class="flex justify-content-between">
  <div class="mb-3">
    <small>
      <strong>Supplier Color:</strong>
      {{ variant().supplier_product_variant?.color }}
    </small>
    @if (!editMode) {
      <div>
        <strong>Color:</strong>
        {{ variant().color }}
        <p-button icon="pi pi-pencil" link size="small" styleClass="p-0" (click)="editMode = true" />
      </div>
    }
  </div>
  <div>
    <p-button icon="pi pi-trash" severity="danger" outlined (click)="openDeleteVariantModal($event)" />
  </div>
</div>

@if (editMode) {
  <div class="mt-2 mb-3">
    <p-inputGroup>
      <p-button type="button" icon="pi pi-check" styleClass="border-noround-right" (onClick)="editMode = false" />

      <input
        [id]="'Color' + variantIndex"
        class="m-0"
        [(ngModel)]="variant().color"
        type="text"
        pInputText
        placeholder="Color"
      />

      <p-button type="button" label="Add to dictinary" styleClass="border-noround-left" (onClick)="addToDictionary()" />
    </p-inputGroup>
  </div>
}

<div class="flex justify-content-between gap-5 flex-wrap">
  <div class="flex flex-column align-items-center">
    @if (imageUrls()?.frontImage) {
      <img [ngSrc]="imageUrls().frontImage" alt="Front Image" style="object-fit: contain" width="100" height="125" />
    } @else {
      <div class="noMediaContainer">
        <p>Front Image</p>
      </div>
    }
    <div class="flex justify-content-between w-full mt-2">
      <p-button icon="pi pi-pencil" outlined (click)="fileInputFront.click()"></p-button>
      <p-button icon="pi pi-trash" outlined severity="danger" (click)="openDeleteMediaModal('front')"></p-button>
    </div>
    <input
      #fileInputFront
      type="file"
      (change)="onFileChange($event, 'front')"
      accept="image/*"
      style="display: none"
    />
  </div>
  <div class="flex flex-column align-items-center">
    @if (imageUrls().rearImage) {
      <img [ngSrc]="imageUrls().rearImage" alt="Rear Image" style="object-fit: contain" width="100" height="125" />
    } @else {
      <div class="noMediaContainer">
        <p>Rear Image</p>
      </div>
    }
    <div class="flex justify-content-between w-full mt-2">
      <p-button icon="pi pi-pencil" outlined (click)="fileInputRear.click()"></p-button>
      <p-button icon="pi pi-trash" outlined severity="danger" (click)="openDeleteMediaModal('rear')"></p-button>
    </div>
    <input #fileInputRear type="file" (change)="onFileChange($event, 'rear')" accept="image/*" style="display: none" />
  </div>
  <div class="flex flex-column align-items-center">
    @if (imageUrls().sideImage) {
      <img [ngSrc]="imageUrls().sideImage" alt="Side Image" style="object-fit: contain" width="100" height="125" />
    } @else {
      <div class="noMediaContainer">
        <p>Side Image</p>
      </div>
    }
    <div class="flex justify-content-between w-full mt-2">
      <p-button icon="pi pi-pencil" outlined (click)="fileInputSide.click()"></p-button>
      <p-button icon="pi pi-trash" outlined severity="danger" (click)="openDeleteMediaModal('side')"></p-button>
    </div>
    <input #fileInputSide type="file" (change)="onFileChange($event, 'side')" accept="image/*" style="display: none" />
  </div>
</div>
<div class="mt-3">
  <p-button icon="pi pi-cog" label="Edit sizes" outlined (click)="handleEditSizes()"></p-button>
</div>
