<form [formGroup]="form">
  <div class="my-3" formArrayName="recipients">
    <p-table #dt2 [value]="recipients.controls" [globalFilterFields]="['value.name']">
      <ng-template pTemplate="caption">
        <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
          <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="onGlobalFilter(dt2, $event)"
              placeholder="Global Search"
              class="w-full"
            />
          </span>

          <div class="flex gap-2">
            <p-button label="Download CSV template" icon="pi pi-download" outlined (onClick)="downloadCSVTemplate()" />
            <p-button label="Upload via CSV" icon="pi pi-upload" outlined (onClick)="fileInput.click()" />
            <p-button label="Add Recepient" icon="pi pi-plus" (onClick)="addRecipient()" />
            <input type="file" #fileInput accept=".csv" style="display: none" (change)="onFileSelected($event)" />
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        @if (recipients.length > 0) {
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Value</th>
            <th class="text-center">
              <p-checkbox
                [(ngModel)]="notifyAll"
                [binary]="true"
                [ngModelOptions]="{ standalone: true }"
                inputId="notifyAll"
                (ngModelChange)="notifyAllChange()"
              />
              <label class="ml-2" for="notifyAll">Send email</label>
            </th>
            <th class="text-center">
              <p-button icon="pi pi-trash" label="Remove All" outlined (onClick)="removeAllRecipients()" />
            </th>
          </tr>
        }
      </ng-template>

      <ng-template pTemplate="body" let-row let-i="rowIndex">
        <tr [formGroupName]="i">
          <td>
            <input class="w-full" formControlName="name" pInputText placeholder="Name" />
            @if (row.get('name')?.invalid && (row.get('name')?.dirty || row.get('name')?.touched)) {
              @if (row.get('name')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Name is required</small>
                </div>
              }
            }
          </td>
          <td>
            <input class="my-1 w-full" formControlName="email" pInputText placeholder="Email" />
            @if (row.get('email')?.invalid && (row.get('email')?.dirty || row.get('email')?.touched)) {
              @if (row.get('email')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Email is required</small>
                </div>
              }
              @if (form.get('email')?.errors?.['email']) {
                <div>
                  <small class="p-text-danger">Email is not valied</small>
                </div>
              }
            }
          </td>
          <td>
            <p-inputGroup>
              <p-inputGroupAddon>$</p-inputGroupAddon>
              <input class="w-full" formControlName="amount" pInputText placeholder="Amount" />
              <p-inputGroupAddon>.00</p-inputGroupAddon>
            </p-inputGroup>
            @if (row.get('amount')?.invalid && (row.get('amount')?.dirty || row.get('amount')?.touched)) {
              @if (row.get('amount')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Amount is required</small>
                </div>
              }
            }
          </td>
          <td class="text-center">
            <p-checkbox formControlName="notify" [binary]="true" value="Notify" (onChange)="notifyChange()" />
          </td>
          <td class="text-center">
            <p-button icon="pi pi-trash" severity="danger" (onClick)="removeRow(i)" />
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">
            <div class="flex justify-content-center align-items-center p-5">
              There are no recipients added yet, please start by clicking button or importing via CSV
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="flex justify-content-end gap-2 mt-4">
    <p-button label="Cancel" (onClick)="closeModal()" />
    <p-button label="Save" (onClick)="submitModal()" />
  </div>
</form>
