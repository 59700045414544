<p-table
  #dt
  [value]="isLoading || isLoadingDelete ? [] : storeFronts"
  [lazy]="true"
  (onLazyLoad)="loadStorefronts($event)"
  dataKey="id"
  [paginator]="true"
  [rows]="10"
  [rowHover]="true"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [loading]="isLoading || isLoadingDelete"
  [showLoader]="false"
  responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [globalFilterFields]="['name']"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
      <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Global Search" class="w-full" />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" class="white-space-nowrap" style="width: 27.25%">
        Name
        <p-sortIcon field="name" />
      </th>
      <th pSortableColumn="url" class="white-space-nowrap" style="width: 27.25%">
        Url
        <p-sortIcon field="url" />
      </th>
      <th pSortableColumn="status" class="white-space-nowrap" style="width: 15%">
        Status
        <p-sortIcon field="status" />
      </th>
      <th pSortableColumn="created_at" class="white-space-nowrap" style="width: 15%">
        Created
        <p-sortIcon field="created_at" />
      </th>
      <th class="white-space-nowrap" style="width: 15%">Actions</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-storefront>
    <tr>
      <td>{{ storefront.name }}</td>
      <td>{{ storefront.url }}</td>
      <td>{{ storefront.status | titlecase }}</td>
      <td>{{ storefront.created_at | date: 'dd/MM/yyyy' }}</td>
      <td>
        <div class="flex gap-2">
          <p-button
            [routerLink]="['/companies', storefront.company_id, 'storefronts', storefront.id]"
            icon="pi pi-eye"
            severity="secondary"
            outlined
          ></p-button>
          <p-button icon="pi pi-pen-to-square" outlined (click)="editStorefront($event, storefront)" />
          <p-button icon="pi pi-trash" (click)="openDeleteModal($event, storefront.id)" severity="danger" outlined />
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="text-center">There are no storefronts</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    @for (row of loadingRows; track row) {
      <tr>
        @for (col of ['name', 'url', 'status', 'created_at', 'actions']; track col) {
          <td>
            <p-skeleton />
          </td>
        }
      </tr>
    }
  </ng-template>
</p-table>
<app-edit-storefront-dialog
  [storefront]="storefrontForEdit"
  [visible]="editDialog"
  (cancelEdit)="hideDialog()"
  (save)="loadStorefronts()"
></app-edit-storefront-dialog>
<p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
