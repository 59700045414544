import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { Supplier } from 'app/_shared/interfaces/supplier';
import { SupplierService } from 'app/_shared/services/supplier.service';

export const suppliersColorsResolver: ResolveFn<IApiResponse<Supplier[]> | boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(SupplierService).getSupplierColors();
};
