import { CommonModule, JsonPipe, NgStyle } from '@angular/common';
import { Component, computed, input, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule, JsonPipe, NgStyle, CommonModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent implements OnInit {
  @Input() control: FormControl | AbstractControl;
  type = input<string>('text');
  fullWidth = input<boolean>(false);
  label = input<string>('');
  displayLabel = input<boolean>(true);
  id = input<string>('');
  appendSymbol = input<string>('');
  placeholder = input<string>();
  name = input<string>('This field');
  readonly = input<boolean>(false);
  errorMessage = computed(() => `${this.label()} is required!`);
  emailMessage = computed(() => {
    if (this.type() === 'email') {
      return 'Must be a valid email address';
    }
    return '';
  });
  inputControl: FormControl;

  ngOnInit() {
    this.inputControl = this.control as FormControl;
  }
}
