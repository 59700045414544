<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end">
    <div class="col-12">
      <div class="field">
        <label for="name">Category Name</label>
        <input
          type="text"
          pInputText
          class="w-full"
          formControlName="name"
          id="name"
          [ngClass]="{
            'ng-dirty': form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)
          }"
        />
        @if ((submitted || form.get('name')?.touched) && form.get('name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Name is required</small>
          </div>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="field">
        <label for="name">Category Slug</label>
        <input
          type="text"
          pInputText
          class="w-full"
          formControlName="slug"
          id="name"
          [ngClass]="{
            'ng-dirty': form.get('slug')?.invalid && (form.get('slug')?.dirty || form.get('slug')?.touched)
          }"
        />
        @if ((submitted || form.get('slug')?.touched) && form.get('slug')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Slug is required</small>
          </div>
        }
      </div>
    </div>

    <div class="col-auto">
      <div class="flex gap-2 justify-content-end">
        <p-button
          icon="pi pi-times"
          label="Cancel"
          type="button"
          outlined
          (onClick)="closeDialog()"
          [disabled]="isLoading"
        />
        <p-button icon="pi pi-check" label="Save" type="submit" [disabled]="isLoading" />
      </div>
    </div>
  </div>
</form>
