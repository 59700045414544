import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static passwordPattern(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /[0-9]/.test(value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const validLength = value.length >= 8;

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar && validLength;

      return !passwordValid ? { passwordPattern: true } : null;
    };
  }

  static matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control.parent && control.parent.get(matchTo)?.value === control.value ? null : { isMatching: true };
  }

  static validateSubdomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const subdomainPattern = /^[a-zA-Z0-9-]+$/;
      const isValid = subdomainPattern.test(value);

      return !isValid ? { invalidSubdomain: true } : null;
    };
  }

  static passwordsMatch(registrationFrom: FormGroup): ValidationErrors | null {
    const password = registrationFrom.get('password')?.value;
    const passwordConfirmation = registrationFrom.get('password_confirmation')?.value;

    return password === passwordConfirmation ? null : { passwordsNotMatched: true };
  }

  static dateAfterGiven(givenDateControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent as FormGroup;
      if (!formGroup) return null;

      const givenDateControl = formGroup.get(givenDateControlName);
      if (!givenDateControl?.value) return null;

      const givenDate = new Date(givenDateControl.value);
      const controlDate = new Date(control.value);

      return controlDate > givenDate ? null : { dateAfterGiven: true };
    };
  }
}
