<p-card>
  <h2>Add new color</h2>
  <p>Select color</p>
  <compact-picker [(color)]="colorValue" [control]="colorControl"></compact-picker>
  <form [formGroup]="newColorForm" (ngSubmit)="handleSubmit()">
    <div class="mt-3 mb-3" [ngStyle]="{ maxWidth: '300px' }">
      <app-input name="name" [control]="name" placeholder="Color name"></app-input>
    </div>
    <button [disabled]="!newColorForm.valid" pButton icon="pi pi-check" type="submit" label="Ok"></button>
  </form>
</p-card>
