import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';
import { finalize, Subscription } from 'rxjs';

import { UserTableListComponent } from '../../_shared/components/user-table-list/user-table-list.component';
import { FormGroupValue } from '../../_shared/models/FormGroup';
import { User } from '../../_shared/models/User';
import { ToastService } from '../../_shared/services/toast.service';
import { UserService } from '../../_shared/services/user.service';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [UserTableListComponent],
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit, OnDestroy {
  userService = inject(UserService);
  toastService = inject(ToastService);

  users: User[] = [];
  totalRecords: number = 0;
  isLoading = false;
  isLoadingEdit = false;
  isLoadingDelete = false;

  private subscriptions: Subscription[] = [];
  editUserDialog = false;

  ngOnInit() {
    this.isLoading = true;
  }

  loadUsers($event?: TableLazyLoadEvent) {
    this.isLoading = true;
    this.userService
      .index($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.users = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  deleteCompanyUser(userId: number) {
    this.isLoadingDelete = true;
    this.subscriptions.push(
      this.userService
        .deleteUser(userId)
        .pipe(
          finalize(() => {
            this.isLoadingDelete = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastService.success('User successfully deleted!');
            this.loadUsers();
          },
          error: err => {
            console.error('Delete action failed:', err);
          }
        })
    );
  }

  editCompanyUser(editData: { formData: FormGroupValue; id: number }) {
    this.isLoadingEdit = true;
    this.subscriptions.push(
      this.userService
        .updateUser(editData.id, editData.formData)
        .pipe(
          finalize(() => {
            this.isLoadingEdit = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastService.success('Successfully updated user!');
            this.loadUsers();
          },
          error: err => {
            console.error('Update failed:', err);
          }
        })
    );
  }

  hideDialog() {
    this.editUserDialog = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
