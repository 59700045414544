<p-table [value]="paymentMethods" [loading]="loading">
  <ng-template pTemplate="header">
    <tr>
      <th>Payment type</th>
      <th>Brand/Bank Name</th>
      <th>Number</th>
      <th>Expiration Date</th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-paymentMethod>
    <tr>
      <td>{{ paymentMethod.bank ? 'Bank Account' : 'Card' }}</td>
      <td>
        {{ paymentMethod.bank ? paymentMethod.bank.bank_name : (paymentMethod.card.display_brand | titlecase) }}
      </td>
      <td>
        {{ paymentMethod.bank ? (paymentMethod.bank.last4 | cardMask) : (paymentMethod.card.last4 | cardMask) }}
      </td>
      <td>
        {{
          paymentMethod.card
            ? (paymentMethod.card.expiring_month < 10 ? '0' : '') +
              paymentMethod.card.expiring_month +
              '/' +
              paymentMethod.card.expiring_year
            : '/'
        }}
      </td>
      <td>
        @if (!paymentMethod?.is_default) {
          <div class="flex gap-2">
            <button
              pButton
              type="button"
              label="Set Default"
              icon="pi pi-check"
              severity="success"
              (click)="openDefaultMethodModal($event, paymentMethod.id)"
            ></button>
            <button
              pButton
              type="button"
              label="Delete"
              icon="pi pi-trash"
              severity="danger"
              (click)="openDeleteModal($event, paymentMethod.id)"
            ></button>
          </div>
        } @else {
          <div>Not Allowed</div>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td>There are no payment methods</td>
    </tr>
  </ng-template>
</p-table>
<div class="pt-3">
  <p-button [disabled]="loading" label="Add New Method" icon="pi pi-plus" (click)="openAddMethodModal()"></p-button>
</div>

<p-confirmDialog
  header="Confirmation"
  key="confirmDefault"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
