import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';

import { PromoCode } from 'app/_shared/models/Coupon';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { PromoCodeService } from 'app/_shared/services/promo-code.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-promo-code-list',
  standalone: true,
  imports: [ButtonModule, ConfirmDialogModule, TableModule, SplitButtonModule, DisplayDatePipe, UsdPipe],
  providers: [ConfirmationService],
  templateUrl: './promo-code-list.component.html',
  styleUrl: './promo-code-list.component.scss'
})
export class PromoCodeListComponent {
  private promoCodeService: PromoCodeService = inject(PromoCodeService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  private toastService: ToastService = inject(ToastService);

  totalRecords: number;
  promoCodes: PromoCode[];
  isLoading = false;
  actionButtonItems: MenuItem[] = [
    {
      label: 'Promo Codes Accounting',
      icon: 'pi pi-file-excel',
      command: () => {
        console.log('download');
      }
    }
  ];

  promoCodeItemActionsModel: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: event => {
        const promoCode = event?.item?.promoCode;
        this.navigateToEditDisount(promoCode.id);
      }
    },
    { separator: true },
    {
      label: 'Delete',
      styleClass: 'p-text-danger',
      icon: 'pi pi-trash',
      command: event => {
        const promoCode = event?.item?.promoCode;
        this.openDeleteModal(promoCode.id);
      }
    }
  ];

  loadPromoCodes($event?: any) {
    this.promoCodeService.paginatedIndex($event).subscribe({
      next: res => {
        this.promoCodes = res.data.data;
        this.totalRecords = res.data.total;
      },
      error: err => console.error(err),
      complete: () => (this.isLoading = false)
    });
  }

  onDropdownClick($event: Event, promoCode: PromoCode) {
    this.promoCodeItemActionsModel.forEach(menuItem => (menuItem.promoCode = promoCode));
  }

  navigateToEditDisount(promoCodeId: number) {
    this.router.navigate([promoCodeId, 'edit'], {
      relativeTo: this.activatedRoute
    });
  }

  openDeleteModal(promoCodeId: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message:
        'Are you sure that you want to delete this promo code?</br></br><strong>This operation cannot be undone!</strong>',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePromoCode(promoCodeId);
      }
    });
  }

  deletePromoCode(promoCodeId: number) {
    this.promoCodeService.remove(promoCodeId).subscribe({
      next: () => {
        this.toastService.success('Promo code successfully deleted!');
        this.loadPromoCodes();
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }
}
