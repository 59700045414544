import { CommonModule } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { finalize, Subscription } from 'rxjs';

// eslint-disable-next-line max-len
import { ContactEmailDialogComponent } from 'app/_shared/components/contact-email-dialog/contact-email-dialog.component';
import { CustomMail } from 'app/_shared/models/CustomMail';
import { FormGroupValue } from 'app/_shared/models/FormGroup';
import { OrderConfirmationData } from 'app/_shared/models/OrderConfirmationData';
import { StoreCommunication } from 'app/_shared/models/StoreCommunication';
import { StoreInvitationData } from 'app/_shared/models/StoreInvitationData';
import { Storefront } from 'app/_shared/models/Storefront';
import { TrackingConfirmationData } from 'app/_shared/models/TrackingConfirmationData';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { StorefrontStore } from 'app/_store/storefront.store';

@Component({
  selector: 'app-communications',
  standalone: true,
  imports: [
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    CommonModule,
    InputSwitchModule,
    PanelModule,
    FormsModule,
    DropdownModule
  ],
  providers: [DialogService],
  templateUrl: './communications.component.html'
})
export class CommunicationsComponent {
  fb = inject(FormBuilder);
  storefrontStore = inject(StorefrontStore);
  storefrontService = inject(StorefrontService);
  dialogRef: DynamicDialogRef | undefined;
  dialogService = inject(DialogService);
  toastService = inject(ToastService);

  selectedStorefront?: Storefront | null = undefined;

  communicationSettings: StoreCommunication;
  private subscriptions: Subscription[] = [];

  contactEmails: { id?: number; email: string }[] = [];
  smtpEnabled = false;
  public form: FormGroup;
  formReady = false;
  submitted = false;
  isLoading = false;

  private storefrontEffect = effect(() => {
    this.selectedStorefront = this.storefrontStore?.selectedStorefront?.();
    if (this.selectedStorefront) {
      this.fetchCommunicationSettings(this.selectedStorefront?.id);
    }
  });

  fetchCommunicationSettings(storefrontId: any) {
    this.subscriptions.push(
      this.storefrontService.getStoreCommunicationData(storefrontId).subscribe({
        next: response => {
          this.communicationSettings = response.data;
          this.contactEmails = this.communicationSettings.contact_emails;
          this.buildForm();
        },
        error: error => {
          console.error(error);
        }
      })
    );
  }

  buildForm() {
    const customMailsArray = this.communicationSettings.communication_custom_mails.map(email => this.handleFormEmailItem(email));

    this.form = this.fb.group({
      custom_mails: this.fb.array(customMailsArray),
      customSmtp: [!!this.communicationSettings?.smtp_settings],
      smtp_settings: this.fb.group({
        host: [
          this.communicationSettings.smtp_settings?.host,
          this.communicationSettings.smtp_settings ? [Validators.required] : []
        ],
        port: [
          this.communicationSettings.smtp_settings?.port,
          this.communicationSettings.smtp_settings ? [Validators.required] : []
        ],
        username: [
          this.communicationSettings.smtp_settings?.username,
          this.communicationSettings.smtp_settings ? [Validators.required] : []
        ],
        password: [
          this.communicationSettings.smtp_settings?.password,
          this.communicationSettings.smtp_settings ? [Validators.required] : []
        ],
        encryption: [
          this.communicationSettings.smtp_settings?.encryption,
          this.communicationSettings.smtp_settings ? [Validators.required] : []
        ]
      })
    });
    this.updateSmtpValidators();
    this.formReady = true;
  }

  updateSmtpValidators() {
    this.form.get('customSmtp')?.valueChanges.subscribe(newValue => {
      const smtpGroup = this.form.get('smtp_settings') as FormGroup;
      if (smtpGroup) {
        if (newValue) {
          smtpGroup?.get('host')?.setValidators([Validators.required]);
          smtpGroup?.get('port')?.setValidators([Validators.required]);
          smtpGroup?.get('username')?.setValidators([Validators.required]);
          smtpGroup?.get('password')?.setValidators([Validators.required]);
          smtpGroup?.get('encryption')?.setValidators([Validators.required]);
        } else {
          smtpGroup?.get('host')?.clearValidators();
          smtpGroup?.get('port')?.clearValidators();
          smtpGroup?.get('username')?.clearValidators();
          smtpGroup?.get('password')?.clearValidators();
          smtpGroup?.get('encryption')?.clearValidators();
          smtpGroup?.reset();
          this.form.patchValue({ smtp_settings: null });
        }
        smtpGroup?.get('host')?.updateValueAndValidity();
        smtpGroup?.get('port')?.updateValueAndValidity();
        smtpGroup?.get('username')?.updateValueAndValidity();
        smtpGroup?.get('password')?.updateValueAndValidity();
        smtpGroup?.get('encryption')?.updateValueAndValidity();
      }
    });
  }

  handleFormEmailItem(email: CustomMail) {
    let formGroup: FormGroup;

    switch (email.type) {
      case 'tracking_confirmation':
        formGroup = this.buildTrackingMailForm(email);
        break;
      case 'order_confirmation':
        formGroup = this.buildOrderConfirmationMailForm(email);
        break;
      default:
        formGroup = this.buildStoreInvitationMailForm(email);
        break;
    }

    return this.fb.group({
      id: [email.id],
      type: [email.type],
      data: formGroup
    });
  }

  buildTrackingMailForm(mail: CustomMail) {
    const trackingMailData = mail.data as TrackingConfirmationData;
    return this.fb.group({
      subject: [trackingMailData.subject, [Validators.required]],
      greeting: [trackingMailData.greeting, [Validators.required]],
      tracking_details_1: [trackingMailData.tracking_details_1, [Validators.required]],
      tracking_details_2: [trackingMailData.tracking_details_2, [Validators.required]],
      tracking_details_3: [trackingMailData.tracking_details_3, [Validators.required]],
      track_info: [trackingMailData.track_info, [Validators.required]],
      end_info: [trackingMailData.end_info, [Validators.required]],
      goodbye_message: [trackingMailData.goodbye_message, [Validators.required]]
    });
  }

  buildOrderConfirmationMailForm(mail: CustomMail) {
    const orderConfirmationData = mail.data as OrderConfirmationData;
    return this.fb.group({
      subject: [orderConfirmationData.subject, [Validators.required]],
      thanks_for_shopping: [orderConfirmationData.thanks_for_shopping, [Validators.required]],
      order_reference: [orderConfirmationData.order_reference, [Validators.required]],
      custom_made: [orderConfirmationData.custom_made, [Validators.required]],
      note: [orderConfirmationData.note, [Validators.required]],
      edit_address: [orderConfirmationData.edit_address, [Validators.required]],
      multiple_shipments: [orderConfirmationData.multiple_shipments, [Validators.required]],
      goodbye_message: [orderConfirmationData.goodbye_message, [Validators.required]]
    });
  }

  buildStoreInvitationMailForm(mail: CustomMail) {
    const storeInvitationMailData = mail.data as StoreInvitationData;
    return this.fb.group({
      subject: [storeInvitationMailData.subject, [Validators.required]],
      welcome_text: [storeInvitationMailData.welcome_text, [Validators.required]],
      first_sentence: [storeInvitationMailData.first_sentence, [Validators.required]],
      second_sentence: [storeInvitationMailData.second_sentence, [Validators.required]],
      button_text: [storeInvitationMailData.button_text, [Validators.required]],
      goodbye_message: [storeInvitationMailData.goodbye_message, [Validators.required]]
    });
  }

  get customEmails(): FormArray<FormGroup> {
    return this.form.get('custom_mails') as FormArray;
  }

  openStoreContactEmailDialog(contactEmail?: { id?: number; email: string }) {
    const existingEmailIndex = this.contactEmails.findIndex(
      oldContactEmail => oldContactEmail.email === contactEmail?.email
    );
    const editMode = existingEmailIndex !== -1;
    this.dialogRef = this.dialogService.open(ContactEmailDialogComponent, {
      header: editMode ? 'Edit contact' : 'Create contact',
      width: '500px',
      data: {
        contactEmail,
        editMode
      }
    });

    this.dialogRef.onClose.subscribe((res: { id?: number; email: string }) => {
      if (res) {
        if (existingEmailIndex !== -1) {
          this.contactEmails[existingEmailIndex] = res;
        } else {
          this.contactEmails.push(res);
        }
      }
    });
  }

  testSmtp() {
    const smtpGroup = this.form.get('smtp_settings') as FormGroup;
    if (smtpGroup && smtpGroup.valid) {
      const smtpSettings = smtpGroup.value;
      this.isLoading = true;
      this.storefrontService
        .testSmtp(smtpSettings, this.selectedStorefront?.id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: response => {
            this.toastService.success('SMTP settings are valid.');
            smtpGroup.addControl('test', this.fb.control(response.data.test));
          },
          error: error => {
            console.error('SMTP test failed:', error);
          }
        });
    }
  }

  onSubmit() {
    console.log(this.form.value)
    if (this.form.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    if (this.form.get('customSmtp')?.value) {
      if (!this.form.get('smtp_settings.test')?.value) {
        this.toastService.error('Please test smtp settings. Settings need to be valid');
        return;
      }
    }

    const formattedData = this.prepareSubmitData();
    this.updateCommunications(formattedData);
  }

  prepareSubmitData() {
    const contactEmails = this.contactEmails.map(({ id, ...email }) => {
      return id === null ? email : { id, ...email };
    });

    const formValues = {
      ...this.form.value,
      contact_emails: contactEmails,
      custom_mails: this.prepareCustomMails(this.form.get('custom_mails')?.value),
      smtp_settings: this.form.get('customSmtp')?.value ? this.form.value.smtp_settings : []
    };
    delete formValues.customSmtp;

    return formValues;
  }

  prepareCustomMails(mails: CustomMail[]) {
    const result: any = {};

    mails.forEach(({ type, data: mailData }) => {
      result[type] = { data: { ...mailData } };
    });

    return result;
  }

  updateCommunications(formValues: FormGroupValue) {
    this.isLoading = true;

    this.subscriptions.push(
      this.storefrontService
        .updateStoreCommunicationData(formValues, this.selectedStorefront?.id)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastService.success('Successfully updated store communications info!');
            this.fetchCommunicationSettings(this.selectedStorefront?.id);
          },
          error: err => {
            console.error('Update failed:', err);
          }
        })
    );
  }
}
