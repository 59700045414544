<div class="navbar">
  <div class="inner-container">
    <div class="nav flex justify-content-between align-items-center">
      <div class="logo">
        @if (logo()) {
          <img class="logo-img cursor-pointer" [src]="logo()" alt="logo" />
        }
      </div>
    </div>
  </div>
</div>
<div class="selection mt-5">
  <a [routerLink]="['../']" [queryParams]="{ token: token }">Back</a>
  <div class="flex justify-content-between align-items-center mb-3 mt-3">
    <h3 class="m-0"><b>Your selection</b></h3>
    <p-button label="Save selection" (click)="saveSelection()"></p-button>
  </div>
  <p>
    Adjust color variants if needed and confirm your choices. We’ll be in touch once your products are added to your
    store. You can use this link to update your selections anytime
  </p>
  <div class="grid">
    @if (products) {
      @for (product of products; track product.id) {
        <div class="col-12 sm:col-6">
          <div class="product-item">
            <div class="image">
              <img [src]="product.featured_image?.full_path" alt="product-image" />
            </div>
            <div class="info">
              <div class="name">
                <p class="m-0">
                  <b>{{ product.name }}</b>
                </p>
                <p-button
                  label="Remove"
                  severity="danger"
                  (onClick)="removeProduct(product)"
                  [outlined]="true"
                ></p-button>
              </div>
              <div class="color-container">
                @for (color of getVisibleColors(product); track color.id) {
                  <div class="color-circle" [style.background-color]="color.hex" (click)="selectColor(product, color)">
                    @if (color.selected) {
                      <i class="pi pi-check"></i>
                    }
                  </div>
                }
                @if (product.colors.length > defaultVisibleLimit) {
                  <div class="more-colors">
                    @if (!product.showAll) {
                      <a href="#" (click)="showAllColors($event, product)">+ More</a>
                    } @else {
                      <a href="#" (click)="showLessColors($event, product)">- Show Less</a>
                    }
                  </div>
                }
              </div>
              <div class="selected-colors">
                <small>Selected colors: {{ getSelectedColors(product.colors).join(', ') }}</small>
              </div>
              <div class="price">
                <small>
                  <b>Starting at {{ product.starting_price | price | usdPipe }}</b>
                </small>
              </div>
              <div class="comment">
                <p-button
                  [label]="product.comment ? 'Edit comment' : 'Add comment (Optional)'"
                  (onClick)="addComment(product)"
                  [outlined]="true"
                ></p-button>
              </div>
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>
<p-dialog header="Add comment" [modal]="true" [(visible)]="commentModal" [style]="{ width: '25rem' }">
  <div class="flex align-items-center gap-3 mb-3">
    <textarea [style]="{ width: '100%' }" rows="5" cols="30" pInputTextarea [(ngModel)]="commentValue"></textarea>
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Cancel" [outlined]="true" severity="secondary" (onClick)="closeCommentModal()" />
    <p-button label="Save" (onClick)="saveComment()" />
  </div>
</p-dialog>
