<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4>Users</h4>
      <app-user-table-list
        [users]="isLoading || isLoadingEdit || isLoadingDelete ? [] : users"
        [totalRecords]="totalRecords"
        [loading]="isLoading || isLoadingEdit || isLoadingDelete"
        [rows]="10"
        [globalFilterFields]="['first_name', 'last_name', 'email', 'phone']"
        [companyUsers]="false"
        (lazyLoadUsers)="loadUsers($event)"
        (editUserEmitter)="editCompanyUser($event)"
        (deleteUserEmmiter)="deleteCompanyUser($event)"
      ></app-user-table-list>
    </div>
  </div>
</div>
