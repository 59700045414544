export class CSVHelper {
  // Method to convert CSV to JSON
  static csvToJson<T>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = event => {
        const csv = event.target?.result as string;
        const lines = csv.split('\n');
        const headers = lines[0].split(',');

        const jsonData = lines.slice(1).map(line => {
          const values = line.split(',');
          const obj: { [key: string]: string } = {};
          headers.forEach((header, index) => {
            obj[header.trim()] = values[index]?.trim();
          });
          return obj;
        });

        resolve(jsonData as T[]);
      };

      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }

  // Method to convert JSON data to CSV format
  static jsonToCsv(data: object[]): string {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(','));
    return [headers, ...rows].join('\n');
  }

  // Method to download CSV file
  static downloadFile(data: string): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    a.click();

    window.URL.revokeObjectURL(url); // Clean up the URL object after download
  }
}
