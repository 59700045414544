import { JsonPipe } from '@angular/common';
import { Component, effect, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LibraryFileUploaderComponent } from 'app/_shared/components/media-library/library-file-uploader/library-file-uploader.component';
import { MediaLibraryComponent } from 'app/_shared/components/media-library/media-library.component';
import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { User } from 'app/_shared/models/User';
import { RoleName } from 'app/_shared/models/UserRole';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';

@Component({
  selector: 'app-libraries-list',
  standalone: true,
  imports: [MediaLibraryComponent, LibraryFileUploaderComponent, JsonPipe],
  templateUrl: './library-editor.component.html',
  styleUrl: './library-editor.component.scss'
})
export class LibraryEditorComponent implements OnInit {
  private libraryService = inject(LibraryService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  toastService = inject(ToastService);
  authStore = inject(AuthStore);

  storefrontLibraryFolders: LibraryFolder[] = [];
  storefrontId: string;
  libraryFolderId: string;
  libraryId: string;
  library: LibraryFile;
  account: User | null = null;
  UserRoleNames = RoleName;

  ngOnInit() {
    this.activatedRoute.params.subscribe(next => {
      this.storefrontId = next.storefrontId;
      this.libraryFolderId = next.libraryFolderId;
      this.libraryId = next.libraryId;
    });
    this.fetchStorefrontLibraryFolders(this.storefrontId);
    this.getLibrary(this.storefrontId, this.libraryId);
  }

  private authUserEff = effect(() => {
    this.account = this.authStore.user();
  });

  getLibrary(storefrontId: string, libraryId: string) {
    this.libraryService.getLibraryFile(storefrontId, libraryId).subscribe(next => {
      this.library = next.data;
    });
  }

  fetchStorefrontLibraryFolders(storefrontId: string) {
    this.libraryService.getStorefrontLibraryFolders(+storefrontId).subscribe({
      next: response => {
        this.storefrontLibraryFolders = response.data;
      },
      error: error => {
        console.error(error);
      }
    });
  }

  onSubmit(formData: FormData) {
    this.libraryService.updateLibraryFile(+this.storefrontId, +this.libraryId, formData).subscribe({
      next: res => {
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      },
      error: err => {
        console.error('Creation failed:', err);
      }
    });
  }

  updateAdditionaFile(additionalFiledata: FormData) {
    this.libraryService.changeLibraryAdditionalFile(+this.storefrontId, additionalFiledata).subscribe({
      next: () => {
        this.toastService.success('Additional file updated.');
      },
      error: error => {
        console.error(error);
      }
    });
  }
}
