<p-dataView
  #dv
  [value]="storefrontSubscriptions"
  [paginator]="true"
  [rows]="9"
  filterBy="name"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  layout="list"
>
  <ng-template pTemplate="header">
    <div class="flex flex-column md:flex-row md:justify-content-between gap-2">
      <p-dropdown [options]="sortOptions" placeholder="Sort By Plan" (onChange)="onSortChange($event)"></p-dropdown>
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input type="search" pInputText placeholder="Search by Name" (input)="onFilter($event)" />
      </span>
    </div>
  </ng-template>

  <ng-template let-storefrontSubscriptions pTemplate="listItem">
    <div class="grid grid-nogutter">
      <div class="col-12" *ngFor="let subscription of storefrontSubscriptions">
        <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
          <img
            src="assets/demo/images/product/blue-t-shirt.jpg"
            alt="storefront_logo"
            class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5"
          />
          <div class="flex-1 flex flex-column align-items-center text-center md:text-left">
            <div class="font-bold text-2xl">
              {{ subscription.storefront.name }}
            </div>
            <div class="mb-2">Plan: {{ subscription.plan?.name || '/' }}</div>
            @if (subscription.status) {
              <div class="flex align-items-center mt-2">
                <i class="pi pi-tag mr-2"></i>
                <span class="font-semibold">
                  <ng-container *ngIf="subscription.status === 'grace_period'; else statusTemplate">
                    Grace period
                  </ng-container>
                  <ng-template #statusTemplate>
                    {{ capitalizeFirstLetter(subscription.status) }}
                  </ng-template>
                </span>
              </div>
            }
          </div>
          <div
            class="flex-1 flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0"
          >
            @if (subscription.plan?.price) {
              <span
                class="text-2xl font-semibold mb-2 align-self-center md:align-self-end"
              >
                ${{ subscription.plan?.price }}
              </span>
            }
            <div class="flex flex-row gap-2">
              @if (subscription.status === 'active') {
                <p-button
                  icon="pi pi-bell"
                  label="Change plan"
                  styleClass="mb-2 p-button-sm"
                  (click)="
                  openChangePlanDialog(
                    subscription
                  )
                "
                ></p-button>
              }
              @if (
                subscription.plan?.price && subscription.status === 'active'
              ) {
                <p-button
                  icon="pi pi-bell-slash"
                  label="Unsubscribe"
                  styleClass="mb-2 p-button-sm"
                  (click)="
                    openUnsubscribeDialog($event, subscription.storefront_id)
                  "
                ></p-button>
              } @else if (
                subscription.plan?.price &&
                subscription.status === 'grace_period'
              ) {
                <p-button
                  icon="pi pi-replay"
                  label="Reactivate"
                  styleClass="mb-2 p-button-sm"
                  (click)="
                    openReactivateDialog($event, subscription.storefront_id)
                  "
                ></p-button>
              } @else if(
                subscription.plan?.price &&
                subscription.status === 'downgrading'
              ) {
                <p-button
                  icon="pi pi-replay"
                  label="Cancel downgrade"
                  styleClass="mb-2 p-button-sm"
                  (click)="
                    openCancelDowngradeDialog($event, subscription.storefront_id)
                  "
                ></p-button>
              } @else {
                <p-button
                  icon="pi pi-bell"
                  label="Start subscription plan"
                  styleClass="mb-2 p-button-sm"
                  (click)="
                  openChangePlanDialog(
                    subscription
                  )"
                ></p-button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>
<p-confirmDialog
  header="Confirmation"
  key="confirmUnsubscribe"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  key="confirmReactivate"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  key="cancelDowngrade"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-success p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
