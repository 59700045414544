import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CompanyStore } from 'app/_store/company.store';

import { ToastService } from '../../../_shared/services/toast.service';

@Component({
  selector: 'app-general',
  standalone: true,
  templateUrl: './general.component.html'
})
export class GeneralComponent {
  companyStore = inject(CompanyStore);
  route = inject(ActivatedRoute);
  toastService = inject(ToastService);
}
