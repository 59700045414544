@if (formReady) {
  <form [formGroup]="storefrontForm" (ngSubmit)="onSubmit()">
    <div class="grid">
      <div class="col-12 md:col-6">
        <div class="card p-fluid">
          @if (editMode) {
            <h5 class="mb-5">Edit storefront</h5>
          } @else {
            <h5 class="mb-5">Create new storefront</h5>
          }
          <div class="field">
            <label for="name-input">Name</label>
            <input id="name-input" type="text" pInputText formControlName="name" />
            @if (
              submitted ||
              (storefrontForm.get('name')?.invalid &&
                (storefrontForm.get('name')?.dirty || storefrontForm.get('name')?.touched))
            ) {
              @if (storefrontForm.get('name')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Name is required</small>
                </div>
              }
            }
          </div>
          <div class="field">
            <label for="subdomain">Url</label>
            <p-inputGroup class="w-full">
              <p-inputGroupAddon>{{ prefix }}</p-inputGroupAddon>
              <input id="subdomain" type="text" pInputText formControlName="subdomain" />
              <p-inputGroupAddon>{{ suffix }}</p-inputGroupAddon>
            </p-inputGroup>
            @if (
              submitted ||
              (storefrontForm.get('subdomain')?.invalid &&
                (storefrontForm.get('subdomain')?.dirty || storefrontForm.get('subdomain')?.touched))
            ) {
              @if (storefrontForm.get('subdomain')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Store subdomain is required</small>
                </div>
              }
              @if (storefrontForm.get('subdomain')?.errors?.['invalidSubdomain']) {
                <div>
                  <small class="p-text-danger">Invalid subdomain format. (Allowed a-z, A-Z, 0-9, -)</small>
                </div>
              }
            }
          </div>
          <div class="mb-3 custom-upload">
            <p-fileUpload
              #fileUploader
              name="logo-uploader"
              [multiple]="false"
              (onUpload)="onUpload($event)"
              [customUpload]="true"
              (onSelect)="onUpload($event)"
              accept="image/*"
              maxFileSize="1000000"
              showUploadButton="false"
            >
              <ng-template pTemplate="content">
                <div class="h-20rem m-1 border-round">
                  @if (!this.logo?.objectURL) {
                    <div
                      class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                      (click)="fileUploader.advancedFileInput.nativeElement.click()"
                    >
                      <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                      <span class="block font-semibold text-900 text-lg mt-3">Drop or select storefront logo</span>
                    </div>
                  }
                  @if (this.logo?.objectURL) {
                    <div class="flex justify-content-center align-items-center h-full w-full">
                      <img [src]="this.logo?.objectURL" class="max-w-full max-h-full" alt="storefront logo" style="object-fit: contain" />
                      <button
                        pButton
                        type="button"
                        aria-label="remove"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                        style="top: 0; right: 0"
                        (click)="$event.stopPropagation(); removeImage()"
                      ></button>
                    </div>
                  }
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
          <div class="field w-10rem">
            <button pButton pRipple class="p-button-primary flex-1" label="Submit"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
}
