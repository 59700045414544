@if (root && item.visible !== false) {
  <div [routerLink]="item.routerLink" class="layout-menuitem-root-text cursor-pointer">
    {{ item.label }}
  </div>
}

@if ((!item.routerLink || item.items) && item.visible !== false) {
  <a
    [attr.href]="item.url"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item['class']"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root && !active)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}
@if (item.routerLink && !item.items && item.visible !== false) {
  <a
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item['class']"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="
      item.routerLinkActiveOptions || {
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    "
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment"
    [skipLocationChange]="item.skipLocationChange"
    [replaceUrl]="item.replaceUrl"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label }}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}

@if (item.items && item.visible !== false) {
  <ul #submenu [@children]="submenuAnimation" (@children.done)="onSubmenuAnimated($event)">
    @for (child of item.items; track i; let i = $index) {
      <li app-nav-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
    }
  </ul>
}
