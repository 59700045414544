import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

import { Color } from 'app/_shared/interfaces/colors';
import { Supplier } from 'app/_shared/interfaces/supplier';
import { CatalogService } from 'app/_shared/services/catalog.service';

@Component({
  selector: 'app-edit-color',
  standalone: true,
  imports: [DropdownModule, SharedModule, FormsModule, ButtonModule],
  templateUrl: './edit-color.component.html',
  styleUrl: './edit-color.component.scss'
})
export class EditColorComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private catalogService = inject(CatalogService);
  color: Color;
  selectedColorHit?: Color;
  hitColors: Color[];
  suppliers: Supplier[];

  ngOnInit() {
    const colorId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    this.activatedRoute.data.subscribe(next => {
      this.suppliers = next.suppliers.data;
      this.color = next.colors.data.find(color => color.id === +colorId);
      this.hitColors = this.suppliers.find(supplier => supplier.slug === 'hit-promo')?.supplierColors ?? [];

      const defaultHitColor = this.color.supplier_colors?.find(color => color.supplier.slug === 'hit-promo');
      this.selectedColorHit = this.hitColors.find(color => color.id === defaultHitColor?.id);
    });
  }

  saveColors() {
    this.catalogService.syncCatalogColors(this.color?.id, [this.selectedColorHit!.id]).subscribe(next => {
      this.router.navigate(['colors']);
    });
  }

  deleteColor() {
    this.catalogService.deleteColor(this.color.id).subscribe(next => {
      this.router.navigate(['colors']);
    });
  }
}
