import { NgClass } from '@angular/common';
import { Component, effect, HostListener, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { LayoutService } from 'app/_shared/services/layout.service';
import { SearchService } from 'app/_shared/services/search.service';
import { AuthStore } from 'app/_store/auth.store';

import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { NavItemComponent } from '../nav/nav-item/nav-item.component';

@Component({
  selector: 'app-superadmin-topbar',
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    MenuModule,
    BadgeModule,
    BreadcrumbComponent,
    OverlayPanelModule,
    NgClass,
    NavItemComponent,
    MenubarModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './superadmin-topbar.component.html',
  styleUrl: './superadmin-topbar.component.scss'
})
export class SuperadminTopbarComponent {
  searchService = inject(SearchService);
  authStore = inject(AuthStore);
  layoutService = inject(LayoutService);
  isSuperAdmin: boolean = false;
  searchModalVisible: boolean = false;
  loading = false;
  search = '';
  searchResults: any;
  model: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'pi pi-objects-column',
      routerLink: '/'
    },
    {
      label: 'Orders',
      icon: 'pi pi-box',
      routerLink: 'orders'
    },

    {
      label: 'Storefronts',
      icon: 'pi pi-warehouse',
      routerLink: 'storefronts'
    },

    {
      label: 'Users',
      icon: 'pi pi-users',
      items: [
        {
          icon: 'pi pi-users',
          label: 'All users',
          routerLink: 'users'
        },
        {
          label: 'Invites',
          icon: 'pi pi-address-book',
          routerLink: 'invites'
        }
      ]
    },

    {
      label: 'Payments',
      icon: 'pi pi-credit-card',
      routerLink: 'coming-soon'
      // routerLink: 'payments'
    },

    {
      label: 'Prep requests',
      icon: 'pi pi-file',
      routerLink: 'file-prep-requests'
    },

    {
      label: 'Catalog',
      icon: 'pi pi-book',
      items: [
        {
          label: 'SSM Catalog',
          icon: 'pi pi-book',
          routerLink: 'catalog'
        },
        {
          label: 'Suppliers',
          icon: 'pi pi-book',
          routerLink: 'suppliers'
        }
      ]
    }
  ];

  constructor() {
    effect(() => {
      this.isSuperAdmin = !!this.authStore.isSuperAdmin();
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if Meta (Command) OR CTRL and 'K' are pressed together
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
      event.preventDefault(); // Prevent default browser behavior if necessary
      this.openModal(); // Open the modal
    }
  }

  openModal() {
    this.searchModalVisible = true;
  }

  closeDialog() {
    this.search = '';
    this.searchResults = undefined;
    this.searchModalVisible = false;
  }

  handleSearch() {
    this.loading = true;
    this.searchService.globalSearch(this.search).subscribe({
      next: next => {
        this.searchResults = next.data;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
}
