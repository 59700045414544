<div class="input-group">
  @if (label() && displayLabel()) {
    <label [for]="name()" [ngStyle]="{ display: 'block' }">{{ label() }}</label>
  }

  <div class="input-wrap">
    <input
      [id]="name()"
      class="mt-1 mb-2"
      [type]="type()"
      [formControl]="inputControl"
      pInputText
      [placeholder]="placeholder()"
      [class.error]="inputControl.invalid && (inputControl.dirty || inputControl.touched)"
      [ngClass]="{
        'w-full': fullWidth(),
        'p-disabled': readonly(),
        'input-append': appendSymbol()
      }"
      [readonly]="readonly()"
    />
    @if (appendSymbol()) {
      <div class="append mt-1 mb-2">{{ appendSymbol() }}</div>
    }
  </div>

  @if (inputControl.invalid && (inputControl.dirty || inputControl.touched)) {
    @if (inputControl.hasError('required')) {
      <div class="flex align-items-center">
        <small class="p-text-danger">{{ errorMessage() }}</small>
      </div>
    }
    @if (inputControl.hasError('email')) {
      <div class="flex align-items-center">
        <small class="p-text-danger">{{ emailMessage() }}</small>
      </div>
    }
  }
</div>
