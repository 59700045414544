<div class="flex flex-column" style="min-height: 650px; transform: rotateX(180deg)">
  <div class="mx-auto col-12" [ngStyle]="{ display: configPatternImage.image ? 'none' : 'block', width: '650px' }">
    <p-skeleton width="650px" height="650px" [ngStyle]="{ position: 'absolute' }"></p-skeleton>
  </div>
  <ko-stage
    #stage
    [config]="configStage"
    class="my-canvas mx-auto col-fixesd"
    [ngStyle]="{ visibility: configPatternImage.image ? 'visible' : 'hidden' }"
  >
    <ko-layer #layer>
      <ko-image #pattern [config]="configPatternImage" />
      <ko-rect #rect [config]="config()" (dragend)="handleMove($event)" />
      <ko-transformer #transformer [config]="transformConfig()"></ko-transformer>
    </ko-layer>
  </ko-stage>
</div>
