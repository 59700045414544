<div class="card">
  <div class="ml-3">
    <h4>Create new collection</h4>
    <label for="" class="block">Collection name</label>
    <input pInputText id="name" class="mb-3" autocomplete="off" [(ngModel)]="name" />
  </div>

  @if ((collectionId && collection) || !collectionId) {
    <app-product-selection
      (onSave)="handleSelectionSave($event)"
      type="collection"
      [isSubmitting]="isLoading"
      [disabled]="!name"
      [collection]="collection"
    />
  }
</div>
