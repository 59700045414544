import { CommonModule, JsonPipe, NgStyle } from '@angular/common';
import { Component, computed, input, Input, model, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule, JsonPipe, NgStyle, CommonModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent implements OnInit {
  @Input() control: FormControl | AbstractControl;
  type = model<string>('text');
  fullWidth = input<boolean>(false);
  label = input<string>('');
  displayLabel = input<boolean>(true);
  id = input<string>('');
  appendSymbol = input<string>('');
  placeholder = input<string>();
  name = input<string>('This field');
  readonly = input<boolean>(false);
  errorMessage = computed(() => `${this.label()} is required!`);
  emailMessage = computed(() => {
    if (this.type() === 'email') {
      return 'Must be a valid email address';
    }
    return '';
  });
  passwordVisibility = signal(false);
  passwordIcon = computed(() => {
    if (this.passwordVisibility()) {
      return 'assets/images/icons/eye.svg';
    }
    return 'assets/images/icons/eye-slash.svg';
  });

  inputControl: FormControl;

  ngOnInit() {
    this.inputControl = this.control as FormControl;
  }

  togglePasswordIcon($event: MouseEvent) {
    $event.preventDefault();
    this.type.update(type => {
      return type === 'text' ? 'password' : 'text';
    });
    this.passwordVisibility.update(val => !val);
  }
}
