<p-orderList [value]="menu()" header="Menu" [dragdrop]="true" (onReorder)="onMenuReorder()">
  <ng-template let-item pTemplate="header">
    <div class="flex justify-content-between align-items-center">
      <p class="m-0 font-bold">Menu</p>
      <p-button
        [outlined]="true"
        [iconPos]="'left'"
        icon="pi pi-plus"
        [label]="'Add new menu item'"
        (click)="showMenuModal = true"
      />
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <p-panel [toggleable]="false">
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <span>{{ item.label }}</span>
        </div>
      </ng-template>
      <ng-template pTemplate="icons">
        <button class="p-panel-header-icon p-link mr-2" (click)="toggleMenuPopup($event, menu, item)">
          <span class="pi pi-cog"></span>
        </button>
        <p-menu #menu id="config_menu" [model]="menuPopupItems" [popup]="true" />
      </ng-template>
    </p-panel>
  </ng-template>
</p-orderList>
<form [formGroup]="menuForm" (ngSubmit)="handleMenuCreate()">
  <p-dialog
    header="Add new menu item"
    [(visible)]="showMenuModal"
    [modal]="true"
    showEffect="fade"
    [breakpoints]="{ '960px': '75vw' }"
  >
    <div class="new-menu">
      <app-input name="Label" [control]="menuForm.controls.label" placeholder="Menu label"></app-input>
      <p-dropdown
        appendTo="body"
        [options]="pages()"
        optionLabel="title"
        formControlName="page"
        placeholder="Select page"
      ></p-dropdown>
    </div>
    <ng-template pTemplate="footer">
      <button
        [disabled]="!menuForm.valid"
        pButton
        icon="pi pi-check"
        type="submit"
        label="Ok"
        class="p-button-outlined"
      ></button>
    </ng-template>
  </p-dialog>
</form>
<p-toast />
<p-confirmDialog />
