import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';

import { IframeComponent } from 'app/_shared/components/iframe/iframe.component';
import { Page } from 'app/_shared/interfaces/designer/page';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { PageBuilderComponent } from 'app/admin/storefront/designer/menu-and-pages/page-builder/page-builder.component';

import { MenuListComponent } from './menu-list/menu-list.component';
import { PagesListComponent } from './pages-list/pages-list.component';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [
    OrderListModule,
    PanelModule,
    MenuModule,
    IframeComponent,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    CardModule,
    DropdownModule,
    ToastModule,
    ConfirmDialogModule,
    PagesListComponent,
    MenuListComponent,
    PageBuilderComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService],
  templateUrl: './menu-and-pages.component.html',
  styleUrl: './menu-and-pages.component.scss'
})
export class MenuAndPagesComponent implements OnInit {
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);

  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront()!);

  pages = computed(() => this.designerStore?.config?.().pages ?? []);
  isLoading = computed(() => this.designerStore.isLoading());

  selectedPage: Page[] = [];
  iframeUrl: SafeUrl;
  // `selectedPage` needs to be an array because p-orderList doesn't natively support single item selection
  //  we are forcing single selection with `selectionChange` function inside PagesList component
  addSectionDialog: boolean = false;
  constructor(private sanitizer: DomSanitizer) {
    effect(() => {
      if (this.selectedPage.length) {
        this.selectedPage = this.pages().filter(page => page.id === this.selectedPage[0].id);
      }
    });

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedStorefront()?.url}/?preview=true`);
  }

  ngOnInit() {
    this.selectedPage = [this.pages()?.[0]];
    const selectedPageUrl = this.selectedPage[0]?.url ? '/' + this.selectedPage[0]?.url : '';
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.selectedStorefront()?.url}${selectedPageUrl}/?preview=true`
    );
  }

  applyChanges() {
    const config = this.designerStore.config();
    this.designerStore.saveConfig(this.selectedStorefront().id!, config).subscribe();
  }

  handleSetIframeUrl(page: Page) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.selectedStorefront()?.url}/${page?.url}/?preview=true`
    );
  }
}
