import { ChangeDetectionStrategy, Component, computed, effect, inject, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabMenuModule } from 'primeng/tabmenu';

import { DesignerService } from 'app/_shared/services/designer.service';
import { StorefrontStore } from 'app/_store/storefront.store';

@Component({
  selector: 'app-designer',
  standalone: true,
  imports: [ProgressSpinnerModule, TabMenuModule],
  templateUrl: './designer.component.html',
  styleUrl: './designer.component.scss',
  providers: [DesignerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignerComponent implements OnInit {
  routeItems: MenuItem[] = [];
  activeItem: MenuItem | undefined;
  storefrontStore = inject(StorefrontStore);
  selectedStorefront = computed(() => this.storefrontStore.selectedStorefront?.());

  ngOnInit(): void {
    this.routeItems = [
      { label: 'Homepage', routerLink: 'homepage', icon: 'pi pi-home' },
      { label: 'Menu and Pages', routerLink: 'menu-and-pages' },
      { label: 'Settings', routerLink: 'settings' }
    ];
    this.activeItem = this.routeItems[0];
  }
}
