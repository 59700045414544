@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex flex-column gap-2 pt-3">
      <div>
        <p class="text-500 m-1">
          <strong class="text-900">Step 1.</strong>
          Provide the value of the payment request It may concern more than one file, so to have one payment increase
          the amount, and after receiving the payment change the rest of the files
        </p>
        <app-input
          name="value"
          label="Value"
          placeholder="Value"
          [control]="form.controls.value"
          [fullWidth]="true"
          [type]="'number'"
          [displayLabel]="false"
        ></app-input>
      </div>
      <div>
        <p class="text-500 m-1">
          <strong class="text-900">Step 2.</strong>
          Provide additional information
        </p>
        <textarea class="w-full" rows="4" pInputTextarea formControlName="additional_info"></textarea>
      </div>
      <div>
        <p class="text-500 m-1">
          <strong class="text-900">Step 3.</strong>
          Provide the email address
        </p>
        <app-input
          name="email"
          label="Email"
          [control]="form.controls.email"
          placeholder="Email"
          [fullWidth]="true"
          [type]="'email'"
          [displayLabel]="false"
        ></app-input>
      </div>
      <div>
        <p class="text-500 m-1">
          <strong class="text-900">Step 4.</strong>
          Provide an additional message
        </p>
        <textarea class="w-full" rows="4" pInputTextarea formControlName="additional_message"></textarea>
      </div>
      <div class="flex justify-content-end gap-1">
        <p-button outlined type="button" label="Cancel" (onClick)="closeDialog()"></p-button>
        <p-button type="submit" label="Create"></p-button>
      </div>
    </div>
  </form>
}
