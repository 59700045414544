import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';

import { CatalogProductCategory } from 'app/_shared/models/CatalogProductCategory';
import { CatalogProductCategoryService } from 'app/_shared/services/catalog-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-category-editor',
  standalone: true,
  imports: [ButtonModule, InputTextModule, DialogModule, ReactiveFormsModule, FileUploadModule, NgClass],
  templateUrl: './category-editor.component.html',
  styleUrl: './category-editor.component.scss'
})
export class CategoryEditorComponent implements OnInit {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  categoryService = inject(CatalogProductCategoryService);

  form: FormGroup;
  category: CatalogProductCategory | null = null;
  parentCategoryId: number;
  dialogConfig = inject(DynamicDialogConfig);
  dialogRef = inject(DynamicDialogRef);

  uploadedImage: File | null = null;
  imagePreviewUrl?: string;
  submitted = false;
  isLoading = false;

  ngOnInit() {
    this.category = this.dialogConfig.data.category;
    this.parentCategoryId = this.dialogConfig.data.parentCategoryId;
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.category?.name, [Validators.required]]
    });

    this.imagePreviewUrl = this.category?.category_image?.full_path;
  }

  onImageUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    if (file) {
      this.uploadedImage = file;
      this.imagePreviewUrl = '';
    }
  }

  onImageRemove() {
    this.uploadedImage = null;
    this.imagePreviewUrl = this.category?.category_image.full_path;
  }

  removeOldImage(): void {
    this.imagePreviewUrl = '';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.category ? this.updateCategory() : this.createCategory();
  }

  createCategory() {
    if (!this.uploadedImage) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const submitData = this.prepareSubmitData();
    this.isLoading = true;
    this.categoryService.createCategory(submitData).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  updateCategory() {
    if (!this.uploadedImage && !this.imagePreviewUrl) {
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const submitData = this.prepareSubmitData();
    this.isLoading = true;
    this.categoryService.updateCategory(submitData, this.category?.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: err => {
        console.error('Operation failed:', err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  prepareSubmitData() {
    const formValue = this.form.value;
    const formData = new FormData();
    formData.append('name', formValue.name);
    if (this.category) {
      formData.append('_method', 'put');
    }
    if (this.uploadedImage) {
      formData.append('category_image', this.uploadedImage);
    }
    //if we want to create subcategory, we need to pass parent category id
    if (this.parentCategoryId) {
      formData.append('admin_category_id', this.parentCategoryId.toString());
    }
    return formData;
  }
}
