<p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
<div class="card" xmlns="http://www.w3.org/1999/html">
  <div class="flex justify-content-end gap-2">
    <p-button outlined type="button" label="Create folder" icon="pi pi-plus" (click)="openFolderEditorDialog($event)" />
    <p-button outlined type="button" label="Request file prep" (click)="openFilePrepDialog()" />
  </div>
  <div class="grid mt-3">
    @for (folder of folders; track folder.id) {
      <div class="col-12 md:col-6 lg:col-3">
        <div class="card h-full">
          <a [routerLink]="[folder.id]">
            <div class="flex align-items-center justify-content-between mb-3">
              <span class="text-900 text-xl font-semibold">{{ folder.name }}</span>
              <div></div>
            </div>
            <div>
              <div [ngClass]="'bg-indigo-100'" class="border-round" style="height: 6px">
                <div [ngClass]="'bg-indigo-500'" class="h-full border-round" style="width: 100%"></div>
              </div>
              <div class="flex align-item-center justify-content-between">
                <span class="text-900 mt-3 text-md font-medium">Libraries count: {{ folder.libraries_count }}</span>
              </div>
              @if (folder.libraries_count === 0) {
                <div class="flex justify-content-center gap-2 pt-2">
                  <p-button icon="pi pi-pencil" outlined (click)="openFolderEditorDialog($event, folder)"></p-button>
                  <p-button
                    icon="pi pi-trash"
                    outlined
                    severity="danger"
                    (click)="openDeleteFodlerDialog($event, folder.id)"
                  ></p-button>
                </div>
              }
            </div>
          </a>
        </div>
      </div>
    }
  </div>
</div>
