<div class="mb-4">
  <div class="text-900 text-xl font-bold mb-2">Register</div>
  <span class="text-600 font-medium">Let's get started</span>
</div>
<form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-column">
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-user"></i>
        <input formControlName="first_name" type="text" pInputText class="w-full md:w-25rem" placeholder="First Name" />
      </span>
      @if (
        submitted() ||
        (registrationForm.get('first_name')?.invalid &&
          (registrationForm.get('first_name')?.dirty || registrationForm.get('first_name')?.touched))
      ) {
        @if (registrationForm.get('first_name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">First Name is required</small>
          </div>
        }
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-user"></i>
        <input formControlName="last_name" type="text" pInputText class="w-full md:w-25rem" placeholder="Last Name" />
      </span>
      @if (
        submitted() ||
        (registrationForm.get('last_name')?.invalid &&
          (registrationForm.get('last_name')?.dirty || registrationForm.get('last_name')?.touched))
      ) {
        @if (registrationForm.get('last_name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Last Name is required</small>
          </div>
        }
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-phone"></i>
        <input formControlName="phone" type="text" pInputText class="w-full md:w-25rem" placeholder="Phone" />
      </span>
      @if (
        submitted() ||
        (registrationForm.get('phone')?.invalid &&
          (registrationForm.get('phone')?.dirty || registrationForm.get('phone')?.touched))
      ) {
        @if (registrationForm.get('phone')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Last Name is required</small>
          </div>
        }
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock z-2"></i>
        <p-password
          [feedback]="false"
          formControlName="password"
          placeholder="Password"
          styleClass="w-full"
          [inputStyle]="{ paddingLeft: '2.5rem' }"
          inputStyleClass="w-full md:w-25rem"
          [toggleMask]="true"
        ></p-password>
      </span>
      @if (
        submitted() ||
        (registrationForm.get('password')?.invalid &&
          (registrationForm.get('password')?.dirty || registrationForm.get('password')?.touched))
      ) {
        @if (registrationForm.get('password')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Password is required</small>
          </div>
        }
        @if (registrationForm.get('password')?.errors?.['passwordPattern']) {
          <div class="w-50%">
            <small class="p-text-danger">
              Password must be at least 8 characters,
              <br />
              contain at least one uppercase letter,
              <br />
              one lowercase letter, one number
              <br />
              and one special character.
            </small>
          </div>
        }
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-key z-2"></i>
        <p-password
          [feedback]="false"
          formControlName="password_confirmation"
          placeholder="Confirm Password"
          styleClass="w-full"
          [inputStyle]="{ paddingLeft: '2.5rem' }"
          inputStyleClass="w-full md:w-25rem"
          [toggleMask]="true"
        ></p-password>
      </span>
      @if (
        submitted() ||
        (registrationForm.get('password_confirmation')?.invalid &&
          (registrationForm.get('password_confirmation')?.dirty ||
            registrationForm.get('password_confirmation')?.touched))
      ) {
        @if (registrationForm.get('password_confirmation')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Password Confirmation is required</small>
          </div>
        } @else if (registrationForm.errors?.['passwordsNotMatched']) {
          <div>
            <small class="p-text-danger">Passwords no not match</small>
          </div>
        }
      }
    </div>

    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-building"></i>
        <input formControlName="company" type="text" pInputText class="w-full md:w-25rem" placeholder="Company Name" />
      </span>
      @if (
        submitted() ||
        (registrationForm.get('company')?.invalid &&
          (registrationForm.get('company')?.dirty || registrationForm.get('company')?.touched))
      ) {
        @if (registrationForm.get('company')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Company Name is required</small>
          </div>
        }
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-building"></i>
        <input
          formControlName="billing_email"
          type="email"
          pInputText
          class="w-full md:w-25rem"
          placeholder="Billing email"
        />
      </span>
      @if (
        submitted() ||
        (registrationForm.get('billing_email')?.invalid &&
          (registrationForm.get('billing_email')?.dirty || registrationForm.get('billing_email')?.touched))
      ) {
        @if (registrationForm.get('billing_email')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Billing email is required</small>
          </div>
        }
      }
    </div>

    <div class="mb-4">
      <div class="flex flex-wrap">
        <p-checkbox formControlName="terms_and_conditions" styleClass="mr-2" [binary]="true"></p-checkbox>
        <label for="terms_and_conditions" class="text-900 font-medium mr-2">I have read the</label>
        <a class="text-600 cursor-pointer hover:text-primary cursor-pointer">Terms and Conditions</a>
      </div>
      @if (submitted() && registrationForm.get('terms_and_conditions')?.errors?.['required']) {
        <div class="mt-1">
          <small class="p-text-danger">Please accept the Terms and Conditions</small>
        </div>
      }
    </div>
    <button type="submit" [disabled]="isLoading()" pButton pRipple label="Sign Up" class="w-full mb-4"></button>
    <span class="font-medium text-600">
      Already have an account?
      <a
        [routerLink]="['/auth/login']"
        class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"
      >
        Login
      </a>
    </span>
  </div>
</form>
