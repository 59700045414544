import { Injectable } from '@angular/core';

import { GiftCard } from 'app/_shared/models/Coupon';
import { ApiService } from 'app/_shared/services/api.service';

@Injectable({ providedIn: 'root' })
export class GiftCardService extends ApiService {
  routePrefix = `${this.apiUrl}storefronts/`;

  public paginatedIndex(storefrontId: number, $event?: any) {
    return this.get(`${this.routePrefix}${storefrontId}/gift-cards`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  find(storefrontId: string, giftCardId: string) {
    return this.get<GiftCard>(`${this.routePrefix}${storefrontId}/gift-cards/${giftCardId}`, {
      params: {
        'relations[]': ['codes']
      }
    });
  }

  store(storefrontId: number, giftCard: GiftCard) {
    return this.post<GiftCard>(`${this.routePrefix}${storefrontId}/gift-cards`, giftCard);
  }

  update(storefrontId: string, giftCardId: string, giftCard: GiftCard) {
    return this.put<GiftCard>(`${this.routePrefix}${storefrontId}/gift-cards/${giftCardId}`, giftCard);
  }

  remove(storefrontId: number, giftCardId: number) {
    return this.delete(`${this.routePrefix}${storefrontId}/gift-cards/${giftCardId}`);
  }

  sendEmails(storefrontId: number, giftCardIds: number[]) {
    return this.post(`${this.routePrefix}${storefrontId}/gift-cards/send-emails`, { gift_card_ids: giftCardIds });
  }
}
