import { Component } from '@angular/core';

import { CatalogProductFormComponent } from 'app/_shared/components/catalog-product-form/catalog-product-form.component';

@Component({
  selector: 'app-create-catalog-product',
  standalone: true,
  imports: [CatalogProductFormComponent],
  templateUrl: './create-catalog-product.component.html',
  styleUrl: './create-catalog-product.component.scss'
})
export class CreateCatalogProductComponent {}
