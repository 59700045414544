<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4>Invites</h4>
      <p-table
        #dt
        [value]="isLoading ? [] : invites"
        [lazy]="true"
        (onLazyLoad)="loadInvites($event)"
        dataKey="id"
        [paginator]="true"
        [rows]="10"
        [totalRecords]="totalRecords"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        [showLoader]="false"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name']"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <span class="p-input-icon-left w-full md:w-20rem flex-order-1 md:flex-order-0">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="onGlobalFilter(dt, $event)"
                placeholder="Global Search"
                class="w-full"
              />
            </span>
            <p-button
              (click)="openInviteCompanyModal()"
              outlined
              class="flex-order-0 md:flex-order-1 ml-auto"
              icon="pi pi-plus"
              label="Invite company"
            ></p-button>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="email" class="white-space-nowrap" style="width: 35%">
              Email
              <p-sortIcon field="email" />
            </th>
            <th class="white-space-nowrap" style="width: 35%">Storefronts</th>
            <th pSortableColumn="status" class="white-space-nowrap" style="width: 15%">
              Status
              <p-sortIcon field="status" />
            </th>
            <th class="white-space-nowrap" style="width: 15%">Actions</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-invite>
          <tr>
            <td>{{ invite.email }}</td>
            <td>/</td>
            <td><app-status-badge [status]="invite.status" /></td>
            <td>
              @if (invite.status !== InviteStatusType.accepted) {
                <div class="flex gap-2">
                  <p-button
                    [icon]="invite.status !== InviteStatusType.requested ? 'pi pi-replay' : 'pi pi-check'"
                    outlined
                    (click)="openResendModal($event, invite.id, invite.status)"
                  />
                  <p-button
                    icon="pi pi-times"
                    outlined
                    severity="danger"
                    (click)="openDeleteModal($event, invite.id)"
                  ></p-button>
                </div>
              } @else {
                not allowed
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5" class="text-center">There are no invites</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          @for (row of loadingRows; track row) {
            <tr>
              @for (col of ['email', 'storefronts', 'status', 'actions']; track col) {
                <td>
                  <p-skeleton />
                </td>
              }
            </tr>
          }
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>

<p-confirmDialog header="Confirmation" key="resendInvite" [style]="{ width: '28rem' }"></p-confirmDialog>
