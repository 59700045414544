<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end p-fluid">
    <div class="col-12">
      <div class="field">
        <label for="email">Email</label>
        <input
          type="email"
          pInputText
          formControlName="email"
          id="email"
          [class.ng-dirty]="submitted || form.get('email')?.touched"
        />
        @if (submitted || form.get('email')?.touched) {
          @if (form.get('email')?.errors?.['required']) {
            <div>
              <small class="p-text-danger">Email is required</small>
            </div>
          } @else if (form.get('email')?.errors?.['email']) {
            <div>
              <small class="p-text-danger">Email is not valid</small>
            </div>
          }
        }
      </div>
    </div>
    <div class="col-auto" style="margin-top: 28px">
      <p-button
        label="Confirm"
        icon="pi pi-check"
        class="p-button-text"
        type="submit"
        [disabled]="isLoading"
      ></p-button>
    </div>
  </div>
</form>
