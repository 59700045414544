<div class="grid grid-nogutter flex-column justify-content-center align-items-center h-25rem">
  <div class="col-fixed">
    <h1 style="font-size: 3rem">COMING SOON</h1>
  </div>
  <div class="col-12 md:col-6 text-center">
    <h6>
      We're hard at work building something amazing for you. It's all coming soon! Stay tuned and get ready for the
      launch—you won’t want to miss it!
    </h6>
  </div>
  <div class="col-12 md:col-6 text-center">
    <h6>Something Exciting is Just Around the Corner!</h6>
  </div>
  <div class="col-fixed mt-5">
    <p-button
      type="button"
      label="Go back"
      icon="pi pi-angle-left"
      class="p-button-text"
      (onClick)="goBack()"
    ></p-button>
  </div>
</div>
