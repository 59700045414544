@if (isSuperAdmin) {
  <div class="layout-sidebar sa-topbar">
    <div class="layout-menu-container">
      <p-menubar [model]="model">
        <ng-template pTemplate="end">
          <div class="flex align-items-center gap-2 w-full">
            <p-button label="Search with ⌘ + K" (onClick)="openModal()" />
          </div>
        </ng-template>
      </p-menubar>
    </div>
  </div>
}
<form (ngSubmit)="handleSearch()">
  <p-dialog
    header="Search everywhere"
    [maximizable]="true"
    [modal]="true"
    position="top"
    [style]="{ width: '60rem', paddingTop: '150px' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [(visible)]="searchModalVisible"
    (onHide)="closeDialog()"
  >
    <div class="flex align-items-center gap-3 mb-3">
      <input
        pInputText
        id="search"
        class="flex-auto"
        autocomplete="off"
        [(ngModel)]="search"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
    @if (searchResults) {
      <div class="search-response grid grid-nogutter">
        @if (searchResults.storefronts.length) {
          <div class="col">
            <h5>Storefronts:</h5>
            @for (storefront of searchResults.storefronts; track storefront.id) {
              <div>
                <a
                  [routerLink]="['/companies', storefront.company_id, 'storefronts', storefront.id]"
                  (click)="closeDialog()"
                >
                  {{ storefront.name }}
                </a>
              </div>
            }
          </div>
        }
        @if (searchResults.users.length) {
          <div class="col">
            <h5>Users:</h5>
            @for (user of searchResults.users; track user.id) {
              <div>
                <a [routerLink]="['/users', user.id]" (click)="closeDialog()">
                  {{ user.full_name }}
                </a>
              </div>
            }
          </div>
        }
        @if (!searchResults.storefronts.length && !searchResults.users.length) {
          <p><b>No results found.</b></p>
        }
      </div>
    }
    <div class="flex justify-content-end gap-2">
      <p-button label="Cancel" severity="secondary" (onClick)="closeDialog()" />
      <p-button label="Search" type="submit" [loading]="loading" />
    </div>
  </p-dialog>
</form>
