@if (form) {
  <form [formGroup]="form" (ngSubmit)="formSubmit()">
    <div class="card">
      <h5>Promo Code Name</h5>
      <div class="col-12 sm:col-6 p-0">
        <label for="name">Name</label>
        <input formControlName="name" type="text" pInputText class="w-full" placeholder="Enter promo code name" />
        @if (form.get('name')?.touched && form.get('name')?.errors?.['required']) {
          <small class="p-text-danger">Promo code name is required</small>
        }
      </div>
    </div>

    <div class="card">
      <h5>Type</h5>
      @for (type of promoCodeTypeOptions; track $index) {
        <div class="field-checkbox">
          <p-radioButton formControlName="type" [inputId]="type.value" [value]="type.value" />
          <label [for]="type.name" class="ml-2">
            {{ type.name }}
          </label>
        </div>
      }
    </div>

    <div class="card">
      <div class="grid">
        <div class="col-12">
          <h5>Code</h5>
        </div>
        <div class="col-12 sm:col-6">
          <label for="code">Code</label>
          <input formControlName="code" type="text" pInputText class="w-full" placeholder="ex. FreeShip" />
          <p class="mb-0">This is a code that your customers will enter at the checkout.</p>
          @if (form.get('code')?.touched && form.get('code')?.errors?.['required']) {
            <small class="p-text-danger">Code is required</small>
          }
          @if (form.get('code')?.touched && form.get('code')?.errors?.['minlength']) {
            <small class="p-text-danger">
              Code must be at least {{ form.get('code')?.errors?.['minlength'].requiredLength }} characters
            </small>
          }
        </div>
      </div>
    </div>

    @if (form.value.type === promoCodeType.CATEGORY) {
      <div class="card">
        <h5>Category</h5>
        <div class="flex flex-column pt-1">
          <label for="product_categories">Product Catagory</label>
          <p-multiSelect
            [options]="productCategories"
            placeholder="Select Categories"
            optionLabel="name"
            optionValue="id"
            [style]="{ width: '50%' }"
            formControlName="product_categories"
          />
          @if (form.get('product_categories')?.touched && form.get('product_categories')?.errors?.['required']) {
            <small class="p-text-danger">At least one category is required</small>
          }
        </div>
      </div>
    }

    @if (form.value.type === promoCodeType.PRODUCT) {
      <div class="card">
        <div class="flex gap-3">
          <h5>Products</h5>
          <p>{{ selectedStorefrontProduct.length }} selected</p>
        </div>
        <p-button
          label="Choose products"
          outlined
          icon="pi pi-arrow-right"
          iconPos="right"
          (onClick)="openProductListSelectorForPromoCode()"
        ></p-button>

        @if (selectedStorefrontProduct) {
          <div>
            @for (product of selectedStorefrontProduct; track $index) {
              <div class="flex justify-content-between mt-2">
                <div class="flex gap-2 align-items-center">
                  <img
                    [src]="product.product_thumbnail?.full_path"
                    [alt]="product.product_thumbnail?.name"
                    style="object-fit: contain"
                    [ngStyle]="{ maxWidth: '80px' }"
                    class="shadow-4"
                    priority
                  />
                  <p>{{ product.name }}</p>
                </div>
                <p-button (onClick)="removeProductFromSelected($index)" icon="pi pi-trash" severity="danger" outlined />
              </div>
            }
          </div>
        }
        @if (form.get('products')?.touched && form.get('products')?.errors?.['required']) {
          <small class="p-text-danger">At least one product is required</small>
        }
      </div>
    }

    @if (form.value.type !== promoCodeType.FREE_SHIPPING) {
      <div class="card">
        <h5>Value</h5>
        <div class="flex gap-2">
          <div class="flex flex-column">
            <input type="text" pInputText placeholder="$2.00" formControlName="promo_code_value" />
            @if (form.get('promo_code_value')?.touched && form.get('promo_code_value')?.errors?.['required']) {
              <small class="p-text-danger">Promo code value is required</small>
            }
          </div>
          <div class="flex flex-column">
            <p-selectButton
              multiple="false"
              [options]="promoCodeValueTypes"
              optionLabel="name"
              optionValue="value"
              formControlName="promo_code_value_type"
              class="custom-height-button"
            ></p-selectButton>
            @if (
              form.get('promo_code_value_type')?.touched && form.get('promo_code_value_type')?.errors?.['required']
            ) {
              <small class="p-text-danger">Promo code value type is required</small>
            }
          </div>
        </div>
      </div>
    }

    @if (form.value.type === promoCodeType.FREE_SHIPPING || form.value.type === promoCodeType.ORDER) {
      <div class="card">
        <h5>Minimum order amount</h5>
        <div class="mb-3">
          <p-checkbox binary formControlName="minimum_order" inputId="minimum_order_amount" />
          <label class="ml-2" for="minimum_order">Set minimum order amount</label>
        </div>
        @if (form.value.minimum_order) {
          <div class="col-12 sm:col-6 p-0">
            <input class="w-full" formControlName="minimum_order_amount" type="text" pInputText placeholder="$.00" />
            @if (form.get('minimum_order_amount')?.touched && form.get('minimum_order_amount')?.errors?.['required']) {
              <small class="p-text-danger">Minimum order is required when its checked</small>
            }
            @if (form.get('minimum_order_amount')?.touched && form.get('minimum_order_amount')?.errors?.['min']) {
              <small class="p-text-danger">
                Minimal value is {{ form.get('minimum_order_amount')?.errors?.['min'].min | currency: 'USD' }}
              </small>
            }
          </div>
        }
      </div>
    }

    @if (form.value.type !== promoCodeType.PRODUCT) {
      <div class="card">
        <h5>Maximum uses</h5>
        <div class="mb-3">
          <p-checkbox formControlName="limit_number_of_use" binary inputId="limit_number_of_use" />
          <label class="ml-2" for="limit_number_of_use">
            Limit number of times this promo code can be used in total
          </label>
        </div>

        <div class="mb-3">
          <p-checkbox formControlName="per_customer" binary inputId="per_customer" />
          <label class="ml-2" for="per_customer">Limit to one use per customer</label>
        </div>

        @if (form.value.limit_number_of_use) {
          <div class="flex flex-column">
            <p-inputNumber
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              [min]="0"
              inputStyleClass="w-3rem text-center"
              formControlName="number_of_uses"
              decrementButtonClass="p-button-text"
              incrementButtonClass="p-button-text"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            ></p-inputNumber>
            @if (form.get('number_of_uses')?.touched && form.get('number_of_uses')?.errors?.['required']) {
              <small class="p-text-danger mt-1">Number of uses is required when limit is checked</small>
            }
            @if (form.get('number_of_uses')?.touched && form.get('number_of_uses')?.errors?.['min']) {
              <small class="p-text-danger mt-1">
                Minimal value is {{ form.get('number_of_uses')?.errors?.['min'].min }}
              </small>
            }
          </div>
        }
      </div>
    }

    <div class="card">
      <h5>Active dates</h5>
      <div class="flex flex-column gap-1">
        <div class="flex flex-column field">
          <label for="start_date" class="block text-900 font-semibold">Set start date</label>
          <div class="flex align-items-center gap-1">
            <span class="p-input-icon-left" style="height: 3.5rem">
              <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
              <p-calendar
                name="start_date"
                hourFormat="12"
                inputId="start_date"
                id="start_date"
                inputStyleClass="pl-7 text-900 font-semibold"
                [inputStyle]="{ height: '3.5rem' }"
                formControlName="start_date"
              ></p-calendar>
            </span>
            <p-button label="Set current date" [text]="true" (click)="setStartDateToToday()" />
          </div>
          <p>If start date is in the future, the created promo code will start automatically on set date.</p>
        </div>
        <div class="mb-3">
          <p-checkbox formControlName="end_date_visible" binary inputId="set_end_date" />
          <label class="ml-2" for="set_end_date">Set end date</label>
        </div>
        @if (form.value.end_date_visible) {
          <div class="flex flex-column field">
            <label for="end_date" class="block text-900 font-semibold">Set end date</label>
            <span class="p-input-icon-left" style="height: 3.5rem">
              <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
              <p-calendar
                name="end_date"
                hourFormat="12"
                inputId="end_date"
                id="end_date"
                inputStyleClass="pl-7 text-900 font-semibold"
                [inputStyle]="{ height: '3.5rem' }"
                formControlName="end_date"
              ></p-calendar>
            </span>
            <p class="mb-0">If no end date is set, the promo code will be active until you delete it</p>
            @if (form.get('end_date')?.touched && form.get('end_date')?.errors?.['dateAfterGiven']) {
              <small class="p-text-danger">End date must be after the start date.</small>
            }
          </div>
        }
      </div>
    </div>

    <div class="card">
      <p-button type="submit" [label]="promoCodeId ? 'Update promo code' : 'Create promo code'" />
    </div>
  </form>
}
