<nav class="layout-breadcrumb">
  <ol>
    @for (
      item of breadcrumbs$ | async;
      track i;
      let last = $last;
      let i = $index
    ) {
      <li [routerLink]="item.url" class="cursor-pointer">{{ item.label }}</li>
      @if (!last) {
        <li class="layout-breadcrumb-chevron">/</li>
      }
    }
  </ol>
</nav>
