import { DatePipe, NgStyle } from '@angular/common';
import { Component, computed, effect, inject, model, OnInit, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreShapeComponent, StageComponent } from 'ng2-konva';
import { ShapeConfigTypes } from 'ng2-konva/lib/utils/configTypes';
import { Button } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { ToggleButtonChangeEvent } from 'primeng/togglebutton';
import { map } from 'rxjs';

import { MediaLibraryComponent } from 'app/_shared/components/media-library/media-library.component';
import { PrintArea, PrintAreaData, CatalogProductVariant } from 'app/_shared/interfaces/catalog';
import { StorefrontProduct, StorefrontProductData } from 'app/_shared/interfaces/storefront';
import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { MockupFile } from 'app/_shared/models/MockupFile';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { ProductDetailsComponent } from 'app/admin/storefront/products/create-product/product-details/product-details.component';

export interface CatalogProductVariantWithChecked extends CatalogProductVariant {
  checked: boolean;
}

interface VariantOverride extends Partial<PrintArea> {
  variantId: string | number;
  productVariantPrintId?: string | number;
}

@Component({
  selector: 'app-create-product',
  standalone: true,
  imports: [
    DividerModule,
    Button,
    DropdownModule,
    FormsModule,
    ButtonGroupModule,
    NgStyle,
    SkeletonModule,
    StageComponent,
    CoreShapeComponent,
    SelectButtonModule,
    ProductDetailsComponent
  ],
  providers: [DatePipe],
  templateUrl: './create-product.component.html',
  styleUrl: './create-product.component.scss'
})
export class CreateProductComponent implements OnInit {
  @ViewChild('printImage') printImageRef: CoreShapeComponent;
  @ViewChild('transformer') printImageTransformerRef: CoreShapeComponent;

  private dialogConfig = inject(DynamicDialogConfig);
  private dialogRefProduct = inject(DynamicDialogRef);
  private dialogRefImage = inject(DynamicDialogRef);
  private dialogService = inject(DialogService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private catalogService = inject(CatalogService);
  private storefrontService = inject(StorefrontService);

  toastService = inject(ToastService);
  datePipe = inject(DatePipe);
  productId: string;
  editMode: boolean;
  isSubmitting: boolean;
  storefrontId: string;
  multipleCreate = false;

  product = signal<StorefrontProduct | undefined>(undefined);
  printAreas = signal<PrintArea[]>([]);
  selectedPrintArea = model<PrintArea | undefined>(undefined);
  productVariants = signal<CatalogProductVariantWithChecked[]>([]);
  selectedProductVariants = computed<{ name: string; value: number }[]>(() => {
    const value = this.productVariants();
    return value.filter(variant => variant.checked).map(v => ({ name: v.color, value: v.id! }));
  });
  resetSelectedColorsEffect = effect(
    () => {
      const value = this.selectedPrintArea();
      this.selectedPrintColor.set(undefined);
    },
    { allowSignalWrites: true }
  );
  logoImageConfig = computed(() => {
    const selectedArea = this.selectedPrintArea();
    const colorConfig = this.specificColorPrintConfig();
    const selectedPrintColor = this.selectedPrintColor();
    const versionConfig = this.selectedVariantConfig();

    if (selectedPrintColor && versionConfig) {
      const printLogoData = versionConfig.logoData as PrintAreaData;
      const printImage = new Image();
      printImage.src = versionConfig?.logo ?? '';
      return {
        ...this.printImageConfigBaseData,
        ...printLogoData,
        image: printImage
      };
    } else {
      if (selectedArea) {
        const printLogoData = this.selectedPrintArea()!.logoData as PrintAreaData;
        const printImage = new Image();
        printImage.src = selectedArea?.logo ?? '';

        return {
          ...this.printImageConfigBaseData,
          ...printLogoData,
          image: printImage
        };
      }
      return this.printImageConfigBaseData;
    }
  });
  printAreaWidth = computed(() => {
    const selectedArea = this.selectedPrintArea();
    return selectedArea ? ((selectedArea.data as PrintAreaData).width / 96).toFixed(2) : 0;
  });
  printAreaHeight = computed(() => {
    const selectedArea = this.selectedPrintArea();
    return selectedArea ? ((selectedArea.data as PrintAreaData).height / 96).toFixed(2) : 0;
  });
  baseData = { x: 0, y: 0, width: 0, height: 0, scaleX: 1, scaleY: 1, rotation: 0, skewX: 0, skewY: 0 };
  configStage = {
    width: 650,
    height: 650
  };
  productImageConfig: {
    x: number;
    y: number;
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
    image: HTMLImageElement | null;
    listening: boolean;
  } = { ...this.baseData, image: null, listening: false };
  printImageConfigBaseData: ShapeConfigTypes = { ...this.baseData, draggable: true };
  printImageOriginalData = this.baseData;
  printImageTransformedWidth = computed(() => {
    const width = this.selectedPrintArea()?.logoData?.width * this.selectedPrintArea()?.logoData?.scaleX;
    return isNaN(width) ? 0 : width;
  });
  printImageTransformedHeight = computed(() => {
    const height = this.selectedPrintArea()?.logoData?.height * this.selectedPrintArea()?.logoData?.scaleY;
    return isNaN(height) ? 0 : height;
  });
  printAreaConfig: ShapeConfigTypes;
  printImageGroupConfig: ShapeConfigTypes;
  printImageTransformerConfig: ShapeConfigTypes = {};

  selectedPrintColor = signal<{ name: string; value: number } | undefined>(undefined);
  selectedLogoVersion: { file: MockupFile; buttonName: number } | undefined;
  deletedPrints: number[] = [];
  libraryFile = computed<LibraryFile | undefined>(() => this.selectedPrintArea()?.library);
  logoVersions = computed(() => {
    const library = this.libraryFile();
    if (!library) return [];

    const mockupKeys = [
      'mockup_file',
      'second_mockup_file',
      'third_mockup_file',
      'fourth_mockup_file',
      'fifth_mockup_file'
    ];

    return mockupKeys
      .map((key, index) => ({
        buttonName: index + 1,
        file: library[key]
      }))
      .filter(item => item.file);
  });
  specificColorPrintConfig = signal<VariantOverride[]>([]);
  selectedVariantConfig = computed(() => {
    return this.specificColorPrintConfig()?.find(
      conf => conf.variantId === this.selectedPrintColor()?.value && this.selectedPrintArea()?.id === conf.id
    );
  });

  ngOnInit(): void {
    this.productId = this.dialogConfig.data.productId;
    this.editMode = this.dialogConfig.data.editMode;
    this.storefrontId = this.dialogConfig.data.storefrontId;
    this.multipleCreate = this.dialogConfig.data.multiple;
    if (!this.storefrontId) {
      this.activatedRoute.paramMap.subscribe(params => {
        this.storefrontId = params.get('storefrontId') ?? '';
      });
    }
    this.fetchProduct();
  }

  fetchProduct() {
    this.handleFetchProduct().subscribe(product => {
      this.product.set(product);
      const areas = product.catalog_product.print_areas.map(area => {
        // Add logo image to printAreas (we have those two in separate objects)
        const logoConfig = product.product_prints?.find(print => print.print_area_id === area.id);
        const printVersion = this.getDefaultPrintVersion(logoConfig?.library, logoConfig?.library_version);
        const logoData = logoConfig?.data as string;
        const printLibrary = product.product_prints?.find(print => print.print_area_id === area.id)?.library;
        return {
          ...area,
          ...(logoConfig && { productPrintId: logoConfig.id }),
          libraryFileId: logoConfig?.library_id,
          library: printLibrary,
          logo: printVersion?.full_path ?? '',
          logoData: logoData ? JSON.parse(logoData) : undefined,
          libraryVersion: logoConfig?.library_version,
          data: JSON.parse(area.data as string)
        };
      });
      this.printAreas.set(areas);
      // Select first print area
      this.selectedPrintArea.set(areas[0]);
      this.selectedLogoVersion = this.logoVersions()?.find(version => {
        const defaultVersion = this.getDefaultPrintVersion(this.libraryFile(), areas[0].libraryVersion);
        return version.file.id === defaultVersion?.id;
      });
      const specificVariantPrints = product.product_variants
        ?.flatMap(variant =>
          variant.product_variant_prints.map(print => {
            const file = this.getDefaultPrintVersion(this.libraryFile(), print.library_version);
            return {
              id: print.print_area_id,
              productVariantPrintId: print.id,
              variantId: variant.catalog_product_variant_id,
              libraryId: print.library_id,
              logoData: JSON.parse(print.data),
              logo: file?.full_path ?? '',
              libraryVersion: print.library_version
            };
          })
        )
        .filter(Boolean);
      this.specificColorPrintConfig.set(specificVariantPrints ?? []);
      this.initKonvaConfig(this.selectedPrintArea()!);
      this.buildVariants();
      this.initTransformer();
    });
  }

  handleFetchProduct() {
    if (!this.editMode) {
      return this.catalogService.getProduct(this.productId).pipe(
        map(res => {
          // Map CatalogProduct to StorefrontProduct
          const catalogPrintPrice = res.data.catalog_product_configuration?.product_price ?? 0;
          const { name, description, print_areas, featured_image, catalog_product_variants } = res.data;
          return {
            name,
            description,
            price: +(catalogPrintPrice / 100).toFixed(2),
            catalog_product: {
              print_areas,
              catalog_product_variants
            },
            product_thumbnail: featured_image
          } as StorefrontProduct;
        })
      );
    } else {
      return this.storefrontService.getStorefrontProduct(this.storefrontId, this.productId).pipe(
        map(data => {
          const price = data.data.price;
          const formatPrice = price ? +(price / 100).toFixed(2) : 0;
          return { ...data.data, price: formatPrice };
        })
      );
    }
  }

  initKonvaConfig(printArea: PrintArea) {
    const data = printArea.data as PrintAreaData;
    this.printAreaConfig = {
      width: data.width,
      height: data.height,
      x: data.x,
      y: data.y,
      scaleX: data.scaleX,
      scaleY: data.scaleY,
      stroke: '#838383',
      strokeWidth: 2, // Border width
      dash: [10, 10], // Dashed pattern: [line length, gap length]
      cornerRadius: 10
    };
    this.printImageGroupConfig = {
      clip: {
        x: data.x,
        y: data.y,
        width: data.width,
        height: data.height
      }
    };
  }

  setProductImage(image: string) {
    const imageObj = new Image();
    imageObj.src = image;
    imageObj.onload = () => {
      this.productImageConfig = {
        ...this.productImageConfig,
        x: 0,
        y: 0,
        image: imageObj,
        width: imageObj.width,
        height: imageObj.height
      };
      this.configStage = {
        width: imageObj.width,
        height: imageObj.height
      };
    };
  }

  initTransformer() {
    const printImageStage = this.printImageRef.getStage();
    this.printImageTransformerConfig = {
      nodes: [printImageStage],
      keepRatio: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      borderStroke: 'rgb(79,75,224)',
      anchorFill: 'rgb(79,75,224)',
      anchorStroke: 'transparent'
    };
    printImageStage.on('transformend', $event => this.updatePrintImageAttributes($event));
  }

  updatePrintImageAttributes($event: any) {
    const logoData = { ...$event.target.attrs };
    //  We remove image object because its too big to be saved inside print areas
    //  We generate image config inside logoImageConfig `computed`
    //  `computed` is running when selected print area changes
    delete logoData.image;

    if (this.selectedPrintColor() && this.selectedLogoVersion) {
      //  Check if this color is already in array
      if (this.selectedVariantConfig()) {
        const updatedConfig = this.specificColorPrintConfig().map(config => {
          if (config.variantId === this.selectedPrintColor()?.value && this.selectedPrintArea()?.id === config.id) {
            return { ...config, logoData };
          }
          return config;
        });
        this.specificColorPrintConfig.set(updatedConfig);
      } else {
        this.specificColorPrintConfig.update(conf => {
          const logoFIle = this.selectedLogoVersion?.file;
          return [
            ...conf,
            {
              id: this.selectedPrintArea()?.id,
              variantId: this.selectedPrintColor()!.value,
              libraryId: logoFIle?.library_id,
              logoData,
              logo: logoFIle?.full_path,
              libraryVersion: logoFIle?.type
            }
          ];
        });
      }
    } else {
      this.updateSelectedArea({ logoData });
    }
  }

  handlePrintAreaChange($event: DropdownChangeEvent) {
    const area: PrintArea = $event.value;
    this.initKonvaConfig(area);
    this.selectedLogoVersion = area.libraryVersion ? this.preselectPrintVersion(area.libraryVersion) : undefined;
  }

  openMediaLibraryModal() {
    const printConfig = this.product()?.product_prints?.find(
      print => print.print_area_id === this.selectedPrintArea()?.id
    );
    this.dialogRefImage = this.dialogService.open(MediaLibraryComponent, {
      header: 'Add your design',
      width: '700px',
      height: 'auto',
      data: {
        storefrontId: this.storefrontId,
        editMode: this.editMode,
        ...(this.editMode && { libraryFile: printConfig?.library })
      }
    });
    this.dialogRefImage.onClose.subscribe((selectedFiles: LibraryFile[]) => {
      if (selectedFiles) {
        this.handlePrintAreaImageUpload(selectedFiles[0]);
      }
    });
  }

  handlePrintAreaImageUpload(libraryFile: LibraryFile) {
    // this.libraryFile.set(libraryFile);
    this.updateSelectedArea({ library: libraryFile });
    // Remove previous image
    const removedPrintId = this.selectedPrintArea()?.productPrintId;
    if (removedPrintId && !this.deletedPrints.includes(removedPrintId)) {
      this.deletedPrints.push(removedPrintId);
    }
    const printImage = new Image();
    const print = this.getDefaultPrintVersion(libraryFile);

    printImage.src = print?.full_path ?? '';
    const printAreaData = this.selectedPrintArea()!.data as PrintAreaData;
    const defaultPrint = this.preselectPrintVersion();
    printImage.onload = () => {
      const scale = this.getImageToAreaScale(printImage, printAreaData);
      const logoData = {
        ...this.printImageConfigBaseData,
        width: printImage.width,
        height: printImage.height,
        scaleX: scale,
        scaleY: scale,
        x: printAreaData.x,
        y: printAreaData.y
      };
      this.printImageOriginalData = {
        x: printAreaData.x,
        y: printAreaData.y,
        width: printImage.width,
        height: printImage.height,
        scaleX: scale,
        scaleY: scale,
        rotation: 0,
        skewX: 0,
        skewY: 0
      };
      this.updateSelectedArea({
        libraryFileId: libraryFile.id,
        logo: defaultPrint?.file.full_path,
        logoData,
        libraryVersion: libraryFile?.main_mockup,
        productPrintId: undefined
      });
    };
  }

  updateSelectedArea(areaData: Partial<PrintArea>) {
    const areas = this.printAreas().map(area => {
      if (area.id === this.selectedPrintArea()!.id) {
        return {
          ...area,
          ...areaData
        } as PrintArea;
      }
      return area;
    });

    this.printAreas.set(areas);
    this.selectedPrintArea.set(areas.find(area => area.id === this.selectedPrintArea()!.id)!);
  }

  buildVariants() {
    const areaImageType = this.selectedPrintArea()!.type as string;
    const colors: CatalogProductVariantWithChecked[] = (
      this.product()?.catalog_product.catalog_product_variants ?? []
    ).map((variant, index) => {
      return { ...variant, checked: index === 0 };
    });

    if (this.editMode) {
      colors.forEach(item => {
        item.checked = (this.product()?.product_variants ?? []).some(
          variant => variant.catalog_product_variant_id === item.id
        );
      });
      this.productVariants.set(colors);
      //  Set print area background image based on selected print variants
      const variant = this.productVariants().find(prod => prod.id === this.selectedProductVariants()[0].value);
      const image = variant?.[areaImageType].supplier_media?.full_path || variant?.[areaImageType]?.full_path;
      this.setProductImage(image ?? '');
    } else {
      this.productVariants.set(colors);
      //  Set print area background image to be the image of the first variant in variants array
      const image = colors[0]?.[areaImageType].supplier_media?.full_path || colors[0]?.[areaImageType]?.full_path;
      this.setProductImage(image ?? '');
    }
  }
  getImageToAreaScale(printImage: HTMLImageElement, printAreaData: PrintAreaData) {
    if (printImage.height > printImage.width) {
      if (printImage.height > printAreaData.height) {
        return printAreaData.height / printImage.height;
      }
    } else {
      if (printImage.width > printAreaData.width) {
        return printAreaData.width / printImage.width;
      }
    }
    return 1;
  }

  verticalAlign(up: boolean = false) {
    const printArea = this.selectedPrintArea();
    if (!printArea?.logoData) return;
    const printAreaData = printArea.data as PrintAreaData;
    const logoData = printArea.logoData as PrintAreaData;
    const displayedPrintImageHeight = logoData.height! * logoData.scaleY!;
    this.updateSelectedArea({
      logoData: {
        ...logoData,
        y: up ? printAreaData.y : printAreaData.y + printAreaData.height - displayedPrintImageHeight
      }
    });
  }

  horizontalAlign(position: string) {
    const printArea = this.selectedPrintArea();
    if (!printArea?.logoData) return;

    const { width, scaleX } = printArea.logoData;
    const printAreaData = printArea.data as PrintAreaData;
    const displayedPrintImageWidth = width! * scaleX!;
    const baseX = printAreaData.x;
    const areaWidth = printAreaData.width;
    const xPosition =
      position === 'left'
        ? baseX
        : position === 'right'
          ? baseX + areaWidth - displayedPrintImageWidth
          : baseX + (areaWidth - displayedPrintImageWidth) / 2;
    this.updateSelectedArea({ logoData: { ...this.selectedPrintArea()?.logoData, x: xPosition } });
  }

  resetChanges() {
    this.updateSelectedArea({ logoData: { ...this.selectedPrintArea()?.logoData, ...this.printImageOriginalData } });
  }

  removePrintImage() {
    const removedPrintId = this.selectedPrintArea()?.productPrintId;
    if (removedPrintId && !this.deletedPrints.includes(removedPrintId)) {
      this.deletedPrints.push(removedPrintId);
    }
    this.updateSelectedArea({ libraryFileId: undefined, logo: '', logoData: undefined, productPrintId: undefined });
    this.printImageOriginalData = this.baseData;
  }

  onSubmit(formData) {
    this.isSubmitting = true;
    const submitData = this.prepareSubmitData(formData);
    this.handleSubmit(submitData).subscribe({
      next: () => {
        this.toastService.success(this.editMode ? 'Product Updated' : 'Product Added');
        this.closeDialog();
        if (!this.editMode && !this.multipleCreate) {
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
      },
      error: () => (this.isSubmitting = false),
      complete: () => (this.isSubmitting = false)
    });
  }

  handleSubmit(submitData: StorefrontProductData) {
    if (this.editMode) {
      return this.storefrontService.updateStorefrontProduct(this.storefrontId, submitData, this.product()?.id);
    }
    return this.storefrontService.createStorefrontProduct(this.storefrontId, submitData);
  }

  prepareSubmitData(formData): StorefrontProductData {
    const { start_publish_date, product_categories, end_publish_date, additional_photos, deleted_additional_photos } =
      formData;
    const printAreasData = this.printAreas()
      .filter(area => area.libraryFileId)
      .map(area => ({
        id: area.productPrintId,
        print_area_id: area.id,
        library_id: area.libraryFileId,
        library_version: area.libraryVersion,
        data: JSON.stringify(area.logoData)
      }));

    const variants = this.productVariants()
      .filter(item => item.checked)
      .map(item => {
        const versionConfig = this.specificColorPrintConfig().filter(conf => conf.variantId === item.id);
        const prints = versionConfig.map(item => {
          return {
            id: item.productVariantPrintId,
            print_area_id: item.id,
            library_id: item.libraryId,
            library_version: item.libraryVersion,
            data: JSON.stringify(item.logoData)
          };
        });
        // const prints = versionConfig{ print_area_id: versionConfig?.id, library_id: 1, library_version: 1, data: 1 };
        return { id: item.id, ...(prints.length > 0 && { prints }) };
      });

    const startPublishDate = start_publish_date
      ? this.datePipe.transform(start_publish_date, 'yyyy-MM-dd HH:mm')
      : null;

    const endPublishDate = end_publish_date ? this.datePipe.transform(end_publish_date, 'yyyy-MM-dd HH:mm') : null;

    const formattedCategories = product_categories || [];
    const mappedCategories = formattedCategories.map(category =>
      category.id ? { id: category.id } : { name: category.name, slug: category.slug }
    );

    const mappedAdditionalPhotos = additional_photos.filter(photo => photo.file).map(photo => photo.base64);
    let catalogProductId;

    if (!this.editMode) {
      catalogProductId = this.multipleCreate ? this.productId[0] : this.productId;
    } else {
      catalogProductId = this.product()?.catalog_product_id;
    }
    const prepared = {
      ...formData,
      catalog_product_id: catalogProductId,
      prints: printAreasData,
      catalog_product_variants: variants,
      additional_photos: mappedAdditionalPhotos,
      start_publish_date: startPublishDate,
      end_publish_date: endPublishDate,
      product_categories: mappedCategories,
      deleted_prints: this.deletedPrints,
      deleted_additional_photos: deleted_additional_photos
    };
    Object.keys(prepared).forEach(key => !prepared[key] && delete prepared[key]);
    return prepared;
  }

  closeDialog() {
    this.dialogRefProduct.close(this.productId[0]);
  }

  handleVariantSelect(data: { $event: ToggleButtonChangeEvent; variantId: number }) {
    const { $event, variantId } = data;
    this.productVariants.update(value => {
      return value.map(v => {
        if (v.id === variantId) {
          return { ...v, checked: $event.checked } as CatalogProductVariantWithChecked;
        }
        return { ...v } as CatalogProductVariantWithChecked;
      });
    });
  }

  handlePrintVariantSelect($event: SelectButtonChangeEvent) {
    // Get new variant image
    const areaImageType = this.selectedPrintArea()!.type;
    const variant = this.productVariants().find(prod => prod.id === $event.value?.value);
    const image =
      variant?.[areaImageType as string].supplier_media?.full_path || variant?.[areaImageType as string]?.full_path;

    if (image) {
      this.setProductImage(image ?? '');
    }
    const versionConfig = this.selectedVariantConfig();
    this.preselectPrintVersion(versionConfig ? versionConfig.libraryVersion : this.selectedPrintArea()?.libraryVersion);
  }

  preselectPrintVersion(fileName?: string) {
    this.selectedLogoVersion = this.logoVersions().find(version => {
      const defaultVersion = this.getDefaultPrintVersion(this.libraryFile(), fileName);
      return version.file.id === defaultVersion?.id;
    });

    return this.selectedLogoVersion;
  }

  selectAllVariants(select: boolean) {
    this.productVariants.update(value => {
      return value.map(v => {
        return { ...v, checked: select } as CatalogProductVariantWithChecked;
      });
    });
  }

  handleLogoVersionChange(file: MockupFile) {
    const removedPrintId = this.selectedPrintArea()?.productPrintId;
    if (removedPrintId && !this.deletedPrints.includes(removedPrintId) && !this.selectedPrintColor()) {
      this.deletedPrints.push(removedPrintId);
    }
    if (!this.selectedPrintColor()) {
      const printImage = new Image();
      printImage.src = file.full_path ?? '';
      const printAreaData = this.selectedPrintArea()!.data as PrintAreaData;
      printImage.onload = () => {
        const scale = this.getImageToAreaScale(printImage, printAreaData);

        const logoData = {
          ...this.printImageConfigBaseData,
          width: printImage.width || 50,
          height: printImage.height || 50,
          scaleX: scale,
          scaleY: scale,
          x: printAreaData.x,
          y: printAreaData.y
        };

        this.updateSelectedArea({
          libraryFileId: file.library_id,
          logo: file.full_path,
          libraryVersion: file.type,
          logoData,
          productPrintId: undefined
        });
      };
    } else {
      const config = this.selectedVariantConfig();
      if (config) {
        const printImage = new Image();
        printImage.src = file.full_path ?? '';
        const printAreaData = this.selectedPrintArea()!.data as PrintAreaData;
        printImage.onload = () => {
          const scale = this.getImageToAreaScale(printImage, printAreaData);
          const logoData = {
            width: printImage.width,
            height: printImage.height,
            scaleX: scale,
            scaleY: scale
          };

          const updatedConfig = this.specificColorPrintConfig().map(config => {
            if (config.variantId === this.selectedPrintColor()?.value) {
              return {
                ...config,
                logo: file.full_path,
                libraryFileId: file.id,
                libraryVersion: file.type,
                logoData: { ...config.logoData, ...logoData }
              };
            }
            return config;
          });
          this.specificColorPrintConfig.set(updatedConfig);
        };
      } else {
        const printImage = new Image();
        printImage.src = file.full_path ?? '';
        const printAreaData = this.selectedPrintArea()!.data as PrintAreaData;
        printImage.onload = () => {
          const scale = this.getImageToAreaScale(printImage, printAreaData);
          const logoData = {
            ...this.printImageConfigBaseData,
            width: printImage.width,
            height: printImage.height,
            scaleX: scale,
            scaleY: scale,
            x: printAreaData.x,
            y: printAreaData.y
          };

          this.specificColorPrintConfig.update(conf => {
            return [
              ...conf,
              {
                id: this.selectedPrintArea()?.id,
                logoData,
                libraryId: file.library_id,
                variantId: this.selectedPrintColor()!.value,
                logo: file.full_path,
                libraryFileId: file.id,
                libraryVersion: file.type
              }
            ];
          });
        };
      }
    }
  }

  getDefaultPrintVersion(library?: LibraryFile, fileName?: string): MockupFile | undefined {
    if (library) {
      const toSnakeCase = (camelCaseKey: string): string => {
        return camelCaseKey.replace(/([A-Z])/g, '_$1').toLowerCase();
      };
      const mockupName = library?.main_mockup ?? '';
      const mockupFormated = toSnakeCase(fileName ? fileName : mockupName);
      return library?.[mockupFormated];
    }
    return undefined;
  }
}
