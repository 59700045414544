import { SuppliersEnum } from 'app/_shared/interfaces/supplier';
import { AlphaBroaderProductMapper } from 'app/_shared/models/alpha-broader-product-mapper.model';
import { HitProductMapper } from 'app/_shared/models/hit-product-mapper.model';
import { SanmarProductMapper } from 'app/_shared/models/sanmar-product-mapper.model';
import { SsActiveProductMapper } from 'app/_shared/models/ssactive-product-mapper.model';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export const handleSupplierMapping = (slug: string, data: any) => {
  if (slug === SuppliersEnum.HIT) {
    return new HitProductMapper(data);
  } else if (slug === SuppliersEnum.SANMAR) {
    return new SanmarProductMapper(data);
  } else if (slug === SuppliersEnum.SS_ACTIVE) {
    return new SsActiveProductMapper(data);
  } else if (slug === SuppliersEnum.ALPHA_BROADER) {
    return new AlphaBroaderProductMapper(data);
  } else {
    return {} as SupplierProduct;
  }
};
