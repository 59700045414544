<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid">
    <div class="col-12 md:col-6">
      <label for="first_name">First Name</label>
      <input type="text" pInputText class="w-full" formControlName="first_name" />
      @if (
        submitted ||
        (form.get('first_name')?.invalid && (form.get('first_name')?.dirty || form.get('first_name')?.touched))
      ) {
        @if (form.get('first_name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">First Name is required</small>
          </div>
        }
      }
    </div>
    <div class="col-12 md:col-6">
      <label for="url">Last Name</label>
      <input type="text" pInputText class="w-full" formControlName="last_name" />
      @if (
        submitted ||
        (form.get('last_name')?.invalid && (form.get('last_name')?.dirty || form.get('last_name')?.touched))
      ) {
        @if (form.get('last_name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Last Name is required</small>
          </div>
        }
      }
    </div>
    <div class="col-12">
      <label for="business_name">Business name (optional)</label>
      <input type="text" pInputText class="w-full" />
    </div>
    <div class="col-12">
      <label for="email">Email</label>
      <input type="email" pInputText class="w-full" formControlName="email" />
      @if (submitted || (form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched))) {
        @if (form.get('email')?.errors?.['email']) {
          <div>
            <small class="p-text-danger">Email is not valied</small>
          </div>
        }
      }
    </div>
    <div class="col-12">
      <label for="address">Address</label>
      <input type="text" pInputText class="w-full" formControlName="address" />
      @if (
        submitted || (form.get('address')?.invalid && (form.get('address')?.dirty || form.get('address')?.touched))
      ) {
        @if (form.get('address')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Address is required</small>
          </div>
        }
      }
    </div>
    <div class="col-12 md:col-6">
      <div class="flex flex-column">
        <label for="country">Country</label>
        <p-dropdown
          [options]="countries"
          formControlName="country_id"
          optionValue="id"
          optionLabel="name"
          placeholder="Select a Country"
          [style]="{ width: '100%' }"
        />
      </div>
      @if (
        submitted ||
        (form.get('country_id')?.invalid && (form.get('country_id')?.dirty || form.get('country_id')?.touched))
      ) {
        @if (form.get('country_id')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Country is required</small>
          </div>
        }
      }
    </div>
    <div class="col-12 md:col-6">
      <div class="flex flex-column">
        <label for="state">State</label>
        <p-dropdown
          [options]="states"
          formControlName="state_id"
          optionValue="id"
          optionLabel="name"
          placeholder="Select a State"
          [style]="{ width: '100%' }"
        />
      </div>
      @if (
        submitted || (form.get('state_id')?.invalid && (form.get('state_id')?.dirty || form.get('state_id')?.touched))
      ) {
        @if (form.get('state_id')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">State is required</small>
          </div>
        }
      }
    </div>

    <div class="col-12 md:col-6">
      <label for="unit">Unit, suite, etc. (optional)</label>
      <input type="text" pInputText class="w-full" formControlName="unit" />
    </div>
    <div class="col-12 md:col-6">
      <label for="city">City</label>
      <input type="text" pInputText class="w-full" formControlName="city" />
      @if (submitted || (form.get('city')?.invalid && (form.get('city')?.dirty || form.get('city')?.touched))) {
        @if (form.get('city')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">City is required</small>
          </div>
        }
      }
    </div>

    <div class="col-12 md:col-6">
      <label for="zip_code">Zip Code</label>
      <input type="text" pInputText class="w-full" formControlName="zip_code" />
      @if (
        submitted || (form.get('zip_code')?.invalid && (form.get('zip_code')?.dirty || form.get('zip_code')?.touched))
      ) {
        @if (form.get('zip_code')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Zip code is required</small>
          </div>
        }
      }
    </div>

    <div class="col-12 md:col-6">
      <label for="phone_number">Phone Number</label>
      <input type="text" pInputText class="w-full" formControlName="phone" />
      @if (submitted || (form.get('phone')?.invalid && (form.get('phone')?.dirty || form.get('phone')?.touched))) {
        @if (form.get('phone')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Phone is required</small>
          </div>
        }
      }
    </div>
    <div class="col-12">
      <p-button label="Save" type="submit" />
    </div>
  </div>
</form>
