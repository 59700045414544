import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Button } from 'primeng/button';

@Component({
  selector: 'app-coming-soon',
  standalone: true,
  imports: [Button, RouterLink],
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.scss'
})
export class ComingSoonComponent {
  location = inject(Location);

  goBack() {
    this.location.back();
  }
}
