import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { Table, TableModule } from 'primeng/table';

import { CSVHelper } from 'app/_shared/helpers/csv-helper';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { ToastService } from 'app/_shared/services/toast.service';

type CSVRow = { name: string; email: string; amount: number; notify: boolean };

type RecipientForm = {
  name: string;
  email: string;
  amount: number;
  notify: boolean;
};

@Component({
  selector: 'app-recipients-management',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    TableModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    InputGroupAddonModule,
    InputGroupModule
  ],
  templateUrl: './recipients-management.component.html',
  styleUrl: './recipients-management.component.scss'
})
export class RecipientsManagementComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private toastService: ToastService = inject(ToastService);
  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  private dialogService: DialogService = inject(DialogService);

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  private instance: DynamicDialogComponent;

  notifyAll = false;
  form: FormGroup;

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      recipients: this.fb.array<RecipientForm>([])
    });

    (this.instance.data?.recipients || []).forEach((recipient: CSVRow) => this.addRecipient(recipient));
  }

  get recipients() {
    return this.form.get('recipients') as FormArray;
  }

  // Method to handle the CSV file selection
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (file) {
      CSVHelper.csvToJson<CSVRow>(file)
        .then((jsonData: CSVRow[]) => {
          if (this.cswRowValid(jsonData)) {
            jsonData.forEach((row: CSVRow) => this.addRecipient(row));
          } else {
            this.toastService.error('Invalid CSV file format');
          }
        })
        .catch(error => {
          this.toastService.error('Error reading CSV file');
          console.error('Error reading CSV file:', error);
        });
    } else {
      this.toastService.error('No file selected');
    }
  }

  // Method to download data as CSV
  downloadCSVTemplate(): void {
    const exampleData = [
      {
        name: 'John Doe',
        email: 'john.doe@example.net',
        amount: 200
      }
    ];
    const csvData = CSVHelper.jsonToCsv(exampleData);
    CSVHelper.downloadFile(csvData);
  }

  removeRow(i: number) {
    this.recipients.removeAt(i);
  }

  newRecipientRow(recipient?: CSVRow) {
    return this.fb.group({
      name: [recipient?.name || null, Validators.required],
      email: [recipient?.email || null, [Validators.required, Validators.email]],
      amount: [recipient?.amount || null, Validators.required],
      notify: [!!recipient?.notify]
    });
  }

  addRecipient(recipient?: CSVRow) {
    this.recipients.push(this.newRecipientRow(recipient));
  }

  closeModal() {
    this.ref.close();
  }

  submitModal() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    this.ref.close(this.form.value);
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  notifyAllChange() {
    const newValue = this.notifyAll;
    this.recipients.controls.forEach((formGroup: AbstractControl) => {
      if (formGroup.get('notify')?.value !== newValue) {
        formGroup.get('notify')?.setValue(newValue);
      }
    });
  }

  notifyChange() {
    this.notifyAll = this.recipients.controls.every((formGroup: AbstractControl) => formGroup.get('notify')?.value);
  }

  removeAllRecipients() {
    this.recipients.clear();
  }

  private cswRowValid(jsonData: CSVRow[]) {
    return jsonData.every((row: CSVRow) => {
      return row.name && row.email && row.amount;
    });
  }
}
