import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { finalize } from 'rxjs';

import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { StorefrontProductCategoryEditorComponent } from '../storefront-product-category-editor/storefront-product-category-editor.component';

@Component({
  selector: 'app-category-managment',
  standalone: true,
  imports: [ButtonModule, TableModule, SkeletonModule, ConfirmDialogModule],
  providers: [ConfirmationService, DialogService],
  templateUrl: './category-managment.component.html',
  styleUrl: './category-managment.component.scss'
})
export class CategoryManagmentComponent implements OnInit {
  categoryService = inject(StorefrontProductCategoryService);
  activatedRoute = inject(ActivatedRoute);
  dialogRef: DynamicDialogRef | undefined;
  dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);

  storefrontId: string;
  isLoading: boolean = false;
  isLoadingDelete: boolean = false;
  productCategories: StorefrontProductCategory[] = [];
  loadingRows: number[] = Array(6)
    .fill(0)
    .map((_, index) => index + 1);

  ngOnInit() {
    this.storefrontId = this.activatedRoute.snapshot.paramMap.get('storefrontId') ?? '';

    this.loadCategories();
  }

  loadCategories() {
    this.isLoading = true;
    this.categoryService
      .index(+this.storefrontId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.productCategories = res.data;
        },
        error: error => console.error(error)
      });
  }

  openCategoryEditor(category?: StorefrontProductCategory) {
    this.dialogRef = this.dialogService.open(StorefrontProductCategoryEditorComponent, {
      header: category ? 'Edit Category' : 'Add New Category',
      width: '38rem',
      height: 'auto',
      data: {
        category,
        storefrontId: +this.storefrontId,
        storeCategory: true
      }
    });
    this.dialogRef.onClose.subscribe((newCategory: StorefrontProductCategory | null) => {
      if (newCategory) {
        this.toastService.success(`Category ${category ? 'updated' : 'added'} successfully!`);
        this.loadCategories();
      }
    });
  }

  openDeleteModal(event: Event, id: number) {
    this.confirmationService.confirm({
      key: 'confirmDeleteCategory',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this category?',
      accept: () => {
        this.deleteCategoryOrSubcategory(id);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  deleteCategoryOrSubcategory(id: number) {
    this.isLoadingDelete = true;
    this.categoryService
      .deleteCategory(+this.storefrontId, id)
      .pipe(finalize(() => (this.isLoadingDelete = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Category successfully deleted!');
          this.loadCategories();
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }
}
