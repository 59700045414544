<div class="input-group">
  @if (label() && displayLabel()) {
    <label class="custom-input-label" [for]="name()" [ngStyle]="{ display: 'block' }">{{ label() }}</label>
  }

  <div class="input-wrap">
    <input
      [id]="id() || label()"
      class="custom-input"
      [type]="type()"
      [formControl]="inputControl"
      pInputText
      [placeholder]="placeholder()"
      [class.error]="control.invalid && (control.dirty || control.touched)"
      [ngClass]="{
        'w-full': fullWidth(),
        'p-disabled': readonly(),
        'input-append': appendSymbol()
      }"
      [readonly]="readonly()"
    />
    @if (appendSymbol()) {
      <div class="append mt-1 mb-2">{{ appendSymbol() }}</div>
    }
    @if (name() === 'password') {
      <div class="password-icon">
        <a href="#" class="flex" (click)="togglePasswordIcon($event)">
          <img width="16px" [src]="passwordIcon()" alt="Icon" />
        </a>
      </div>
    }
  </div>

  @if (control.invalid && (control.dirty || control.touched)) {
    @if (control.hasError('required')) {
      <div class="flex align-items-center">
        <small class="p-text-danger">{{ errorMessage() }}</small>
      </div>
    }
    @if (control.hasError('email')) {
      <div class="flex align-items-center">
        <small class="p-text-danger">{{ emailMessage() }}</small>
      </div>
    }
  }
</div>
