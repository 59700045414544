import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subscription } from 'rxjs';

import { User } from '../../_shared/models/User';
import { InviteService } from '../../_shared/services/invite.service';
import { ToastService } from '../../_shared/services/toast.service';
import { AuthStore } from '../../_store/auth.store';

@Component({
  selector: 'app-user-invite-status',
  standalone: true,
  imports: [ButtonModule, RouterLink, ProgressSpinnerModule],
  templateUrl: './user-invite-status.component.html'
})
export class UserInviteStatusComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  router = inject(Router);
  authStore = inject(AuthStore);

  inviteService = inject(InviteService);
  toastService = inject(ToastService);

  private subscriptions: Subscription[] = [];

  token: string;
  companyId: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;

      if (this.token) {
        this.checkUserInviteStatus();
      }
    });
  }

  checkUserInviteStatus() {
    this.subscriptions.push(
      this.inviteService.accept(this.token).subscribe({
        next: res => {
          this.loginUser(res.data.access_token, res.data.user);
        },
        error: err => {
          if (err.status === 400) {
            this.toastService.error('Invalid invite token. Please try again.');
            this.router.navigateByUrl('/auth/login');
          } else if (err.status === 432) {
            this.router.navigateByUrl(
              `/auth/register?token=${this.token}&email=${err.error.data.email}&companyId=${err.error.data.company_id}`
            );
          } else {
            console.error('Action failed:', err);
          }
        }
      })
    );
  }
  loginUser(accessToken: string, user: User) {
    this.authStore.setToken(accessToken);
    this.authStore.setAuthUser(user);
    this.router.navigateByUrl('/');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
