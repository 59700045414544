import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { TableLazyLoadEvent } from 'primeng/table';
import { finalize } from 'rxjs';

import {
  Columns,
  HeaderActions,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { StorefrontStore } from '../../../_store/storefront.store';
import { CreateProductComponent } from './create-product/create-product.component';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [CardModule, ListProductsComponent, ButtonModule, RippleModule, ConfirmDialogModule],
  providers: [ConfirmationService, DialogService],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent implements OnInit {
  storefrontService = inject(StorefrontService);
  activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  storefrontStore = inject(StorefrontStore);
  selectedProducts: StorefrontProduct[] | 'allSelected';
  dialogService = inject(DialogService);
  ref: DynamicDialogRef | undefined;
  isLoading: boolean = false;
  isLoadingDelete: boolean = false;
  storefrontId: string;
  companyId: string;
  storefrontProducts: StorefrontProduct[];
  cols: Columns[] = [
    { element: 'image', field: 'product_thumbnail', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '65%' },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '15%',
      actions: [
        {
          icon: 'pi pi-eye',
          label: '',
          severity: 'secondary',
          onClick: (event: MouseEvent, product: StorefrontProduct) => this.openProductPreview(event, product.id)
        },
        {
          icon: 'pi pi-pencil ',
          label: '',
          onClick: ($event: MouseEvent, product: StorefrontProduct) => {
            this.handleProductTypeEdit($event, product);
          }
        },
        {
          icon: 'pi pi-trash ',
          label: '',
          severity: 'danger',
          onClick: (event: MouseEvent, product: StorefrontProduct) => this.openDeleteModal(event, product)
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 5,
    first: 0
  };

  headerActions: HeaderActions[] = [
    {
      icon: 'pi pi-pen-to-square',
      label: 'Manage categories',
      onClick: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.router.navigate(['category-management'], { relativeTo: this.activatedRoute });
      }
    },
    {
      icon: 'pi pi-ticket',
      label: 'Create Custom Product',
      onClick: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.router.navigate(['custom', 'create'], { relativeTo: this.activatedRoute });
      }
    },
    {
      icon: 'pi pi-plus',
      label: 'Create Catalog Product',
      onClick: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.router.navigate(['create'], { relativeTo: this.activatedRoute });
      }
    }
  ];

  ngOnInit(): void {
    this.storefrontId = this.activatedRoute.snapshot.paramMap.get('storefrontId') ?? '';
    this.isLoading = true;
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoading = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1
    };
    this.storefrontService
      .getStorefrontProducts(this.storefrontId, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(products => {
        this.storefrontProducts = products.data.data;
        this.paginatorOptions = {
          total: products.data.total,
          rows: products.data.per_page,
          first: products.data.from - 1
        };
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchProducts($event);
  }

  handleSelectionChange($event: any) {
    this.selectedProducts = $event;
  }

  openProductPreview(event: MouseEvent, productId: number) {
    event.stopPropagation();
    const baseURL = this.storefrontStore.selectedStorefront()?.url;
    if (!baseURL) {
      return;
    }
    window.open(`${baseURL}/products/${productId}`, '_blank');
    // this.router.navigate([productId], { relativeTo: this.activatedRoute });
  }

  handleProductTypeEdit(event: MouseEvent, product: StorefrontProduct) {
    event.stopPropagation();
    if (product.is_custom) {
      this.router.navigate(['custom', product.id], { relativeTo: this.activatedRoute });
      return;
    }
    this.openCatalogProductEditModal(product.id);
  }

  openDeleteModal(event: MouseEvent, product: StorefrontProduct) {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete this product?',
      accept: () => {
        this.deleteProduct(product.id);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private deleteProduct(productId: number) {
    this.isLoadingDelete = true;
    this.storefrontService
      .deleteStorefrontProduct(+this.storefrontId, productId)
      .pipe(finalize(() => (this.isLoadingDelete = false)))
      .subscribe({
        next: () => {
          this.toastService.success('Product successfully deleted!');
          this.fetchProducts(this.paginatorOptions);
        },
        error: err => {
          console.error('Delete action failed:', err);
        }
      });
  }

  openCatalogProductEditModal(productId: number) {
    this.ref = this.dialogService.open(CreateProductComponent, {
      data: {
        productId: productId,
        editMode: true
      },
      focusOnShow: false,
      header: undefined, // 'Product Creator',
      showHeader: false,
      contentStyle: {
        padding: '0px',
        borderRadius: 0,
        width: '100%'
      }
    });
    this.ref.onClose.subscribe(res => {
      if (res) {
        this.fetchProducts(this.paginatorOptions);
      }
    });
  }
}
