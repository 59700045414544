import { JsonPipe, NgStyle } from '@angular/common';
import { AfterViewInit, Component, computed, input, OnDestroy, output, signal, ViewChild, effect } from '@angular/core';

import { Layer } from 'konva/lib/Layer';
import { KonvaEventObject } from 'konva/lib/Node';
import { KonvaComponent as KonvaComponentLib, CoreShapeComponent, NgKonvaEventObject, StageComponent } from 'ng2-konva';
import { ShapeConfigTypes } from 'ng2-konva/lib/utils/configTypes';
import { SkeletonModule } from 'primeng/skeleton';

import { PrintArea, PrintAreaData } from 'app/_shared/interfaces/catalog';

@Component({
  selector: 'app-konva',
  standalone: true,
  imports: [CoreShapeComponent, StageComponent, JsonPipe, SkeletonModule, NgStyle],
  templateUrl: './konva.component.html',
  styleUrl: './konva.component.scss'
})
export class KonvaComponent implements AfterViewInit, OnDestroy {
  onChange = output<KonvaEventObject<MouseEvent>>();
  @ViewChild('rect') rect: KonvaComponentLib;
  @ViewChild('stage') stage: StageComponent;
  @ViewChild('layer') layer: Layer;
  @ViewChild('transformer') transformer: CoreShapeComponent;
  public configStage = {
    width: 650,
    height: 650
  };
  printArea = input.required<PrintArea>();
  config = computed(() => this.printArea()?.data as PrintAreaData);
  transformConfig = signal<ShapeConfigTypes>({});

  configPatternImage: {
    x: number;
    y: number;
    width: number;
    height: number;
    image: HTMLImageElement | null;
    listening: boolean;
  } = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    image: null,
    // remove background from hit graph for better perf
    // because we don't need any events on the background
    listening: false
  };

  constructor() {
    effect(() => {
      const src = (this.printArea()?.data as PrintAreaData).image;
      if (src) {
        this.loadPatternImage(src);
      }
    });
  }

  loadPatternImage(src: string) {
    const imageObj = new Image();
    imageObj.src = src;
    imageObj.onload = () => {
      this.configStage = {
        width: imageObj.width,
        height: imageObj.height
      };
      this.configPatternImage = {
        ...this.configPatternImage,
        image: imageObj,
        width: imageObj.width,
        height: imageObj.height
      };
    };
  }

  ngAfterViewInit() {
    const rect = this.rect.getStage();
    this.transformConfig.set({ nodes: [rect] });
    rect.on('transformend', $event => this.onChange.emit($event));
  }
  ngOnDestroy() {
    this.transformConfig.set({ nodes: [] });
  }

  handleMove($event: NgKonvaEventObject<MouseEvent>) {
    this.onChange.emit($event.event);
  }
}
