<div class="forgot-password">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onFormSubmit()">
    <div class="mb-4 header">
      <h1>Forgot Password</h1>
      <h4>Enter your email to reset your password</h4>
    </div>
    <div class="mb-4">
      <app-input
        type="email"
        [control]="forgotPasswordForm.get('email')!"
        placeholder="Email"
        [fullWidth]="true"
        label="Email"
      />
    </div>
    <div class="mb-3">
      <button pButton pRipple label="Submit" class="w-full" type="submit" [disabled]="isLoading()"></button>
    </div>
    <div>
      <button pButton pRipple label="Cancel" class="w-full p-button-outlined" [routerLink]="['/']"></button>
    </div>
  </form>
</div>
