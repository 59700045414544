<app-list-products
  [products]="collections"
  [columns]="cols"
  [paginatorOptions]="paginatorOptions"
  (lazyLoadProducts)="getCollections($event)"
  [loading]="isLoading"
  [headerActions]="headerActions"
  emptyMessage="There are no collections"
></app-list-products>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
