<div class="product-creator">
  <div class="header">
    <h3>Product Creator</h3>
    <p-divider styleClass="divider" layout="vertical" />
    <p-button icon="pi pi-times" (click)="closeDialog()" text class="ml-auto" />
  </div>
  <div class="grid grid-nogutter content">
    <div class="col-2">
      <div class="info">
        <p class="product-name">{{ product()?.name }}</p>
        <p-divider styleClass="divider" layout="horizontal" />
        <p class="print-placement">Choose print placement</p>
        <div class="field">
          <p-dropdown
            [options]="printAreas()"
            optionLabel="name"
            [(ngModel)]="selectedPrintArea"
            (onChange)="handlePrintAreaChange($event)"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
        <p class="max-print-area">Max.Print Area</p>
        <div class="size-icon">
          <img ngSrc="assets/images/icons/vertical.png" alt="height" height="17" width="16" />
          <p class="text m-0">
            Height:
            <strong>{{ printAreaHeight() }} in</strong>
          </p>
        </div>
        <div class="size-icon">
          <img ngSrc="assets/images/icons/horizontal.png" alt="width" height="17" width="18" />
          <p class="text m-0">
            Width:
            <strong>{{ printAreaWidth() }} in</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="print-area">
        <div class="controls col-fixed mb-5">
          <div class="align-options">
            <p-buttonGroup>
              <p-button
                text
                icon="pi pi-align-left"
                size="large"
                (click)="horizontalAlign('left')"
                severity="secondary"
              ></p-button>
              <p-button
                text
                icon="pi pi-align-center"
                size="large"
                (click)="horizontalAlign('center')"
                severity="secondary"
              ></p-button>
              <p-button
                text
                icon="pi pi-align-right"
                size="large"
                (click)="horizontalAlign('right')"
                severity="secondary"
              ></p-button>
            </p-buttonGroup>
            <p-buttonGroup>
              <p-button
                text
                icon="pi pi-arrow-up"
                size="large"
                (click)="verticalAlign(true)"
                severity="secondary"
              ></p-button>
              <p-button
                text
                icon="pi pi-arrow-down"
                size="large"
                (click)="verticalAlign()"
                severity="secondary"
              ></p-button>
            </p-buttonGroup>
            <p-button text label="RESET" severity="secondary" (click)="resetChanges()"></p-button>
            <p-button text icon="pi pi-trash" size="large" (click)="removePrintImage()" severity="danger"></p-button>
          </div>
          <div class="size-input">
            <input
              pInputText
              id="name1"
              type="text"
              [style]="{ width: '70px', height: '35px' }"
              [value]="printImageTransformedWidth()"
              disabled
            />
            <input
              pInputText
              id="name2"
              type="text"
              [style]="{ width: '70px', height: '35px' }"
              [value]="printImageTransformedHeight()"
              disabled
            />
          </div>
        </div>
        <div class="col-12 overflow-x-auto" style="transform: rotateX(180deg)">
          <div class="flex flex-column" style="min-height: 650px; transform: rotateX(180deg)">
            <div
              class="mx-auto col-12"
              [ngStyle]="{ display: productImageConfig.image ? 'none' : 'block', width: '650px' }"
            >
              <p-skeleton width="650px" height="650px" [ngStyle]="{ position: 'absolute' }"></p-skeleton>
            </div>
            <ko-stage
              #stage
              [config]="configStage"
              class="my-canvas mx-auto col-fixed"
              [ngStyle]="{ visibility: productImageConfig.image ? 'visible' : 'hidden' }"
            >
              <ko-layer #layer>
                <ko-image [config]="productImageConfig"></ko-image>
                <ko-rect #printArea [config]="printAreaConfig" />
                <ko-transformer #transformer [config]="printImageTransformerConfig"></ko-transformer>
                <ko-group [config]="printImageGroupConfig">
                  <ko-image
                    #printImage
                    [config]="logoImageConfig()"
                    (dragend)="updatePrintImageAttributes($event.event)"
                  ></ko-image>
                </ko-group>
              </ko-layer>
            </ko-stage>
          </div>
        </div>
        <div class="col-12">
          @if (selectedPrintArea()?.library) {
            <p class="mb-0 text-center">Select logo version</p>
          }
          <div class="flex justify-content-center mb-3">
            <p-selectButton
              [(ngModel)]="selectedLogoVersion"
              [options]="logoVersions()"
              (onChange)="handleLogoVersionChange($event.value.file)"
              optionLabel="buttonName"
              [styleClass]="'selected-variants'"
              [allowEmpty]="false"
            ></p-selectButton>
          </div>
          <p-selectButton
            [(ngModel)]="selectedPrintColor"
            [options]="selectedProductVariants()"
            (onChange)="handlePrintVariantSelect($event)"
            optionLabel="name"
            [styleClass]="'selected-variants'"
          ></p-selectButton>
          <div class="flex justify-content-center mt-3">
            @if (!selectedPrintColor()) {
              <p>
                Changing print version and position will be apply to
                <b>ALL</b>
                product variants
              </p>
            } @else {
              <p>
                Changing print version and position will be applied only to
                <b>THIS</b>
                variant:
                <b>{{ selectedPrintColor()?.name }}</b>
              </p>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      @if (product() && storefrontId && selectedPrintArea() && productVariants()) {
        <app-product-details
          [product]="product()!"
          (handleFormSubmit)="onSubmit($event)"
          [editMode]="editMode"
          [selectedPrintArea]="selectedPrintArea()!"
          [storefrontId]="storefrontId"
          [productVariants]="productVariants()"
          [isSubmitting]="isSubmitting"
          (setProductImage)="setProductImage($event)"
          (handlePrintAreaImageUpload)="handlePrintAreaImageUpload($event)"
          (handleOpenMediaLibraryModal)="openMediaLibraryModal()"
          (selectAllVariants)="selectAllVariants($event)"
          (handleVariantSelect)="handleVariantSelect($event)"
        />
      }
    </div>
  </div>
</div>
