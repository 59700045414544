import { Route } from '@angular/router';

import { UserDetailsComponent } from 'app/_shared/components/user-details/user-details.component';
import { colorsResolver } from 'app/_shared/resolvers/colors.resolver';
import { suppliersResolver } from 'app/_shared/resolvers/suppliers.resolver';
import { suppliersColorsResolver } from 'app/_shared/resolvers/suppliersColors.resolver';
import { OrderDetailsComponent } from 'app/admin/orders/order-details/order-details.component';
import { CatalogProductCategoryManagementComponent } from 'app/superadmin/catalog/catalog-product-category-managment/catalog-product-category-management.component';
import { CatalogProductsListComponent } from 'app/superadmin/catalog/catalog-products-list.component';
import { CatalogSupplierListComponent } from 'app/superadmin/catalog/catalog-supplier-list/catalog-supplier-list.component';
import { CreateCatalogProductComponent } from 'app/superadmin/catalog/create-catalog-product/create-catalog-product.component';
import { EditCatalogProductComponent } from 'app/superadmin/catalog/edit-catalog-product/edit-catalog-product.component';
import { AddColorComponent } from 'app/superadmin/colors/add-color/add-color.component';
import { ColorsComponent } from 'app/superadmin/colors/colors.component';
import { EditColorComponent } from 'app/superadmin/colors/edit-color/edit-color.component';
import { FilePrepRequestListComponent } from 'app/superadmin/file-prep-request-list/file-prep-request-list.component';
import { InviteCompanyListComponent } from 'app/superadmin/invite-company-list/invite-company-list.component';
import { OrderListComponent as SuperadminOrderListComponent } from 'app/superadmin/order-list/order-list.component';
import { PaymentsComponent } from 'app/superadmin/payments/payments.component';
import { StorefrontListComponent as SuperadminStorefrontList } from 'app/superadmin/storefront-list/storefront-list.component';
import { ImportProductComponent } from 'app/superadmin/suppliers/import-product/import-product.component';
import { SupplierDetailsComponent } from 'app/superadmin/suppliers/supplier-details/supplier-details.component';
import { SupplierDictionaryComponent } from 'app/superadmin/suppliers/supplier-dictionary/supplier-dictionary.component';
import { SupplierProductViewComponent } from 'app/superadmin/suppliers/supplier-product-view/supplier-product-view.component';
import { SupplierProductComponent } from 'app/superadmin/suppliers/supplier-product/supplier-product.component';
import { SupplierProductsComponent } from 'app/superadmin/suppliers/supplier-products/supplier-products.component';
import { SupplierSettingsComponent } from 'app/superadmin/suppliers/supplier-settings/supplier-settings.component';
import { SuppliersComponent } from 'app/superadmin/suppliers/suppliers.component';
import { UserListComponent as SuperadminUserList } from 'app/superadmin/user-list/user-list.component';

// Prefix: ""
export const SuperAdminRoutes: Route[] = [
  {
    path: 'storefronts',
    data: { breadcrumb: 'Storefronts' },
    component: SuperadminStorefrontList
  },
  {
    path: 'orders',
    data: { breadcrumb: 'Orders' },
    children: [
      {
        path: '',
        component: SuperadminOrderListComponent
      },
      {
        path: ':orderId',
        component: OrderDetailsComponent,
        data: {
          superadminMode: true,
          breadcrumb: 'Order'
        }
      }
    ]
  },
  {
    path: 'users',
    data: { breadcrumb: 'Users' },
    children: [
      {
        path: '',
        component: SuperadminUserList
      },
      {
        path: ':id',
        component: UserDetailsComponent,
        data: { companyUser: false, breadcrumb: 'User' }
      }
    ]
  },
  {
    path: 'invites',
    component: InviteCompanyListComponent,
    data: { breadcrumb: 'Invites' }
  },
  {
    path: 'catalog',
    data: { breadcrumb: 'Catalog Products' },
    children: [
      { path: '', component: CatalogProductsListComponent },
      {
        path: 'create',
        component: CatalogSupplierListComponent,
        data: { breadcrumb: 'Create Catalog Product' }
      },
      {
        path: 'category-management',
        component: CatalogProductCategoryManagementComponent,
        data: { breadcrumb: 'Categories' }
      },
      {
        path: 'create/:slug/products/:id',
        component: CreateCatalogProductComponent,
        data: { breadcrumb: 'Create Catalog Product' }
      },
      {
        path: ':id',
        // TODO not implemented preview, uncomment after implementation
        // data: { breadcrumb: 'Catalog Product' },
        children: [{ path: 'edit', component: EditCatalogProductComponent, data: { breadcrumb: 'Edit' } }]
      }
    ]
  },
  {
    path: 'suppliers',
    resolve: { suppliers: suppliersResolver },
    runGuardsAndResolvers: 'always',
    data: { breadcrumb: 'Suppliers' },
    children: [
      {
        path: '',
        component: SuppliersComponent
      },
      {
        path: ':slug',
        data: { breadcrumb: 'Supplier details' },
        component: SupplierDetailsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'settings'
          },
          {
            path: 'settings',
            component: SupplierSettingsComponent
          },
          {
            path: 'dictionary',
            component: SupplierDictionaryComponent
          }
        ]
      },
      {
        path: ':slug/products',
        data: { breadcrumb: 'Supplier' },
        children: [
          {
            path: '',
            component: SupplierProductsComponent
          },
          {
            path: 'import',
            component: ImportProductComponent,
            data: { breadcrumb: 'Import Product' }
          },
          {
            path: ':id',
            component: SupplierProductViewComponent,
            data: { breadcrumb: 'Product' }
          },
          {
            path: ':id/create',
            component: SupplierProductComponent,
            data: { breadcrumb: 'Create Catalog Product' }
          }
        ]
      }
    ]
  },
  {
    path: 'colors',
    runGuardsAndResolvers: 'always',
    resolve: {
      colors: colorsResolver,
      suppliers: suppliersColorsResolver
    },
    children: [
      {
        path: '',
        component: ColorsComponent
      },
      {
        path: 'create',
        component: AddColorComponent
      },
      {
        path: ':id',
        component: EditColorComponent
      }
    ]
  },
  {
    path: 'payments',
    component: PaymentsComponent
  },
  {
    path: 'file-prep-requests',
    component: FilePrepRequestListComponent
  }
];
