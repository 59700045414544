@if (variant) {
  <p-dialog
    [(visible)]="visible"
    [style]="{ width: '32rem' }"
    header="Variant Details"
    [modal]="true"
    class="p-fluid"
    (onHide)="hideDialog()"
  >
    <ng-template pTemplate="content">
      <form [formGroup]="variantForm" (ngSubmit)="onSubmit()">
        <div class="field">
          <label for="color">Color</label>
          <input id="color" type="text" pInputText formControlName="color" />
          @if ((submitted || variantForm.get('color')?.touched) && variantForm.get('color')?.errors?.['required']) {
            <div>
              <small class="p-text-danger">Color is required</small>
            </div>
          }
        </div>
        <div class="flex justify-content-center gap-5 flex-wrap">
          <div class="flex flex-column align-items-center">
            @if (frontImage) {
              <img [src]="frontImage" alt="Front Image" style="object-fit: contain" width="100" height="125" priority />
            } @else {
              <div class="noMediaContainer">
                <p>Front Image</p>
              </div>
            }
            <div class="flex justify-content-between w-full mt-2">
              <p-button icon="pi pi-pencil" outlined (click)="fileInputFront.click()"></p-button>
              <p-button
                icon="pi pi-trash"
                outlined
                severity="danger"
                (click)="openDeleteMediaModal('front')"
              ></p-button>
            </div>
            <input
              #fileInputFront
              type="file"
              (change)="onFileChange($event, 'front')"
              accept="image/*"
              style="display: none"
            />
          </div>
          <div class="flex flex-column align-items-center">
            @if (rearImage) {
              <img [src]="rearImage" alt="Rear Image" style="object-fit: contain" width="100" height="125" priority />
            } @else {
              <div class="noMediaContainer">
                <p>Rear Image</p>
              </div>
            }
            <div class="flex justify-content-between w-full mt-2">
              <p-button icon="pi pi-pencil" outlined (click)="fileInputRear.click()"></p-button>
              <p-button icon="pi pi-trash" outlined severity="danger" (click)="openDeleteMediaModal('rear')"></p-button>
            </div>
            <input
              #fileInputRear
              type="file"
              (change)="onFileChange($event, 'rear')"
              accept="image/*"
              style="display: none"
            />
          </div>
        </div>
        <div class="mt-2">
          <div formArrayName="product_variant_sizes" class="item-sizes">
            <p>Sizes</p>
            <p-table [value]="variantSizes.controls">
              <ng-template pTemplate="header">
                <tr>
                  <th>Size</th>
                  <th>Weight</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-size let-i="rowIndex">
                <tr>
                  <td>
                    <app-input
                      label="Size"
                      [displayLabel]="false"
                      [control]="size.get('size')"
                      placeholder=""
                    ></app-input>
                  </td>
                  <td>
                    <app-input
                      label="Weight"
                      [displayLabel]="false"
                      [control]="size.get('weight')"
                      placeholder=""
                    ></app-input>
                  </td>
                  <td>
                    <app-input
                      label="Price"
                      [displayLabel]="false"
                      [control]="size.get('price')"
                      placeholder=""
                    ></app-input>
                  </td>
                  <td>
                    <app-input
                      label="Quantity"
                      [displayLabel]="false"
                      [control]="size.get('quantity')"
                      placeholder=""
                    ></app-input>
                  </td>
                  <td>
                    <p-button
                      (click)="removeSize(i)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger"
                    />
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div class="w-full flex justify-content-start mt-2">
              <p-button
                outlined
                size="small"
                icon="pi pi-plus"
                type="button"
                label="Add variant size"
                (click)="addSize()"
              ></p-button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
        outlined
      ></p-button>
      <p-button type="button" label="Save" icon="pi pi-check" class="p-button-text" (click)="onSubmit()"></p-button>
    </ng-template>
  </p-dialog>
}
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteMedia"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
