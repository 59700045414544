<div class="card">
  <h5>Transactions</h5>
  <p-table
    #dt
    [value]="transactions"
    [lazy]="true"
    (onLazyLoad)="loadTransactions($event)"
    dataKey="id"
    [paginator]="true"
    [rows]="10"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [showLoader]="false"
    responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[10, 25, 50]"
    [globalFilterFields]="['status']"
    [expandedRowKeys]="expandedRows"
  >
    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="col-12 sm:col-6 md:col-4 xl:col-2">
          <p-dropdown
            [options]="storefronts"
            placeholder="Filter by Store"
            optionLabel="name"
            optionValue="id"
            [(ngModel)]="selectedStorefront"
            (onChange)="onFilterChange('storefront_id', $event.value)"
            [showClear]="true"
          >
            <ng-template let-option>
              <div class="p-multiselect-representative-option">
                <span class="ml-2">{{ option.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem">
          <p-button
            type="button"
            [icon]="isAllExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="toggleExpandAll()"
            tooltip="Expand/Collapse All"
            [plain]="true"
            [text]="true"
          ></p-button>
        </th>
        <th style="min-width: 3rem">
          <div class="flex justify-content-between align-items-center">ID</div>
        </th>
        <th style="min-width: 6rem">
          <div class="flex justify-content-between align-items-center">Created at</div>
        </th>
        <th style="min-width: 8rem">
          <div class="flex justify-content-between align-items-center">Type</div>
        </th>
        <th style="min-width: 8rem">
          <div class="flex justify-content-between align-items-center">Balance</div>
        </th>
        <th style="min-width: 8rem">
          <div class="flex justify-content-between align-items-center">Status</div>
        </th>

        <th style="min-width: 6rem">
          <div class="flex justify-content-between align-items-center">Actions</div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-expanded="expanded">
      <tr class="cursor-pointer">
        <td>
          <p-button
            type="button"
            pRipple
            [pRowToggler]="transaction"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          />
        </td>
        <td>{{ transaction.id }}</td>
        <td>{{ transaction.created_at | usdDate }}</td>
        <td>{{ transaction.type }}</td>
        <td>{{ transaction.transaction_amount_balance | usdPipe }}</td>
        <td>{{ transaction.status || 'In progress' }}</td>
        <td>
          <div class="flex gap-2">
            <p-button icon="pi pi-eye" severity="secondary" outlined></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-transaction>
      <tr>
        <td colspan="12">Additional info</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="text-center">There are no transactions</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      @for (row of loadingRows; track row) {
        <tr>
          @for (col of ['id', 'created_at', 'type', 'transaction_amount_balance', 'status', 'actions']; track col) {
            <td>
              <p-skeleton />
            </td>
          }
        </tr>
      }
    </ng-template>
  </p-table>
</div>
