<app-list-products
  [products]="storefrontProducts"
  [columns]="cols"
  [paginatorOptions]="paginatorOptions"
  (lazyLoadProducts)="handleLazyLoadProducts($event)"
  [loading]="isLoading"
  [multiSelect]="true"
  (onSelectionChange)="handleSelectionChange($event)"
></app-list-products>
<div class="flex justify-content-end gap-1">
  <p-button outlined type="button" label="Back" (onClick)="closeDialog()"></p-button>
  <p-button type="button" label="Confirm" (onClick)="onFinishSelection()"></p-button>
</div>
