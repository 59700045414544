import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';

import { MenuItem } from 'primeng/api';

import { CompanyStore } from 'app/_store/company.store';

import { Storefront } from '../../../_shared/models/Storefront';
import { AuthStore } from '../../../_store/auth.store';
import { StorefrontStore } from '../../../_store/storefront.store';
import { NavItemComponent } from './nav-item/nav-item.component';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [NavItemComponent],
  templateUrl: './nav.component.html',
  styles: ''
})
export class NavComponent implements OnInit, OnDestroy {
  public storefrontStore = inject(StorefrontStore);
  public authStore = inject(AuthStore);
  public companyStore = inject(CompanyStore);

  model: MenuItem[] = [];
  public selectedStorefront?: Storefront | null = undefined;

  private storefrontEffect = effect(() => {
    this.selectedStorefront = this.storefrontStore?.selectedStorefront?.();
    this.buildMenu();
  });

  ngOnInit() {
    this.buildMenu();
  }

  ngOnDestroy() {
    this.storefrontEffect.destroy();
  }

  buildMenu() {
    const menuItems: MenuItem[] = [
      {
        label: 'Dashboard',
        icon: 'pi pi-objects-column',
        routerLink: '/'
      }
    ];

    if (this.companyStore.workingCompany()) {
      menuItems.push(
        ...[
          {
            label: 'Company',
            icon: 'pi pi-building-columns',
            routerLink: ['/companies', this.companyStore.workingCompany()?.id, 'management']
          },
          {
            label: 'Storefronts',
            icon: 'pi pi-warehouse',
            routerLink: ['/companies', this.companyStore.workingCompany()?.id, 'storefronts']
          },
          {
            label: 'Orders',
            icon: 'pi pi-box',
            routerLink: ['companies', this.companyStore.workingCompany()?.id, 'orders']
          }
        ]
      );

      if (this.selectedStorefront) {
        menuItems.push({
          label: 'Storefront #1',
          items: this.getStorefrontItems(this.selectedStorefront.id)
        });
      }
    }

    this.model = menuItems;
  }

  getStorefrontItems(storefrontId: number): MenuItem[] {
    return [
      {
        label: 'Products',
        icon: 'pi pi-shopping-bag',
        routerLink: ['companies', this.companyStore.workingCompany()?.id, 'storefronts', storefrontId, 'products']
      },
      {
        label: 'Design',
        icon: 'pi pi-palette',
        routerLink: ['companies', this.companyStore.workingCompany()?.id, 'storefronts', storefrontId, 'designer']
      },
      {
        label: 'Settings',
        icon: 'pi pi-cog',
        routerLink: ['companies', this.companyStore.workingCompany()?.id, 'storefronts', storefrontId, 'settings']
      }
    ];
  }
}
