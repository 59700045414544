import { Component, effect, ElementRef, inject, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';

import { CompanyStore } from 'app/_store/company.store';

import { Company } from '../../../_shared/models/Company';
import { LayoutService } from '../../../_shared/services/layout.service';
import { AuthStore } from '../../../_store/auth.store';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink, NavComponent],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  layoutService: LayoutService = inject(LayoutService);
  el: ElementRef = inject(ElementRef);
  public authStore = inject(AuthStore);
  public companyStore = inject(CompanyStore);

  @ViewChild('menuContainer') menuContainer!: ElementRef;
  timeout: any = null;

  public selectedWorkingCompany: Company | null = null;

  private workingCompanyEffect = effect(
    () => {
      this.selectedWorkingCompany = this.companyStore.workingCompany();
    },
    { allowSignalWrites: true }
  );

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(() => (this.layoutService.state.sidebarActive = false), 300);
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }
}
