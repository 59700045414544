export enum OrderStatus {
  NEW_ORDER = 1,
  IN_PROGRESS = 2,
  SHIPPED = 3,
  PARTIALLY_SHIPPED = 4,
  CANCELED = 5,
  ON_HOLD = 6,
  NOT_PAID = 7,
  REFUND = 8
}
