<div class="flex justify-content-end align-items-center mb-5">
  <p-button label="Apply changes" (onClick)="applyChanges()" [loading]="isLoading()" />
</div>
<div class="grid">
  <div class="col-12 xl:col-3 md:col-12 flex-order-0 lg:flex-order-0">
    <app-pages-list [(selectedPage)]="selectedPage" (onSetIframeUrl)="handleSetIframeUrl($event)" />
    <div class="mt-4 mb-4">
      <app-menu-list />
    </div>
  </div>
  <div class="col-12 xl:col-6 md:col-12 flex-order-2 lg:flex-order-1">
    <p-card [style]="{ minHeight: '1000px' }">
      @if (iframeUrl) {
        <app-iframe [iframeUrl]="iframeUrl"></app-iframe>
      }
    </p-card>
  </div>
  <div class="col-12 xl:col-3 md:col-12 flex-order-1 lg:flex-order-2">
    <p-card>
      @if (selectedPage.length) {
        <app-page-builder [selectedPage]="selectedPage[0]" />
      } @else {
        <p>Select page on the left to start editing it</p>
      }
    </p-card>
    <p-dialog
      header="Add page section"
      [(visible)]="addSectionDialog"
      [modal]="true"
      showEffect="fade"
      [style]="{ width: '30vw' }"
      [breakpoints]="{ '960px': '75vw' }"
    >
      <div class="new-page"></div>
      <ng-template pTemplate="footer">
        <button pButton icon="pi pi-check" type="submit" label="Ok" class="p-button-outlined"></button>
      </ng-template>
    </p-dialog>
  </div>
  <p-toast />
  <p-confirmDialog />
</div>
