import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { environment } from 'environments/environment';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { finalize, Subscription } from 'rxjs';

import { CustomValidators } from 'app/_shared/services/custom-validators.service';

import { Storefront } from '../../models/Storefront';
import { StorefrontService } from '../../services/storefront.service';
import { ToastService } from '../../services/toast.service';

interface Logo {
  file: File | null;
  objectURL: string | null;
  name: string | null;
}
@Component({
  selector: 'app-edit-storefront-dialog',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    DialogModule,
    CommonModule,
    ReactiveFormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    FileUploadModule
  ],
  templateUrl: './edit-storefront-dialog.component.html',
  styleUrl: './edit-storefront-dialog.component.scss'
})
export class EditStorefrontDialogComponent implements OnChanges {
  protected prefix = environment.PREFIX;
  protected suffix = environment.SUFFIX;
  storefrontService = inject(StorefrontService);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);

  @Input() storefront: Storefront;
  @Input() visible: boolean;
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];

  storefrontForm: FormGroup;
  submitted = false;
  isLoading = false;
  formReady = false;

  logo: Logo = {
    file: null,
    objectURL: null,
    name: null
  };

  ngOnChanges() {
    if (this.visible && this.storefront) {
      this.buildForm();
      this.initLogo();
    }
  }

  buildForm() {
    this.storefrontForm = this.fb.group({
      id: this.storefront?.id,
      name: [this.storefront?.name, [Validators.required]],
      subdomain: [this.storefront?.subdomain, [Validators.required, CustomValidators.validateSubdomain()]]
    });
    this.formReady = true;
  }

  initLogo() {
    if (this.storefront.logo) {
      const { name, full_path } = this.storefront.logo;
      this.logo = { name, file: null, objectURL: full_path };
    }
  }

  hideDialog() {
    this.cancelEdit.emit();
  }

  onLogoUpload(event: FileUploadEvent | FileSelectEvent) {
    const file = event.files[0];
    const fileName = event.files[0].name;
    const objectURL = URL.createObjectURL(file);
    this.logo.file = file;
    this.logo.objectURL = objectURL;
    this.logo.name = fileName;
  }

  removeLogo(): void {
    this.logo.file = null;
    this.logo.objectURL = null;
    this.toastService.success(`Logo successfully removed.`);
  }

  onSubmit() {
    if (this.storefrontForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const submitData = this.prepareSubmitData();
    this.updateStorefront(submitData);
  }

  prepareSubmitData() {
    const formData = new FormData();
    const formValue = this.storefrontForm.value;
    formData.append('id', formValue.id);
    formData.append('name', formValue.name);
    formData.append('subdomain', formValue.subdomain);
    if (this.logo.file) {
      formData.append('logo', this.logo.file);
    }
    if (this.storefront.logo && !this.logo.objectURL) {
      formData.append('delete_logo', 'true');
    }
    formData.append('_method', 'put');

    return formData;
  }
  updateStorefront(submitData: FormData) {
    this.isLoading = true;

    this.storefrontService
      .update(this.storefrontForm.value.id, submitData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.toastService.success('Successfully updated storefront!');
          this.visible = false;
          this.save.emit();
        },
        error: err => {
          console.error('Update failed:', err);
        }
      });
  }
}
