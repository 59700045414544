<div class="card">
  <a [routerLink]="['../../']">Back</a>
  <h3>{{ selectionId ? 'Edit' : 'Create' }} selection</h3>
  @if (selectionForm) {
    <form [formGroup]="selectionForm" (ngSubmit)="handleSubmit()">
      <div class="new-selection">
        <app-input
          name="Title"
          label="Selection name"
          [control]="selectionForm.get('title')!"
          placeholder="Selection name"
        ></app-input>
        <p class="m-0">Upload logo</p>
        <p-fileUpload
          #fileUploader
          (onSelect)="onUpload($event)"
          [customUpload]="true"
          accept="image/*"
          [showUploadButton]="false"
          [showCancelButton]="false"
          [auto]="true"
          styleClass="border-1 surface-border surface-card p-0 border-round mb-4"
          [style]="{ width: '300px' }"
        >
          <ng-template pTemplate="content">
            <div class="h-20rem m-1 border-round">
              @if (!logo) {
                <div
                  class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                  (click)="fileUploader.advancedFileInput.nativeElement.click()"
                >
                  <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                  <span class="block font-semibold text-900 text-lg mt-3">Drop or select selection logo</span>
                </div>
              }
              @if (logo) {
                <div class="h-full relative border-round p-0 text-center">
                  <img [src]="logo" class="border-round text-center logo" alt="blog cover" />
                  <button
                    pButton
                    type="button"
                    aria-label="remove"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                    style="top: -10px; right: -10px"
                    (click)="$event.stopPropagation(); removeImage()"
                  ></button>
                </div>
              }
            </div>
          </ng-template>
        </p-fileUpload>
        <app-input
          name="Comment"
          label="Comment"
          [control]="selectionForm.get('comment')!"
          placeholder="Comment"
        ></app-input>
      </div>
      <p>Add collections</p>
      <app-list-products
        [products]="collections"
        [columns]="cols"
        [paginatorOptions]="paginatorOptions"
        (lazyLoadProducts)="getCollections($event)"
        [multiSelect]="true"
        [loading]="false"
        [(selectedItems)]="selectedItems"
        (onSelectionChange)="handleSelectionChange($event)"
        emptyMessage="There are no collections"
      ></app-list-products>
      <div class="flex justify-content-end mt-2">
        <button
          [disabled]="!selectionForm.valid || !selectedItems.length"
          pButton
          icon="pi pi-check"
          type="submit"
          label="Save"
        ></button>
      </div>
    </form>
  }
</div>
