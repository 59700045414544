import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateHelperService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getDateFormat(): string {
    switch (this.locale) {
      case 'en-US':
        return 'mm/dd/yy';
      case 'en-GB':
        return 'dd/mm/yy';
      case 'de-DE':
        return 'dd.mm.yy';
      default:
        return 'yy-mm-dd';
    }
  }

  parseDateString(dateString: string | undefined): Date | null {
    return dateString ? new Date(dateString) : null;
  }
}
