<form [formGroup]="forgotPasswordForm" (ngSubmit)="onFormSubmit()">
  <div class="mb-4">
    <div class="text-900 text-xl font-bold mb-2">Forgot Password</div>
    <span class="text-600 font-medium">Enter your email to reset your password</span>
  </div>
  <div class="flex flex-column">
    <span class="p-input-icon-left w-full mb-2">
      <i class="pi pi-envelope"></i>
      <input
        id="email"
        type="text"
        [ngClass]="{
          'ng-dirty':
            forgotPasswordForm.get('email')?.invalid &&
            (forgotPasswordForm.get('email')?.dirty || forgotPasswordForm.get('email')?.touched)
        }"
        formControlName="email"
        pInputText
        class="w-full md:w-25rem"
        placeholder="Email"
      />
    </span>
    @if (
      submitted() ||
      (forgotPasswordForm.get('email')?.invalid &&
        (forgotPasswordForm.get('email')?.dirty || forgotPasswordForm.get('email')?.touched))
    ) {
      @if (forgotPasswordForm.get('email')?.errors?.['required']) {
        <div>
          <small class="p-text-danger">Email is required field</small>
        </div>
      }
      @if (forgotPasswordForm.get('email')?.errors?.['email']) {
        <div><small class="p-text-danger">Must be valid email</small></div>
      }
    }
    <div class="flex flex-wrap gap-2 justify-content-between mt-4">
      <button pButton pRipple label="Cancel" class="flex-auto p-button-outlined" [routerLink]="['/']"></button>
      <button pButton pRipple label="Submit" class="flex-auto" type="submit" [disabled]="isLoading()"></button>
    </div>
  </div>
</form>
