import { StorefrontProduct } from '../interfaces/storefront';
import { StorefrontProductCategory } from './StorefrontProductCategory';

export type CouponType = 'Gift Card' | 'Promo Code';
export type GiftCardCodeStatus = 'Active' | 'Inactive' | 'Expired' | 'Used';
export enum PromoCodeType {
  FREE_SHIPPING = 'free_shipping',
  PRODUCT = 'product',
  CATEGORY = 'category',
  ORDER = 'order'
}

export enum PromoCodeValueType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage'
}

export abstract class Coupon {
  name: string;
  // value_used: number;

  created_at: string;
  expires?: string;
}

export class GiftCard extends Coupon {
  id: number;
  codes: GiftCardCode[];
  number_of_codes: GiftCardCodesNumber;
  cover_shipping: boolean;
  cover_sales_tax: boolean;
  specified_recipients: boolean;
  single_use: boolean;
  expiration_date: string | null;
}

export class GiftCardCode {
  id?: number;
  name: string;
  email: string;
  amount: number;
  balance: number;
  notify: boolean;
  status: GiftCardCodeStatus;
  gift_card?: GiftCard;
  gift_card_id: number;
  gift_card_tier_id: number;
}
export class GiftCardCodesNumber {
  active: number;
  deleted: number;
  expired: number;
  total: number;
  used: number;
}

export class GiftCardCustomMail {
  id: number;
  type: string;
  data: CustomMailData;
}

export class CustomMailData {
  button_text: string;
  email_subject: string;
  goodbye_message: string;
  greeting: string;
  line_1: string;
  line_2: string;
  line_3: string;
  sender: string;
  template_name: string;
}
export class PromoCode extends Coupon {
  id?: number;
  code: string;
  type: PromoCodeType;
  number_of_uses: number;
  minimum_order_amount: number;
  product_categories: StorefrontProductCategory[];
  per_customer: boolean;
  start_date: string;
  end_date: string;
  products: StorefrontProduct[];
  promo_code_value_type: PromoCodeValueType;
  promo_code_value: number;
}
