import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';

import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { Color } from 'app/_shared/interfaces/colors';
import { CatalogService } from 'app/_shared/services/catalog.service';

export const colorsResolver: ResolveFn<IApiResponse<Color[]> | boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(CatalogService).getColors();
};
