import { NgClass, NgIf } from '@angular/common';
import { Component, computed, inject, OnInit, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor, EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';

import { Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

@Component({
  selector: 'app-text-image',
  standalone: true,
  imports: [
    ButtonModule,
    FileUploadModule,
    NgIf,
    SharedModule,
    RadioButtonModule,
    FormsModule,
    NgClass,
    EditorModule,
    ReactiveFormsModule
  ],
  templateUrl: './text-image.component.html',
  styleUrl: './text-image.component.scss'
})
export class TextImageComponent implements OnInit {
  @ViewChild('editor') editor: Editor;
  private designerService: DesignerService = inject(DesignerService);
  private designerStore = inject(DesignerStore);
  public storefrontStore = inject(StorefrontStore);
  designerHelperService = inject(DesignerHelperService);

  image: any;
  formGroup: FormGroup;
  selectedPage: Page;
  selectedSection: Section;

  imagePosition = signal<string>('left');
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);

  constructor(
    private dynamicConfig: DynamicDialogConfig,
    private fb: FormBuilder,
    public ref: DynamicDialogRef
  ) {
    this.selectedPage = this.dynamicConfig.data.selectedPage;
    this.selectedSection = this.dynamicConfig.data.section;
  }

  ngOnInit() {
    const value = this.dynamicConfig.data.section.content?.text;
    this.formGroup = this.fb.group({
      text: new FormControl(value ?? '')
    });

    this.image = this.dynamicConfig.data.section.content?.img;
    this.imagePosition.set(this.dynamicConfig.data.section.content.imagePosition);
  }

  onUpload(event: any) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append('image', file);
    this.designerService.uploadSectionImage(formData, this.storefrontId()!).subscribe(next => {
      this.image = {
        url: next.data.full_path,
        id: next.data.id
      };
    });
  }

  removeImage() {
    this.designerService.removeSectionImage(this.storefrontId()!, this.image.id).subscribe(next => {
      this.image = null;
      const pages = this.designerHelperService.saveSectionData(this.selectedPage, this.selectedSection, {
        img: this.image,
        text: this.formGroup.value.text,
        imagePosition: this.imagePosition()
      });
      this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
        this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      });
    });
  }

  setDefaultValue() {
    const value = this.dynamicConfig.data.section.content?.text;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }

  onSave() {
    const pages = this.designerHelperService.saveSectionData(this.selectedPage, this.selectedSection, {
      img: this.image,
      text: this.formGroup.value.text,
      imagePosition: this.imagePosition()
    });
    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next, () => this.ref.close());
    });
  }
}
