import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';



import { Button } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';



import { InputComponent } from 'app/_shared/components/input/input.component';
import { Supplier } from 'app/_shared/interfaces/supplier';
import { SupplierService } from 'app/_shared/services/supplier.service';
import { ToastService } from 'app/_shared/services/toast.service';


@Component({
  selector: 'app-supplier-settings',
  standalone: true,
  imports: [Button, InputComponent, PaginatorModule, ReactiveFormsModule],
  templateUrl: './supplier-settings.component.html',
  styleUrl: './supplier-settings.component.scss'
})
export class SupplierSettingsComponent implements OnInit {
  private supplierService = inject(SupplierService);
  private activatedRoute = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private toastService = inject(ToastService);

  supplier: Supplier;
  form: FormGroup;

  ngOnInit() {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.supplierService.getSupplierBySlug(slug).subscribe(next => {
      this.supplier = next.data;
      this.form = this.fb.group({
        margin: [this.supplier?.price_margin ?? '']
      });
    });
  }

  handleSubmit() {
    this.supplierService.updateSupplier(this.supplier.id, { price_margin: this.form.value.margin }).subscribe(() => {
      this.toastService.success('Supplier updated!');
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    });
  }
}
