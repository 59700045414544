import { Route } from '@angular/router';

import { ComingSoonComponent } from 'app/_shared/components/coming-soon/coming-soon.component';
import { CompanySelectDashboardComponent } from 'app/_shared/components/company-select-dashboard/company-select-dashboard.component';
import { UserAccountComponent } from 'app/_shared/components/user-account/user-account.component';
import { authGuard } from 'app/_shared/guards/auth.guard';
import { superAdminGuard } from 'app/_shared/guards/super-admin.guard';
import { companyResolver } from 'app/_shared/resolvers/company-resolver';
import { companyBreadcrumbResolver } from 'app/_shared/resolvers/companyBreadcrumb.resolver';
import { CompanyComponent } from 'app/admin/company/company.component';
import { CompanyRoutes } from 'app/routes/company.routes';
import { SuperAdminRoutes } from 'app/routes/superAdmin.routes';

export const AdminRoutes: Route[] = [
  // Auth User Routes
  {
    path: '',
    component: CompanySelectDashboardComponent,
    data: { breadcrumb: 'Dashboard' }
  },
  { path: 'account', component: UserAccountComponent },
  { path: 'coming-soon', component: ComingSoonComponent, data: { breadcrumb: 'Coming soon' } },

  // Super Admin Routes
  {
    path: '',
    canActivate: [superAdminGuard],
    children: SuperAdminRoutes
  },

  // Company Routes
  {
    path: 'companies/:id',
    component: CompanyComponent,
    canActivate: [authGuard],
    resolve: { company: companyResolver, breadcrumb: companyBreadcrumbResolver },
    data: { breadcrumb: 'Company' },
    children: CompanyRoutes
  }
];
