import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';

import { DateHelperService } from 'app/_shared/helpers/date-helper.service';
import { StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { PromoCode, PromoCodeType, PromoCodeValueType } from 'app/_shared/models/Coupon';
import { StorefrontProductCategory } from 'app/_shared/models/StorefrontProductCategory';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { markAllAsTouched } from 'app/_shared/services/helper.service';
import { PromoCodeService } from 'app/_shared/services/promo-code.service';
import { StorefrontProductCategoryService } from 'app/_shared/services/storefront-product-category.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { PromoCodeProductListSelectionComponent } from '../promo-code-product-list-selection/promo-code-product-list-selection.component';

@Component({
  selector: 'app-promo-code-editor',
  standalone: true,
  imports: [
    RadioButtonModule,
    ButtonModule,
    InputTextModule,
    CommonModule,
    FormsModule,
    CheckboxModule,
    CalendarModule,
    InputNumberModule,
    SelectButtonModule,
    MultiSelectModule,
    ReactiveFormsModule
  ],
  providers: [DialogService, DatePipe],
  templateUrl: './promo-code-editor.component.html',
  styleUrl: './promo-code-editor.component.scss'
})
export class PromoCodeEditorComponent implements OnInit {
  private categoryService: StorefrontProductCategoryService = inject(StorefrontProductCategoryService);
  private fb: FormBuilder = inject(FormBuilder);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private dialogService: DialogService = inject(DialogService);
  private toastService: ToastService = inject(ToastService);
  private promoCodeService: PromoCodeService = inject(PromoCodeService);
  private router: Router = inject(Router);
  private dateHelperService = inject(DateHelperService);
  private datePipe = inject(DatePipe);

  form: FormGroup;
  dialogRef: DynamicDialogRef | undefined;
  promoCodeType = PromoCodeType;
  promoCodeValueType = PromoCodeValueType;

  promoCodeTypeOptions = [
    { name: 'Free shipping Promo Code', value: PromoCodeType.FREE_SHIPPING },
    { name: 'Promo Code for products', value: PromoCodeType.PRODUCT },
    { name: 'Promo Code for category', value: PromoCodeType.CATEGORY },
    { name: 'Promo Code for order (products only)', value: PromoCodeType.ORDER }
  ];

  promoCodeValueTypes = [
    { name: 'Fixed', value: PromoCodeValueType.FIXED },
    { name: 'Percentage', value: PromoCodeValueType.PERCENTAGE }
  ];

  productCategories: StorefrontProductCategory[];
  storefrontId: string;
  promoCodeId: string;
  promoCodeForEdit: PromoCode;
  selectedStorefrontProduct: StorefrontProduct[] = [];

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.storefrontId = params.get('storefrontId') ?? '';
      this.promoCodeId = params.get('promoCodeId') ?? '';
      this.fetchStorefrontProductCategories();
      if (this.promoCodeId) {
        this.fetchPromoCode();
        return;
      }
      this.buildForm();
    });
  }

  fetchPromoCode() {
    this.promoCodeService.getById(this.promoCodeId).subscribe({
      next: res => {
        this.promoCodeForEdit = res.data;
        this.buildForm();
      },
      error: err => {
        console.error('Operation failed:', err);
      }
    });
  }

  fetchStorefrontProductCategories() {
    this.categoryService.index(+this.storefrontId).subscribe({
      next: res => {
        this.productCategories = res.data;
      },
      error: error => console.error(error)
    });
  }

  buildForm() {
    const categoryIds = this.promoCodeForEdit?.product_categories.map(category => category.id);
    const productIds = this.promoCodeForEdit?.products.map(product => product.id);
    if (this.promoCodeForEdit?.products) {
      this.selectedStorefrontProduct = this.promoCodeForEdit.products;
    }

    this.form = this.fb.group({
      name: [this.promoCodeForEdit?.name ?? null, Validators.required],
      type: [this.promoCodeForEdit?.type ?? PromoCodeType.FREE_SHIPPING, [Validators.required]],
      code: [this.promoCodeForEdit?.code ?? null, [Validators.required, Validators.minLength(6)]],
      minimum_order: [!!this.promoCodeForEdit?.minimum_order_amount],
      minimum_order_amount: [this.promoCodeForEdit?.minimum_order_amount ?? null],
      product_categories: [categoryIds],
      limit_number_of_use: [!!this.promoCodeForEdit?.number_of_uses],
      number_of_uses: [this.promoCodeForEdit?.number_of_uses ?? 0],
      per_customer: [!!this.promoCodeForEdit?.per_customer],
      start_date: [
        this.promoCodeForEdit?.start_date
          ? this.dateHelperService.parseDateString(this.promoCodeForEdit?.start_date)
          : null
      ],
      end_date: [
        this.promoCodeForEdit?.end_date
          ? this.dateHelperService.parseDateString(this.promoCodeForEdit?.end_date)
          : null,
        [CustomValidators.dateAfterGiven('start_date')]
      ],
      end_date_visible: [!!this.promoCodeForEdit?.end_date],
      products: [productIds],
      promo_code_value_type: [this.promoCodeForEdit?.promo_code_value_type ?? null],
      promo_code_value: [this.promoCodeForEdit?.promo_code_value ?? null]
    });
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.subscirbeToMinimumOrderChange();
    this.subscirbeToLimitUseChange();
    this.subscribeToPromoCodeTypeChange();
  }

  subscirbeToMinimumOrderChange() {
    const isMinimumOrder = this.form.get('minimum_order');
    const minimumOrderAmount = this.form.get('minimum_order_amount');
    isMinimumOrder?.valueChanges.subscribe(isMinimumChecked => {
      minimumOrderAmount?.clearValidators();
      if (isMinimumChecked) {
        minimumOrderAmount?.setValidators([Validators.required, Validators.min(1)]);
      }
      minimumOrderAmount?.updateValueAndValidity();
    });
  }

  subscirbeToLimitUseChange() {
    const limitNumberOfUse = this.form.get('limit_number_of_use');
    const numberOfUses = this.form.get('number_of_uses');
    limitNumberOfUse?.valueChanges.subscribe(isLimit => {
      numberOfUses?.clearValidators();
      if (isLimit) {
        numberOfUses?.setValidators([Validators.required, Validators.min(1)]);
      }
      numberOfUses?.updateValueAndValidity();
    });
  }

  subscribeToPromoCodeTypeChange() {
    const promoCodeTypeControl = this.form.get('type');
    const promoCodeValueTypeControl = this.form.get('promo_code_value_type');
    const promoCodeValueControl = this.form.get('promo_code_value');
    const productsControl = this.form.get('products');
    const categoriesControl = this.form.get('product_categories');
    promoCodeTypeControl?.valueChanges.subscribe((value: PromoCodeType) => {
      productsControl?.clearValidators();
      categoriesControl?.clearValidators();
      promoCodeValueTypeControl?.clearValidators();
      promoCodeValueControl?.clearValidators();
      if (value === PromoCodeType.PRODUCT) {
        productsControl?.setValidators([Validators.required]);
        promoCodeValueTypeControl?.setValidators([Validators.required]);
        promoCodeValueControl?.setValidators([Validators.required]);
      }
      if (value === PromoCodeType.CATEGORY) {
        categoriesControl?.setValidators([Validators.required]);
        promoCodeValueTypeControl?.setValidators([Validators.required]);
        promoCodeValueControl?.setValidators([Validators.required]);
      }
      if (value === PromoCodeType.ORDER) {
        promoCodeValueTypeControl?.setValidators([Validators.required]);
        promoCodeValueControl?.setValidators([Validators.required]);
      }
      productsControl?.updateValueAndValidity();
      productsControl?.markAsUntouched();
      categoriesControl?.updateValueAndValidity();
      categoriesControl?.markAsUntouched();
      promoCodeValueTypeControl?.updateValueAndValidity();
      promoCodeValueTypeControl?.markAsUntouched();
      promoCodeValueControl?.updateValueAndValidity();
      promoCodeValueControl?.markAsUntouched();
    });
  }

  setStartDateToToday() {
    const today = new Date();
    this.form.patchValue({ start_date: today });
  }

  openProductListSelectorForPromoCode() {
    this.dialogRef = this.dialogService.open(PromoCodeProductListSelectionComponent, {
      header: 'Choose Products',
      width: '700px',
      data: {
        storefrontId: this.storefrontId,
        productCategories: this.productCategories
      }
    });
    this.dialogRef.onClose.subscribe((selectedProduct: StorefrontProduct[] | null) => {
      if (selectedProduct) {
        this.selectedStorefrontProduct = selectedProduct;
        this.form.patchValue({ products: selectedProduct.map(product => product.id) });
      }
    });
  }

  removeProductFromSelected(index: number) {
    this.selectedStorefrontProduct.splice(index, 1);
    const updatedProductIds = this.selectedStorefrontProduct.map(product => product.id);
    this.form.patchValue({ products: updatedProductIds });
  }

  private getCommonSubmitData() {
    const { name, code, type, start_date, end_date } = this.form.value;

    const start_dateFormatted = start_date ? this.datePipe.transform(start_date, 'yyyy-MM-dd') : null;
    const endDateFormatted = end_date ? this.datePipe.transform(end_date, 'yyyy-MM-dd') : null;

    const commonData: any = {
      name,
      code,
      type,
      ...(start_dateFormatted ? { start_date: start_dateFormatted } : {}),
      ...(endDateFormatted ? { end_date: endDateFormatted } : {})
    };

    return commonData;
  }

  private prepareFreeShippingSubmitData() {
    const commonData = this.getCommonSubmitData();
    const { minimum_order_amount, number_of_uses, per_customer, limit_number_of_use } = this.form.value;

    return {
      ...commonData,
      per_customer,
      ...(minimum_order_amount ? { minimum_order_amount } : {}),
      ...(limit_number_of_use ? { number_of_uses } : {})
    };
  }

  private prepareProductSubmitData() {
    const commonData = this.getCommonSubmitData();
    const { promo_code_value_type, promo_code_value, products } = this.form.value;

    return {
      ...commonData,
      promo_code_value_type,
      promo_code_value,
      products
    };
  }

  private prepareCategorySubmitData() {
    const commonData = this.getCommonSubmitData();
    const {
      product_categories,
      promo_code_value_type,
      promo_code_value,
      number_of_uses,
      per_customer,
      limit_number_of_use
    } = this.form.value;

    return {
      ...commonData,
      product_categories,
      promo_code_value_type,
      promo_code_value,
      per_customer,
      ...(limit_number_of_use ? { number_of_uses } : {})
    };
  }

  private prepareOrderSubmitData() {
    const commonData = this.getCommonSubmitData();
    const {
      promo_code_value_type,
      promo_code_value,
      minimum_order_amount,
      number_of_uses,
      per_customer,
      limit_number_of_use
    } = this.form.value;

    return {
      ...commonData,
      promo_code_value_type,
      promo_code_value,
      minimum_order_amount,
      per_customer,
      ...(limit_number_of_use ? { number_of_uses } : {})
    };
  }

  private prepareSubmitData() {
    if (this.form.value.type === PromoCodeType.FREE_SHIPPING) {
      return this.prepareFreeShippingSubmitData();
    }
    if (this.form.value.type === PromoCodeType.CATEGORY) {
      return this.prepareCategorySubmitData();
    }
    if (this.form.value.type === PromoCodeType.ORDER) {
      return this.prepareOrderSubmitData();
    }
    return this.prepareProductSubmitData();
  }

  createPromoCode(submitData: PromoCode) {
    this.promoCodeService.store(submitData).subscribe({
      next: () => {
        this.toastService.success('Successfully created promo code!');
        this.router.navigate([`../`], { relativeTo: this.activatedRoute });
      }
    });
  }

  updatePromoCode(submitData: PromoCode) {
    this.promoCodeService.update(this.promoCodeId, submitData).subscribe({
      next: () => {
        this.toastService.success('Successfully updated promo code!');
        this.router.navigate([`../../`], { relativeTo: this.activatedRoute });
      }
    });
  }

  formSubmit() {
    if (!this.form.valid) {
      markAllAsTouched(this.form);
      return this.toastService.error('Please fill all required fields correctly!');
    }
    const submitData = this.prepareSubmitData();
    if (!this.promoCodeForEdit) {
      this.createPromoCode(submitData);
    } else {
      this.updatePromoCode(submitData);
    }
  }
}
