<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end">
    <div class="col-12">
      <app-input
        name="name"
        label="Full Name"
        [control]="form.controls.name"
        placeholder="Full Name"
        [fullWidth]="true"
      ></app-input>
    </div>
    <div class="col-12">
      <app-input
        name="email"
        label="Emai;"
        [control]="form.controls.email"
        placeholder="Email"
        [fullWidth]="true"
        [type]="'email'"
      ></app-input>
    </div>

    <div class="col-12">
      <app-input
        name="amount"
        label="Value"
        [control]="form.controls.amount"
        placeholder="Value"
        [fullWidth]="true"
        [type]="'number'"
      ></app-input>
    </div>

    <div class="col-12">
      <p-checkbox binary formControlName="notify" inputId="notify" />
      <label class="ml-2" for="notify">Send Gift Card email to the recepient</label>
    </div>

    <div class="col-auto">
      <div class="flex gap-2 justify-content-end">
        <p-button
          icon="pi pi-times"
          label="Cancel"
          type="button"
          outlined
          (onClick)="closeDialog()"
          [disabled]="isLoading"
        />
        <p-button icon="pi pi-check" label="Save" type="submit" [disabled]="isLoading" />
      </div>
    </div>
  </div>
</form>
