import { Injectable } from '@angular/core';

import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  success(message: string, summary = 'Success') {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: message
    });
  }

  info(message: string, summary = 'Info') {
    this.messageService.add({
      severity: 'info',
      summary: summary,
      detail: message
    });
  }

  warning(message: string, summary = 'Warning') {
    this.messageService.add({
      severity: 'warn',
      summary: summary,
      detail: message
    });
  }

  error(message: string, summary = 'Error') {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: message
    });
  }
}
