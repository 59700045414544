import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StorefrontProductCategoryService extends ApiService {
  public index(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/product-categories`);
  }

  public deleteCategory(storefrontId: number, categoryId: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/product-categories/${categoryId}`);
  }

  public createCategory(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/product-categories`, data);
  }

  public updateCategory(storefrontId: number, data: FormData, categoryId?: number) {
    return this.put(`${this.apiUrl}storefronts/${storefrontId}/product-categories/${categoryId}`, data);
  }

  public checkSlug(storefrontId: number, slug: string) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/product-categories/check-slug`, { slug });
  }
}
