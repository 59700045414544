import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiResponse } from '../interfaces/IApiResponse';
import { FormGroupValue } from '../models/FormGroup';
import { User } from '../models/User';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends ApiService {
  routePrefix = `${this.apiUrl}auth/`;
  authCheck(): Observable<IApiResponse> {
    return this.post(`${this.routePrefix}auth-check`, {});
  }

  getUser(): Promise<void> {
    return new Promise(resolve => {
      this.authCheck().subscribe({
        next: res => {
          this.authStore.setAuthUser(res.data.user);
          resolve();
        },
        error: error => {
          console.error(error);
          resolve();
        }
      });
    });
  }

  login(
    email: string,
    password: string,
    remember: boolean = false
  ): Observable<IApiResponse<{ user: User; access_token: string }>> {
    return this.post(`${this.routePrefix}login`, {
      email,
      password,
      remember
    });
  }

  logout() {
    return this.get(`${this.routePrefix}logout`);
  }

  forgotEmail(email: string) {
    const params = new HttpParams().set('email', email);
    return this.get(`${this.routePrefix}forgot-password`, { params });
  }

  resetPassword(data: FormGroupValue) {
    return this.post(`${this.routePrefix}reset-password`, data);
  }

  companyRegister(data: FormGroupValue) {
    return this.post(`${this.routePrefix}company/register`, data);
  }

  userRegister(data: FormGroupValue) {
    return this.post(`${this.routePrefix}register`, data);
  }

  verifyEmail(token: string) {
    return this.get(`${this.routePrefix}email/verify/${token}`);
  }

  resendVerificationEmail() {
    return this.get(`${this.routePrefix}email/verification-notification`);
  }
}
