<ul class="layout-menu">
  @for (item of model; track i; let i = $index) {
    <ng-container>
      @if (item.separator) {
        <li class="menu-separator"></li>
      } @else {
        <li app-nav-item [item]="item" [index]="i" [root]="true"></li>
      }
    </ng-container>
  }
</ul>
