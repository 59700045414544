@if (variantsForm) {
  <form [formGroup]="variantsForm">
    <div formArrayName="catalog_variants" class="grid grid-nogutter">
      @for (variant of formVariants.controls; let i = $index; track i) {
        <div [formGroupName]="i" class="xl:col-4 lg:col-4 col-12">
          <div class="variant-item">
            <div class="flex justify-content-between align-items-center">
              <div>
                <small>
                  <strong>Supplier Color:</strong>
                  {{ variants()[i].supplier_product_variant?.color }}
                </small>

                @if (!variantEditMode[i]) {
                  <div>
                    <strong>Color:</strong>
                    {{ variant.get('color')?.value }}
                    <p-button
                      icon="pi pi-pencil"
                      link
                      size="small"
                      styleClass="p-0"
                      (click)="variantEditMode[i] = true"
                    />
                  </div>
                }
              </div>
              <div>
                <p-button icon="pi pi-trash" severity="danger" outlined (click)="openDeleteVariantModal($event, i)" />
              </div>
            </div>

            @if (variantEditMode[i]) {
              <div class="mt-2 mb-3">
                <p-inputGroup>
                  <p-button
                    type="button"
                    icon="pi pi-check"
                    styleClass="border-noround-right"
                    (onClick)="variantEditMode[i] = false"
                  />

                  <input
                    [id]="'Color' + i"
                    class="m-0"
                    type="text"
                    [formControl]="getColorFormControl(variant.get('color')!)"
                    pInputText
                    placeholder="Color"
                  />

                  <p-button
                    type="button"
                    label="Add to dictinary"
                    styleClass="border-noround-left"
                    (onClick)="addToDictionary(i)"
                  />
                </p-inputGroup>
              </div>
            }

            <div class="flex justify-content-center gap-5 flex-wrap">
              <div class="flex flex-column align-items-center">
                @if (imageUrls[i].frontImage) {
                  <img
                    [src]="imageUrls[i].frontImage"
                    alt="Front Image"
                    style="object-fit: contain"
                    width="100"
                    height="125"
                    priority
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Front Image</p>
                  </div>
                }
                <div class="flex justify-content-between w-full mt-2">
                  <p-button icon="pi pi-pencil" outlined (click)="fileInputFront.click()"></p-button>
                  <p-button
                    icon="pi pi-trash"
                    outlined
                    severity="danger"
                    (click)="openDeleteMediaModal(i, 'front')"
                  ></p-button>
                </div>
                <input
                  #fileInputFront
                  type="file"
                  (change)="onFileChange($event, i, 'front')"
                  accept="image/*"
                  style="display: none"
                />
              </div>
              <div class="flex flex-column align-items-center">
                @if (imageUrls[i].rearImage) {
                  <img
                    [src]="imageUrls[i].rearImage"
                    alt="Rear Image"
                    style="object-fit: contain"
                    width="100"
                    height="125"
                    priority
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Rear Image</p>
                  </div>
                }
                <div class="flex justify-content-between w-full mt-2">
                  <p-button icon="pi pi-pencil" outlined (click)="fileInputRear.click()"></p-button>
                  <p-button
                    icon="pi pi-trash"
                    outlined
                    severity="danger"
                    (click)="openDeleteMediaModal(i, 'rear')"
                  ></p-button>
                </div>
                <input
                  #fileInputRear
                  type="file"
                  (change)="onFileChange($event, i, 'rear')"
                  accept="image/*"
                  style="display: none"
                />
              </div>
              <div class="flex flex-column align-items-center">
                @if (imageUrls[i].sideImage) {
                  <img
                    [src]="imageUrls[i].sideImage"
                    alt="Side Image"
                    style="object-fit: contain"
                    width="100"
                    height="125"
                    priority
                  />
                } @else {
                  <div class="noMediaContainer">
                    <p>Side Image</p>
                  </div>
                }
                <div class="flex justify-content-between w-full mt-2">
                  <p-button icon="pi pi-pencil" outlined (click)="fileInputSide.click()"></p-button>
                  <p-button
                    icon="pi pi-trash"
                    outlined
                    severity="danger"
                    (click)="openDeleteMediaModal(i, 'side')"
                  ></p-button>
                </div>
                <input
                  #fileInputSide
                  type="file"
                  (change)="onFileChange($event, i, 'side')"
                  accept="image/*"
                  style="display: none"
                />
              </div>
            </div>
            <div class="mt-2">
              <p-panel header="Sizes" [toggleable]="true" [collapsed]="true">
                <div formArrayName="catalog_variant_sizes" class="item-sizes">
                  <p>Sizes</p>
                  <p-table [value]="sizeControl(variant).controls">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Size</th>
                        <th>Weight</th>
                        <th>Total</th>
                        <th>Qty</th>
                      </tr>
                    </ng-template>
                  </p-table>
                  @for (size of sizeControl(variant).controls; let j = $index; track j) {
                    <div class="row">
                      <app-input [name]="'Size' + i + j" [control]="size.get('size')!" placeholder="Color"></app-input>
                      <app-input
                        [name]="'Wight' + i + j"
                        [control]="size.get('weight')!"
                        placeholder="Color"
                      ></app-input>

                      <app-input
                        [name]="'Total' + i + j"
                        [control]="size.get('total')!"
                        placeholder="Color"
                      ></app-input>

                      <app-input
                        [name]="'Quantity' + i + j"
                        [control]="size.get('quantity')!"
                        placeholder="Color"
                        [readonly]="true"
                      ></app-input>
                    </div>
                  }
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      }
    </div>
  </form>
}
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteMedia"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
