import { Injectable } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SuperadminOrderService extends ApiService {
  routePrefix = `${this.apiUrl}orders/`;

  public paginatedIndex(event?: TableLazyLoadEvent, filters?: any) {
    return this.get(this.routePrefix, {
      params: this.handleTableLazyLoadEvent(event, filters)
    });
  }

  public getById(id: number, params?: any) {
    return this.get(this.routePrefix + id, { params });
  }

  public deleteOrder(id: number) {
    return this.delete(`${this.routePrefix}${id}`);
  }
}
