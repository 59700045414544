import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';

import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { LibraryFileSelectorComponent } from './library-file-selector/library-file-selector.component';
import { LibraryFileUploaderComponent } from './library-file-uploader/library-file-uploader.component';

@Component({
  selector: 'app-media-library',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    LibraryFileUploaderComponent,
    LibraryFileSelectorComponent,
    ProgressSpinnerModule
  ],
  templateUrl: './media-library.component.html',
  styleUrl: './media-library.component.scss'
})
export class MediaLibraryComponent implements OnInit {
  @Input() multiple = false;
  @Input() storefrontId: number;
  @Input() editMode = false;
  @Input() libraryFile: LibraryFile;

  dialogRef = inject(DynamicDialogRef);
  dialogConfig = inject(DynamicDialogConfig);
  toastService = inject(ToastService);
  libraryService = inject(LibraryService);

  storefrontLibraryFolders: LibraryFolder[] = [];
  selectedFolderFiles: LibraryFile[] = [];
  selectedImageIndexes: Set<number> = new Set();

  loading = false;

  ngOnInit() {
    this.storefrontId = this.dialogConfig.data?.storefrontId;
    this.editMode = this.dialogConfig.data?.editMode;
    this.libraryFile = this.dialogConfig.data?.libraryFile;
    this.fetchStorefrontLibraryFolders(this.storefrontId);
  }

  fetchStorefrontLibraryFolders(storefrontId: number) {
    this.loading = true;
    this.libraryService.getStorefrontLibraryFolders(storefrontId).subscribe({
      next: response => {
        this.storefrontLibraryFolders = response.data;
      },
      error: error => {
        console.error(error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  fetchFilesForSelectedFolder(folderId: number) {
    this.loading = true;
    this.libraryService.getLibraryFolderFiles(this.storefrontId, folderId).subscribe({
      next: response => {
        this.selectedFolderFiles = response.data;
      },
      error: error => {
        console.error(error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onFileUploaderSubmit(formData: FormData) {
    if (!this.editMode) {
      this.createLibrary(formData);
      return;
    }
    this.updateLibrary(formData);
  }

  createLibrary(formData: FormData) {
    this.libraryService.createLibraryFile(this.storefrontId, formData).subscribe({
      next: res => {
        this.toastService.success('Successfully saved library file!');
        const uploadedFile: LibraryFile = res.data;
        this.dialogRef.close([uploadedFile]);
      },
      error: err => {
        console.error('Creation failed:', err);
      }
    });
  }

  updateLibrary(formData: FormData) {
    this.libraryService.updateLibraryFile(this.storefrontId, this.libraryFile.id, formData).subscribe({
      next: res => {
        this.toastService.success('Successfully updaed library file!');
        const uploadedFile: LibraryFile = res.data;
        this.dialogRef.close([uploadedFile]);
      },
      error: err => {
        console.error('Creation failed:', err);
      }
    });
  }
  onFileSelectorFileSelected(selectedFiles: LibraryFile[]) {
    this.dialogRef.close(selectedFiles);
  }
}
