<div class="register">
  <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
    <div class="header mb-4">
      <h1>Company Register</h1>
      <h4>Let's get started</h4>
    </div>
    <div class="flex flex-column">
      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('first_name')!"
          placeholder="First Name"
          [fullWidth]="true"
          label="First Name"
        />
      </div>
      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('last_name')!"
          placeholder="Last Name"
          [fullWidth]="true"
          label="Last Name"
        />
      </div>
      <div class="mb-4">
        <app-input [control]="registrationForm.get('phone')!" placeholder="Phone" [fullWidth]="true" label="Phone" />
      </div>
      <div class="mb-4">
        <label for="password" class="block label">Password</label>
        <span class="p-input-icon-left w-full">
          <p-password
            [feedback]="false"
            formControlName="password"
            placeholder="Password"
            styleClass="w-full"
            [inputStyle]="{ borderRadius: '10px' }"
            inputStyleClass="w-full"
            [toggleMask]="true"
            id="password"
          ></p-password>
        </span>
        @if (
          submitted() ||
          (registrationForm.get('password')?.invalid &&
            (registrationForm.get('password')?.dirty || registrationForm.get('password')?.touched))
        ) {
          @if (registrationForm.get('password')?.errors?.['required']) {
            <div>
              <small class="p-text-danger">Password is required</small>
            </div>
          }
          @if (registrationForm.get('password')?.errors?.['passwordPattern']) {
            <div class="w-50%">
              <small class="p-text-danger">
                Password must be at least 8 characters,
                <br />
                contain at least one uppercase letter,
                <br />
                one lowercase letter, one number
                <br />
                and one special character.
              </small>
            </div>
          }
        }
      </div>
      <div class="mb-4">
        <label for="confirm-password" class="block label">Confirm Password</label>
        <span class="p-input-icon-left w-full">
          <p-password
            [feedback]="false"
            formControlName="password_confirmation"
            placeholder="Confirm Password"
            styleClass="w-full"
            [inputStyle]="{ borderRadius: '10px' }"
            inputStyleClass="w-full"
            [toggleMask]="true"
            id="confirm-password"
          ></p-password>
        </span>
        @if (
          submitted() ||
          (registrationForm.get('password_confirmation')?.invalid &&
            (registrationForm.get('password_confirmation')?.dirty ||
              registrationForm.get('password_confirmation')?.touched))
        ) {
          @if (registrationForm.get('password_confirmation')?.errors?.['required']) {
            <div>
              <small class="p-text-danger">Password Confirmation is required</small>
            </div>
          } @else if (registrationForm.errors?.['passwordsNotMatched']) {
            <div>
              <small class="p-text-danger">Passwords no not match</small>
            </div>
          }
        }
      </div>

      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('company')!"
          placeholder="Company"
          [fullWidth]="true"
          label="Company"
        />
      </div>
      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('billing_email')!"
          placeholder="Billing email"
          [fullWidth]="true"
          label="Billing email"
        />
      </div>

      <div class="mb-4">
        <div class="flex flex-wrap terms align-items-center">
          <p-checkbox formControlName="terms_and_conditions" styleClass="mr-2" [binary]="true"></p-checkbox>
          <label for="terms_and_conditions" class="text-900 font-medium mr-2">I have read the</label>
          <a class="text-600 cursor-pointer hover:text-primary cursor-pointer">Terms and Conditions</a>
        </div>
        @if (submitted() && registrationForm.get('terms_and_conditions')?.errors?.['required']) {
          <div class="mt-1">
            <small class="p-text-danger">Please accept the Terms and Conditions</small>
          </div>
        }
      </div>
      <button type="submit" [disabled]="isLoading()" pButton pRipple label="Sign Up" class="w-full mb-4"></button>
      <span class="font-medium text-600">
        Already have an account?
        <a
          [routerLink]="['/auth/login']"
          class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"
        >
          Login
        </a>
      </span>
    </div>
  </form>
</div>
