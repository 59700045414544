import { Component, computed, inject, model, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor, EditorModule } from 'primeng/editor';

import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

@Component({
  selector: 'app-text-editor-section-modal',
  standalone: true,
  imports: [DialogModule, ReactiveFormsModule, EditorModule, ButtonModule, FormsModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements OnInit {
  @ViewChild('editor') editor: Editor;
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
  designerHelperService = inject(DesignerHelperService);
  visible = model<boolean>(false);
  pages = computed(() => this.designerStore.config().pages ?? []);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  formGroup: FormGroup;
  constructor(
    private dynamicConfig: DynamicDialogConfig,
    private fb: FormBuilder,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    const value = this.dynamicConfig.data.section.content;
    this.formGroup = this.fb.group({
      text: new FormControl(value ?? '')
    });
  }

  onSubmit() {
    const selectedPage = this.dynamicConfig.data.selectedPage;
    const selectedSection = this.dynamicConfig.data.section;

    const pages = this.designerHelperService.saveSectionData(selectedPage, selectedSection, {
      text: this.formGroup.value.text
    });

    this.designerStore.saveConfig(this.storefrontId()!, { pages }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next, () => this.ref.close());
    });
  }
  setDefaultValue() {
    const value = this.dynamicConfig.data.section.content?.text;
    this.editor.quill.clipboard.dangerouslyPasteHTML(value);
  }
}
