<p-tabView [activeIndex]="!editMode ? 1 : 0">
  <p-tabPanel header="Upload File">
    <app-library-file-uploader
      [storefrontLibraryFolders]="storefrontLibraryFolders"
      [editMode]="editMode"
      [libraryFile]="libraryFile"
      (formSubmitted)="onFileUploaderSubmit($event)"
    />
  </p-tabPanel>
  <p-tabPanel header="Select from Library">
    @if (loading) {
      <p-progressSpinner ariaLabel="loading" />
    } @else {
      <app-library-file-selector
        [storefrontLibraryFolders]="storefrontLibraryFolders"
        [selectedFolderFiles]="selectedFolderFiles"
        [multiple]="multiple"
        (folderSelected)="fetchFilesForSelectedFolder($event)"
        (filesSelected)="onFileSelectorFileSelected($event)"
      />
    }
  </p-tabPanel>
</p-tabView>
