import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-company',
  standalone: true,
  imports: [MenubarModule, TabMenuModule],
  templateUrl: './company-management.component.html'
})
export class CompanyManagementComponent {
  menuItems: MenuItem[] = [
    {
      label: 'General',
      routerLink: './general',
      icon: 'pi pi-building-columns'
    },
    {
      label: 'Users',
      routerLink: './users',
      icon: 'pi pi-users'
    },
    {
      label: 'Invites',
      routerLink: './invites',
      icon: 'pi pi-address-book'
    },
    {
      label: 'Tax',
      routerLink: './tax-forms',
      icon: 'pi pi-receipt'
    },
    {
      label: 'Billing',
      routerLink: './billing',
      icon: 'pi pi-dollar'
    },
    {
      label: 'Payment methods',
      routerLink: './payment-methods',
      icon: 'pi pi-credit-card'
    },
    {
      label: 'Client selection',
      routerLink: './client-selection',
      icon: 'pi pi-file-edit'
    },
    {
      label: 'Collections',
      routerLink: './collections',
      icon: 'pi pi-file-edit'
    }
  ];
}
