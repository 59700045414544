<p-card>
  <h5>Import new {{ SupplierNameFromSlug[slug] }} product</h5>
  <div class="field mt-3">
    <form [formGroup]="productForm">
      <app-input
        [name]="SupplierNameFromSlug[slug] + ' Product ID'"
        [label]="SupplierNameFromSlug[slug] + ' Product ID'"
        [control]="productId"
        placeholder="XXXXXXXXXXXX"
      />
    </form>
  </div>
  <p-button label="Cancel" icon="pi pi-times" [text]="true" (click)="handleCancel()" />
  <p-button
    [disabled]="loading"
    [loading]="loading"
    label="Import product"
    icon="pi pi-check"
    (click)="saveProduct()"
  />
</p-card>
<p-toast />
