import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';

import { Order } from 'app/_shared/models/Order';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';

@Component({
  selector: 'app-order-quick-preview',
  standalone: true,
  imports: [DialogModule, ButtonModule, DividerModule, DisplayDatePipe, UsdPipe, PricePipe],
  templateUrl: './order-quick-preview.component.html',
  styleUrl: './order-quick-preview.component.scss'
})
export class OrderQuickPreviewComponent {
  @Input() order: Order;
  @Input() visible: boolean;
  @Output() cancelEdit = new EventEmitter<void>();
  hideDialog() {
    this.cancelEdit.emit();
  }
}
