<div class="flex justify-content-end align-items-center mb-5 gap-3">
  <p-button label="Apply changes" (onClick)="applyChanges()" />
</div>
<div class="grid">
  <div class="col-4">
    <p-card>
      <p-accordion [multiple]="true">
        <p-accordionTab header="Colors" [selected]="false" class="line-height-3 m-0">
          <p><strong>Select site primary color</strong></p>
          <compact-picker [(color)]="primaryColor" [control]="primaryColorControl"></compact-picker>
          <p-divider layout="horizontal"></p-divider>
          <p><strong>Select site secondary color</strong></p>
          <compact-picker [(color)]="secondaryColor" [control]="secondaryColorControl"></compact-picker>
        </p-accordionTab>
        <p-accordionTab header="Logo" [selected]="false" class="line-height-3 m-0">
          <p-fileUpload
            #fileUploader
            name="demo[]"
            url="./upload.php"
            (onSelect)="onUpload($event)"
            [customUpload]="true"
            accept="image/*"
            [showUploadButton]="false"
            [showCancelButton]="false"
            [auto]="true"
            styleClass="border-1 surface-border surface-card p-0 border-round mb-4"
          >
            <ng-template pTemplate="content">
              <div class="h-20rem m-1 border-round">
                <div
                  *ngIf="!logo()"
                  class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
                  (click)="fileUploader.advancedFileInput.nativeElement.click()"
                >
                  <i class="pi pi-fw pi-file text-4xl text-primary"></i>
                  <span class="block font-semibold text-900 text-lg mt-3">Drop or select a site logo</span>
                </div>
                <div *ngIf="logo()" class="h-full relative border-round p-0 text-center">
                  <img [src]="logo()" class="h-full border-round text-center" alt="blog cover" />
                  <button
                    pButton
                    pRipple
                    type="button"
                    aria-label="remove"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
                    style="top: -10px; right: -10px"
                    (click)="$event.stopPropagation(); removeImage()"
                  ></button>
                </div>
              </div>
            </ng-template>
          </p-fileUpload>

          <p><strong>Select logo position</strong></p>
          <div class="grid formgrid">
            <div class="col-12 md:col-4">
              <div class="field-radiobutton">
                <p-radioButton
                  name="logoPosition"
                  value="left"
                  [(ngModel)]="logoPosition"
                  (onClick)="saveSettings({ logoPosition: $event.value })"
                  inputId="left"
                  id="left"
                ></p-radioButton>
                <label for="left">Left</label>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field-radiobutton">
                <p-radioButton
                  name="logoPosition"
                  value="center"
                  [(ngModel)]="logoPosition"
                  (onClick)="saveSettings({ logoPosition: $event.value })"
                  inputId="center"
                  id="center"
                ></p-radioButton>
                <label for="center">Center</label>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Buttons" [selected]="false" class="line-height-3 m-0">
          <div class="field-radiobutton">
            <p-radioButton
              name="buttonShape"
              value="square"
              [(ngModel)]="buttonShape"
              id="square"
              (onClick)="saveSettings({ buttonShape: $event.value })"
            ></p-radioButton>
            <label for="square">Square</label>
            <div class="button-example square">
              <button pButton type="button" label="Button" aria-label="Button"></button>
              <button pButton type="button" label="Button" aria-label="Button" class="p-button-outlined"></button>
            </div>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="buttonShape"
              value="round"
              [(ngModel)]="buttonShape"
              id="round"
              (onClick)="saveSettings({ buttonShape: $event.value })"
            ></p-radioButton>
            <label for="round">Round</label>
            <div class="button-example round">
              <button pButton type="button" label="Button" aria-label="Button"></button>
              <button pButton type="button" label="Button" aria-label="Button" class="p-button-outlined"></button>
            </div>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="buttonShape"
              value="pill"
              [(ngModel)]="buttonShape"
              (onClick)="saveSettings({ buttonShape: $event.value })"
              id="pill"
            ></p-radioButton>
            <label for="pill">Pill</label>
            <div class="button-example pill">
              <button pButton type="button" label="Button" aria-label="Button" class="p-button-rounded"></button>
              <button
                pButton
                type="button"
                label="Button"
                aria-label="Button"
                class="p-button-outlined p-button-rounded"
              ></button>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <p><strong>Select footer text</strong></p>
      <p-editor [(ngModel)]="footerText" [style]="{ height: '220px' }" (onTextChange)="onTextChange($event)">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
          </span>
        </ng-template>
      </p-editor>
    </p-card>
  </div>
  <div class="col-8">
    <p-card>
      <app-iframe [iframeUrl]="iframeUrl"></app-iframe>
    </p-card>
  </div>
</div>
