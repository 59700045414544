import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { finalize } from 'rxjs';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { FormGroupValue } from 'app/_shared/models/FormGroup';
import { AuthService } from 'app/_shared/services/auth.service';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { LayoutService } from 'app/_shared/services/layout.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';

@Component({
  selector: 'app-company-register',
  standalone: true,
  imports: [
    PasswordModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    RouterLink,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    InputComponent
  ],
  templateUrl: './company-register.component.html',
  styleUrl: './company-register.component.scss'
})
export class CompanyRegisterComponent implements OnInit {
  layoutService = inject(LayoutService);
  authService = inject(AuthService);
  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  toastService = inject(ToastService);
  authStore = inject(AuthStore);
  router = inject(Router);
  token: string;
  email: string;

  registrationForm: FormGroup;
  submitted = signal(false);
  isLoading = signal(false);

  confirmed: boolean = false;

  get dark(): boolean {
    return this.layoutService.config().colorScheme !== 'light';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.email = params.email;
      this.buildRegistrationForm();
    });
  }

  buildRegistrationForm() {
    this.registrationForm = this.fb.group(
      {
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        password: [null, [Validators.required, CustomValidators.passwordPattern()]],
        password_confirmation: [null, [Validators.required, CustomValidators.passwordPattern()]],
        phone: [null, [Validators.required]],
        company: [null, [Validators.required]],
        billing_email: [null, [Validators.required, Validators.email]],
        terms_and_conditions: [false, [Validators.requiredTrue]]
      },
      { validators: CustomValidators.passwordsMatch }
    );
  }

  onSubmit() {
    if (this.registrationForm.invalid) {
      this.submitted.set(true);
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formattedData = this.prepareRegistrationData();
    this.registerCompany(formattedData);
  }

  prepareRegistrationData() {
    const formData = this.registrationForm.value;
    const formattedData = {
      ...formData,
      company: { name: formData.company, billing_email: formData.billing_email },
      email: this.email,
      token: this.token
    };
    return formattedData;
  }

  registerCompany(data: FormGroupValue) {
    this.isLoading.set(true);
    this.authService
      .companyRegister(data)
      .pipe(finalize(() => this.isLoading.set(false)))
      .subscribe({
        next: res => {
          this.authStore.setToken(res.data.access_token);
          this.authStore.setAuthUser(res.data.user);
          this.router.navigateByUrl('/auth/email-verification');
        },
        error: err => {
          console.error('Operation failed:', err);
          this.toastService.error(err.error.message);
        }
      });
  }
}
