import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize } from 'rxjs';

import { OrderDetailsCollapseItemComponent } from 'app/_shared/components/order-details-collapse-item/order-details-collapse-item.component';
import { OrderQuickPreviewComponent } from 'app/_shared/components/order-quick-preview/order-quick-preview.component';
import { StatusBadgeComponent } from 'app/_shared/components/status-badge/status-badge.component';
import { statusOptions } from 'app/_shared/constants/order-status-options';
import { Order } from 'app/_shared/models/Order';
import { Storefront } from 'app/_shared/models/Storefront';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { SuperadminOrderService } from 'app/_shared/services/superadmin-order.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface OrderFilters {
  global_filter?: string;
  date_from?: string;
  date_to?: string;
  status?: number;
  storefront_id?: number;
}

export interface StorefrontPaginatorOptions {
  total: number;
  rows: number;
  first: number;
}

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    FormsModule,
    DropdownModule,
    ProgressBarModule,
    CommonModule,
    InputTextModule,
    CalendarModule,
    RouterLink,
    ConfirmDialogModule,
    DisplayDatePipe,
    UsdPipe,
    SkeletonModule,
    StatusBadgeComponent,
    PricePipe,
    OrderQuickPreviewComponent,
    OrderDetailsCollapseItemComponent
  ],
  providers: [DatePipe, ConfirmationService],
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit {
  orderService = inject(SuperadminOrderService);
  storefrontService = inject(StorefrontService);
  datePipe = inject(DatePipe);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);

  isLoading = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);
  orders: Order[] = [];
  totalRecords: number = 0;

  public storefronts: Storefront[] = [];

  statusOptions = statusOptions;

  filtersSubject = new BehaviorSubject<any>(null);

  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;

  global_filter: string;
  date_from: Date | null;
  date_to: Date | null;
  status: number | null;
  storefront_id: string | null;

  orderForQuickPreview: Order;
  quickOrderPreviewDialog = false;
  paginatorOptions: StorefrontPaginatorOptions = {
    total: 0,
    rows: 15,
    first: 0
  };

  expandedRows: { [key: number]: boolean } = {};
  isAllExpanded = false;

  ngOnInit() {
    this.fetchStorefronts();
    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadOrders(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  fetchStorefronts() {
    const { rows, first } = this.paginatorOptions;
    const params = {
      rows,
      first
    };
    this.isLoading = true;
    this.storefrontService
      .indexPaginated(params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.storefronts = [...this.storefronts, ...res.data.data];
          this.paginatorOptions = {
            total: res.data.total,
            rows: res.data.per_page,
            first: res.data.from - 1
          };
        },
        error: error => console.error(error)
      });
  }

  loadMoreStorefronts(event: any) {
    const { first, last } = event;
    if (last === this.storefronts.length) {
      if (this.paginatorOptions.first + this.paginatorOptions.rows <= this.paginatorOptions.total) {
        this.paginatorOptions.first += this.paginatorOptions.rows;
        this.fetchStorefronts();
      }
    }
  }

  loadOrders(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }

    const orderFilters = this.buildOrderFilters();

    this.isLoading = true;
    this.orderService
      .paginatedIndex(this.lastLazyLoadEvent, orderFilters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.orders = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  buildOrderFilters() {
    const orderFilters: OrderFilters = {};

    if (this.global_filter) {
      orderFilters.global_filter = this.global_filter;
    }
    if (this.date_from) {
      orderFilters.date_from = this.datePipe.transform(this.date_from, 'dd.MM.yyyy')!;
    }
    if (this.date_to) {
      orderFilters.date_to = this.datePipe.transform(this.date_to, 'dd.MM.yyyy')!;
    }
    if (this.status) {
      orderFilters.status = this.status;
    }
    if (this.storefront_id) {
      orderFilters.storefront_id = +this.storefront_id;
    }

    const params = {
      'relations[]': [
        'contactInfo',
        'storefront',
        'items.product',
        'items.productSize',
        'items.productVariant',
        'items.product.productThumbnail'
      ],
      ...orderFilters
    };

    return params;
  }

  onFilterChange(filterKey: string, filterValue: any): void {
    const updatedFilters = { ...this.filtersSubject.value };

    if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
      updatedFilters[filterKey] = filterValue;
    } else {
      delete updatedFilters[filterKey];
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  clearFilters(): void {
    this.global_filter = '';
    this.date_from = null;
    this.date_to = null;
    this.status = null;
    this.storefront_id = null;

    this.filtersSubject.next({});
    this.filtersChanged = true;
  }

  openDeleteModal(event: Event, orderId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this order?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteStorefront(orderId);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private deleteStorefront(orderId: number) {
    this.orderService.deleteOrder(orderId).subscribe({
      next: () => {
        this.toastService.success('This order has been deleted');
        this.loadOrders();
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }

  quickPreview(order: Order) {
    this.orderForQuickPreview = { ...order };
    this.quickOrderPreviewDialog = true;
  }

  hideDialog() {
    this.quickOrderPreviewDialog = false;
  }

  toggleExpandAll() {
    const allExpanded =
      Object.keys(this.expandedRows).length === this.orders.length && Object.values(this.expandedRows).every(Boolean);
    if (allExpanded) {
      this.expandedRows = {};
    } else {
      this.expandedRows = this.orders.reduce((acc, order) => {
        acc[order.id] = true;
        return acc;
      }, {});
    }
    this.isAllExpanded = !allExpanded;
  }
}
