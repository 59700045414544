<div class="text-900 text-xl font-semibold mb-3">Folders</div>

<div class="grid">
  @for (folder of storefrontLibraryFolders; track $index) {
    <div class="col-12 md:col-6 xl:col-4" (click)="selectFolder(folder)">
      <div
        [ngClass]="{
          'p-3 border-1 surface-border flex align-items-center justify-content-between cursor-pointer border-round': true,
          'surface-100': selectedFolder === folder
        }"
      >
        <div class="flex align-items-center">
          <i class="pi pi-image text-2xl mr-3"></i>
          <span class="text-900 text-lg font-medium">
            {{ folder.name }}
          </span>
        </div>
        <span class="text-600 text-lg font-semibold">
          {{ folder.libraries_count }}
        </span>
      </div>
    </div>
  }
</div>

<div class="grid">
  @for (file of selectedFolderFiles; track $index; let i = $index) {
    <div class="col-4 md:col-3">
      <img
        [ngClass]="{ 'border-primary': isSelected(file) }"
        [src]="file.mockup_file.full_path"
        class="w-full cursor-pointer border-2 border-transparent transition-colors transition-duration-150 border-round"
        (click)="toggleSelection(file)"
      />
    </div>
  }
</div>
<div class="flex w-full justify-content-end">
  <p-button label="Save & Close" type="button" (click)="onSaveAndClose()" />
</div>
