import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientSelectionList } from 'app/_shared/interfaces/clientSelection';
import { ProductSelectionComponent } from 'app/_shared/product-selection/product-selection.component';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-client-selection',
  standalone: true,
  imports: [ProductSelectionComponent],
  templateUrl: './client-selection.component.html',
  styleUrl: './client-selection.component.scss'
})
export class ClientSelectionComponent implements OnInit {
  private companyService = inject(CompanyService);
  private activatedRoute = inject(ActivatedRoute);
  private toastService = inject(ToastService);

  token: string;
  selection: ClientSelectionList;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token;
    });

    this.companyService
      .getSelectionByToken({
        token: this.token,
        'relations[]': ['catalogProductSelections', 'catalogProductSelections.featuredImage']
      })
      .subscribe(next => (this.selection = next.data));
  }

  handleSelectionSave($event: any) {
    const data = {
      token: this.token,
      catalog_products_ids: $event
    };
    this.companyService.editClientSelection(data).subscribe(next => {
      this.toastService.success('You selection is saved successfully!');
    });
  }
}
