import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CatalogProductFormComponent } from 'app/_shared/components/catalog-product-form/catalog-product-form.component';

@Component({
  selector: 'app-supplier-product',
  standalone: true,
  templateUrl: './supplier-product.component.html',
  imports: [CatalogProductFormComponent],
  styleUrl: './supplier-product.component.scss'
})
export class SupplierProductComponent implements OnInit {
  activatedRoute = inject(ActivatedRoute);
  redirectTo: string;

  ngOnInit(): void {
    const slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';
    this.redirectTo = `/suppliers/${slug}/products`;
  }
}
