<p-card>
  <div class="flex align-items-center mb-4">
    <h5>Products</h5>
  </div>
  <app-list-products
    [products]="storefrontProducts"
    [columns]="cols"
    [headerActions]="headerActions"
    [paginatorOptions]="paginatorOptions"
    (lazyLoadProducts)="fetchProducts($event)"
    [loading]="isLoading || isLoadingDelete"
    [multiSelect]="true"
    (onSelectionChange)="handleSelectionChange($event)"
  ></app-list-products>
</p-card>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '28rem' }"
></p-confirmDialog>
<p-dialog
  header="Select client selection list"
  [(visible)]="showClientSelectionDialog"
  [modal]="true"
  (visibleChange)="showClientSelectionDialog = false"
  showEffect="fade"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '30vh' }"
>
  <div class="new-selection">
    <p>Select Client selection you want to use</p>
    <p-dropdown
      [options]="selectionsList"
      [(ngModel)]="selectedSelectionList"
      optionLabel="title"
      placeholder="Client selection"
      appendTo="body"
    />
  </div>
  <ng-template pTemplate="footer">
    <button
      [disabled]="!selectedSelectionList"
      pButton
      icon="pi pi-check"
      (click)="handleSelectSelection()"
      label="Add products"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
