<p-steps [model]="steps" [(activeIndex)]="activeIndex"></p-steps>
@if (activeIndex === 0) {
  <form [formGroup]="form">
    <div class="flex flex-column gap-3 pt-3">
      <p class="text-400">
        File preparation is required prior to launching a store. Single Serve will create all of the necessary versions
        of your logo/artwork provided below for all decoration methods including embroidery digitizing if needed.
        Supplied embroidery files with proper thread information can be used if you prefer.
      </p>
      <app-input
        name="name"
        label="Name"
        [control]="form.controls.name"
        placeholder="Design name"
        [fullWidth]="true"
      ></app-input>
      <div>
        <label for="">Mockup file(.png)</label>
        <p-fileUpload
          [multiple]="false"
          [customUpload]="true"
          (onSelect)="onLogoUpload($event)"
          accept=".png"
          maxFileSize="1000000"
          showUploadButton="false"
          customUpload="true"
        >
          <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
              <div class="w-full flex justify-content-between gap-2">
                <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Logo" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="file"></ng-template>
          <ng-template pTemplate="content">
            @if (logo?.objectURL) {
              <div class="flex">
                <div class="col-4">
                  <img [src]="logo?.objectURL" alt="Uploaded Image" style="width: 50px" />
                </div>
                <div class="col-4">
                  <div class="flex justify-content-center align-items-center text-center h-full">
                    {{ logo?.name }}
                  </div>
                </div>
                <div class="col-4">
                  <div class="flex justify-content-end">
                    <p-button type="button" icon="pi pi-times" (onClick)="removeLogo()"></p-button>
                  </div>
                </div>
              </div>
            }
          </ng-template>
        </p-fileUpload>
      </div>
      <div>
        <label for="decoration_method">Decoration Method</label>
        <div class="full-width-select">
          <p-selectButton
            [options]="decorationMethodOptions"
            optionLabel="name"
            formControlName="decoration_method"
          ></p-selectButton>
        </div>
      </div>
      <div>
        <p class="text-400">
          Please add any additional files below such as eps, embroidery, etc that you would like us to use and assist us
          with the creation of these production files.
        </p>
        <p-fileUpload
          [multiple]="true"
          [customUpload]="true"
          (onSelect)="onAdditionalFilesUpload($event)"
          accept=".pdf"
          maxFileSize="1000000"
          showUploadButton="false"
          customUpload="true"
          showUploadButton="false"
        >
          <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
              <div class="w-full flex justify-content-between gap-2">
                <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Additional Files" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="file"></ng-template>

          <ng-template pTemplate="content">
            <div>
              @if (additionaFiles.length) {
                <div class="flex flex-wrap gap-5">
                  @for (additionalItem of additionaFiles; track $index) {
                    <p-chip
                      styleClass="mr-2 py-2 px-3 text-900 font-bold surface-card border-1 surface-border"
                      [style]="{ 'border-radius': '20px' }"
                      onclick=""
                    >
                      <span class="mr-3">{{ additionalItem.name }}</span>
                      <span
                        class="chip-remove-icon flex align-items-center justify-content-center border-1 surface-border bg-gray-100 border-circle cursor-pointer"
                        (click)="onAdditionalFileRemove($index)"
                      >
                        <i class="pi pi-fw pi-times text-black-alpha-60"></i>
                      </span>
                    </p-chip>
                  }
                </div>
              }
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
      <div>
        <p class="text-400">
          Please provide all information related to color versions needed as well as any additional information to
          deliver the best results to you. Supplied embroidery files must include all thread colors and sequences.
        </p>
        <app-input
          name="input_information"
          label="Name"
          [control]="form.controls.input_information"
          placeholder="Input information"
          [fullWidth]="true"
        ></app-input>
      </div>

      <div class="flex justify-content-end gap-1">
        <p-button outlined type="button" label="Cancel" (onClick)="closeDialog()"></p-button>
        <p-button outlined type="button" label="Go to summary" (click)="nextStep()"></p-button>
        <p-button type="button" label="Submit"></p-button>
      </div>
    </div>
  </form>
} @else {
  <div class="flex flex-column gap-3 pt-3">
    <img [src]="logo?.objectURL" alt="Uploaded Image" style="width: 150px" />
    <p>We will begin to prepare your files so that you may start to build your products shortly.</p>
    <p>We will email you as soon as your files are prepared and ready to be used for product creation.</p>
  </div>
  <div class="flex justify-content-end gap-1">
    <p-button outlined type="button" label="Back" (click)="previousStep()"></p-button>
    <p-button type="button" label="Submit"></p-button>
  </div>
}
