<p-card>
  <div class="flex justify-content-between align-items-center mb-4">
    <h5>{{ SupplierNameFromSlug[slug] }} Products</h5>
    <p-button icon="pi pi-plus" label="Import new product" outlined (click)="importNewProduct()" />
  </div>

  <app-list-products
    [products]="supplierProducts"
    [columns]="cols"
    [paginatorOptions]="paginatorOptions"
    (lazyLoadProducts)="handleLazyLoadProducts($event)"
    [loading]="isLoading"
  ></app-list-products>
</p-card>
