import { FormControl, FormGroup } from '@angular/forms';

// TODO: Angular forms already have this https://angular.dev/api/forms/AbstractControl#markAllAsTouched @Aleksandar
export function markAllAsTouched(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach(control => {
    if (control instanceof FormControl) {
      control.markAsTouched();
    } else if (control instanceof FormGroup) {
      markAllAsTouched(control);
    }
  });
}

export class HelperService {
  static formFieldInvalid(formField: FormControl) {
    return {
      'ng-invalid': formField.invalid,
      'ng-dirty': formField.dirty || formField.touched
    };
  }
}
