import { Injectable } from '@angular/core';

import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { LibraryFolder } from 'app/_shared/models/LibraryFolder';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService extends ApiService {
  getStorefrontLibraryFolders(storefrontId: number) {
    return this.get<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders`);
  }

  createStorefrontLibraryFolder(storefrontId: number, folder: LibraryFolder) {
    return this.post<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders`, folder);
  }

  updateStorefrontLibraryFolder(storefrontId: number, folderId: number, folder: LibraryFolder) {
    return this.put<LibraryFolder[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders/${folderId}`, folder);
  }

  deleteStorefrontLibraryFolder(storefrontId: number, folderId: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/libraries/folders/${folderId}`);
  }

  getLibraryFolderFiles(storefrontId: number, folderId: number) {
    return this.get<LibraryFile[]>(`${this.apiUrl}storefronts/${storefrontId}/libraries/library-folders/${folderId}`);
  }

  getLibraryFile(storefrontId: string, libraryId: string) {
    return this.get<LibraryFile>(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}`);
  }

  createLibraryFile(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries`, data);
  }

  updateLibraryFile(storefrontId: number, libraryId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/${libraryId}`, data);
  }

  getLatestUsedFiles(storefrontId: number) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/latest-used`);
  }

  createPrepFileRequest(storefrontId: number, data: any) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests`, data);
  }

  deletePreparationRequest(storefrontId: number, prepRequestId: number) {
    return this.delete(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests/${prepRequestId}`);
  }

  storefrontFilePrepRequestsPaginated(storefrontId: number, $event?: any) {
    return this.get(`${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests`, {
      params: this.handleTableLazyLoadEvent($event)
    });
  }

  allFilePrepRequestsPaginated($event?: any) {
    const params = {
      ...this.handleTableLazyLoadEvent($event),
      'relations[]': ['library.mockupFile', 'storefront']
    };
    return this.get(`${this.apiUrl}preparation-requests`, {
      params
    });
  }

  changeLibraryAdditionalFile(storefrontId: number, data: FormData) {
    return this.post(`${this.apiUrl}storefronts/${storefrontId}/libraries/library-files/additional-file`, data);
  }

  updatePrepRequestPaidStatus(storefrontId: number, paidStatus: number, prepRequestId?: number) {
    return this.put(
      `${this.apiUrl}storefronts/${storefrontId}/libraries/preparation-requests/${prepRequestId}/change-payment-status`,
      {
        status: 0
      }
    );
  }
}
