import { ChangeDetectionStrategy, Component, computed, effect, inject, input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';

@Component({
  selector: 'app-iframe',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './iframe.component.html',
  styleUrl: './iframe.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent implements OnInit, OnDestroy {
  storefrontStore = inject(StorefrontStore);
  designerStore = inject(DesignerStore);
  iframeUrl = input<SafeUrl>();
  isLoading = computed(() => this.designerStore.isLoading());
  iframeOrigin = computed(() => this.storefrontStore.selectedStorefront?.()?.url);
  constructor() {}

  ngOnInit() {
    window.addEventListener('message', event => this.receiveMessage(event), false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.receiveMessage(event));
  }

  receiveMessage(event: MessageEvent): void {
    if (event.origin !== this.iframeOrigin()) {
      return;
    }
    this.designerStore.setLoading(false);
  }
}
