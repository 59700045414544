<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="mb-4">
    <div class="text-900 text-xl font-bold mb-2">New Password</div>
    <span class="text-600 font-medium">Enter your new password</span>
  </div>
  <div class="flex flex-column">
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock z-2"></i>
        <p-password
          [feedback]="false"
          formControlName="password"
          placeholder="Password"
          styleClass="w-full"
          [inputStyle]="{ paddingLeft: '2.5rem' }"
          inputStyleClass="w-full md:w-25rem"
          [toggleMask]="true"
        ></p-password>
      </span>
      @if (submitted() || form.get('password')?.touched) {
        <div class="mt-1">
          @if (form.get('password')?.errors?.['required']) {
            <small class="p-text-danger">Password is required</small>
          } @else if (form.get('password')?.errors?.['passwordPattern']) {
            <div class="w-50%">
              <small class="p-text-danger">
                Password must be at least 8 characters,
                <br />
                contain at least one uppercase letter,
                <br />
                one lowercase letter, one number
                <br />
                and one special character.
              </small>
            </div>
          }
        </div>
      }
    </div>
    <div class="mb-4">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-key z-2"></i>
        <p-password
          [feedback]="false"
          formControlName="password_confirmation"
          placeholder="Confirm Password"
          styleClass="w-full"
          [inputStyle]="{ paddingLeft: '2.5rem' }"
          inputStyleClass="w-full md:w-25rem"
          [toggleMask]="true"
        ></p-password>
      </span>
      @if (submitted() || form.get('password_confirmation')?.touched) {
        <div class="mt-1">
          @if (form.get('password_confirmation')?.errors?.['required']) {
            <small class="p-text-danger">Password Confirmation is required</small>
          } @else if (form.errors?.['passwordsNotMatched']) {
            <small class="p-text-danger">Passwords no not match</small>
          }
        </div>
      }
    </div>
    <div class="flex flex-wrap gap-2 justify-content-between">
      <button pButton pRipple label="Cancel" class="flex-auto p-button-outlined" [routerLink]="['/']"></button>
      <button pButton pRipple label="Submit" class="flex-auto" type="submit" [disabled]="isLoading()"></button>
    </div>
  </div>
</form>
