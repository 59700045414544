<div class="grid">
  <div class="col-12">
    <div class="card">
      <h4>Store Settings</h4>
      <p-tabMenu [model]="menuItems" />

      <div class="mt-3">
        <router-outlet />
      </div>
    </div>
  </div>
</div>
