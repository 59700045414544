import { ChangeDetectionStrategy, Component, computed, inject, model, OnInit, output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfirmationService, MenuItem, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { Menu as PMenu } from 'primeng/menu/menu';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { v4 as uuidv4 } from 'uuid';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Page } from 'app/_shared/interfaces/designer/page';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

@Component({
  selector: 'app-pages-list',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    DialogModule,
    FormsModule,
    InputComponent,
    MenuModule,
    OrderListModule,
    PanelModule,
    SharedModule,
    ReactiveFormsModule,
    ConfirmDialogModule
  ],
  providers: [ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pages-list.component.html',
  styleUrl: './pages-list.component.scss'
})
export class PagesListComponent implements OnInit {
  selectedPage = model<Page[]>([]);
  onSetIframeUrl = output<Page>();
  designerHelperService = inject(DesignerHelperService);

  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  pages = computed(() => this.designerStore.config().pages ?? []);
  menu = computed(() => this.designerStore.config().menu ?? []);

  showNewPageDialog: boolean = false;
  pagePopupItems: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-file-edit',
      command: event => {
        event.originalEvent?.stopPropagation();
        this.handleEditPage(event?.item?.page);
      }
    },
    {
      separator: true
    },
    {
      label: 'Delete',
      icon: 'pi pi-times',
      command: event => {
        event.originalEvent?.stopPropagation();
        this.handleDeletePage(event?.item?.page.id);
      }
    }
  ];
  newPageForm: FormGroup;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.newPageForm = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      url: this.fb.control('', [Validators.required])
    });
  }

  get title(): FormControl {
    return this.newPageForm.get('title') as FormControl;
  }
  get url(): FormControl {
    return this.newPageForm.get('url') as FormControl;
  }

  togglePagePopup($event: Event, menu: PMenu, page: any) {
    $event.stopPropagation();
    this.pagePopupItems.forEach(menuItem => {
      menuItem.page = page;
    });
    menu.toggle($event);
  }

  selectionChange($event: any) {
    this.onSetIframeUrl.emit($event.value[0]);
    //  Force only single page select
    if ($event.value.length > 1) {
      $event.value.splice(0, $event.value.length - 1);
    }
  }

  handleDeletePage(pageId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this page?',
      header: 'Delete Page',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        const data = {
          pages: this.pages().filter(page => page.id !== pageId),
          menu: this.menu().filter(item => item.page.id !== pageId)
        };
        this.designerStore.saveConfig(this.storefrontId()!, data, true).subscribe(next => {
          this.designerHelperService.sendToPreview(this.storefrontId()!, next);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Page deleted successfully.'
          });
        });
      },
      reject: () => {}
    });
  }

  handleCreatePage() {
    const pages = [...this.pages(), { ...this.newPageForm.value, sections: [], id: uuidv4() } as Page];
    this.showNewPageDialog = false;
    this.newPageForm.reset();
    this.designerStore.saveConfig(this.storefrontId()!, { pages }).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Page created successfully.'
      });
    });
  }

  handleEditPage(page: Page) {}

  protected readonly FormControl = FormControl;
}
