@if (formReady) {
  <p-dialog
    [(visible)]="visible"
    [style]="{ width: '28rem' }"
    header="Storefront Details"
    [modal]="true"
    class="p-fluid"
    (onHide)="hideDialog()"
    [closable]="!isLoading"
  >
    <ng-template pTemplate="content">
      <form [formGroup]="storefrontForm" (ngSubmit)="onSubmit()">
        <div class="field">
          <label for="name-input">Name</label>
          <input id="name-input" type="text" pInputText formControlName="name" />
          @if (
            submitted ||
            (storefrontForm.get('name')?.invalid &&
              (storefrontForm.get('name')?.dirty || storefrontForm.get('name')?.touched))
          ) {
            @if (storefrontForm.get('name')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Name is required</small>
              </div>
            }
          }
        </div>
        <div class="field">
          <label for="subdomain">Url</label>
          <p-inputGroup class="w-full">
            <p-inputGroupAddon>{{ prefix }}</p-inputGroupAddon>
            <input id="subdomain" type="text" pInputText formControlName="subdomain" />
            <p-inputGroupAddon>{{ suffix }}</p-inputGroupAddon>
          </p-inputGroup>
          @if (
            submitted ||
            (storefrontForm.get('subdomain')?.invalid &&
              (storefrontForm.get('subdomain')?.dirty || storefrontForm.get('subdomain')?.touched))
          ) {
            @if (storefrontForm.get('subdomain')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Store subdomain is required</small>
              </div>
            }
            @if (storefrontForm.get('subdomain')?.errors?.['invalidSubdomain']) {
              <div>
                <small class="p-text-danger">Invalid subdomain format. (Allowed a-z, A-Z, 0-9, -)</small>
              </div>
            }
          }
        </div>
      </form>
      <div class="field">
        <p-fileUpload
          [multiple]="false"
          [customUpload]="true"
          (onSelect)="onLogoUpload($event)"
          accept="image/*"
          maxFileSize="1000000"
          showUploadButton="false"
          customUpload="true"
        >
          <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
              <div class="w-full flex justify-content-between gap-2">
                <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Logo" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="file"></ng-template>
          <ng-template pTemplate="content">
            @if (logo?.objectURL) {
              <div class="flex">
                <div class="col-4">
                  <img [src]="logo?.objectURL" alt="Uploaded Image" style="width: 50px" />
                </div>
                <div class="col-4">
                  <div class="flex justify-content-center align-items-center text-center h-full">
                    {{ logo?.name }}
                  </div>
                </div>
                <div class="col-4">
                  <div class="flex justify-content-end">
                    <p-button type="button" icon="pi pi-times" (onClick)="removeLogo()"></p-button>
                  </div>
                </div>
              </div>
            }
          </ng-template>
        </p-fileUpload>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
        outlined
        [disabled]="isLoading"
      ></p-button>
      <p-button
        type="button"
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        (click)="onSubmit()"
        [disabled]="isLoading"
      ></p-button>
    </ng-template>
  </p-dialog>
}
