import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [RouterOutlet, TabMenuModule],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent implements OnInit {
  routeItems: MenuItem[] = [];
  activeItem: MenuItem | undefined;

  ngOnInit(): void {
    this.routeItems = [
      { label: 'Homepage', routerLink: '../homepage', icon: 'pi pi-home' },
      { label: 'Menu and Pages', routerLink: '../menu-and-pages' },
      { label: 'Settings', routerLink: '../settings' }
    ];
    this.activeItem = this.routeItems[0];
  }
}
