import { HitSupplierProduct } from 'app/_shared/interfaces/supplier';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export class HitProductMapper extends SupplierProduct {
  constructor(product: HitSupplierProduct) {
    super(product);
    this.name = product.product_name;
    this.description = product.description;
  }
}
