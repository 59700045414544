import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { CustomProductVariant, CustomProductVariantSize } from 'app/_shared/models/CustomProduct';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-custom-product-variant-editor',
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    DialogModule,
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    InputComponent,
    ConfirmDialogModule
  ],
  providers: [ConfirmationService],

  templateUrl: './custom-product-variant-editor.component.html',
  styleUrl: './custom-product-variant-editor.component.scss'
})
export class CustomProductVariantEditorComponent implements OnChanges {
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  confirmationService = inject(ConfirmationService);

  @Input() variant: CustomProductVariant | null;
  @Input() visible: boolean;
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() validFormEmitter: EventEmitter<CustomProductVariant> = new EventEmitter<CustomProductVariant>();
  @Output() deletedProductSizesEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  variantForm: FormGroup;
  submitted = false;

  frontImage: string;
  rearImage: string;
  deletedProductSizes: number[] = [];

  ngOnChanges() {
    if (this.visible) {
      this.buildForm();
    }
    if (this.variant) {
      this.mapImageUrls();
    }
  }

  mapImageUrls() {
    if (this.variant?.front_image) {
      this.frontImage = this.variant?.front_image?.full_path || this.variant?.front_image;
    }
    if (this.variant?.rear_image) {
      this.rearImage = this.variant?.rear_image?.full_path || this.variant?.rear_image;
    }
  }

  buildForm() {
    this.variantForm = this.fb.group({
      id: [this.variant?.id || null],
      color: [this.variant?.color || '', [Validators.required]],
      product_variant_sizes: this.fb.array([]),
      front_image: [this.variant?.front_image],
      rear_image: [this.variant?.rear_image]
    });

    if (this.variant && this.variant?.product_variant_sizes?.length > 0) {
      this.variant?.product_variant_sizes.forEach(size => this.addSize(size));
    } else {
      this.addSize();
    }
  }

  addSize(variantSize?: CustomProductVariantSize) {
    const sizeFormGroup = this.fb.group({
      id: [variantSize?.id || null],
      size: [variantSize?.size || '', [Validators.required]],
      weight: [variantSize?.weight || '', [Validators.required]],
      price: [variantSize?.price ?? '', Validators.required],
      quantity: [variantSize?.quantity || '', [Validators.required]]
    });
    this.variantSizes.push(sizeFormGroup);
  }

  removeSize(index: number) {
    const sizeToRemove = this.variantSizes.at(index).value;

    if (sizeToRemove.id) {
      this.deletedProductSizes.push(sizeToRemove.id);
    }

    this.variantSizes.removeAt(index);

    this.deletedProductSizesEmitter.emit(this.deletedProductSizes);
  }

  get variantSizes() {
    return this.variantForm.get('product_variant_sizes') as FormArray;
  }

  hideDialog() {
    this.cancelEdit.emit();
  }

  onSubmit() {
    if (this.variantForm.invalid) {
      this.variantForm.markAllAsTouched();
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    if (!this.frontImage || !this.rearImage) {
      this.toastService.error('Front and rear images are required!');
      return;
    }

    const variant = this.variantForm.value as CustomProductVariant;
    this.updateVariant(variant);
    this.visible = false;
    this.frontImage = '';
    this.rearImage = '';
  }

  updateVariant(variant: CustomProductVariant) {
    this.validFormEmitter.emit(variant);
  }

  openDeleteMediaModal(imageType: 'front' | 'rear') {
    this.confirmationService.confirm({
      key: 'confirmDeleteMedia',
      message: 'Are you sure that you want to delete this image',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteVariantMedia(imageType);
      }
    });
  }

  deleteVariantMedia(imageType: 'front' | 'rear') {
    this.variantForm.get(`${imageType}_image`)?.setValue(null);
    imageType === 'front' ? (this.frontImage = '') : (this.rearImage = '');
  }

  onFileChange(event: Event, imageType: 'front' | 'rear') {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const imageUrl = e.target?.result as string;

        if (imageType === 'front') {
          this.variantForm.get('front_image')?.setValue(imageUrl);
          this.frontImage = imageUrl;
        } else if (imageType === 'rear') {
          this.variantForm.get('rear_image')?.setValue(imageUrl);
          this.rearImage = imageUrl;
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
