import { Environment } from 'app/_shared/interfaces/environment';

export const environment: Environment = {
  API_URL: 'https://api.singleserve.dev/api/',
  STORAGE_URL: 'https://api.singleserve.dev/storage/',
  PREFIX: 'https://',
  SUFFIX: 'singleserve.dev',
  stripeKey:
    'pk_test_51QAs5SBCZkSJTuhj8pPh5Tj3tUyn07t9K18HctRQpV8hpspcmMXpstx8ia3mZapwuRfRj3rrfDAa67so8JF41Y4A00e4RkVTeY',
  REVERB_APP_ID: '205020',
  REVERB_APP_KEY: 'ekuw1wujmf47jq6uw364',
  REVERB_APP_SECRET: 'gyq83ntb1vppddcr28zs',
  REVERB_WS_HOST: 'ws-api.singleserve.dev'
};
