import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StorefrontProduct } from 'app/_shared/interfaces/storefront';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { StorefrontService } from 'app/_shared/services/storefront.service';

@Component({
  selector: 'app-single-product',
  standalone: true,
  imports: [UsdPipe, CommonModule],
  templateUrl: './single-product.component.html',
  styleUrl: './single-product.component.scss'
})
export class SingleProductComponent implements OnInit {
  storefrontService = inject(StorefrontService);
  activatedRoute = inject(ActivatedRoute);
  storefrontId: string;
  productId: string;
  product: StorefrontProduct;
  ngOnInit() {
    this.storefrontId = this.activatedRoute.snapshot.paramMap.get('storefrontId') ?? '';
    this.productId = this.activatedRoute.snapshot.paramMap.get('productId') ?? '';

    this.storefrontService.getStorefrontProduct(this.storefrontId, this.productId).subscribe(next => {
      this.product = next.data;
    });
  }
}
