@if (order) {
  <p-dialog
    [(visible)]="visible"
    [style]="{ width: '28rem' }"
    header="Quick Preview"
    [modal]="true"
    class="p-fluid"
    (onHide)="hideDialog()"
  >
    <ng-template pTemplate="content">
      <div class="flex flex-column gap-2">
        <div class="mb-5 sm:mb-2">
          <span class="font-medium text-xl text-900 mr-2">Order ID:</span>
          <span class="font-medium text-xl text-blue-500">{{ order.id }}</span>
        </div>

        <div class="w-full">
          <ul class="list-none p-0 m-0 w-full">
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Created at</span>
              <span class="text-900 font-medium text-lg">{{ order.created_at | usdDate }}</span>
            </li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Store</span>
              <span class="text-900 font-medium text-lg">{{ order.storefront.name }}</span>
            </li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Sales tax</span>
              <span class="text-900 font-medium text-lg">{{ order.sales_tax | price | usdPipe }}</span>
            </li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Shipping price</span>
              <span class="text-900 font-medium text-lg">{{ order.shipping_price | price | usdPipe }}</span>
            </li>

            <li class="flex justify-content-between border-top-1 surface-border py-3">
              <span class="text-900 font-medium">Total</span>
              <span class="text-900 font-bold text-lg">{{ order.total | price | usdPipe }}</span>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        type="button"
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        outlined
        (click)="hideDialog()"
      ></p-button>
    </ng-template>
  </p-dialog>
}
