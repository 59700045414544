import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthStore } from '../../_store/auth.store';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authStore = inject(AuthStore);

  if (authStore.user()) {
    return true;
  }

  localStorage.setItem('previousURL', window.location.href);
  router.navigateByUrl('/auth/login');
  return false;
};

export const verifiedUserGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authStore = inject(AuthStore);

  if (authStore.isVerified()) {
    return true;
  }

  router.navigateByUrl('/auth/email-verification');
  return false;
};

export const nonVerifiedUserGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authStore = inject(AuthStore);

  if (!authStore.isVerified()) {
    return true;
  }

  router.navigateByUrl('');
  return false;
};
