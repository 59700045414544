import { AlphaBroaderSupplierProduct } from 'app/_shared/interfaces/supplier';
import { SupplierProduct } from 'app/_shared/models/supplier-product.model';

export class AlphaBroaderProductMapper extends SupplierProduct {
  constructor(product: AlphaBroaderSupplierProduct) {
    super(product);
    // this.price = product.price;
    this.name = product.name;
    this.description = product.additional_description;
  }
}
