import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { TableLazyLoadEvent } from 'primeng/table';
import { catchError, map, Observable, throwError } from 'rxjs';

import { AuthStore } from 'app/_store/auth.store';

import { IApiResponse } from '../interfaces/IApiResponse';
import { ToastService } from './toast.service';

export abstract class ApiService {
  protected apiUrl = environment.API_URL;
  private http = inject(HttpClient);
  protected router = inject(Router);
  protected authStore = inject(AuthStore);
  toastService = inject(ToastService);

  protected get<T = any>(url: string, options?: object) {
    return this.http.get<IApiResponse<T>>(url, options).pipe(catchError(err => this.handleError(err)));
  }

  protected post<T = any>(url: string, body?: any, options?: any) {
    return this.http.post(url, body, options).pipe(
      map(res => res as unknown as IApiResponse<T>),
      catchError(err => this.handleError(err))
    );
  }

  protected put(url: string, body: any, options?: any): Observable<IApiResponse> {
    return this.http.put<HttpResponse<IApiResponse>>(url, body, options).pipe(
      map(res => res as unknown as IApiResponse),
      catchError(err => this.handleError(err))
    );
  }

  protected delete(url: string, options?: any): Observable<IApiResponse> {
    return this.http.delete<HttpResponse<IApiResponse>>(url, options).pipe(
      map(res => res as unknown as IApiResponse),
      catchError(err => this.handleError(err))
    );
  }

  protected patch<T = any>(url: string, body?: any, options?: any): Observable<IApiResponse<T>> {
    return this.http.patch<HttpResponse<IApiResponse<T>>>(url, body, options).pipe(
      map(res => res as unknown as IApiResponse<T>),
      catchError(err => this.handleError(err))
    );
  }

  /**
   * Handle all api errors.
   *
   * @param err Error from backend
   *
   * @return Observable<never>
   */
  public handleError(err: any): Observable<never> {
    if (err.error?.status === 400 || err.status === 400) {
      this.toastService.error(err.error.message);
    }
    if (err.error?.status === 401 || err.status === 401) {
      this.handleAuthError();
    }
    if (err.error?.status === 403 || err.status === 403) {
      this.handleUnauthorizedError();
    }
    if (err.error?.status === 422 || err.status === 422) {
      this.handleUnprocessableEntityError(err);
    }
    if (err.error?.status === 500 || err.status === 500) {
      this.toastService.error('Something went wrong. Please try again!');
    }

    return throwError(err);
  }

  public handleAuthError(): void {
    // If user is not authorized or token expired
    // Clear token
    this.authStore.clearAuthUser();
  }

  public handleUnauthorizedError(): void {
    console.error('NOT IMPLEMENTED');
  }

  public handleUnprocessableEntityError(error: HttpErrorResponse): void {
    if (error.error && error.error.errors) {
      Object.keys(error.error.errors).forEach(field => {
        error.error.errors[field].forEach((message: string) => {
          this.toastService.error(message);
        });
      });
    }
  }

  //  TODO: this needs to be removed from here @Aleksandar
  protected handleTableLazyLoadEvent(event?: TableLazyLoadEvent, filters?: any) {
    const params: any = {};

    if (event) {
      const { sortField, sortOrder, globalFilter, rows, first } = event;

      if (sortField) {
        params.sort_field = sortField;
        params.sort_order = sortOrder === -1 ? 'desc' : 'asc';
      }

      if (globalFilter) {
        params.global_filter = globalFilter;
      }

      if (rows) {
        params.rows = rows;
        params.page = first ? Math.floor(first / rows) + 1 : 1;
      }
    }

    if (filters) {
      Object.assign(params, filters);
    }

    return params;
  }
}
