import { JsonPipe } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { PrimeTemplate } from 'primeng/api';
import { ButtonDirective } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';

import { InputComponent } from 'app/_shared/components/input/input.component';
import {
  Columns,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { PaginatorOptions } from 'app/_shared/interfaces/IApiPaginatedData';
import { ClientSelectionList, Collection } from 'app/_shared/interfaces/clientSelection';
import { Storefront } from 'app/_shared/models/Storefront';
import { CompanyService } from 'app/_shared/services/company.service';
import { CompanyStore } from 'app/_store/company.store';

@Component({
  selector: 'app-client-selection-editor',
  standalone: true,
  imports: [
    ButtonDirective,
    DialogModule,
    DropdownModule,
    InputComponent,
    ListProductsComponent,
    PaginatorModule,
    PrimeTemplate,
    ReactiveFormsModule,
    JsonPipe,
    RouterLink,
    FileUploadModule
  ],
  templateUrl: './client-selection-editor.component.html',
  styleUrl: './client-selection-editor.component.scss'
})
export class ClientSelectionEditorComponent implements OnInit {
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  companyService = inject(CompanyService);
  companyStore = inject(CompanyStore);

  selection = signal<ClientSelectionList | undefined>(undefined);
  selectedItems: Collection[] = [];
  allItemsSelected: boolean;
  collections: Collection[];
  companyId: string;
  selectionId: string;
  selectionForm: FormGroup;
  selectedStorefront: Storefront;
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };
  cols: Columns[] = [{ field: 'title', header: 'Title', width: '90%' }];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.id;
      this.selectionId = params.selectionId;
    });
    if (this.selectionId) {
      this.companyService
        .getSelection(this.companyId, this.selectionId, { 'relations[]': ['catalogProductCollections', 'logo'] })
        .subscribe(next => {
          this.selection.set(next.data);
          this.buildForm(next.data);
          this.selectedItems = next.data.catalog_product_collections;
        });
    } else {
      this.buildForm();
    }
  }

  get logo() {
    return this.selectionForm.value.logo?.objectURL ?? this.selectionForm.value.logo;
  }

  buildForm(data?: ClientSelectionList) {
    this.selectionForm = new FormGroup<{
      title: AbstractControl;
      comment: AbstractControl;
      id: AbstractControl;
      logo: AbstractControl;
    }>({
      id: new FormControl(data?.id ?? ''),
      title: new FormControl(data?.title ?? '', [Validators.required]),
      comment: new FormControl(data?.comment ?? '', [Validators.required]),
      logo: new FormControl(data?.logo?.full_path)
    });
  }

  getCollections(paginatorOptions: ProductPaginatorOptions | PaginatorOptions) {
    const { rows, first } = paginatorOptions;
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1
    };
    this.companyService.getCollections(1, params).subscribe(next => {
      this.collections = next.data.data;
      this.paginatorOptions = {
        total: next.data.total,
        rows: next.data.per_page,
        first: next.data.from - 1
      };
    });
  }

  handleCreateEditSelection() {
    const { title, comment, id, logo } = this.selectionForm.value;
    const selectedItems = this.selectedItems.map(item => item.id.toString());
    const values = new FormData();
    values.append('title', title);
    values.append('comment', comment);
    if (logo instanceof File) {
      values.append('logo', logo);
    } else if (logo === null) {
      if (this.selection()?.logo) values.append('delete_logo', '1');
    }
    selectedItems.forEach(item => {
      values.append('catalog_product_collections[]', item);
    });

    if (this.selectionId) {
      values.append('id', id);
      values.append('_method', 'patch');
      return this.companyService.editSelection(this.companyId, id, values);
    }
    return this.companyService.createSelection(this.companyId, values);
  }

  handleSubmit() {
    this.handleCreateEditSelection().subscribe(() => {
      this.router.navigate([this.selectionId ? '../../' : '../'], { relativeTo: this.activatedRoute });
    });
  }

  handleSelectionChange($event: Collection[] | 'allSelected') {
    this.allItemsSelected = $event === 'allSelected';
  }

  onUpload($event: FileSelectEvent) {
    this.selectionForm.patchValue({ logo: $event.files[0] });
  }

  removeImage() {
    this.selectionForm.patchValue({ logo: null });
  }
}
