<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  message="Are you sure you want to proceed?"
  [style]="{ width: '450px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
/>
<div class="list-actions ga-2">
  <p-splitButton outlined label="Actions" [model]="actionButtonItems" />
  <a class="p-button" routerLink="create">
    Create
    <i class="ml-2 pi pi-plus"></i>
  </a>
</div>

<p-table
  [value]="promoCodes"
  dataKey="id"
  [lazy]="true"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [loading]="isLoading"
  responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [globalFilterFields]="['name']"
  (onLazyLoad)="loadPromoCodes($event)"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox name="Select all" />
      </th>
      <th pSortableColumn="created_at">
        Created
        <p-sortIcon field="created_at" />
      </th>
      <th>Promo code name</th>
      <th>Total value</th>
      <th>Times used</th>
      <th>Total Discount Amount</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-promoCode>
    <tr>
      <td>
        <p-tableCheckbox [value]="promoCodes" [name]="promoCode.name" />
      </td>
      <td>{{ promoCode.created_at | usdDate }}</td>
      <td>{{ promoCode.name }}</td>
      <td>{{ promoCode.promo_code_value | usdPipe }}</td>
      <td>{{ promoCode.number_of_uses || '/' }}</td>
      <td>200$</td>
      <td>
        <p-splitButton
          label="Actions"
          appendTo="body"
          [model]="promoCodeItemActionsModel"
          (onDropdownClick)="onDropdownClick($event, promoCode)"
        />
      </td>
    </tr>
  </ng-template>
</p-table>
