import { Component, inject, OnInit } from '@angular/core';

import { CardModule } from 'primeng/card';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { finalize } from 'rxjs';

import {
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { CreateProductComponent } from 'app/admin/storefront/products/create-product/create-product.component';

@Component({
  selector: 'app-list-catalog-products',
  standalone: true,
  imports: [CardModule, ListProductsComponent],
  providers: [DialogService],
  templateUrl: './list-catalog-products.component.html',
  styleUrl: './list-catalog-products.component.scss'
})
export class ListCatalogProductsComponent implements OnInit {
  catalogService = inject(CatalogService);
  ref: DynamicDialogRef | undefined;
  isLoading: boolean = false;
  catalogProducts: CatalogProduct[];
  cols = [
    { element: 'image', field: 'featured_image', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '65%' },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '15%',
      actions: [
        {
          icon: 'pi pi-plus',
          label: '',
          onClick: ($event: MouseEvent, product: CatalogProduct) => {
            $event.stopPropagation();
            this.openCreateModal(product.id);
          }
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 5,
    first: 0
  };

  constructor(public dialogService: DialogService) {}

  ngOnInit(): void {
    this.isLoading = true;
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoading = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      'relations[]': ['catalogProductConfiguration', 'featuredImage']
    };
    this.catalogService
      .getProducts(params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: products => {
          this.catalogProducts = products.data.data;
          this.paginatorOptions = {
            total: products.data.total,
            rows: products.data.per_page,
            first: products.data.from - 1
          };
        },
        error: err => {
          console.error(err);
        }
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchProducts($event);
  }

  openCreateModal(productId: number) {
    this.ref = this.dialogService.open(CreateProductComponent, {
      data: {
        productId: productId,
        editMode: false
      },
      focusOnShow: false,
      header: undefined, // 'Product Creator',
      showHeader: false,
      contentStyle: {
        padding: '0px',
        borderRadius: 0,
        width: '100%'
      }
    });
  }
}
