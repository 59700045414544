import { Injectable } from '@angular/core';

import { ApiService } from 'app/_shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiService {
  routePrefix = `${this.apiUrl}notifications`;

  public getLatestNotifications() {
    return this.get(`${this.routePrefix}/latest`);
  }
}
