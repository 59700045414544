import { Injectable } from '@angular/core';

import { DesignerImage } from 'app/_shared/interfaces/designer/image';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DesignerService extends ApiService {
  routePrefix = `${this.apiUrl}storefronts/`;

  saveConfig(storefrontId: number, data) {
    return this.post(`${this.routePrefix}${storefrontId}/design`, data);
  }

  sendToPreview(storefrontId: number, data) {
    return this.post(`${this.routePrefix}${storefrontId}/designer/preview`, data);
  }

  uploadLogoPreview(data: FormData, storefrontId: number) {
    return this.post(`${this.routePrefix}${storefrontId}/designer/upload-logo`, data);
  }

  uploadSectionImage(data: FormData, storefrontId: number) {
    return this.post<DesignerImage>(`${this.routePrefix}${storefrontId}/designer/upload-section-image`, data);
  }

  removeSectionImage(storefrontId: number, imageId: string) {
    return this.delete(`${this.routePrefix}${storefrontId}/designer/remove-section-image/${imageId}`);
  }

  removeLogo(storefrontId: number) {
    return this.post(`${this.routePrefix}${storefrontId}/designer/remove-logo`);
  }

  getDesignSettings(storefrontId: number) {
    return this.get(`${this.routePrefix}${storefrontId}/design`);
  }
}
