import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { DataViewLazyLoadEvent, DataViewModule } from 'primeng/dataview';
import { finalize } from 'rxjs';

import { ProductPaginatorOptions } from 'app/_shared/components/list-products/list-products.component';
import { toSnakeCase } from 'app/_shared/helpers/shared';
import { LibraryFile } from 'app/_shared/models/LibraryFile';
import { PricePipe } from 'app/_shared/pipes/price.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { LibraryService } from 'app/_shared/services/library.service';

@Component({
  selector: 'app-libraries-list',
  standalone: true,
  imports: [Button, DataViewModule, PricePipe, PrimeTemplate, UsdPipe, RouterLink],
  templateUrl: './libraries-list.component.html',
  styleUrl: './libraries-list.component.scss'
})
export class LibrariesListComponent implements OnInit {
  private libraryService = inject(LibraryService);
  private activatedRoute = inject(ActivatedRoute);

  storefrontId: string;
  libraryFolderId: string;
  isLoading: false;
  libraries = signal<(LibraryFile & { mainImage: string })[]>([]);
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 9,
    first: 0
  };
  ngOnInit() {
    this.activatedRoute.params.subscribe(next => {
      this.storefrontId = next.storefrontId;
      this.libraryFolderId = next.libraryFolderId;
    });
    // this.getLibraries(this.storefrontId, this.libraryFolderId);
  }

  getLibraries($event: ProductPaginatorOptions | DataViewLazyLoadEvent) {
    this.libraryService
      .getLibraryFolderFiles(+this.storefrontId, +this.libraryFolderId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: data => {
          const libraries = data.data.map(lib => {
            const mockupFormated = toSnakeCase(lib?.main_mockup ?? '');
            return { ...lib, mainImage: lib[mockupFormated].full_path };
          });
          this.libraries.set(libraries);
          // this.paginatorOptions = {
          //   total: products.data.total,
          //   rows: products.data.per_page,
          //   first: products.data.from - 1
          // };
        },
        error: err => {
          console.error(err);
        }
      });
  }
}
