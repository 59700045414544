import { NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { FilePrepRequest } from 'app/_shared/models/FilePrepRequest';
import { LibraryService } from 'app/_shared/services/library.service';
import { ToastService } from 'app/_shared/services/toast.service';

import { FilePrepDetailsDialogComponent } from './file-prep-details-dialog/file-prep-details-dialog.component';

@Component({
  selector: 'app-file-prep-request-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    SkeletonModule,
    InputTextModule,
    ProgressBarModule,
    NgStyle,
    ChipModule,
    ConfirmDialogModule,
    TooltipModule
  ],
  providers: [ConfirmationService, DialogService],
  templateUrl: './file-prep-request-list.component.html',
  styleUrl: './file-prep-request-list.component.scss'
})
export class FilePrepRequestListComponent {
  libraryService = inject(LibraryService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  requests: FilePrepRequest[];
  totalRecords: number;
  loading = false;

  rows = 10;
  dialogRef: DynamicDialogRef | undefined;

  loadingRows = Array(this.rows)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });

  loadFilePrepRequests($event?: any) {
    this.libraryService.allFilePrepRequestsPaginated($event).subscribe({
      next: res => {
        this.requests = res.data.data;
        this.totalRecords = res.data.total;
      },
      error: err => console.error(err),
      complete: () => (this.loading = false)
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  openDeleteModal(event: Event, prepRequestId: number, storefrontId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this preparation request?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deletePreparationRequest(storefrontId, prepRequestId);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  deletePreparationRequest(storefrontId: number, prepRequestId: number) {
    this.libraryService.deletePreparationRequest(storefrontId, prepRequestId).subscribe({
      next: () => {
        this.toastService.success('Preparation request successfully deleted!');
        this.loadFilePrepRequests();
      },
      error: err => {
        console.error('Delete action failed:', err);
      }
    });
  }

  openFilePrepDetailsDialog(request: FilePrepRequest) {
    this.dialogRef = this.dialogService.open(FilePrepDetailsDialogComponent, {
      header: 'Admin File Prep Request Details',
      width: '600px',
      data: {
        request
      }
    });
  }

  openChangePaymentStatusDialog(event: Event, request: FilePrepRequest, storefrontId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: 'confirmChangePaymentStatus',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to change payment status for this preparation request?',
      icon: 'pi pi-question',
      accept: () => {
        this.changePaymentStatusForPrepRequest(storefrontId, request);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-success',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  changePaymentStatusForPrepRequest(storefrontId: number, request: FilePrepRequest) {
    const newPaymentStatus = request.paid === 1 ? 0 : 1;
    this.libraryService.updatePrepRequestPaidStatus(storefrontId, newPaymentStatus, request.id).subscribe({
      next: () => {
        this.loadFilePrepRequests();
      },
      error: err => console.error(err),
      complete: () => (this.loading = false)
    });
  }
}
