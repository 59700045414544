<div class="login">
  <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
    <div class="header mb-4">
      <h1>
        Welcome to
        <br />
        Single Serve Merch
      </h1>

      <h4>Sign in with email</h4>
    </div>
    <div class="mb-4">
      <app-input
        [control]="loginForm.get('email')!"
        placeholder="example@example.com"
        [fullWidth]="true"
        label="Email"
      />
    </div>
    <div class="mb-1">
      <app-input
        [control]="loginForm.get('password')!"
        placeholder="Password"
        [fullWidth]="true"
        type="password"
        name="password"
        label="Password"
      />
    </div>
    <div class="mb-4">
      <p-checkbox name="checkbox" formControlName="remember" styleClass="mr-2" [binary]="true"></p-checkbox>
      <label for="checkbox" class="text-900 font-medium mr-8">Remember Me</label>
    </div>

    <div class="mb-4">
      <p class="dont-have-account">
        Dont have an account?
        <a routerLink="/auth/register">Create Account</a>
      </p>
      <p class="dont-have-account">
        <a routerLink="/auth/forgot-password">Forgot Password</a>
      </p>
    </div>
    <p-button [style]="{ width: '100%' }" label="Log In" class="w-full mb-4" type="submit" [disabled]="isLoading()" />
    <!--      <span class="font-medium text-600">-->
    <!--        Don't have an account?-->
    <!--        <br />-->
    <!--        <a-->
    <!--          (click)="openInviteCompanyModal()"-->
    <!--          class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"-->
    <!--        >-->
    <!--          Request invite to SSM platform-->
    <!--        </a>-->
    <!--      </span>-->
  </form>
</div>
