import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { CompanyStore } from 'app/_store/company.store';

import { StorefrontStore } from '../../_store/storefront.store';
import { CompanyService } from '../services/company.service';

export const companyResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const companyStore = inject(CompanyStore);
  const companyService = inject(CompanyService);

  const { id } = route.params;

  companyStore.setLoading(true);
  return companyService.getById(+id).subscribe({
    next: res => companyStore.setWorkingCompany(res.data),
    error: error => console.log(error),
    complete: () => companyStore.setLoading(false)
  });
};

export const unselectCompanyResolver: ResolveFn<any> = () => {
  // TODO: This shouldn't be in the resolver
  // TODO: Remove from here @Aleksandar

  const companyStore = inject(CompanyStore);
  const storefrontStore = inject(StorefrontStore);

  companyStore.unsetWorkingCompany();
  storefrontStore.unsetSelectedStorefront();
};
