import { ChangeDetectorRef, Component, effect, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, finalize } from 'rxjs';

import { Storefront } from 'app/_shared/models/Storefront';
import { DisplayDatePipe } from 'app/_shared/pipes/date.pipe';
import { UsdPipe } from 'app/_shared/pipes/usd-currency.pipe';
import { TransactionService } from 'app/_shared/services/transaction.service';
import { CompanyStore } from 'app/_store/company.store';

@Component({
  selector: 'app-transactions',
  standalone: true,
  imports: [
    TableModule,
    TagModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    SkeletonModule,
    DisplayDatePipe,
    UsdPipe
  ],
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit {
  companyStore = inject(CompanyStore);
  transactionService = inject(TransactionService);

  transactions: any[] = [];

  storefronts: Storefront[] | undefined = [];

  totalRecords: number;

  filtersSubject = new BehaviorSubject<any>(null);

  filtersChanged = false;
  lastLazyLoadEvent: TableLazyLoadEvent | undefined;

  expandedRows: { [key: number]: boolean } = {};
  isAllExpanded = false;

  globalFilter: string;
  selectedStorefront: string | null;

  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);

  ngOnInit() {
    combineLatest([this.filtersSubject.pipe(debounceTime(300), distinctUntilChanged())]).subscribe(() => {
      if (this.filtersChanged) {
        this.loadTransactions(this.lastLazyLoadEvent);
      }
      this.filtersChanged = false;
    });
  }

  private storefrontEff = effect(() => {
    this.storefronts = this.companyStore.workingCompany()?.storefronts;
  });

  onFilterChange(filterKey: string, filterValue: any): void {
    const updatedFilters = { ...this.filtersSubject.value };

    if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
      updatedFilters[filterKey] = filterValue;
    } else {
      delete updatedFilters[filterKey];
    }

    this.filtersSubject.next(updatedFilters);
    this.filtersChanged = true;
  }

  loadTransactions(event?: TableLazyLoadEvent) {
    if (event) {
      this.lastLazyLoadEvent = event;
    }

    const filters = this.filtersSubject.value || {};
    const params = {
      ...filters
    };

    this.transactionService.index(this.companyStore.workingCompany()?.id, params).subscribe({
      next: res => {
        this.transactions = res.data;
        this.totalRecords = res.data.length;
      },
      error: error => console.error(error)
    });
  }

  toggleExpandAll() {
    const allExpanded =
      Object.keys(this.expandedRows).length === this.transactions.length &&
      Object.values(this.expandedRows).every(Boolean);
    if (allExpanded) {
      this.expandedRows = {};
    } else {
      this.expandedRows = this.transactions.reduce((acc, transaction) => {
        acc[transaction.id] = true;
        return acc;
      }, {});
    }
    this.isAllExpanded = !allExpanded;
  }
}
