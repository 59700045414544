import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { finalize, Subscription } from 'rxjs';

import { CompanyStore } from 'app/_store/company.store';

import { User } from '../../models/User';
import { CompanyService } from '../../services/company.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';
import { UserPerrmissionsModalComponent } from '../user-permissions-modal/user-permissions-modal.component';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [CommonModule, FormsModule, InputNumberModule, ButtonModule, RippleModule, TabViewModule, SkeletonModule],
  providers: [DialogService],
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  userService = inject(UserService);
  companyService = inject(CompanyService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  toastService = inject(ToastService);
  companyStore = inject(CompanyStore);
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;
  isLoading = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => index + 1);
  subscriptions: Subscription[] = [];

  user: User;
  companyUser = false;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyUser = params.companyUser;
      this.checkUrlForUserId(params.id);
    });
  }

  checkUrlForUserId(userId: string): void {
    if (userId) {
      if (!this.companyUser) {
        this.fetchUser(+userId);
        return;
      }
      this.fetchCompanyUser(+userId, this.companyStore.workingCompany()?.id);
      return;
    }

    this.router.navigate([`../`], { relativeTo: this.route });
  }

  fetchUser(id: number) {
    this.isLoading = true;
    this.subscriptions.push(
      this.userService
        .getById(id)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: response => {
            this.user = response.data;
          },
          error: error => {
            console.error(error);
            this.toastService.error('Error occurred on user fetch. Please try again!');
            this.router.navigate(['../'], { relativeTo: this.route });
          }
        })
    );
  }

  fetchCompanyUser(userId: number, workingCompanyId?: number) {
    this.isLoading = true;
    this.subscriptions.push(
      this.companyService
        .getSingleCompanyUser(userId, workingCompanyId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: response => {
            this.user = response.data;
          },
          error: error => {
            console.error(error);
            this.toastService.error('Error occurred on user fetch. Please try again!');
            this.router.navigate(['../'], { relativeTo: this.route });
          }
        })
    );
  }

  openPermissionEditor() {
    this.dialogRef = this.dialogService.open(UserPerrmissionsModalComponent, {
      header: 'Change User Permissions',
      width: '540px',
      data: {
        userCompany: this.user?.companies![0],
        userId: this.user.id
      }
    });
    this.dialogRef.onClose.subscribe((res: boolean) => {
      if (res) {
        this.toastService.success('The user permissions have been successfully updated.');
        this.fetchCompanyUser(this.user.id, this.companyStore.workingCompany()?.id);
      }
    });
  }

  noStorefronts() {
    return this.user?.companies?.every(company => company.storefronts.length === 0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
