<p-card>
  <h4 class="mb-3">Suppliers list</h4>
  <p-table [value]="suppliers" responsiveLayout="scroll" [rowHover]="true">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" style="width: 25%">Name</th>
        <th class="white-space-nowrap" style="width: 25%">Slug</th>
        <th class="white-space-nowrap text-center" style="width: 20%">Imported products</th>
        <th class="white-space-nowrap text-center" style="width: 15%">Status</th>
        <th class="white-space-nowrap text-center" style="width: 15%">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-supplier>
      <tr>
        <td>{{ supplier.name }}</td>
        <td>{{ supplier.slug }}</td>
        <td class="text-center">{{ supplier.product_count }}</td>
        <td class="text-center">
          <app-status-badge [status]="'active'" />
        </td>
        <td>
          <div class="flex gap-2 justify-content-center">
            <p-button
              pTooltip="View"
              tooltipPosition="top"
              (onClick)="openSupplier(supplier.slug)"
              icon="pi pi-eye"
              outlined
              severity="secondary"
            ></p-button>
            <p-button
              (onClick)="openSupplierDetails(supplier.slug)"
              pTooltip="Config"
              tooltipPosition="top"
              icon="pi pi-cog"
              outlined
              severity="secondary"
            ></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5" class="text-center">There are no suppliers</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
