import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { PickListModule } from 'primeng/picklist';

import { ChangePlanModalComponent } from 'app/_shared/components/change-plan-modal/change-plan-modal.component';
import { Storefront } from 'app/_shared/models/Storefront';
import { StorefrontSubscripiton } from 'app/_shared/models/StorefrontSubscription';
import { SubscriptionService } from 'app/_shared/services/subscription.service';
import { ToastService } from 'app/_shared/services/toast.service';

interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [
    CommonModule,
    DataViewModule,
    PickListModule,
    OrderListModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    ConfirmDialogModule
  ],
  providers: [ConfirmationService, DialogService],
  templateUrl: './billing.component.html'
})
export class BillingComponent implements OnInit {
  route = inject(ActivatedRoute);
  subcriptionService = inject(SubscriptionService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  dialogService = inject(DialogService);

  dialogRef: DynamicDialogRef | undefined;

  storefrontSubscriptions: StorefrontSubscripiton[] = [];
  subscriptionsInfo: {
    subscriptions: StorefrontSubscripiton[];
    unsubscribed_storefront: Storefront[];
  };
  companyId: number;

  sortOptions: SelectItem[] = [];

  sortOrder: number = 0;

  sortField: string = '';

  constructor(private http: HttpClient) {}

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    if (!companyId) {
      console.error('Company ID is null.');
      return;
    }
    this.companyId = +companyId;
    this.loadStorefrontsSubscriptions();

    this.sortOptions = [
      {
        label: 'Price High to Low',
        value: '!price'
      },
      {
        label: 'Price Low to High',
        value: 'price'
      }
    ];
  }

  loadStorefrontsSubscriptions(): void {
    this.subcriptionService.getCompanyStorefrontsSubscriptions(this.companyId).subscribe({
      next: res => {
        const subscriptions = res.data.subscriptions;
        const unsubscribedStorefronts = this.transformUnsubscribedStorefronts(res.data.unsubscribed_storefronts);
        this.storefrontSubscriptions = subscriptions.concat(unsubscribedStorefronts);
      },
      error: error => console.error(error)
    });
  }

  transformUnsubscribedStorefronts(storefronts: Storefront[]): StorefrontSubscripiton[] {
    return storefronts.map(storefront => ({
      storefront_id: storefront.id,
      storefront: storefront,
      active: null,
      status: null,
      plan: null
    }));
  }

  openUnsubscribeDialog(event: Event, storefrontId: number) {
    this.confirmationService.confirm({
      key: 'confirmUnsubscribe',
      target: event.target || new EventTarget(),
      message:
        'Are you sure you want to unsubscribe from this plan? You will lose access to all features associated with this subscription',
      accept: () => {
        this.storefrontUnsubscribe(storefrontId);
      }
    });
  }

  openReactivateDialog(event: Event, storefrontId: number) {
    this.confirmationService.confirm({
      key: 'confirmReactivate',
      target: event.target || new EventTarget(),
      message:
        'Are you sure you want to reactivate your storefront subscription? Your subscription plan resume normally',
      accept: () => {
        this.storefrontReactivate(storefrontId);
      }
    });
  }

  openCancelDowngradeDialog(event: Event, storefrontId: number) {
    this.confirmationService.confirm({
      key: 'cancelDowngrade',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to cancel your downgrade? Your current plan will resume normally',
      accept: () => {
        this.storefrontCancelDowngrade(storefrontId);
      }
    });
  }

  storefrontUnsubscribe(storefrontId: number) {
    this.subcriptionService.unsubscribe(storefrontId).subscribe({
      next: () => {
        this.toastService.success('You have successfully unsubscribed from the plan.');
        this.loadStorefrontsSubscriptions();
      },
      error: err => {
        console.error(
          'We encountered an issue while trying to unsubscribe you from the plan. Please try again later.',
          err
        );
      }
    });
  }

  storefrontCancelDowngrade(storefrontId: number) {
    this.subcriptionService.cancelDowngrade(storefrontId).subscribe({
      next: () => {
        this.toastService.success('You have successfully reactivated your subscription.');
        this.loadStorefrontsSubscriptions();
      },
      error: err => {
        console.error('We encountered an issue while trying to cancel your downgrade. Please try again later.', err);
      }
    });
  }

  storefrontReactivate(storefrontId: number) {
    this.subcriptionService.reactivate(storefrontId).subscribe({
      next: () => {
        this.toastService.success('You have successfully reactivated your subscription.');
        this.loadStorefrontsSubscriptions();
      },
      error: err => {
        console.error(
          'We encountered an issue while trying to reactivate your subscription. Please try again later.',
          err
        );
      }
    });
  }

  openChangePlanDialog(storefrontSubscription: StorefrontSubscripiton) {
    this.dialogRef = this.dialogService.open(ChangePlanModalComponent, {
      header: 'Choose new plan',
      width: '700px',
      data: {
        storefrontSubscription
      }
    });
    this.dialogRef.onClose.subscribe((newPlanId: number) => {
      this.loadStorefrontsSubscriptions();
      // if (!oldPlanId && newPlanId) { # TODO: Check if it's okay to remove this
      //   this.subscribeStorefrontToPlan(newPlanId, storefrontId);
      //   return;
      // }
      //
      // if (!newPlanId) {
      //   return;
      // }

      // this.changeStorefrontPlan(newPlanId, storefrontId);
    });
  }

  subscribeStorefrontToPlan(planId: number, storefrontId: number) {
    this.subcriptionService
      .addStorefrontSubscription({
        plan_id: planId,
        storefront_id: storefrontId
      })
      .subscribe({
        next: () => {
          this.toastService.success('You have successfully switched to the new plan.');
          this.loadStorefrontsSubscriptions();
        },
        error: err => {
          console.error('Failed to update subscription plan. Please try again later.', err);
        }
      });
  }

  changeStorefrontPlan(newPlanId: number, storefrontId: number) {
    this.subcriptionService
      .updateStorefrontSubscription({
        plan_id: newPlanId,
        storefront_id: storefrontId
      })
      .subscribe({
        next: () => {
          this.toastService.success('You have successfully switched to the new plan.');
          this.loadStorefrontsSubscriptions();
        },
        error: err => {
          console.error('Failed to update subscription plan. Please try again later.', err);
        }
      });
  }

  onSortChange(event: DropdownChangeEvent) {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onFilter(event: Event) {
    // dv.filter((event.target as HTMLInputElement).value);
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  protected readonly JSON = JSON;
}
