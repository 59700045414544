import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { finalize, Subscription } from 'rxjs';

import { EditStorefrontDialogComponent } from 'app/_shared/components/edit-storefront-dialog/edit-storefront-dialog.component';
import { Storefront } from 'app/_shared/models/Storefront';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-active-storefront-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    ConfirmDialogModule,
    InputTextModule,
    RouterLinkActive,
    EditStorefrontDialogComponent,
    CommonModule,
    RouterLink,
    SkeletonModule,
    TitleCasePipe
  ],
  providers: [ConfirmationService],
  templateUrl: './active-storefront-list.component.html'
})
export class ActiveStorefrontListComponent implements OnDestroy {
  storefrontService = inject(StorefrontService);
  router = inject(Router);
  toastService = inject(ToastService);
  confirmationService = inject(ConfirmationService);

  storeFronts: Storefront[] = [];
  totalRecords: number = 0;
  isLoading = false;
  isLoadingDelete = false;
  loadingRows = Array(10)
    .fill(0)
    .map((_, index) => {
      return index + 1;
    });
  private subscriptions: Subscription[] = [];

  editDialog: boolean = false;
  storefrontForEdit: Storefront;

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  loadStorefronts($event?: TableLazyLoadEvent) {
    this.isLoading = true;

    this.storefrontService
      .indexPaginatedWithLogo($event)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.storeFronts = res.data.data;
          this.totalRecords = res.data.total;
        },
        error: error => console.error(error)
      });
  }

  editStorefront($event: MouseEvent, storefront: Storefront) {
    $event.stopPropagation();
    this.storefrontForEdit = { ...storefront };
    this.editDialog = true;
  }

  hideDialog() {
    this.editDialog = false;
  }

  openDeleteModal(event: Event, storefrontId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure you want to delete this store? This item is scheduled for permanent deletion in 10 days.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteStorefront(storefrontId);
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  private deleteStorefront(storefrontId: number) {
    this.isLoadingDelete = true;
    this.subscriptions.push(
      this.storefrontService
        .deleteStorefront(storefrontId)
        .pipe(finalize(() => (this.isLoadingDelete = false)))
        .subscribe({
          next: () => {
            this.toastService.success(
              'This store has been marked for deletion and will be permanently removed in 10 days.'
            );
            this.loadStorefronts();
          },
          error: err => {
            console.error('Delete action failed:', err);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
