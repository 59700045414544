import { Routes } from '@angular/router';

import { authGuard, nonVerifiedUserGuard, verifiedUserGuard } from 'app/_shared/guards/auth.guard';
import { guestGuard } from 'app/_shared/guards/guest.guard';
import { LayoutComponent as AdminLayout } from 'app/admin/layout/layout.component';
import { CompanyRegisterComponent } from 'app/auth/company-register/company-register.component';
import { EmailVerificationComponent } from 'app/auth/email-verification/email-verification.component';
import { ForgotPasswordComponent } from 'app/auth/forgot-password/forgot-password.component';
import { LayoutComponent as AuthLayout } from 'app/auth/layout/layout.component';
import { LockScreenComponent } from 'app/auth/lock-screen/lock-screen.component';
import { LoginComponent } from 'app/auth/login/login.component';
import { RegisterComponent } from 'app/auth/register/register.component';
import { UpdatePasswordComponent } from 'app/auth/update-password/update-password.component';
import { UserInviteStatusComponent } from 'app/auth/user-invite-status/user-invite-status.component';
import { AdminRoutes } from 'app/routes/admin.routes';
import { ClientSelectionDetailsComponent } from 'app/superadmin/client-selection/client-selection-details/client-selection-details.component';
import { ClientSelectionComponent } from 'app/superadmin/client-selection/client-selection.component';

// TODO: Fix routes hierarchy
export const routes: Routes = [
  // Public routes
  {
    path: 'client-selection',
    children: [
      { path: '', component: ClientSelectionComponent },
      { path: 'view', component: ClientSelectionDetailsComponent }
    ]
  },
  {
    path: 'auth',
    component: AuthLayout,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', canActivate: [guestGuard], component: LoginComponent },
      {
        path: 'register',
        canActivate: [guestGuard],
        component: RegisterComponent
      },
      {
        path: 'company-register',
        canActivate: [guestGuard],
        component: CompanyRegisterComponent
      },
      {
        path: 'email-verification',
        canActivate: [authGuard, nonVerifiedUserGuard],
        component: EmailVerificationComponent
      },
      {
        path: 'user-invite-status',
        canActivate: [guestGuard],
        component: UserInviteStatusComponent
      },
      {
        path: 'forgot-password',
        canActivate: [guestGuard],
        component: ForgotPasswordComponent
      },
      {
        path: 'update-password',
        canActivate: [guestGuard],
        component: UpdatePasswordComponent
      },
      {
        path: 'locked',
        canActivate: [guestGuard],
        component: LockScreenComponent
      }
    ]
  },

  // Auth Protected Routes
  {
    path: '',
    canActivate: [authGuard, verifiedUserGuard],
    component: AdminLayout,
    title: 'SSM Admin',
    data: { breadcrumb: 'Admin' },
    children: AdminRoutes
  }
];
