<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Company orders</h5>
      <p-table
        #dt
        [value]="orders"
        [lazy]="true"
        (onLazyLoad)="loadOrders($event)"
        dataKey="id"
        [paginator]="true"
        [rows]="10"
        [totalRecords]="totalRecords"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        [showLoader]="false"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['status']"
        [expandedRowKeys]="expandedRows"
      >
        <ng-template pTemplate="caption">
          <div class="grid">
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  #filter
                  [(ngModel)]="globalFilter"
                  (input)="onFilterChange('global_filter', filter?.value)"
                  placeholder="Search Keyword"
                  class="lg:w-full"
                />
              </span>
            </div>
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <p-calendar
                [showIcon]="true"
                dateFormat="yy-mm-dd"
                inputId="start"
                placeholder="Start Date"
                [(ngModel)]="startDate"
                (onSelect)="onFilterChange('date_from', $event)"
                [showClear]="true"
                (onClear)="onFilterChange('date_from', null)"
              ></p-calendar>
            </div>
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <p-calendar
                [showIcon]="true"
                dateFormat="yy-mm-dd"
                [showTime]="false"
                inputId="end"
                placeholder="Due Date"
                [(ngModel)]="endDate"
                (onSelect)="onFilterChange('date_to', $event)"
                [showClear]="true"
                (onClear)="onFilterChange('date_to', null)"
              ></p-calendar>
            </div>
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <p-dropdown
                [options]="statusOptions"
                placeholder="Filter by Status"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="selectedStatus"
                (onChange)="onFilterChange('status', $event.value)"
                [showClear]="true"
              >
                <ng-template let-option>
                  <div class="p-multiselect-representative-option">
                    <span class="ml-2">{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <p-dropdown
                [options]="storefronts"
                placeholder="Filter by Store"
                optionLabel="name"
                optionValue="id"
                [(ngModel)]="selectedStorefront"
                (onChange)="onFilterChange('storefront_id', $event.value)"
                [showClear]="true"
              >
                <ng-template let-option>
                  <div class="p-multiselect-representative-option">
                    <span class="ml-2">{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-12 sm:col-6 md:col-4 xl:col-2">
              <button
                pButton
                pRipple
                type="button"
                label="Clear"
                class="p-button-text mb-2"
                (click)="clearFilters()"
              ></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem">
              <p-button
                type="button"
                [icon]="isAllExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="toggleExpandAll()"
                tooltip="Expand/Collapse All"
                [plain]="true"
                [text]="true"
              ></p-button>
            </th>
            <th style="min-width: 6rem">
              <div class="flex justify-content-between align-items-center">Order number</div>
            </th>
            <th pSortableColumn="status" style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">Status</div>
            </th>
            <th style="min-width: 14rem">
              <div class="flex justify-content-between align-items-center">Buyer</div>
            </th>
            <th style="min-width: 12rem">
              <div class="flex justify-content-between align-items-center">Store</div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex justify-content-between align-items-center">Created at</div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex justify-content-between align-items-center">Paid at</div>
            </th>
            <th style="min-width: 6rem">
              <div class="flex justify-content-between align-items-center">Order total</div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex justify-content-between align-items-center">Actions</div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order let-expanded="expanded">
          <tr class="cursor-pointer">
            <td>
              <p-button
                type="button"
                pRipple
                [pRowToggler]="order"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              />
            </td>
            <td>
              {{ order.uuid }}
            </td>
            <td>
              <app-status-badge [status]="order.status" />
            </td>
            <td>
              {{ order.contact_info.first_name }}
              {{ order.contact_info.last_name }}
            </td>
            <td>
              {{ order.storefront?.name }}
            </td>
            <td>
              {{ order.created_at | usdDate }}
            </td>
            <td>
              {{ order.paid_at ? (order.paid_at | usdDate) : 'Not Paid' }}
            </td>
            <td>
              {{ order.total | price | usdPipe }}
            </td>
            <td>
              <div class="flex gap-2">
                <p-button [routerLink]="[order.id]" icon="pi pi-eye" severity="secondary" outlined></p-button>
                <p-button icon="pi pi-bolt" (click)="quickPreview(order)" outlined></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-order>
          <tr>
            <td colspan="12">
              <app-order-details-collapse-item [order]="order"></app-order-details-collapse-item>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7" class="text-center">There are no orders</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          @for (row of loadingRows; track row) {
            <tr>
              @for (col of ['id', 'status', 'buyer', 'store', 'created', 'order_total', 'actions']; track col) {
                <td>
                  <p-skeleton />
                </td>
              }
            </tr>
          }
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<app-order-quick-preview
  [order]="orderForQuickPreview"
  [visible]="quickOrderPreviewDialog"
  (cancelEdit)="hideDialog()"
></app-order-quick-preview>
