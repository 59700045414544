import { JsonPipe, NgOptimizedImage } from '@angular/common';
import { Component, inject, input, OnInit, output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { CatalogProductVariant } from 'app/_shared/interfaces/catalog';
import { ColorDictionaryService } from 'app/_shared/services/color-dictionary.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-variants-list',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    TableModule,
    ButtonModule,
    PanelModule,
    ConfirmDialogModule,
    NgOptimizedImage,
    InputGroupModule,
    InputTextModule,
    JsonPipe
  ],
  providers: [ConfirmationService],
  templateUrl: './variants-list.component.html',
  styleUrl: './variants-list.component.scss'
})
export class VariantsListComponent implements OnInit {
  variants = input.required<CatalogProductVariant[]>();
  type = input.required<string>();
  supplierId = input.required<number>();

  onVariantChange = output<CatalogProductVariant[]>();
  onDeleteVariant = output<number[]>();
  onDeleteVariantMedia = output<number[]>();

  private fb = inject(FormBuilder);
  private confirmationService = inject(ConfirmationService);
  private colorDictionaryService = inject(ColorDictionaryService);
  private toastService = inject(ToastService);

  variantsForm: FormGroup;
  imageUrls: { [key: number]: { frontImage: string; rearImage: string; sideImage: string } } = {};
  deletedVariants: number[] = [];
  deletedCatalogProductMedias: number[] = [];
  variantEditMode: { [index: number]: boolean } = {};

  ngOnInit() {
    this.buildForm();
    this.fetchColorDictionary();
    this.mapImageUrls();

    this.variantsForm.valueChanges.subscribe(next => {
      const data = next.catalog_variants.map((x: CatalogProductVariant, i: number) => {
        return {
          ...x,
          supplier_product_variant: this.variants()[i].supplier_product_variant
        };
      });
      this.onVariantChange.emit(data as CatalogProductVariant[]);
    });
  }

  private buildForm() {
    this.variantsForm = this.fb.group({
      catalog_variants: this.fb.array((this.variants() ?? []).map(variant => this.createItemForm(variant)))
    });
  }

  private mapImageUrls() {
    this.variants().forEach((variant, index) => {
      this.imageUrls[index] = {
        frontImage: variant.front_image?.supplier_media?.full_path || variant.front_image?.full_path || '',
        rearImage: variant.rear_image?.supplier_media?.full_path || variant.rear_image?.full_path || '',
        sideImage: variant.side_image?.supplier_media?.full_path || variant.side_image?.full_path || ''
      };
    });
  }

  get formVariants() {
    return this.variantsForm.get('catalog_variants') as FormArray;
  }

  sizeControl(variant: AbstractControl) {
    return variant.get('catalog_variant_sizes') as FormArray;
  }

  createItemForm(variant: CatalogProductVariant) {
    return this.fb.group({
      id: [variant.id],
      color: [variant.color, [Validators.required]],
      supplier_product_variant_id: [variant.supplier_product_variant_id, [Validators.required]],
      front_image: [variant.front_image],
      rear_image: [variant.rear_image],
      side_image: [variant.side_image],
      catalog_variant_sizes: this.fb.array(
        variant.catalog_variant_sizes?.map(size => {
          return this.fb.group({
            id: [size.id],
            quantity: [size.quantity, [Validators.required]],
            supplier_variant_size_id: [size.supplier_variant_size_id, [Validators.required]],
            size: [size.size, [Validators.required]],
            weight: [size.weight, [Validators.required]],
            total: [size.total, [Validators.required]]
          });
        })
      )
    });
  }

  openDeleteVariantModal(event: Event, index: number) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      target: event.target || new EventTarget(),
      message: 'Are you sure that you want to delete variant',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteCatalogProductVariant(index);
      }
    });
  }

  deleteCatalogProductVariant(index: number) {
    const variantId = this.formVariants.at(index).get('id')?.value;
    if (variantId) {
      this.deletedVariants.push(variantId);
      this.onDeleteVariant.emit(this.deletedVariants);
    }
    this.formVariants.removeAt(index);

    delete this.imageUrls[index];
    this.imageUrls = Object.values(this.imageUrls).reduce((acc, item, index) => {
      acc[index] = item;
      return acc;
    }, {});
  }

  onFileChange(event: Event, variantIndex: number, imageType: 'front' | 'rear' | 'side') {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const imageUrl = e.target?.result as string;

        if (imageType === 'front') {
          this.formVariants.at(variantIndex).get('front_image')?.setValue(imageUrl);
        } else if (imageType === 'rear') {
          this.formVariants.at(variantIndex).get('rear_image')?.setValue(imageUrl);
        } else if (imageType === 'side') {
          this.formVariants.at(variantIndex).get('side_image')?.setValue(imageUrl);
        }

        this.imageUrls[variantIndex] = {
          frontImage: imageType === 'front' ? imageUrl : this.imageUrls[variantIndex].frontImage,
          rearImage: imageType === 'rear' ? imageUrl : this.imageUrls[variantIndex].rearImage,
          sideImage: imageType === 'side' ? imageUrl : this.imageUrls[variantIndex].sideImage
        };
      };
      reader.readAsDataURL(file);
    }
  }

  openDeleteMediaModal(variantIndex: number, imageType: 'front' | 'rear' | 'side') {
    this.confirmationService.confirm({
      key: 'confirmDeleteMedia',
      message: 'Are you sure that you want to delete this image',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteVariantMedia(variantIndex, imageType);
      }
    });
  }

  deleteVariantMedia(variantIndex: number, imageType: 'front' | 'rear' | 'side') {
    const variant = this.formVariants.at(variantIndex);
    const imageId = variant.get(`${imageType}_image`)?.value?.id;

    this.imageUrls[variantIndex][`${imageType}Image`] = '';
    variant.get(`${imageType}_image`)?.setValue(null);

    if (imageId && this.type() === 'edit') {
      this.deletedCatalogProductMedias.push(imageId);
      this.onDeleteVariantMedia.emit(this.deletedCatalogProductMedias);
    }
  }

  getColorFormControl(abstractControl: AbstractControl) {
    return abstractControl as FormControl;
  }

  private fetchColorDictionary() {
    const supplierId = 4;
    this.colorDictionaryService.getDictionary(supplierId).subscribe({
      next: res => this.handleDictionaryResponse(res),
      error: err => console.error(err)
    });
  }

  addToDictionary(index: number) {
    const variant = this.variants()[index];
    const value = variant.color;
    const key = variant.supplier_product_variant?.color;

    if (key && key != value) {
      return this.colorDictionaryService.addToDictionary(this.supplierId(), key, value).subscribe({
        next: () => this.toastService.success('Saved!'),
        error: err => {
          this.toastService.error('An error occurred. Please try again');
          console.error(err);
        },
        complete: () => (this.variantEditMode[index] = false)
      });
    }

    this.variantEditMode[index] = false;
    return this.toastService.warning('Name is same as supplier name, skipping!');
  }

  private handleDictionaryResponse(res: IApiResponse) {
    const colorDictionary: { [key: string]: string } = {};
    res.data.forEach((record: { key: string; value: string }) => (colorDictionary[record.key] = record.value));

    this.formVariants.controls.forEach((control: AbstractControl) => {
      const originalValue = control.value;
      const key = originalValue.color;

      if (colorDictionary[key]) {
        control.patchValue({ ...originalValue, color: colorDictionary[key] });
      }
    });
  }
}
