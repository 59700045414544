import { Clipboard } from '@angular/cdk/clipboard';
import { Component, computed, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, PrimeTemplate } from 'primeng/api';
import { ButtonDirective } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';

import { InputComponent } from 'app/_shared/components/input/input.component';
import {
  Columns,
  HeaderActions,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { PaginatorOptions } from 'app/_shared/interfaces/IApiPaginatedData';
import { ClientSelectionList, Collection } from 'app/_shared/interfaces/clientSelection';
import { Storefront } from 'app/_shared/models/Storefront';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { CompanyStore } from 'app/_store/company.store';
import { CreateProductComponent } from 'app/admin/storefront/products/create-product/create-product.component';

@Component({
  selector: 'app-client-selection-list',
  standalone: true,
  imports: [
    ListProductsComponent,
    ButtonDirective,
    DialogModule,
    InputComponent,
    PrimeTemplate,
    ReactiveFormsModule,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule
  ],
  providers: [DialogService, ConfirmationService],
  templateUrl: './client-selection-list.component.html',
  styleUrl: './client-selection-list.component.scss'
})
export class ClientSelectionListComponent implements OnInit {
  private companyService = inject(CompanyService);
  private activatedRoute = inject(ActivatedRoute);
  private clipboard = inject(Clipboard);
  private dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  toastService = inject(ToastService);
  router = inject(Router);
  companyStore = inject(CompanyStore);
  companyStorefronts = computed(() => this.companyStore.workingCompany()?.storefronts ?? []);
  ref: DynamicDialogRef | undefined;
  isLoading: boolean;
  edit: boolean;
  showAddProductDialog: boolean;
  clientSelectionList: ClientSelectionList[];
  companyId: number;
  selectedStorefront: Storefront;
  productsToBeAdded: number[];
  cols: Columns[] = [
    { field: 'title', header: 'Title', width: '30%' },
    { field: 'comment', header: 'Comment', width: '30%' },
    {
      field: 'products',
      header: 'Number of products selected',
      width: '30%',
      value: selection => (selection.catalog_product_selections ?? []).map(c => c.id).length
    },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '10%',
      actions: [
        {
          icon: 'pi pi-plus',
          label: 'Add to storefront',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => {
            this.showAddProductDialog = true;
            this.productsToBeAdded = selection.catalog_product_selections.map(c => c.id);
          }
        },
        {
          icon: 'pi pi-share-alt',
          label: 'Copy selection link',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => this.copySelectionLink(selection)
        },
        {
          icon: 'pi pi-pencil ',
          label: 'Edit',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => {
            this.router.navigate([selection.id, 'edit'], { relativeTo: this.activatedRoute });
          }
        },
        {
          icon: 'pi pi-trash ',
          label: 'Remove',
          severity: 'danger',
          onClick: (_: MouseEvent, selection: ClientSelectionList) => this.openDeleteModal(selection)
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 10,
    first: 0
  };
  headerActions: HeaderActions[] = [
    {
      icon: 'pi pi-share-alt',
      label: 'Create selection',
      onClick: () => this.router.navigate(['create'], { relativeTo: this.activatedRoute })
    }
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.id;
    });
  }

  copySelectionLink(selection: ClientSelectionList) {
    const baseUrl = window.location.origin;
    this.clipboard.copy(baseUrl + '/client-selection?token=' + selection.token);
  }

  handleLazyLoadProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      ...(globalFilter && { global_filter: globalFilter }),
      'relations[]': ['catalogProductSelections']
    };
    this.companyService.getClientSelectionList(this.companyId, params).subscribe(next => {
      this.clientSelectionList = next.data.data;
      this.paginatorOptions = {
        total: next.data.total,
        rows: next.data.per_page,
        first: next.data.from - 1
      };
    });
  }

  createProducts(ids: number[]) {
    this.handleProductModalClose();
    if (ids.length === 0) {
      return; // Stop if there are no more IDs to process
    }
    this.ref = this.dialogService.open(CreateProductComponent, {
      data: {
        productId: ids,
        editMode: false,
        storefrontId: this.selectedStorefront.id,
        multiple: true
      },
      focusOnShow: false,
      header: undefined,
      showHeader: false,
      contentStyle: {
        padding: '0px',
        borderRadius: 0,
        width: '100%'
      }
    });

    this.ref.onClose.subscribe(res => {
      if (res) {
        const remainingIds = ids.filter(id => id !== res);
        setTimeout(() => {
          this.createProducts(remainingIds);
        }, 300);
      }
    });
  }

  openDeleteModal(selection: ClientSelectionList) {
    this.confirmationService.confirm({
      key: 'confirmDelete',
      message: 'Are you sure that you want to delete this selection?',
      accept: () => {
        this.companyService.deleteClientSelection(this.companyId, selection.id).subscribe(() => {
          this.handleLazyLoadProducts(this.paginatorOptions);
          this.toastService.success('Selection deleted successfully.');
        });
      },
      acceptButtonStyleClass: 'p-button-filled p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined'
    });
  }

  handleProductModalClose() {
    this.showAddProductDialog = false;
    this.productsToBeAdded = [];
  }
}
