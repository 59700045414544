<div class="card">
  <p-steps [model]="steps" [(activeIndex)]="activeIndex"></p-steps>
  <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
    <!-- Step 1: Basic Info -->
    @if (activeIndex === 0) {
      <div class="grid mt-3">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="name">Product Name</label>
            <input
              type="text"
              pInputText
              class="w-full"
              formControlName="name"
              id="name"
              [ngClass]="{
                'ng-dirty':
                  productForm.get('name')?.invalid &&
                  (productForm.get('name')?.dirty || productForm.get('name')?.touched)
              }"
            />
            @if ((submitted || productForm.get('name')?.touched) && productForm.get('name')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Name is required</small>
              </div>
            }
          </div>
          <p-button
            styleClass="p-0"
            [text]="true"
            label="Edit slug"
            (click)="visibleSlugInput = !visibleSlugInput"
          ></p-button>
        </div>

        @if (visibleSlugInput) {
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="slug">Slug</label>
              <input
                type="text"
                pInputText
                class="w-full"
                formControlName="slug"
                id="slug"
                [ngClass]="{
                  'ng-dirty':
                    productForm.get('slug')?.invalid &&
                    (productForm.get('slug')?.dirty || productForm.get('slug')?.touched)
                }"
              />
              @if ((submitted || productForm.get('slug')?.touched) && productForm.get('slug')?.errors?.['required']) {
                <div>
                  <small class="p-text-danger">Slug is required</small>
                </div>
              }
            </div>
          </div>
          <div class="col-12 md:col-6"></div>
        }

        <div class="col-12">
          <label for="product_categories">Categories</label>
          <p-selectButton
            [options]="productCategoryOptions()"
            [multiple]="true"
            optionLabel="name"
            formControlName="product_categories"
          />
          <div class="pt-2">
            <p-button styleClass="p-0" [text]="true" label="Add category" (click)="openCategoryEditor()"></p-button>
          </div>
        </div>

        <div class="col-12 md:col-6">
          <p-editor formControlName="description" [style]="{ height: '100px' }" placeholder="Description">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
          @if (
            productForm.get('description')?.invalid &&
            (productForm.get('description')?.dirty || productForm.get('description')?.touched)
          ) {
            @if (productForm.get('description')?.errors?.['required']) {
              <div class="flex align-items-center mt-2">
                <small class="p-text-danger">Description is required!</small>
              </div>
            }
          }
        </div>
        <div class="col-12">
          <div class="flex justify-content-center">
            <p-button type="button" label="Next" (click)="nextStep()"></p-button>
          </div>
        </div>
      </div>
    }

    <!-- Step 2: Add Product Variants -->
    @if (activeIndex === 1) {
      <div class="grid mt-3">
        <div class="col-12 mt-3">
          <p-table
            [value]="variants.value"
            dataKey="color"
            [expandedRowKeys]="expandedRows"
            responsiveLayout="scroll"
            rowExpandMode="single"
          >
            <ng-template pTemplate="caption">
              <div class="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
                <p-button
                  outlined
                  type="button"
                  label="New Variant"
                  icon="pi pi-plus"
                  (click)="openEditVariantModal()"
                ></p-button>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 3rem"></th>
                <th>Image</th>
                <th>Color</th>
                <th>Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-variant let-rowIndex="rowIndex" let-expanded="expanded">
              <tr>
                <td>
                  <p-button
                    type="button"
                    pRipple
                    [pRowToggler]="variant"
                    [text]="true"
                    [rounded]="true"
                    [plain]="true"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                  />
                </td>
                <td>
                  <img
                    [src]="variant.front_image.full_path || variant.front_image"
                    [alt]="variant.color"
                    height="60"
                    width="60"
                    style="object-fit: contain"
                    class="shadow-4"
                  />
                </td>
                <td style="min-width: 12rem">{{ variant.color }}</td>
                <td>
                  <div class="flex justify-content-between flex-column-reverse">
                    <div class="flex gap-2">
                      <p-button
                        (click)="openEditVariantModal(variant, rowIndex)"
                        icon="pi pi-pencil"
                        pTooltip="Edit Variant"
                        tooltipPosition="top"
                        outlined
                      />
                      <p-button
                        icon="pi pi-trash"
                        severity="danger"
                        outlined
                        (click)="openDeleteVariantModal($event, rowIndex)"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-variant>
              <tr>
                <td colspan="7">
                  <div class="p-3">
                    <p-table [value]="variant.product_variant_sizes" dataKey="id" responsiveLayout="scroll">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>Size</th>
                          <th pSortableColumn="weight">
                            Weight
                            <p-sortIcon field="weight"></p-sortIcon>
                          </th>
                          <th pSortableColumn="price">
                            Price
                            <p-sortIcon field="price"></p-sortIcon>
                          </th>
                          <th pSortableColumn="quantity">
                            Quantity
                            <p-sortIcon field="quantity"></p-sortIcon>
                          </th>
                          <th style="width: 4rem"></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-size>
                        <tr>
                          <td>{{ size.size }}</td>
                          <td>{{ size.weight }}</td>
                          <td>{{ size.price }}</td>
                          <td>{{ size.quantity }}</td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="6">There are no order for this product yet.</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-12 mt-3">
          <p-panel header="Additional Images" [toggleable]="true" [collapsed]="true">
            <p-fileUpload
              name="demo[]"
              (onSelect)="onAdditionalPhotosUpload($event)"
              [multiple]="true"
              accept="image/*"
              maxFileSize="1000000"
              showUploadButton="false"
            >
              <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
                <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                  <div class="w-full flex justify-content-between gap-2">
                    <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Upload Photo" />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="file"></ng-template>
              <ng-template pTemplate="content">
                @for (photo of additionalPhotos; let i = $index; track i) {
                  @if (photo?.objectURL) {
                    <div class="flex">
                      <div class="col-4">
                        <img [src]="photo?.objectURL" alt="Uploaded Image" style="width: 50px" />
                      </div>
                      <div class="col-4">
                        <div class="flex justify-content-center align-items-center text-center h-full">
                          {{ photo?.fileName }}
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="flex justify-content-end">
                          <p-button type="button" icon="pi pi-times" (onClick)="removeAdditionalPhoto(i)"></p-button>
                        </div>
                      </div>
                    </div>
                  }
                }
              </ng-template>
            </p-fileUpload>
          </p-panel>
        </div>
        <div class="col-12">
          <div class="flex justify-content-center gap-2">
            <p-button outlined type="button" label="Back" (click)="previousStep()"></p-button>
            <p-button type="button" label="Next" (click)="nextStep()" [disabled]="!variantsValid()"></p-button>
          </div>
        </div>
      </div>
    }

    <!-- Step 3: Product Pricing -->
    @if (activeIndex === 2) {
      <div class="grid mt-3">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="lowestVariantPrice">Lowest variant price</label>
            <input [value]="lowestVariantPrice" id="col" type="text" pInputText class="w-full" disabled />
          </div>
        </div>

        <div class="col-12 md:col-6">
          <div class="field">
            <label for="margin">Product Margin</label>
            <input
              type="number"
              pInputText
              class="w-full"
              formControlName="margin"
              id="margin"
              [ngClass]="{
                'ng-dirty':
                  productForm.get('margin')?.invalid &&
                  (productForm.get('margin')?.dirty || productForm.get('margin')?.touched)
              }"
            />
            @if ((submitted || productForm.get('margin')?.touched) && productForm.get('margin')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Margin is required</small>
              </div>
            }
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="price">Product Price (with platform fee)</label>
            <input
              type="text"
              pInputText
              class="w-full"
              formControlName="price"
              id="price"
              [ngClass]="{
                'ng-dirty':
                  productForm.get('price')?.invalid &&
                  (productForm.get('price')?.dirty || productForm.get('price')?.touched)
              }"
              readonly
            />
            @if ((submitted || productForm.get('price')?.touched) && productForm.get('price')?.errors?.['required']) {
              <div>
                <small class="p-text-danger">Price is required</small>
              </div>
            }
          </div>
        </div>
        <div class="col-12">
          <div class="flex justify-content-center gap-2">
            <p-button outlined type="button" label="Back" (click)="previousStep()"></p-button>
            <p-button type="button" label="Next" (click)="nextStep()" [disabled]="!pricingValid()"></p-button>
          </div>
        </div>
      </div>
    }

    <!-- Step 4: Product Description -->
    @if (activeIndex === 3) {
      <div class="grid mt-3">
        <div class="col-12 md:col-6">
          <p-editor
            formControlName="short_description"
            [style]="{ height: '100px' }"
            placeholder="Short description (optional)"
          >
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
        <div class="col-12 md:col-6">
          <p-editor
            formControlName="copy_underneath_description"
            [style]="{ height: '100px' }"
            placeholder="Copy underneath Add to cart"
          >
            <ng-template pTemplate="header">
              <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
            </ng-template>
          </p-editor>
        </div>
        <div class="col-12 md:col-6">
          <div class="formgrid grid">
            @for (productStatus of productStatusOptions; track productStatus) {
              <div class="field-radiobutton col-6">
                <p-radioButton
                  formControlName="status"
                  [value]="productStatus.value"
                  [inputId]="productStatus.value"
                ></p-radioButton>
                <label [for]="productStatus.value">{{ productStatus.name }}</label>
              </div>
            }
          </div>
        </div>
        @if (productForm.get('status')?.value === 'scheduled') {
          <div class="col-12 md:col-6">
            <div class="flex gap-1">
              <div class="flex flex-column field w-full">
                <label for="startDate" class="block text-900 font-semibold">Start Date</label>
                <span class="p-input-icon-left w-full" style="height: 3.5rem">
                  <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
                  <p-calendar
                    formControlName="start_publish_date"
                    name="startDate"
                    [dateFormat]="displayDateFormat"
                    hourFormat="12"
                    [required]="productForm.get('status')?.value === 'scheduled'"
                    inputId="startDate"
                    id="startDate"
                    inputStyleClass="w-full pl-7 text-900 font-semibold"
                    styleClass="w-full"
                    [inputStyle]="{ height: '3.5rem' }"
                  ></p-calendar>
                </span>
                @if (
                  productForm.get('start_publish_date')?.invalid &&
                  (productForm.get('start_publish_date')?.touched || productForm.get('start_publish_date')?.dirty)
                ) {
                  <div class="flex align-items-center">
                    <small class="p-text-danger">A start date is required when scheduling this product</small>
                  </div>
                }
              </div>
              <div class="flex flex-column field w-full">
                <label for="endDate" class="block text-900 font-semibold">End Date</label>
                <span class="p-input-icon-left w-full" style="height: 3.5rem">
                  <i class="pi pi-clock z-1" style="left: 1.5rem"></i>
                  <p-calendar
                    formControlName="end_publish_date"
                    name="endDate"
                    [dateFormat]="displayDateFormat"
                    [required]="true"
                    inputId="endDate"
                    id="endDate"
                    inputStyleClass="w-full pl-7 text-900 font-semibold"
                    styleClass="w-full"
                    [inputStyle]="{ height: '3.5rem' }"
                    [minDate]="productForm.get('start_publish_date')?.value"
                  ></p-calendar>
                </span>
              </div>
            </div>
          </div>
        }
        <div class="col-12">
          <div class="flex justify-content-center gap-2">
            <p-button outlined type="button" label="Back" (click)="previousStep()"></p-button>
            <p-button
              type="button"
              [label]="!customProduct ? 'Create Product' : 'Update Product'"
              type="submit"
            ></p-button>
          </div>
        </div>
      </div>
    }
  </form>
</div>

<app-custom-product-variant-editor
  [variant]="variantForEdit"
  [visible]="editVariantDialog"
  (cancelEdit)="hideDialog()"
  (validFormEmitter)="addOrUpdateVariant($event)"
  (deletedProductSizesEmitter)="handleDeletedProductSizes($event)"
></app-custom-product-variant-editor>
<p-confirmDialog header="Confirmation" key="confirmDelete" [style]="{ width: '28rem' }"></p-confirmDialog>
