<div class="register">
  <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
    <div class="header mb-4">
      <h1>Register</h1>
      <h4>Let's get started</h4>
    </div>
    <div class="flex flex-column">
      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('first_name')!"
          placeholder="First Name"
          [fullWidth]="true"
          label="First Name"
        />
      </div>
      <div class="mb-4">
        <app-input
          [control]="registrationForm.get('last_name')!"
          placeholder="Last Name"
          [fullWidth]="true"
          label="Last Name"
        />
      </div>

      <div class="mb-4">
        <label for="password" class="block label">Password</label>
        <span class="p-input-icon-left w-full">
          <p-password
            [feedback]="false"
            formControlName="password"
            placeholder="Password"
            styleClass="w-full"
            inputStyleClass="w-full"
            [inputStyle]="{ borderRadius: '10px' }"
            [toggleMask]="true"
            id="password"
          ></p-password>
        </span>
        @if (submitted() || registrationForm.get('password')?.touched) {
          <div class="mt-1">
            @if (registrationForm.get('password')?.errors?.['required']) {
              <small class="p-text-danger">Password is required</small>
            } @else if (registrationForm.get('password')?.errors?.['passwordPattern']) {
              <div class="w-50%">
                <small class="p-text-danger">
                  Password must be at least 8 characters,
                  <br />
                  contain at least one uppercase letter,
                  <br />
                  one lowercase letter, one number
                  <br />
                  and one special character.
                </small>
              </div>
            }
          </div>
        }
      </div>
      <div class="mb-4">
        <label for="confirm-password" class="block label">Confirm password</label>
        <span class="p-input-icon-left w-full">
          <p-password
            [feedback]="false"
            formControlName="password_confirmation"
            placeholder="Confirm Password"
            styleClass="w-full"
            [inputStyle]="{ borderRadius: '10px' }"
            inputStyleClass="w-full"
            [toggleMask]="true"
            id="confirm-password"
          ></p-password>
        </span>
        @if (submitted() || registrationForm.get('password_confirmation')?.touched) {
          <div class="mt-1">
            @if (registrationForm.get('password_confirmation')?.errors?.['required']) {
              <small class="p-text-danger">Password Confirmation is required</small>
            } @else if (registrationForm.errors?.['passwordsNotMatched']) {
              <small class="p-text-danger">Passwords no not match</small>
            }
          </div>
        }
      </div>
      <div class="mb-4">
        <div class="flex flex-wrap terms align-items-center">
          <p-checkbox formControlName="terms_and_conditions" styleClass="mr-2" [binary]="true"></p-checkbox>
          <label for="terms_and_conditions" class="text-900 font-medium mr-2">I agree to the</label>
          <a>Terms & Conditions</a>
        </div>
        @if (submitted() && registrationForm.get('terms_and_conditions')?.errors?.['required']) {
          <div class="mt-1">
            <small class="p-text-danger">Please accept the Terms and Conditions</small>
          </div>
        }
      </div>

      <button type="submit" [disabled]="isLoading()" pButton pRipple label="Sign Up" class="w-full mb-4"></button>
      <div class="mb-4">
        <p class="have-account">
          Already have an account?
          <a [routerLink]="['/auth/login']">Login</a>
        </p>
      </div>
    </div>
  </form>
</div>
