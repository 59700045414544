import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';
import { TableLazyLoadEvent } from 'primeng/table';
import { finalize } from 'rxjs';

import {
  Columns,
  DropdownFilter,
  HeaderActions,
  ListProductsComponent,
  ProductPaginatorOptions
} from 'app/_shared/components/list-products/list-products.component';
import { ProductsFromCatalogProductComponent } from 'app/_shared/components/products-from-catalog-product/products-from-catalog-product.component';
import { CatalogProduct, DecorationMethod } from 'app/_shared/interfaces/catalog';
import { CatalogService } from 'app/_shared/services/catalog.service';
import { DecorationMethodService } from 'app/_shared/services/decoration-method.service';

@Component({
  selector: 'app-catalog',
  standalone: true,
  imports: [ButtonModule, CardModule, RippleModule, ListProductsComponent, ProductsFromCatalogProductComponent],
  providers: [DialogService],
  templateUrl: './catalog-products-list.component.html'
})
export class CatalogProductsListComponent implements OnInit {
  catalogService = inject(CatalogService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  decorationMethodService = inject(DecorationMethodService);
  dialogService = inject(DialogService);
  dialogRef: DynamicDialogRef | undefined;

  isLoading: boolean = false;
  catalogProducts: CatalogProduct[];
  decorationMethodOptions: DecorationMethod[];
  selectedDecorationMethodId: number | null = null;

  cols: Columns[] = [
    { element: 'image', field: 'featured_image', header: 'Image', width: '20%' },
    { field: 'name', header: 'Name', width: '20%' },
    { field: 'decoration_methods', header: 'Decoration Methods', width: '20%' },
    {
      field: 'products_count',
      header: 'Storefront Product Count ',
      width: '10%',
      cellAction: ($event: MouseEvent, catalogProductId: number) => {
        this.openStorefrontsProductsList(catalogProductId);
      }
    },
    {
      element: 'actions',
      field: 'actions',
      header: 'Actions',
      width: '15%',
      actions: [
        {
          icon: 'pi pi-pen-to-square',
          label: '',
          onClick: ($event: MouseEvent, product: CatalogProduct) => {
            $event.stopPropagation();
            this.router.navigate([product.id, 'edit'], {
              relativeTo: this.activatedRoute
            });
          }
        }
      ]
    }
  ];
  paginatorOptions: ProductPaginatorOptions = {
    total: 0,
    rows: 5,
    first: 0
  };
  dropdownFilters: DropdownFilter[];
  headerActions: HeaderActions[] = [
    {
      icon: 'pi pi-pen-to-square',
      label: 'Manage catalog categories',
      onClick: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.router.navigate(['catalog', 'category-management']);
      }
    },
    {
      icon: 'pi pi-plus',
      label: 'Create catalog product',
      onClick: ($event: MouseEvent) => {
        $event.stopPropagation();
        this.router.navigate(['catalog', 'create']);
      }
    }
  ];

  ngOnInit(): void {
    this.fetchDecorationMethods();
    this.isLoading = true;
  }

  fetchDecorationMethods() {
    this.decorationMethodService.index().subscribe({
      next: res => {
        this.decorationMethodOptions = res.data;
        this.dropdownFilters = [
          {
            placeholder: 'Decoration method',
            optionLabel: 'name',
            optionValue: 'id',
            options: this.decorationMethodOptions,
            onChange: (decorationMethodId: number) => {
              this.selectedDecorationMethodId = decorationMethodId ?? null;
              this.fetchProducts(this.paginatorOptions);
            }
          }
        ];
      },
      error: error => console.error(error)
    });
  }

  fetchProducts(paginatorOptions: ProductPaginatorOptions | TableLazyLoadEvent) {
    this.isLoading = true;
    const { rows, first, globalFilter } = paginatorOptions;
    const params = {
      rows,
      ...(globalFilter && { global_filter: globalFilter }),
      page: first && rows ? Math.floor(first / rows) + 1 : 1,
      'relations[]': ['catalogProductConfiguration', 'featuredImage', 'decorationMethods'],
      'count_relations[]': ['products'],
      ...(this.selectedDecorationMethodId && { decoration_method_id: this.selectedDecorationMethodId })
    };
    this.catalogService
      .getProducts(params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: products => {
          this.catalogProducts = products.data.data;
          this.paginatorOptions = {
            total: products.data.total,
            rows: products.data.per_page,
            first: products.data.from - 1
          };
        },
        error: err => {
          console.error(err);
        }
      });
  }

  handleLazyLoadProducts($event: TableLazyLoadEvent) {
    this.fetchProducts($event);
  }

  openStorefrontsProductsList(catalogProductId: number) {
    this.dialogRef = this.dialogService.open(ProductsFromCatalogProductComponent, {
      header: 'Products created from this catalog product',
      width: '700px',
      height: 'auto',
      data: {
        catalogProductId
      }
    });
  }
}
