import { FeaturedImage } from 'app/_shared/interfaces/catalog';
import {
  AlphaBroaderSupplierProduct,
  HitSupplierProduct,
  SanmarSupplierProduct,
  SsActiveSupplierProduct,
  SupplierVariant
} from 'app/_shared/interfaces/supplier';

export class SupplierProduct {
  constructor(
    product: AlphaBroaderSupplierProduct | SsActiveSupplierProduct | HitSupplierProduct | SanmarSupplierProduct
  ) {
    this.product_variants = product.supplier_product_variants;
    this.id = product.id;
    this.image = product.featured_image;
    this.supplierId = product.supplier_id;
    this.import_status = product.import_status;
    this.import_sub_status = product.import_sub_status;
    this.price = product.lowest_variant_price;
    this.price_margin = product.supplier?.price_margin ?? '0';
    this.external_id = product.external_id;
  }

  id: number;
  name: string;
  image: FeaturedImage;
  price: number;
  price_margin: string;
  supplierId: number;
  product_variants: SupplierVariant[];
  import_status: string;
  import_sub_status: string;
  description: string;
  external_id: string;
}
