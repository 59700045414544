<div class="navbar">
  <div class="inner-container">
    <div class="nav flex justify-content-between align-items-center">
      <div class="logo">
        @if (selection?.logo?.full_path) {
          <img class="logo-img" [src]="selection?.logo?.full_path" alt="logo" />
        }
      </div>
      <p-button [label]="'View selection ' + '(' + noOfSelectedProducts() + ')'" (onClick)="viewSelection()"></p-button>
    </div>
  </div>
</div>
@if (defaultSelection) {
  <div class="selection">
    <app-product-selection (onSave)="handleSelectionSave($event)" [defaultSelection]="defaultSelection" />
  </div>
}
