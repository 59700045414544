import { Component } from '@angular/core';

import { CatalogProductFormComponent } from 'app/_shared/components/catalog-product-form/catalog-product-form.component';

@Component({
  selector: 'app-edit-catalog-product',
  standalone: true,
  imports: [CatalogProductFormComponent],
  templateUrl: './edit-catalog-product.component.html',
  styleUrl: './edit-catalog-product.component.scss'
})
export class EditCatalogProductComponent {}
