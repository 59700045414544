import { computed } from '@angular/core';

import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';

import { User } from '../_shared/models/User';

export type AuthState = {
  user: User | null;
  token?: string;
  isLoading: boolean;
};

const initialState: AuthState = {
  token: undefined,
  user: null,
  isLoading: false
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withDevtools('auth'),
  withState(initialState),
  withComputed(({ token, user }) => ({
    isSuperAdmin: computed(() => user() && user()?.role && user()?.role.id === 1),
    isVerified: computed(() => user() && user()?.email_verified_at)
  })),
  withMethods(store => ({
    setToken: (token: string) => {
      localStorage.setItem('access_token', token);
      patchState(store, { token });
    },
    setAuthUser: (user: User) => patchState(store, { user }),
    setLoading: (isLoading: boolean) => patchState(store, { isLoading }),
    clearAuthUser: () => {
      localStorage.removeItem('access_token');
      patchState(store, {
        user: undefined,
        token: undefined
      });
    }
  })),
  withHooks(store => ({
    onInit: () => {
      const localStorageToken = localStorage.getItem('access_token');
      if (localStorageToken && localStorageToken !== '') {
        patchState(store, { token: localStorageToken });
      }
    }
  }))
);
