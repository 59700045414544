import { FeaturedImage, SupplierImage } from 'app/_shared/interfaces/catalog';
import { Color } from 'app/_shared/interfaces/colors';

export interface Supplier {
  name: string;
  id: number;
  slug: string;
  supplierColors: Color[];
  price_margin: string;
}

export interface SupplierProductDefault {
  featured_image: FeaturedImage;
  supplier_id: number;
  id: number;
  lowest_variant_price: number;
  supplier_product_variants: SupplierVariant[];
  import_status: string;
  import_sub_status: string;
  supplier?: Supplier;
}

export interface HitSupplierProduct extends SupplierProductDefault {
  product_name: string;
  description: string;
}

export interface SanmarSupplierProduct extends SupplierProductDefault {
  product_name: string;
  description: string;
}

export interface SsActiveSupplierProduct extends SupplierProductDefault {
  name: string;
  additional_description: string;
}

export interface AlphaBroaderSupplierProduct extends SupplierProductDefault {
  name: string;
  additional_description: string;
}

export enum SuppliersEnum {
  HIT = 'hit-promo',
  SANMAR = 'sanmar',
  SS_ACTIVE = 'ss-active-wear',
  ALPHA_BROADER = 'alpha-broder'
}

export const SupplierNameFromSlug = {
  [SuppliersEnum.HIT]: 'Hit Promo',
  [SuppliersEnum.SANMAR]: 'SanMar',
  [SuppliersEnum.SS_ACTIVE]: 'SS Active Wear',
  [SuppliersEnum.ALPHA_BROADER]: 'Alpha Broder'
};

export interface SupplierVariant {
  available_for_store: number;
  color: string;
  id: number;
  variantable_id: number;
  supplier_variant_sizes: SupplierVariantSize[];
  front_image: SupplierImage;
  rear_image: SupplierImage;
  side_image?: SupplierImage;
}

export interface SupplierVariantSize {
  id: number;
  quantity: number;
  size: string;
  total: string;
  supplier_product_variant_id: number;
  weight: string;
}
