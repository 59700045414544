@if (pageSelected) {
  <div class="select-all">
    <p>Selected {{ selectedItems()?.length }} products on this page.</p>
    @if (paginatorOptions().total > paginatorOptions().rows) {
      <p-button [label]="'Select all ' + totalProducts() + ' items'" outlined (click)="handleAllProductsSelect()" />
      @if (allSelected) {
        <p>All {{ totalProducts() }} items selected</p>
      }
    }
  </div>
}
<p-table
  #dt
  [value]="loading() ? [] : products()"
  [columns]="columns()"
  responsiveLayout="scroll"
  [rows]="paginatorOptions().rows"
  [totalRecords]="paginatorOptions().total"
  [first]="paginatorOptions().first"
  [lazy]="true"
  [paginator]="true"
  [rowsPerPageOptions]="[5, 10]"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowHover]="true"
  (onLazyLoad)="loadProducts($event)"
  [loading]="loading()"
  [showLoader]="false"
  [(selection)]="selectedItems"
  (selectionChange)="handleSelectionChange($event)"
  (onHeaderCheckboxToggle)="handleSelectAllChange($event)"
  dataKey="id"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-column">
      <div class="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
        @if (headerActions()) {
          <div class="flex gap-2">
            @for (action of headerActions(); track action.label) {
              <p-button [icon]="action.icon" [label]="action.label" outlined (click)="action.onClick($event)" />
            }
          </div>
        }
      </div>
      <div class="flex flex-column md:flex-row md:align-items-center gap-2">
        <span class="block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            name="Search"
            (input)="onGlobalFilter(dt, $event)"
            placeholder="Search..."
            class="w-full sm:w-auto"
          />
        </span>
        @if (dropdownFilters()) {
          <div class="flex gap-2">
            @for (dropdownFilter of dropdownFilters(); track dropdownFilter) {
              <p-dropdown
                [options]="dropdownFilter.options"
                (onChange)="dropdownFilter.onChange($event.value)"
                [placeholder]="dropdownFilter.placeholder"
                [optionValue]="dropdownFilter.optionValue"
                [optionLabel]="dropdownFilter.optionLabel"
                class="w-full"
                [showClear]="true"
              ></p-dropdown>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      @if (multiSelect()) {
        <th style="width: 4rem"><p-tableHeaderCheckbox name="Select all" /></th>
      }
      @for (column of columns(); track column.field) {
        <th [ngStyle]="{ width: column.width ?? '15%' }">
          {{ column.header }}
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      @if (multiSelect()) {
        <td>
          <p-tableCheckbox [value]="product" [name]="product.name" />
        </td>
      }
      @for (column of columns(); track column.field) {
        @if (column.element === 'image') {
          <td>
            <img
              [src]="
                product[column.field]?.path
                  ? storageUrl + product[column.field]?.path
                  : 'assets/demo/images/product/blue-t-shirt.jpg'
              "
              [alt]="product[column.field]?.url"
              style="object-fit: contain"
              [ngStyle]="{ maxWidth: '80px' }"
              class="shadow-4"
              priority
            />
          </td>
        } @else if (column.element === 'actions') {
          <td>
            <div class="flex justify-content-between flex-column-reverse">
              <div class="flex gap-2">
                @for (action of column['actions']; track action.icon) {
                  <p-button
                    [icon]="action.icon"
                    [pTooltip]="action.label"
                    [severity]="action.severity ?? 'primary'"
                    tooltipPosition="top"
                    outlined
                    [disabled]="action.disabled ? action.disabled(product) : false"
                    (click)="action.onClick($event, product)"
                  />
                }
              </div>
              <div class="mb-2">
                @if (product.import_status) {
                  <p-tag severity="info">{{ product.import_status }}</p-tag>
                }
              </div>
            </div>
          </td>
        } @else if (column.field === 'decoration_methods') {
          <td>
            @if (product[column.field]?.length) {
              <ng-container>
                @for (method of product[column.field]; let last = $last; track method) {
                  <span>
                    {{ method.name }}
                    @if (!last) {
                      <span>,</span>
                    }
                  </span>
                }
              </ng-container>
            } @else {
              Not set
            }
          </td>
        } @else if (column.field === 'products_count') {
          @if (column.cellAction) {
            <td class="cursor-pointer" (click)="column.cellAction($event, product.id)">
              {{ product[column.field] }}
            </td>
          }
        } @else if (column.field === 'price') {
          <td>{{ product[column.field] | price }}</td>
        } @else {
          <td>
            {{ column.value ? column.value(product) : product[column.field] }}
          </td>
        }
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="columns().length" class="text-center">
        {{ emptyMessage() ?? 'There are no products available' }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    @for (row of loadingRows; track row) {
      <tr>
        @for (col of columns(); track col) {
          <td>
            @if (col.element === 'image') {
              <p-skeleton width="7rem" height="8rem" />
            } @else if (col.element === 'actions') {
              <div class="flex">
                @for (action of col['actions']; track action.icon) {
                  <p-skeleton size="3rem" styleClass="mr-2" />
                }
              </div>
            } @else {
              <p-skeleton />
            }
          </td>
        }
      </tr>
    }
  </ng-template>
</p-table>
