import { Injectable } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TransactionService extends ApiService {
  routePrefix = `${this.apiUrl}transactions/`;

  public index(companyId?: number, params?: any) {
    return this.get(`${this.routePrefix}${companyId}`, { params });
  }

  public paginatedIndex(companyId?: number, $event?: TableLazyLoadEvent, params?: any) {
    return this.get(`${this.routePrefix}${companyId}`, {
      params: this.handleTableLazyLoadEvent($event, params)
    });
  }
}
