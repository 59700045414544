import { Component, computed, inject, input, OnInit, output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { NgKonvaEventObject, StageComponent } from 'ng2-konva';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { v4 as uuidv4 } from 'uuid';

import { KonvaComponent } from 'app/_shared/components/catalog-product-form/print-area/konva/konva.component';
import { InputComponent } from 'app/_shared/components/input/input.component';
import { CatalogProduct, PrintArea, PrintAreaData } from 'app/_shared/interfaces/catalog';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-print-area',
  standalone: true,
  imports: [
    StageComponent,
    SelectButtonModule,
    FormsModule,
    KonvaComponent,
    ButtonModule,
    DialogModule,
    InputComponent,
    ReactiveFormsModule,
    RadioButtonModule,
    SplitButtonModule
  ],
  templateUrl: './print-area.component.html',
  styleUrl: './print-area.component.scss'
})
export class PrintAreaComponent implements OnInit {
  private toastService = inject(ToastService);
  printAreas = input.required<PrintArea[]>();
  product = input.required<CatalogProduct>();
  onPrintAreasChange = output<PrintArea[]>();
  selectedType: string;
  selectedArea: PrintArea;
  selectedAreaId: number | string | undefined;
  addPrintAreaModal: boolean;
  editMode: boolean;
  areaTypes: any[] = [
    { name: 'Front', key: 'front_image' },
    { name: 'Rear', key: 'rear_image' },
    { name: 'Side', key: 'side_image' }
  ];

  printAreaForm: FormGroup;
  printAreaOptions = computed(() =>
    this.printAreas().map(x => [
      {
        label: 'Rename',
        command: () => this.renameArea(x)
      },
      {
        label: 'Delete',
        command: () => this.removePrintArea(x)
      }
    ])
  );

  ngOnInit() {
    this.printAreaForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required])
    });
    this.selectedArea = this.printAreas()[0];
  }

  handleSelectPrintArea(printArea: PrintArea) {
    this.selectedArea = printArea;
  }

  handlePrintAreaChange($event: NgKonvaEventObject<MouseEvent>) {
    const areasArray = this.printAreas().slice();
    const objIndex = this.printAreas().findIndex(area => area.id == this.selectedArea.id);

    areasArray[objIndex].data = {
      ...$event.event.target.attrs
    };
    this.onPrintAreasChange.emit(areasArray);
  }

  handleAddPrintArea() {
    if (this.printAreaForm.valid) {
      const { name, type } = this.printAreaForm.value;
      if (this.editMode) {
        const areas = this.printAreas().map(area => {
          if (area.id == this.selectedArea.id) {
            const image = this.product().catalog_product_variants[0][type].supplier_media.full_path;
            return { ...area, name, type, data: { ...(area.data as PrintAreaData), image } };
          }
          return area;
        });
        this.selectedArea = areas.find(area => area.id == this.selectedAreaId)!;
        this.onPrintAreasChange.emit(areas);
      } else {
        const areas = [
          ...this.printAreas(),
          {
            id: uuidv4(),
            name,
            type,
            data: {
              x: 60,
              y: 60,
              width: 100,
              height: 90,
              image:
                this.product().catalog_product_variants[0][type]?.full_path ??
                this.product().catalog_product_variants[0][type]?.supplier_media?.full_path,
              fill: 'rgba(79,75,224, 0.5)',
              name: 'rect',
              draggable: true,
              scaleX: 1,
              scaleY: 1
            }
          }
        ];
        this.onPrintAreasChange.emit(areas);
      }
      this.closeModal();
    }
  }

  closeModal() {
    this.addPrintAreaModal = false;
    this.editMode = false;
    this.printAreaForm.reset();
  }

  renameArea(printArea: PrintArea) {
    this.editMode = true;
    this.addPrintAreaModal = true;
    this.printAreaForm.setValue({ name: printArea.name, type: printArea.type });
  }

  removePrintArea(printArea: PrintArea) {
    if (this.printAreas().length == 1) {
      return this.toastService.error('You cannot remove last print areas');
    }

    const areasArray = this.printAreas().filter(x => x.id !== printArea.id);

    const selectedExists = areasArray.some(x => x.id === this.selectedArea.id);

    if (!selectedExists) {
      this.selectedArea = areasArray[0];
    }

    this.onPrintAreasChange.emit(areasArray);
  }
}
