<div class="card">
  @if (companyId) {
    <app-user-table-list
      [users]="isLoading || isLoadingEdit || isLoadingDelete ? [] : users"
      [totalRecords]="totalRecords"
      [loading]="isLoading || isLoadingEdit || isLoadingDelete"
      [rows]="10"
      [globalFilterFields]="['first_name', 'last_name', 'email', 'phone']"
      (lazyLoadUsers)="loadCompanyUsers($event)"
      (editUserEmitter)="editCompanyUser($event)"
      (deleteUserEmmiter)="deleteCompanyUser($event)"
    ></app-user-table-list>
  }
</div>
