import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfirmationService, MenuItem, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { Menu as PMenu } from 'primeng/menu/menu';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { v4 as uuidv4 } from 'uuid';

import { InputComponent } from 'app/_shared/components/input/input.component';
import { Menu } from 'app/_shared/interfaces/designer/menu';
import { Page } from 'app/_shared/interfaces/designer/page';
import { DesignerStore } from 'app/_store/designer.store';
import { StorefrontStore } from 'app/_store/storefront.store';
import { DesignerHelperService } from 'app/admin/storefront/designer/designer-helper.service';

@Component({
  selector: 'app-menu-list',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    InputComponent,
    MenuModule,
    OrderListModule,
    PanelModule,
    ReactiveFormsModule,
    SharedModule,
    ConfirmDialogModule,
    ToastModule
  ],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu-list.component.html',
  styleUrl: './menu-list.component.scss'
})
export class MenuListComponent {
  designerStore = inject(DesignerStore);
  storefrontStore = inject(StorefrontStore);
  designerHelperService = inject(DesignerHelperService);

  showMenuModal: boolean = false;
  menuPopupItems: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-file-edit'
    },
    {
      separator: true
    },
    {
      label: 'Delete',
      icon: 'pi pi-times',
      command: event => {
        this.handleDeleteMenuItem(event?.item?.menuItem.id);
      }
    }
  ];
  menuForm = new FormGroup({
    label: new FormControl('', [Validators.required]),
    page: new FormControl<Page | undefined>(undefined, [Validators.required])
  });

  storefrontId = computed(() => this.storefrontStore.selectedStorefront?.()?.id);
  menu = computed(() => this.designerStore.config().menu ?? []);
  pages = computed(() => this.designerStore.config().pages ?? []);

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  onMenuReorder() {
    this.designerStore.saveConfig(this.storefrontId()!, { menu: this.menu() }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
    });
  }

  toggleMenuPopup($event: Event, menu: PMenu, item: any) {
    this.menuPopupItems.forEach(menuItem => {
      menuItem.menuItem = item;
    });
    menu.toggle($event);
  }

  handleMenuCreate() {
    const menu = [...this.menu(), { ...this.menuForm.value, id: uuidv4() } as Menu];
    this.showMenuModal = false;
    this.menuForm.reset();
    this.designerStore.saveConfig(this.storefrontId()!, { menu }, true).subscribe(next => {
      this.designerHelperService.sendToPreview(this.storefrontId()!, next);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Menu deleted successfully.'
      });
    });
  }

  handleDeleteMenuItem(menuItemId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this menu item?',
      header: 'Delete Menu Item',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.designerStore
          .saveConfig(
            this.storefrontId()!,
            {
              menu: this.menu().filter(item => item.id !== menuItemId)
            },
            true
          )
          .subscribe(next => {
            this.designerHelperService.sendToPreview(this.storefrontId()!, next);
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Menu item deleted successfully.'
            });
          });
      },
      reject: () => {}
    });
  }
}
