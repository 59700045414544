<div class="grid grid-nogutter">
  @for (variant of variants(); let i = $index; track i) {
    <div class="xl:col-4 lg:col-4 col-12">
      <div class="variant-item">
        <app-variants-item
          [variantIndex]="i"
          [supplierId]="supplierId()"
          [(variant)]="variants()[i]"
          [(imageUrls)]="imageUrls[i]"
          (variantDeleted)="deleteCatalogProductVariant($event)"
          (mediaDeleted)="deleteVariantMedia($event.index, $event.type)"
          (sizesChangeDialog)="handleSizeDialogOpen(i)"
        ></app-variants-item>
      </div>
    </div>
  }
</div>

<p-dialog header="Edit sizes" [modal]="true" [(visible)]="showSizesDialog" [style]="{ width: '25rem' }">
  <div class="item-sizes">
    <div class="header">
      <div>Size</div>
      <div>Weight</div>
      <div>Total</div>
      <div>Qty</div>
    </div>
    @for (size of sizesDialogVariant?.catalog_variant_sizes; let j = $index; track j) {
      <div class="row">
        <input pInputText [name]="'Size' + size.id" [(ngModel)]="size.size" placeholder="Size" />
        <input pInputText [name]="'Weight' + size.id" [(ngModel)]="size.weight" placeholder="Weight" />
        <input pInputText [name]="'Total' + size.id" [(ngModel)]="size.total" placeholder="Total" />
        <input
          pInputText
          [name]="'Quantity' + size.id"
          [ngModel]="size.quantity"
          placeholder="Quantity"
          [readonly]="true"
          class="p-disabled"
        />
      </div>
    }
  </div>
  <div class="flex justify-content-end gap-2">
    <p-button label="Cancel" severity="secondary" (onClick)="showSizesDialog = false" />
    <p-button label="Save" (onClick)="handleSizeChange()" />
  </div>
</p-dialog>
<p-confirmDialog
  header="Confirmation"
  key="confirmDelete"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  key="confirmDeleteMedia"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '350px' }"
  acceptButtonStyleClass="p-button-danger p-button-text"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
