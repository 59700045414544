import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { SkeletonModule } from 'primeng/skeleton';

import { StorefrontStore } from '../../_store/storefront.store';

@Component({
  selector: 'app-storefront',
  standalone: true,
  imports: [RouterOutlet, SkeletonModule],
  templateUrl: './storefront.component.html',
  styles: ''
})
export class StorefrontComponent {
  public storefrontStore = inject(StorefrontStore);
}
