import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { catchError, map, of } from 'rxjs';

import { StorefrontService } from '../services/storefront.service';

export const selectedStorefrontBreadcrumbResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const storefrontService = inject(StorefrontService);
  const { storefrontId } = route.params;

  return storefrontService.getById(storefrontId).pipe(
    map(res => {
      return res.data.name;
    }),
    catchError(error => {
      console.error('An error occurred:', error);

      return of('Error occurred, fallback value');
    })
  );
};
