import { NgClass } from '@angular/common';
import { Component, computed, ElementRef, inject, OnInit, signal, ViewChild } from '@angular/core';

import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { BroadcastNotificationCreatedEvent } from 'app/_shared/interfaces/sockets/BroadcastNotificationCreatedEvent';
import { Notification } from 'app/_shared/models/Notification';
import { EchoService } from 'app/_shared/services/echo.service';
import { LayoutService } from 'app/_shared/services/layout.service';
import { NotificationService } from 'app/_shared/services/notification.service';
import { AuthStore } from 'app/_store/auth.store';
import { SuperadminTopbarComponent } from 'app/admin/layout/superadmin-topbar/superadmin-topbar.component';

import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    MenuModule,
    BadgeModule,
    BreadcrumbComponent,
    OverlayPanelModule,
    NgClass,
    SuperadminTopbarComponent
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  authStore = inject(AuthStore);
  private echoService: EchoService = inject(EchoService);
  private notificationService = inject(NotificationService);
  private layoutService = inject(LayoutService);

  notifications = signal<Notification[]>([]);
  notificationCount = computed(() => this.notifications().length as unknown as string);

  @ViewChild('menubutton') menuButton!: ElementRef;

  constructor() {
    this.echoService.listen(
      `App.Models.Notifications.User.${this.authStore.user()?.id}`,
      BroadcastNotificationCreatedEvent.eventName,
      (e: BroadcastNotificationCreatedEvent) => {
        const notification: Notification = {
          id: e.id,
          data: { title: e.title, message: e.message }
        };
        this.notifications.update(value => [notification, ...value]);
      }
    );
  }

  ngOnInit() {
    this.notificationService.getLatestNotifications().subscribe(next => {
      this.notifications.set(next.data ?? []);
    });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightSidebar();
  }
}
