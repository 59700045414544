import { Injectable } from '@angular/core';

import { TableLazyLoadEvent } from 'primeng/table';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SuperadminProductService extends ApiService {
  routePrefix = `${this.apiUrl}products/`;

  public paginatedIndex(event?: TableLazyLoadEvent, filters?: any) {
    return this.get(this.routePrefix, {
      params: this.handleTableLazyLoadEvent(event, filters)
    });
  }
}
