import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'environments/environment';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FileSelectEvent, FileUploadEvent, FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, finalize } from 'rxjs';

import { SlugHelper } from 'app/_shared/helpers/slug-helper';
import { Storefront } from 'app/_shared/models/Storefront';
import { CustomValidators } from 'app/_shared/services/custom-validators.service';
import { StorefrontService } from 'app/_shared/services/storefront.service';
import { ToastService } from 'app/_shared/services/toast.service';
import { AuthStore } from 'app/_store/auth.store';
import { CompanyStore } from 'app/_store/company.store';

interface Logo {
  file: File | null;
  objectURL: string | null;
  name: string | null;
}
@Component({
  selector: 'app-storefront-editor',
  standalone: true,
  imports: [
    CheckboxModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    FileUploadModule,
    InputGroupAddonModule,
    InputGroupModule
  ],
  templateUrl: './storefront-editor.component.html',
  styleUrl: './storefront-editor.component.scss'
})
export class StorefrontEditorComponent implements OnInit {
  protected prefix = environment.PREFIX;
  protected suffix = environment.SUFFIX;
  authStore = inject(AuthStore);
  companyStore = inject(CompanyStore);
  slugHelper = inject(SlugHelper);

  isLoading = 0;
  logo: Logo = {
    file: null,
    objectURL: null,
    name: null
  };
  storefrontForm: FormGroup;
  formReady = false;

  public submitted = false;
  public editMode = false;

  private storefront: Storefront;

  constructor(
    private route: ActivatedRoute,
    private storefrontService: StorefrontService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.editMode = this.route.snapshot.data.editMode;
    this.initForm();
  }

  initForm() {
    if (!this.editMode) {
      this.initCreateMode();
      return;
    }
    this.initEditMode();
  }

  initCreateMode() {
    this.storefront = new Storefront();
    this.buildForm();
  }

  initEditMode() {
    const storefrontId = this.route.snapshot.paramMap.get('id');
    if (!storefrontId) {
      console.error('Storefront ID is null.');
      return;
    }
    this.storefrontService.getById(+storefrontId).subscribe({
      next: response => {
        this.storefront = response.data;
        if (response.data.logo) {
          this.logo.objectURL = response.data.logo?.full_path;
        }
        this.buildForm();
      },
      error: error => {
        console.error(error);
      }
    });
  }

  buildForm() {
    this.storefrontForm = this.fb.group({
      id: this.storefront?.id,
      name: [this.storefront?.name, [Validators.required]],
      subdomain: [this.storefront?.subdomain, [Validators.required, CustomValidators.validateSubdomain()]]
    });
    this.formReady = true;
    this.subscribeToNameChange();
  }

  subscribeToNameChange() {
    this.storefrontForm
      .get('name')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(name => {
        const baseUrl = this.slugHelper.generateBaseSlug(name);
        this.storefrontForm.get('subdomain')?.setValue(baseUrl, { emitEvent: false });
      });
  }

  onUpload(event: FileUploadEvent | FileSelectEvent) {
    const [file] = event.files;
    const objectURL = URL.createObjectURL(file);

    this.logo = {
      file,
      objectURL,
      name: file.name
    };
  }

  onSubmit() {
    if (this.storefrontForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }

    const formattedCreateData = this.prepareCreateFormData();
    if (!this.editMode) {
      this.createStorefront(formattedCreateData);
      return;
    }
    this.updateStorefront();
  }

  createStorefront(data: FormData) {
    this.isLoading++;
    this.storefrontService
      .create(data)
      .pipe(
        finalize(() => {
          this.isLoading--;
        })
      )
      .subscribe({
        next: next => {
          this.toastService.success('Successfully created storefront!');
          this.companyStore.addEditStorefront(next.data);
          this.router.navigate([`../`], { relativeTo: this.route });
        },
        error: err => {
          console.error('Creation failed:', err);
        }
      });
  }

  updateStorefront() {
    const formData = new FormData();
    const storefrontFormValues = this.storefrontForm.value;

    formData.append('name', storefrontFormValues.name);
    formData.append('subdomain', storefrontFormValues.subdomain);
    if (this.logo.file) {
      formData.append('logo', this.logo?.file);
    }
    if (this.storefront.logo && !this.logo.objectURL) {
      formData.append('delete_logo', 'true');
    }
    formData.append('_method', 'put');

    this.storefrontService.update(this.storefrontForm.value.id, formData).subscribe({
      next: storefront => {
        this.toastService.success('Successfully updated storefront!');
        this.companyStore.addEditStorefront(storefront.data, storefront.data.id);
        this.router.navigate([`../../`], { relativeTo: this.route });
      },
      error: err => {
        console.error('Update failed:', err);
      }
    });
  }

  prepareCreateFormData() {
    const formData = new FormData();
    const companyId = this.companyStore.workingCompany()?.id.toString();
    const storefrontFormValues = this.storefrontForm.value;

    formData.append('name', storefrontFormValues.name);
    formData.append('subdomain', storefrontFormValues.subdomain);
    if (companyId) {
      formData.append('company_id', companyId);
    }
    formData.append('data', JSON.stringify({}));
    formData.append('status', 'draft');

    if (this.logo.file) {
      formData.append('logo', this.logo.file);
    }
    if (this.editMode) {
      formData.append('_method', 'put');
    }
    return formData;
  }

  removeImage() {
    this.logo = {
      file: null,
      name: null,
      objectURL: null
    };
    this.toastService.success(`Logo successfully removed.`);
  }
}
