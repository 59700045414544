<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="app-logo">
      SingleServeMerch &trade;
    </a>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-nav />
  </div>
</div>
