<div class="card">
  <div>
    <h4>Create new collection</h4>
    <div class="flex justify-content-between">
      <div>
        <label for="" class="block">Collection name</label>
        <input pInputText id="name" class="mb-3" autocomplete="off" [(ngModel)]="name" />
      </div>
      <p-button
        label="Save collection"
        (onClick)="saveCollection()"
        [disabled]="!selectedItems?.length || !name"
      ></p-button>
    </div>
  </div>

  @if ((collectionId && collection) || !collectionId) {
    <app-product-selection
      (onSave)="handleSelectionSave($event)"
      [isSubmitting]="isLoading"
      [disabled]="!name"
      [defaultSelection]="collection?.catalog_products"
    />
  }
</div>
