import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IApiPaginatedData } from 'app/_shared/interfaces/IApiPaginatedData';
import { IApiResponse } from 'app/_shared/interfaces/IApiResponse';
import { StorefrontProduct, StorefrontProductData } from 'app/_shared/interfaces/storefront';
import { GeneralStoreSettings } from 'app/_shared/models/GeneralStoreSettings';
import { Storefront } from 'app/_shared/models/Storefront';

import { CustomMailData } from '../models/Coupon';
import { CustomStorefrontProduct } from '../models/CustomProduct';
import { FormGroupValue } from '../models/FormGroup';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StorefrontService extends ApiService {
  routePrefix = `${this.apiUrl}storefronts/`;

  public index() {
    return this.get(this.routePrefix);
  }

  public indexPaginatedWithLogo($event?: any, deleted?: boolean) {
    const params = {
      ...this.handleTableLazyLoadEvent($event),
      ...(deleted !== undefined ? { deleted } : {}),
      'relations[]': ['logo']
    };
    return this.get(`${this.routePrefix}paginate`, { params });
  }

  public indexPaginated($event?: any, deleted?: boolean) {
    const params = {
      ...this.handleTableLazyLoadEvent($event),
      ...(deleted !== undefined ? { deleted } : {})
    };
    return this.get(`${this.routePrefix}paginate`, { params });
  }

  public getById(id: number) {
    return this.get(this.routePrefix + id, {
      params: {
        'relations[]': ['logo']
      }
    });
  }

  public create(data: FormData) {
    return this.post<Storefront>(this.routePrefix, data);
  }

  public update(id: number, data: FormData) {
    return this.post<Storefront>(`${this.routePrefix}${id}`, data);
  }

  public deleteStorefront(id?: number) {
    return this.delete(`${this.routePrefix}${id}`);
  }

  public forceDelete(id: number) {
    return this.delete(`${this.routePrefix}force-delete/${id}`);
  }

  public updateLogo(id: number, data: FormData) {
    return this.post(`${this.routePrefix}${id}/logo`, data);
  }

  public getStoreGeneralSettings(id: number) {
    return this.get<GeneralStoreSettings>(`${this.routePrefix + id}/general-store-settings`);
  }

  public updateGeneralStoreSettings(data: FormData, id?: number) {
    return this.put(`${this.routePrefix + id}/general-store-settings`, data);
  }

  public createOrUpdateStoreOperatorInfo(data: FormData, id: number) {
    return this.post(`${this.routePrefix + id}/operator-information`, data);
  }

  public updateStatus(data: { status: string }, id?: number) {
    return this.put(`${this.routePrefix + id}/change-status`, data);
  }

  public storeCountries() {
    return this.get(`${this.routePrefix}countries`);
  }

  public getStoreCommunicationData(id: number) {
    return this.get(`${this.routePrefix + id}/communication`);
  }

  public updateStoreCommunicationData(data: FormGroupValue, id?: number) {
    return this.put(`${this.routePrefix + id}/communication`, data);
  }

  public testSmtp(data: FormGroupValue, id?: number) {
    return this.post(`${this.routePrefix + id}/test/smtp`, data);
  }

  public restore(id: number) {
    return this.post(`${this.routePrefix}restore/${id}`);
  }

  public createStorefrontProduct(storefrontId: string, data: StorefrontProductData) {
    return this.post(`${this.routePrefix}${storefrontId}/products`, data);
  }

  public updateStorefrontProduct(storefrontId: string, data: StorefrontProductData, productId?: number) {
    return this.put(`${this.routePrefix}${storefrontId}/products/${productId}`, data);
  }

  public getStorefrontProducts(
    storefrontId: string,
    params: any
  ): Observable<IApiResponse<IApiPaginatedData<StorefrontProduct[]>>> {
    return this.get(`${this.routePrefix}${storefrontId}/products`, { params });
  }

  public getStorefrontProduct(storefrontId: string, productId: string) {
    return this.get<StorefrontProduct>(`${this.routePrefix}${storefrontId}/products/${productId}`, {
      params: {
        'relations[]': [
          'storefront',
          'catalogProduct',
          'catalogProduct.catalogProductVariants.frontImage.supplierMedia',
          'catalogProduct.catalogProductVariants.rearImage.supplierMedia',
          'catalogProduct.catalogProductVariants.sideImage.supplierMedia',
          'catalogProduct.printAreas',
          'productSizes',
          'productCategories',
          'productPrints.library.mockupFile',
          'productPrints.library.secondMockupFile',
          'productPrints.library.thirdMockupFile',
          'productPrints.library.fourthMockupFile',
          'productPrints.library.fifthMockupFile',
          'productVariants',
          'productVariants.productVariantSizes',
          'productVariants.frontImage',
          'productVariants.rearImage',
          'productVariants.sideImage',
          'productVariants.productVariantPrints',
          'productVariants.catalogProductVariant.frontImage.supplierMedia',
          'productVariants.catalogProductVariant.rearImage.supplierMedia',
          'productVariants.catalogProductVariant.sideImage.supplierMedia',
          'productThumbnail',
          'additionalPhotos'
        ]
      }
    });
  }

  public deleteStorefrontProduct(storefrontId: number, productId: number) {
    return this.delete(`${this.routePrefix}${storefrontId}/products/${productId}`);
  }

  public checkSlug(storefrontId: number, slug: string) {
    return this.post(`${this.routePrefix}${storefrontId}/products/check-slug`, { slug });
  }

  public createCustomStorefrontProduct(storefrontId: string, data: FormGroupValue) {
    return this.post(`${this.routePrefix}${storefrontId}/custom/products`, data);
  }

  public updateCustomStorefrontProduct(storefrontId: string, data: FormGroupValue, productId?: string) {
    return this.put(`${this.routePrefix}${storefrontId}/custom/products/${productId}`, data);
  }

  public getCustomProduct(storefrontId: string, productId: string) {
    return this.get<CustomStorefrontProduct>(`${this.routePrefix}${storefrontId}/products/${productId}`, {
      params: {
        'relations[]': [
          'productVariants.productVariantSizes',
          'productVariants.frontImage',
          'productVariants.sideImage',
          'productVariants.rearImage',
          'productVariants.productVariantPrints',
          'productCategories',
          'additionalPhotos'
        ]
      }
    });
  }

  public getStorefrontStorageFiles(storefrontId: string) {
    return this.get(`${this.routePrefix}${storefrontId}/storage`);
  }

  public removeFiles(storefrontId: string, fileIds: string[]) {
    return this.delete(`${this.routePrefix}${storefrontId}/storage`, { params: { 'ids[]': fileIds } });
  }
  public updateFiles(storefrontId: string, fileIds: string[], lock: 0 | 1) {
    return this.put(`${this.routePrefix}${storefrontId}/storage`, { params: { 'ids[]': fileIds, lock: lock } });
  }

  public getStorefrontWithGiftCardMail(id: number) {
    return this.get(this.routePrefix + id, {
      params: {
        'relations[]': ['giftCardCustomMails', 'logo']
      }
    });
  }

  updateCustomMailTemplate(storefrontId: number, mailId: number, mailData: CustomMailData) {
    return this.put(`${this.routePrefix}${storefrontId}/custom-mails/${mailId}`, { data: mailData });
  }
}
