import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';

import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { provideNgxStripe } from 'ngx-stripe';
import { MessageService } from 'primeng/api';

import { AuthService } from 'app/_shared/services/auth.service';

import { tokenInterceptor } from './_shared/interceptors/token.interceptor';
import { routes } from './app.routes';

export function initializeApp(authService: AuthService): () => Promise<void> {
  return () => authService.getUser();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideAnimations(),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode()
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService],
      useFactory: initializeApp
    },
    MessageService,
    provideNgxStripe(environment.stripeKey)
  ]
};
