<div class="grid formgrid p-2">
  <div class="col-7" [ngClass]="{ 'flex-order-1': imagePosition() === 'right' }">
    <p-fileUpload
      #fileUploader
      name="demo[]"
      url="./upload.php"
      (onSelect)="onUpload($event)"
      [customUpload]="true"
      accept="image/*"
      [showUploadButton]="false"
      [showCancelButton]="false"
      [auto]="true"
      styleClass="border-1 surface-border surface-card p-0 border-round mb-4 h-full"
    >
      <ng-template pTemplate="content">
        <div class="h-20rem m-1 border-round">
          <div
            *ngIf="!image?.url"
            class="flex flex-column w-full h-full justify-content-center align-items-center cursor-pointer"
            (click)="fileUploader.advancedFileInput.nativeElement.click()"
          >
            <i class="pi pi-fw pi-file text-4xl text-primary"></i>
            <span class="block font-semibold text-900 text-lg mt-3">Drop or select section image</span>
          </div>
          <div
            *ngIf="image?.url"
            class="flex w-full h-full relative border-round p-0 justify-content-center align-items-center"
          >
            <img [src]="image?.url" class="h-full border-round text-center" alt="blog cover" />
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center"
              style="top: -10px; right: -10px"
              (click)="$event.stopPropagation(); removeImage()"
            ></button>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="col-5" [ngClass]="{ 'flex-order-0': imagePosition() === 'right' }">
    <form [formGroup]="formGroup">
      <p-editor #editor (onInit)="setDefaultValue()" formControlName="text" [style]="{ height: '320px' }" />
    </form>
  </div>
</div>
<div class="">
  <p><strong>Select image position</strong></p>
  <div class="field-radiobutton">
    <p-radioButton name="imagePosition" value="left" [(ngModel)]="imagePosition" id="left"></p-radioButton>
    <label for="left">Left</label>
  </div>

  <div class="field-radiobutton">
    <p-radioButton name="imagePosition" value="right" [(ngModel)]="imagePosition" id="center"></p-radioButton>
    <label for="center">Right</label>
  </div>
  <div class="flex justify-content-end mt-4">
    <button pButton icon="pi pi-check" (click)="onSave()" label="Save"></button>
  </div>
</div>
