<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="grid grid-nogutter justify-content-end">
    <div class="col-12">
      <div class="field">
        <label for="name">Category Name</label>
        <input
          type="text"
          pInputText
          class="w-full"
          formControlName="name"
          id="name"
          [ngClass]="{
            'ng-dirty': form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)
          }"
        />
        @if ((submitted || form.get('name')?.touched) && form.get('name')?.errors?.['required']) {
          <div>
            <small class="p-text-danger">Name is required</small>
          </div>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="mb-3">
        <div [ngClass]="{ 'invalid-upload': submitted && !uploadedImage && (!category || !imagePreviewUrl) }">
          <p-fileUpload
            name="catefory-image-uploader"
            [multiple]="false"
            (onUpload)="onImageUpload($event)"
            [customUpload]="true"
            (onSelect)="onImageUpload($event)"
            (onRemove)="onImageRemove()"
            (onClear)="onImageRemove()"
            accept=".jpg,.jpeg,.png"
            maxFileSize="1000000"
          >
            <ng-template pTemplate="header" let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
              <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                <div class="flex gap-2">
                  <p-button (onClick)="chooseCallback()" icon="pi pi-images" outlined label="Choose" />
                  @if (category && !imagePreviewUrl) {
                    <p-button (onClick)="clearCallback()" icon="pi pi-refresh" outlined label="Reset" />
                  }
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              @if (imagePreviewUrl) {
                <div class="flex">
                  <div class="col-3">
                    <img [src]="imagePreviewUrl" alt="Uploaded Image" style="width: 50px" />
                  </div>
                  <div class="col-3 flex justify-content-start align-items-center image-name">
                    {{ category?.category_image?.name }}
                  </div>
                  <div class="col-3"></div>
                  <div class="col-3 flex justify-content-end align-items-center">
                    <p-button type="button" icon="pi pi-times" (onClick)="removeOldImage()"></p-button>
                  </div>
                </div>
              }
            </ng-template>
            <ng-template pTemplate="empty">
              @if (!imagePreviewUrl) {
                <div class="flex align-items-center justify-content-center flex-column">
                  <i class="pi pi-cloud-upload border-1 border-circle p-5 text-4xl text-400 border-400"></i>
                  <p class="mt-4 mb-0">Drag and drop files here to upload.</p>
                </div>
              }
            </ng-template>
          </p-fileUpload>
        </div>
        @if (submitted && !uploadedImage && (!category || !imagePreviewUrl)) {
          <div>
            <small class="p-text-danger">Image is required</small>
          </div>
        }
      </div>
    </div>
    <div class="col-auto">
      <div class="flex gap-2 justify-content-end">
        <p-button
          icon="pi pi-times"
          label="Cancel"
          type="button"
          outlined
          (onClick)="closeDialog()"
          [disabled]="isLoading"
        />
        <p-button icon="pi pi-check" label="Save" type="submit" [disabled]="isLoading" />
      </div>
    </div>
  </div>
</form>
