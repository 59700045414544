import { Component, computed, input } from '@angular/core';

import { ChipModule } from 'primeng/chip';

import { statusOptions } from 'app/_shared/constants/order-status-options';
import { InviteStatusType } from 'app/_shared/models/InviteStatusType';
import { OrderStatus } from 'app/_shared/models/OrderStatus';

type Status = 'active' | 'inactive' | 'draft' | OrderStatus | InviteStatusType;
@Component({
  selector: 'app-status-badge',
  standalone: true,
  imports: [ChipModule],
  template: `
    <p-chip [styleClass]="'statusBadge ' + chipClassColor()" [label]="label()" />
  `,
  styles: [
    `
      ::ng-deep {
        .statusBadge {
          .p-chip-text {
            text-transform: capitalize;
          }

          &.colorDefault {
            background-color: var(--gray-100);
          }

          &.colorActive {
            background-color: var(--green-100);
          }

          &.colorInactive {
            background-color: var(--red-100);
          }

          &.colorDraft {
            background-color: var(--yellow-200);
          }

          &.newOrder {
            background-color: var(--blue-100);
          }

          &.inProgressOrder {
            background-color: var(--orange-100);
          }

          &.shippedOrder {
            background-color: var(--pink-100);
          }

          &.partiallyShippedOrder {
            background-color: var(--indigo-100);
          }

          &.canceledOrder {
            background-color: var(--teal-100);
          }

          &.onHoldOrder {
            background-color: var(--gray-200);
          }

          &.notPaidOrder {
            background-color: var(--bluegray-100);
          }

          &.refundOrder {
            background-color: var(--purple-100);
          }

          &.invitedInvite {
            background-color: var(--cyan-100);
          }

          &.acceptedInvite {
            background-color: var(--lime-100);
          }

          &.requestedInvited {
            background-color: var(--amber-100);
          }
        }
      }
    `
  ]
})
export class StatusBadgeComponent {
  status = input.required<Status>();
  color = input<Status>();

  chipClassColor = computed(() => {
    const colorKey = this.color() ?? this.status();
    return this.statusColors[colorKey] || this.statusColors.default;
  });

  statusColors: { [key: string]: string } = {
    active: 'colorActive',
    inactive: 'colorInactive',
    draft: 'colorDraft',
    [OrderStatus.NEW_ORDER]: 'newOrder',
    [OrderStatus.IN_PROGRESS]: 'inProgressOrder',
    [OrderStatus.SHIPPED]: 'shippedOrder',
    [OrderStatus.PARTIALLY_SHIPPED]: 'partiallyShippedOrder',
    [OrderStatus.CANCELED]: 'canceledOrder',
    [OrderStatus.ON_HOLD]: 'onHoldOrder',
    [OrderStatus.NOT_PAID]: 'notPaidOrder',
    [OrderStatus.REFUND]: 'refundOrder',
    [InviteStatusType.invited]: 'invitedInvite',
    [InviteStatusType.accepted]: 'acceptedInvite',
    [InviteStatusType.requested]: 'requestedInvite',
    default: 'colorDefault'
  };

  label = computed(() => {
    const colorKey = this.color() ?? this.status();

    const statusOption = statusOptions.find(option => option.value === colorKey);
    return statusOption ? statusOption.label : colorKey.toString().toLowerCase();
  });
}
