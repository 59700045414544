import { Routes } from '@angular/router';

import { CompanySelectDashboardComponent } from 'app/_shared/components/company-select-dashboard/company-select-dashboard.component';
import { UserAccountComponent } from 'app/_shared/components/user-account/user-account.component';
import { UserDetailsComponent } from 'app/_shared/components/user-details/user-details.component';
import { authGuard, nonVerifiedUserGuard, verifiedUserGuard } from 'app/_shared/guards/auth.guard';
import { guestGuard } from 'app/_shared/guards/guest.guard';
import { superAdminGuard } from 'app/_shared/guards/super-admin.guard';
import { colorsResolver } from 'app/_shared/resolvers/colors.resolver';
import { companyResolver, unselectCompanyResolver } from 'app/_shared/resolvers/company-resolver';
import { selectedStorefrontResolver } from 'app/_shared/resolvers/storefronts.resolver';
import { suppliersResolver } from 'app/_shared/resolvers/suppliers.resolver';
import { suppliersColorsResolver } from 'app/_shared/resolvers/suppliersColors.resolver';
import { BillingComponent } from 'app/admin/company-managment/billing/billing.component';
import { ClientSelectionEditorComponent } from 'app/admin/company-managment/client-selection-editor/client-selection-editor.component';
import { ClientSelectionListComponent } from 'app/admin/company-managment/client-selection-list/client-selection-list.component';
import { CollectionsEditorComponent } from 'app/admin/company-managment/collections/collections-editor/collections-editor.component';
import { CollectionsListComponent } from 'app/admin/company-managment/collections/collections-list.component';
import { CompanyManagementComponent } from 'app/admin/company-managment/company-management.component';
import { GeneralComponent } from 'app/admin/company-managment/general/general.component';
import { InviteListComponent } from 'app/admin/company-managment/invite-list/invite-list.component';
import { TaxFormsComponent } from 'app/admin/company-managment/tax-forms/tax-forms.component';
import { UserListComponent as CompanyUserList } from 'app/admin/company-managment/user-list/user-list.component';
import { CompanyComponent } from 'app/admin/company/company.component';
import { LayoutComponent as AdminLayout } from 'app/admin/layout/layout.component';
import { OrderDetailsComponent } from 'app/admin/orders/order-details/order-details.component';
import { OrdersComponent } from 'app/admin/orders/orders.component';
import { DesignerComponent } from 'app/admin/storefront/designer/designer.component';
import { HomepageComponent } from 'app/admin/storefront/designer/homepage/homepage.component';
import { MenuAndPagesComponent } from 'app/admin/storefront/designer/menu-and-pages/menu-and-pages.component';
import { SettingsComponent } from 'app/admin/storefront/designer/settings/settings.component';
import { FilesComponent } from 'app/admin/storefront/files/files.component';
import { CreateProductComponent } from 'app/admin/storefront/products/create-product/create-product.component';
import { ListCatalogProductsComponent } from 'app/admin/storefront/products/list-catalog-products/list-catalog-products.component';
import { ProductsComponent } from 'app/admin/storefront/products/products.component';
import { SingleProductComponent } from 'app/admin/storefront/products/single-product/single-product.component';
import { CommunicationsComponent } from 'app/admin/storefront/settings/communications/communications.component';
import { GeneralSettingsComponent } from 'app/admin/storefront/settings/general-settings/general-settings.component';
import { SettingsComponent as StorefrontSettings } from 'app/admin/storefront/settings/settings.component';
import { StorefrontComponent } from 'app/admin/storefront/storefront.component';
import { StorefrontEditorComponent } from 'app/admin/storefronts/storefront-editor/storefront-editor.component';
import { StorefrontListComponent } from 'app/admin/storefronts/storefront-list/storefront-list.component';
import { CompanyRegisterComponent } from 'app/auth/company-register/company-register.component';
import { EmailVerificationComponent } from 'app/auth/email-verification/email-verification.component';
import { ForgotPasswordComponent } from 'app/auth/forgot-password/forgot-password.component';
import { LayoutComponent as AuthLayout } from 'app/auth/layout/layout.component';
import { LockScreenComponent } from 'app/auth/lock-screen/lock-screen.component';
import { LoginComponent } from 'app/auth/login/login.component';
import { RegisterComponent } from 'app/auth/register/register.component';
import { UpdatePasswordComponent } from 'app/auth/update-password/update-password.component';
import { UserInviteStatusComponent } from 'app/auth/user-invite-status/user-invite-status.component';
import { CatalogProductsListComponent } from 'app/superadmin/catalog/catalog-products-list.component';
import { CatalogSupplierListComponent } from 'app/superadmin/catalog/catalog-supplier-list/catalog-supplier-list.component';
import { CreateCatalogProductComponent } from 'app/superadmin/catalog/create-catalog-product/create-catalog-product.component';
import { EditCatalogProductComponent } from 'app/superadmin/catalog/edit-catalog-product/edit-catalog-product.component';
import { ClientSelectionComponent } from 'app/superadmin/client-selection/client-selection.component';
import { AddColorComponent } from 'app/superadmin/colors/add-color/add-color.component';
import { ColorsComponent } from 'app/superadmin/colors/colors.component';
import { EditColorComponent } from 'app/superadmin/colors/edit-color/edit-color.component';
import { InviteCompanyListComponent } from 'app/superadmin/invite-company-list/invite-company-list.component';
import { OrderListComponent as SuperadminOrderListComponent } from 'app/superadmin/order-list/order-list.component';
import { PaymentsComponent } from 'app/superadmin/payments/payments.component';
import { StorefrontListComponent as SuperadminStorefrontList } from 'app/superadmin/storefront-list/storefront-list.component';
import { ImportProductComponent } from 'app/superadmin/suppliers/import-product/import-product.component';
import { SupplierDetailsComponent } from 'app/superadmin/suppliers/supplier-details/supplier-details.component';
import { SupplierDictionaryComponent } from 'app/superadmin/suppliers/supplier-dictionary/supplier-dictionary.component';
import { SupplierProductViewComponent } from 'app/superadmin/suppliers/supplier-product-view/supplier-product-view.component';
import { SupplierProductComponent } from 'app/superadmin/suppliers/supplier-product/supplier-product.component';
import { SupplierProductsComponent } from 'app/superadmin/suppliers/supplier-products/supplier-products.component';
import { SupplierSettingsComponent } from 'app/superadmin/suppliers/supplier-settings/supplier-settings.component';
import { SuppliersComponent } from 'app/superadmin/suppliers/suppliers.component';
import { UserListComponent as SuperadminUserList } from 'app/superadmin/user-list/user-list.component';

import { ComingSoonComponent } from './_shared/components/coming-soon/coming-soon.component';
import { PaymentMethodsComponent } from './admin/company-managment/payment-methods/payment-methods.component';
import { CategoryManagmentComponent } from './admin/storefront/products/category-managment/category-managment.component';
import { CustomProductEditorComponent } from './admin/storefront/products/custom-product-editor/custom-product-editor.component';
import { CatalogProductCategoryManagementComponent } from './superadmin/catalog/catalog-product-category-managment/catalog-product-category-management.component';

// TODO: Fix routes hierarchy
export const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayout,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', canActivate: [guestGuard], component: LoginComponent },
      {
        path: 'register',
        canActivate: [guestGuard],
        component: RegisterComponent
      },
      {
        path: 'company-register',
        canActivate: [guestGuard],
        component: CompanyRegisterComponent
      },
      {
        path: 'email-verification',
        canActivate: [authGuard, nonVerifiedUserGuard],
        component: EmailVerificationComponent
      },
      {
        path: 'user-invite-status',
        canActivate: [guestGuard],
        component: UserInviteStatusComponent
      },
      {
        path: 'forgot-password',
        canActivate: [guestGuard],
        component: ForgotPasswordComponent
      },
      {
        path: 'update-password',
        canActivate: [guestGuard],
        component: UpdatePasswordComponent
      },
      {
        path: 'locked',
        canActivate: [guestGuard],
        component: LockScreenComponent
      }
    ]
  },
  { path: 'client-selection', component: ClientSelectionComponent },
  {
    path: '',
    canActivate: [authGuard, verifiedUserGuard],
    component: AdminLayout,
    title: 'SSM Admin',
    data: { breadcrumb: 'Admin' },
    children: [
      {
        path: '',
        component: CompanySelectDashboardComponent,
        resolve: [unselectCompanyResolver],
        data: { breadcrumb: 'Dashboard' }
      },
      { path: 'account', component: UserAccountComponent },
      { path: 'coming-soon', component: ComingSoonComponent, data: { breadcrumb: 'Coming soon' } },
      {
        path: 'storefronts',
        canActivate: [superAdminGuard],
        data: { breadcrumb: 'Storefronts' },
        component: SuperadminStorefrontList
      },
      {
        path: 'orders',
        canActivate: [superAdminGuard],
        data: { breadcrumb: 'Orders' },
        children: [
          {
            path: '',
            canActivate: [superAdminGuard],
            component: SuperadminOrderListComponent
          },
          {
            path: ':orderId',
            canActivate: [superAdminGuard],
            component: OrderDetailsComponent,
            data: {
              superadminMode: true,
              breadcrumb: 'Order'
            }
          }
        ]
      },
      {
        path: 'users',
        canActivate: [superAdminGuard],
        data: { breadcrumb: 'Users' },
        children: [
          {
            path: '',
            canActivate: [superAdminGuard],
            component: SuperadminUserList
          },
          {
            path: ':id',
            canActivate: [superAdminGuard],
            component: UserDetailsComponent,
            data: { companyUser: false, breadcrumb: 'User' }
          }
        ]
      },
      {
        path: 'invites',
        canActivate: [superAdminGuard],
        component: InviteCompanyListComponent,
        data: { breadcrumb: 'Invites' }
      },
      {
        path: 'catalog',
        canActivate: [superAdminGuard],
        data: { breadcrumb: 'Catalog Products' },
        children: [
          { path: '', component: CatalogProductsListComponent },
          {
            path: 'create',
            component: CatalogSupplierListComponent,
            data: { breadcrumb: 'Create Catalog Product' }
          },
          {
            path: 'category-management',
            component: CatalogProductCategoryManagementComponent,
            data: { breadcrumb: 'Categories' }
          },
          {
            path: 'create/:slug/products/:id',
            component: CreateCatalogProductComponent,
            data: { breadcrumb: 'Create Catalog Product' }
          },
          {
            path: ':id',
            // TODO not implemented preview, uncomment after implementation
            // data: { breadcrumb: 'Catalog Product' },
            children: [{ path: 'edit', component: EditCatalogProductComponent, data: { breadcrumb: 'Edit' } }]
          }
        ]
      },

      {
        path: 'suppliers',
        canActivate: [superAdminGuard],
        resolve: { suppliers: suppliersResolver },
        runGuardsAndResolvers: 'always',
        data: { breadcrumb: 'Suppliers' },
        children: [
          {
            path: '',
            component: SuppliersComponent
          },
          {
            path: ':slug',
            data: { breadcrumb: 'Supplier details' },
            component: SupplierDetailsComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'settings'
              },
              {
                path: 'settings',
                component: SupplierSettingsComponent
              },
              {
                path: 'dictionary',
                component: SupplierDictionaryComponent
              }
            ]
          },
          {
            path: ':slug/products',

            data: { breadcrumb: 'Supplier' },
            children: [
              {
                path: '',
                component: SupplierProductsComponent
              },
              {
                path: 'import',
                component: ImportProductComponent,
                data: { breadcrumb: 'Import Product' }
              },
              {
                path: ':id',
                component: SupplierProductViewComponent,
                data: { breadcrumb: 'Product' }
              },
              {
                path: ':id/create',
                component: SupplierProductComponent,
                data: { breadcrumb: 'Create Catalog Product' }
              }
            ]
          }
        ]
      },
      {
        path: 'colors',
        canActivate: [superAdminGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          colors: colorsResolver,
          suppliers: suppliersColorsResolver
        },
        children: [
          {
            path: '',
            component: ColorsComponent
          },
          {
            path: 'create',
            component: AddColorComponent
          },
          {
            path: ':id',
            component: EditColorComponent
          }
        ]
      },
      {
        path: 'payments',
        canActivate: [superAdminGuard],
        component: PaymentsComponent
      },
      {
        path: 'companies/:id',
        component: CompanyComponent,
        canActivate: [authGuard],
        resolve: [companyResolver],
        data: { breadcrumb: 'Company' },
        children: [
          { path: 'management/collections/create', component: CollectionsEditorComponent },
          {
            path: 'management/collections/:collectionId/edit',
            component: CollectionsEditorComponent
          },
          { path: 'management/client-selection/create', component: ClientSelectionEditorComponent },
          { path: 'management/client-selection/:selectionId/edit', component: ClientSelectionEditorComponent },
          {
            path: 'management',
            component: CompanyManagementComponent,
            children: [
              { path: '', redirectTo: 'general', pathMatch: 'full' },
              { path: 'general', component: GeneralComponent },
              { path: 'tax-forms', component: TaxFormsComponent },
              { path: 'billing', component: BillingComponent },
              {
                path: 'users',
                children: [
                  { path: '', component: CompanyUserList },
                  { path: ':id', component: UserDetailsComponent, data: { companyUser: true } }
                ]
              },
              {
                path: 'invites',
                component: InviteListComponent
              },
              {
                path: 'payment-methods',
                component: PaymentMethodsComponent
              },
              {
                path: 'client-selection',
                component: ClientSelectionListComponent
              },
              {
                path: 'collections',
                children: [{ path: '', component: CollectionsListComponent }]
              }
            ]
          },
          {
            path: 'storefronts',
            component: StorefrontComponent,
            data: { breadcrumb: 'Storefronts' },
            children: [
              { path: '', component: StorefrontListComponent },
              {
                path: 'create',
                component: StorefrontEditorComponent,
                data: { editMode: false }
              },
              {
                path: ':id/edit',
                component: StorefrontEditorComponent,
                data: { editMode: true }
              },
              {
                path: ':storefrontId',
                data: { breadcrumb: 'Storefront' },
                resolve: { storefront: selectedStorefrontResolver },
                component: StorefrontComponent,
                children: [
                  {
                    path: 'products',
                    data: { breadcrumb: 'Products' },
                    children: [
                      {
                        path: '',
                        component: ProductsComponent
                      },
                      {
                        path: 'create',
                        data: { breadcrumb: 'Create' },
                        children: [
                          {
                            path: '',
                            component: ListCatalogProductsComponent
                          },

                          {
                            path: ':catalogProductId',
                            component: CreateProductComponent
                          }
                        ]
                      },
                      {
                        path: 'custom',
                        children: [
                          {
                            path: 'create',
                            component: CustomProductEditorComponent
                          },

                          {
                            path: ':customProductId',
                            component: CustomProductEditorComponent
                          }
                        ]
                      },
                      {
                        path: 'category-management',
                        component: CategoryManagmentComponent,
                        data: { breadcrumb: 'Categories' }
                      },
                      {
                        path: ':productId',
                        component: SingleProductComponent
                      }
                    ]
                  },
                  {
                    path: 'designer',
                    data: { breadcrumb: 'Designer' },
                    component: DesignerComponent,
                    children: [
                      {
                        path: '',
                        redirectTo: 'homepage',
                        pathMatch: 'full'
                      },
                      {
                        path: 'homepage',
                        component: HomepageComponent
                      },
                      {
                        path: 'menu-and-pages',
                        component: MenuAndPagesComponent
                      },
                      {
                        path: 'settings',
                        component: SettingsComponent
                      }
                    ]
                  },
                  {
                    path: 'files',
                    component: FilesComponent,
                    data: { breadcrumb: 'Files' }
                  },
                  {
                    path: 'settings',
                    component: StorefrontSettings,
                    data: { breadcrumb: 'Settings' },
                    children: [
                      {
                        path: '',
                        redirectTo: 'general',
                        pathMatch: 'full'
                      },
                      {
                        path: 'general',
                        component: GeneralSettingsComponent
                      },
                      {
                        path: 'communications',
                        component: CommunicationsComponent
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'orders',
            component: OrdersComponent,
            data: { breadcrumb: 'Orders' }
          },
          {
            path: 'orders/:orderId',
            component: OrderDetailsComponent,
            data: {
              superadminMode: false,
              breadcrumb: 'Order details'
            }
          }
        ]
      }
    ]
  }
];
