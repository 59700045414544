import { computed, inject, Injectable } from '@angular/core';

import { Page, Section } from 'app/_shared/interfaces/designer/page';
import { DesignerService } from 'app/_shared/services/designer.service';
import { Config, DesignerStore } from 'app/_store/designer.store';

@Injectable({
  providedIn: 'root'
})
export class DesignerHelperService {
  designerStore = inject(DesignerStore);
  designerService = inject(DesignerService);
  pages = computed(() => this.designerStore.config().pages ?? []);

  constructor() {}

  sendToPreview(storefrontId: number, data: Config, onComplete?: () => void, onError?: () => void) {
    this.designerStore.setLoading(true);
    this.designerService
      .sendToPreview(storefrontId, {
        preview_data: JSON.stringify(data)
      })
      .subscribe({
        next: res => {
          onComplete && onComplete();
        },
        error: err => {
          onError && onError();
          console.error(err);
        },
        complete: () => {}
      });
  }

  saveSectionData(selectedPage: Page, selectedSection: Section, data: any) {
    const newSections = selectedPage.sections.map(section =>
      section.id === selectedSection.id ? { ...section, content: data } : section
    );
    return this.pages().map(page =>
      page.id === selectedPage.id
        ? {
            ...selectedPage,
            sections: [...newSections]
          }
        : page
    );
  }
}
