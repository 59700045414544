import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { finalize } from 'rxjs';

import { CatalogProduct } from 'app/_shared/interfaces/catalog';
import { Collection } from 'app/_shared/interfaces/clientSelection';
import { ProductSelectionComponent } from 'app/_shared/product-selection/product-selection.component';
import { CompanyService } from 'app/_shared/services/company.service';
import { ToastService } from 'app/_shared/services/toast.service';

@Component({
  selector: 'app-collections-editor',
  standalone: true,
  imports: [ProductSelectionComponent, FormsModule, InputTextModule, Button],
  templateUrl: './collections-editor.component.html',
  styleUrl: './collections-editor.component.scss'
})
export class CollectionsEditorComponent implements OnInit {
  toastService = inject(ToastService);
  companyService = inject(CompanyService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  name = '';
  companyId: string;
  collectionId: string;
  isLoading: boolean;
  selectedItems: number[];
  collection: Collection;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.id;
      this.collectionId = params.collectionId;

      if (this.collectionId) {
        this.companyService
          .getCollection(this.companyId, this.collectionId, {
            'relations[]': ['catalogProducts.featuredImage']
          })
          .subscribe(next => {
            this.collection = next.data;
            this.name = next.data.title;
          });
      }
    });
  }

  handleSelectionSave($event: CatalogProduct[]) {
    this.selectedItems = $event.map(item => item.id);
  }

  saveCollection() {
    this.isLoading = true;
    if (this.collectionId) {
      this.companyService
        .updateCollection(this.companyId, this.collectionId, {
          title: this.name,
          catalog_product_ids: this.selectedItems
        })
        .subscribe(next => {
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
          this.toastService.success('Collection saved successfully.');
        });
    } else {
      this.companyService
        .createCollection(this.companyId, { title: this.name, catalog_product_ids: this.selectedItems })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: data => {
            this.toastService.success('Collection saved successfully.');
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
          }
        });
    }
  }
}
