import { inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { StorefrontProductCategoryService } from '../services/storefront-product-category.service';
import { StorefrontService } from '../services/storefront.service';

@Injectable({
  providedIn: 'root'
})
export class SlugHelper {
  private storefrontService = inject(StorefrontService);
  private categoryService = inject(StorefrontProductCategoryService);

  public generateBaseSlug(textToFormat: string): string {
    const lowerCaseName = textToFormat.toLowerCase();
    return lowerCaseName
      .trim()
      .replace(/[\s]+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove special characters
      .replace(/\-\-+/g, '-') // Replace multiple - with a single one
      .replace(/^-+|-+$/g, ''); // Trim - from start and end
  }

  public generateUniqueProductSlug(storefrontId: number, textToFormat: string): Observable<string> {
    const baseSlug = this.generateBaseSlug(textToFormat);
    let counter = 1;

    const checkSlugUniqueness = (slug: string): Observable<string> => {
      return this.storefrontService.checkSlug(storefrontId, slug).pipe(
        switchMap(res => {
          if (res.data) {
            const newSlug = `${baseSlug}-v${counter++}`;
            return checkSlugUniqueness(newSlug);
          } else {
            return of(slug);
          }
        })
      );
    };

    return checkSlugUniqueness(baseSlug);
  }

  public generateUniqueCategorySlug(storefrontId: number, textToFormat: string): Observable<string> {
    const baseSlug = this.generateBaseSlug(textToFormat);
    let counter = 1;

    const checkSlugUniqueness = (slug: string): Observable<string> => {
      return this.categoryService.checkSlug(storefrontId, slug).pipe(
        switchMap(res => {
          if (res.data) {
            const newSlug = `${baseSlug}-v${counter++}`;
            return checkSlugUniqueness(newSlug);
          } else {
            return of(slug);
          }
        })
      );
    };

    return checkSlugUniqueness(baseSlug);
  }
}
