@if (isLoading) {
  <div>
    <div class="p-fluid">
      <div class="flex gap-2 my-2">
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="" style="min-width: 200px">
          <p-skeleton height="2rem" />
        </div>
      </div>
      <div class="flex gap-2 my-2">
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="" style="min-width: 200px">
          <p-skeleton height="2rem" />
        </div>
      </div>
      <div class="flex gap-2 my-2">
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="flex-1">
          <p-skeleton height="2rem" />
        </div>
        <div class="" style="min-width: 200px">
          <p-skeleton height="2rem" />
        </div>
      </div>
    </div>
  </div>
}

@if (form) {
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="p-fluid" formGroupName="records">
      <div class="flex gap-2 my-2">
        <div class="flex-1">Supplier color</div>
        <div class="flex-1">Catalog mapped color</div>
        <div class="flex flex-row gap-2" style="min-width: 200px"></div>
      </div>
      @for (record of records.controls; track record.value; let i = $index) {
        <div class="flex gap-2 my-2" [formArrayName]="i">
          <div class="flex-1">
            <!-- PrimeNG Input for Key -->
            <input pInputText formControlName="key" placeholder="Key" />
          </div>
          <div class="flex-1">
            <!-- PrimeNG Input for Value -->
            <input pInputText formControlName="value" placeholder="Value" />
          </div>

          <div class="flex flex-row gap-2" style="min-width: 200px">
            <!-- PrimeNG Buttons for Update and Delete -->
            <p-button label="Save" (click)="updateRecord(i)" class="p-button-success" />
            <p-button label="Delete" icon="pi pi-times" (click)="confirmDelete(i)" class="p-button-danger" />
          </div>
        </div>
      }
    </div>

    <div class="text-center mt-4">
      <p-button type="button" label="Add new" class="p-button-primary" (click)="addNewRow()" />
    </div>
  </form>
}
<p-confirmDialog />
