import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

import { FormGroupValue } from '../../models/FormGroup';
import { User } from '../../models/User';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-edit-user-dialog',
  standalone: true,
  imports: [ButtonModule, InputTextModule, DialogModule, CommonModule, ReactiveFormsModule],
  templateUrl: './edit-user-dialog.component.html',
  styles: ''
})
export class EditUserDialogComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() visible: boolean;
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() validFormEmitter: EventEmitter<FormGroupValue> = new EventEmitter<FormGroupValue>();

  userEditForm: FormGroup;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges() {
    if (this.visible && this.user) {
      this.buildForm();
    }
  }

  buildForm() {
    this.userEditForm = this.fb.group({
      first_name: [this.user?.first_name, [Validators.required]],
      last_name: [this.user?.last_name, [Validators.required]],
      phone: this.user?.phone,
      email: [this.user?.email, [Validators.required, Validators.email]]
    });
  }

  hideDialog() {
    this.cancelEdit.emit();
  }

  onSubmit() {
    if (this.userEditForm.invalid) {
      this.submitted = true;
      this.toastService.error('Please fill all required fields correctly!');
      return;
    }
    const formData = this.userEditForm.value;
    this.updateUser(formData);
    this.visible = false;
  }

  updateUser(formData: FormGroupValue) {
    this.validFormEmitter.emit(formData);
  }
}
